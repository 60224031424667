import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { DEFAULT_IMAGE } from 'config/restaurant.const';
import clsx from 'clsx';
import { IPackage } from '../types/package.types';
import SelectPackageBtn from './SelectPackageBtn';

interface IPackageCardVerticalProps {
   item: IPackage;
   addToCartText: string;
   handlePackageSelection: (packageId: string) => void;
   handlePackageView: (packageId: string) => void;
}

const PackageCardVertical: React.FC<IPackageCardVerticalProps> = ({
   item,
   handlePackageSelection,
   handlePackageView,
   addToCartText,
}) => {
   return (
      <>
         <Card
            className={clsx('product-card product-card-vertical', { 'with-add-text': addToCartText })}
            sx={{ justifyContent: 'space-between', alignItems: 'center', borderRadius: '1rem' }}
         >
            <Box className="product-image" sx={{ position: 'relative' }}>
               <CardMedia
                  component="img"
                  image={item?.image ? item.image : DEFAULT_IMAGE}
                  alt={item.name}
                  onClick={() => {
                     handlePackageView(item.id);
                  }}
                  sx={{ position: 'relative', zIndex: 1 }}
               />
            </Box>
            <Box className={clsx('product-detail', { 'with-add-text': addToCartText })}>
               <CardContent sx={{ flex: '1 0 auto', padding: '10px' }}>
                  <Typography
                     sx={{ fontWeight: 600, textAlign: 'center' }}
                     gutterBottom
                     variant="h5"
                     component="div"
                     className="card-content card-title"
                  >
                     {item.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" className="card-content">
                     {item.description}
                  </Typography>
               </CardContent>
            </Box>
            <div className={clsx('product-quick-add-fab', { 'product-quick-add-btn': addToCartText })}>
               <SelectPackageBtn {...{ addToCartText, item, handlePackageSelection }} />
            </div>
         </Card>
      </>
   );
};

export default PackageCardVertical;
