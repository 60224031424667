import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
   ICart,
   ICartProduct,
   ICartResponse,
   IChangeCartProductPayload,
   IOrderFrequencyPayload,
   IPostAddRemoveCartProductPayload,
   IPostCartProductPayload,
   ISubscribeAllResponse,
   TOrderRecurrence,
} from '../types/cart.types';
import {
   changeCartProduct,
   fetchUserCart,
   postAddRemoveCartProduct,
   postCartProduct,
   postSubscribeAll,
} from '../services/cart.api';
import { LoadStatus } from 'config/utils';
import { CART_DEFAULT, SUBSCRIBE_ALL_DEFAULT } from './default.const';
import { IProduct } from 'features/order/types/product.types';
import { setAccountMessages, setIsLoggedIn, setLoginMessages, setUser } from 'features/account/stores/account.slice';
import { IMessage } from 'features/account/types/account.types';
import { IDeliveryLocation } from 'features/checkout/types/checkout.types';
import { OrderType } from 'features/order/config/order.const';
import { setCompanyInfo } from 'features/company/stores/company.slice';
import { updateMPTFrequency, updateMPTSession } from 'utils/mpt-session.helper';
import {
   initialOrderState,
   setSelectedDeliverySlot,
   setSelectedDeliverySlotWithCart,
} from 'features/order/stores/order.slice';
import { initialPackageState, setPackageId } from 'features/package/stores/package.slice';
import { TrackGTagType } from 'features/order/types/event.types';
import { resetCartRuleError } from 'features/checkout/stores/checkout.slice';

const getMPTFrequency = () => {
   if (localStorage.hasOwnProperty('MPTSessionV2')) {
      const mptLocalStorage = JSON.parse(localStorage.getItem('MPTSessionV2') as string);
      return mptLocalStorage.MPTOrderFrequency;
   }

   return 'onetime';
};

export interface CartState {
   cart: ICart;
   status: LoadStatus;
   cartResponse: ICartResponse;
   fetchCartStatus: LoadStatus;
   fetchCartResponse: ICartResponse;
   postCartStatus: LoadStatus;
   postCartResponse: ICartResponse;
   postAddRemoveStatus: LoadStatus;
   changeCartLoadStatus: LoadStatus;
   postSubscribeAllStatus: LoadStatus;
   subscribeAllResponse: ISubscribeAllResponse;
   deliveryLocations: IDeliveryLocation[];
   orderFrequency: TOrderRecurrence;
   orderMethod: OrderType | null;
   selectedCartItemIdx: number;
   editCartProduct: ICartProduct | null;
}

export const initialState: CartState = {
   cart: CART_DEFAULT.cart,
   status: LoadStatus.idle,
   cartResponse: CART_DEFAULT,
   fetchCartStatus: LoadStatus.idle,
   fetchCartResponse: CART_DEFAULT,
   postCartStatus: LoadStatus.idle,
   postSubscribeAllStatus: LoadStatus.idle,
   postCartResponse: CART_DEFAULT,
   postAddRemoveStatus: LoadStatus.idle,
   changeCartLoadStatus: LoadStatus.idle,
   subscribeAllResponse: SUBSCRIBE_ALL_DEFAULT,
   deliveryLocations: [],
   orderFrequency: getMPTFrequency(),
   orderMethod: OrderType.homeDelivery,
   selectedCartItemIdx: -1,
   editCartProduct: null,
};

const filterByEmailModifierRule = (deliveryLocationsInput: IDeliveryLocation[], userEmail: string) => {
   return deliveryLocationsInput.filter((deliveryLocation) => {
      if (deliveryLocation.attributes && deliveryLocation.attributes.prohibitedRules) {
         const foundEmailRule = deliveryLocation.attributes.prohibitedRules.find(
            (prohibitedRule) => prohibitedRule.variable === 'userEmail',
         );
         if (foundEmailRule) {
            const emailList = JSON.parse(foundEmailRule.value);
            if (!emailList.includes(userEmail)) {
               return false;
            }
         }
      }

      return true;
   });
};

export const fetchUserCartAsync = createAsyncThunk('cart/fetchUserCart', async (input, { dispatch }) => {
   const result = await fetchUserCart();
   const updatedMptSession = updateMPTSession(result);
   if (!updatedMptSession.authToken) {
      dispatch(setIsLoggedIn(false));
   }

   const { companyInfo, messages, user } = result;

   user && dispatch(setUser(user));

   // We didn't get a user object in the response, but we sent an authToken and think we are still authenticated.
   // The server is now in a state that thinks we are not authenticated, so we will match the UI to that.
   if (!user && updatedMptSession.authToken) {
      updateMPTSession(null);
      dispatch(setSelectedDeliverySlot(initialOrderState.selectedDeliverySlot));
      dispatch(setPackageId(initialPackageState.packageId));
      dispatch(setCart({ ...initialState.cart, init: false }));
      return { ...CART_DEFAULT, success: true };
   }

   dispatch(setCompanyInfo(companyInfo));
   dispatch(setAccountMessages(messages as IMessage[]));
   return result;
});

export const postCartProductAsync = createAsyncThunk(
   'delivery/postCartProduct',
   async (
      saveInput: { productPayLoad: IPostCartProductPayload; product?: IProduct; gtagEvent?: TrackGTagType },
      { dispatch },
   ) => {
      const result = await postCartProduct(saveInput.productPayLoad, saveInput.product, saveInput.gtagEvent);
      if (result?.success) {
         dispatch(setCart(result.cart));
         dispatch(setSelectedDeliverySlotWithCart(result.cart));
         dispatch(setAccountMessages(result.messages as IMessage[]));
         dispatch(setLoginMessages([]));
         dispatch(resetCartRuleError());
      }
      return result;
   },
);

export const postAddRemoveCartProductAsync = createAsyncThunk(
   'delivery/postAddRemoveCartProduct',
   async (productPayLoad: IPostAddRemoveCartProductPayload, { dispatch }) => {
      const result = await postAddRemoveCartProduct(productPayLoad);
      if (result?.success) {
         dispatch(setCart(result.cart));
         dispatch(setSelectedDeliverySlotWithCart(result.cart));
         dispatch(setAccountMessages(result.messages as IMessage[]));
         dispatch(setLoginMessages([]));
         dispatch(resetCartRuleError());
      }
      return result;
   },
);

export const changeCartProductAsync = createAsyncThunk(
   'delivery/changeCartProduct',
   async (productPayLoad: IChangeCartProductPayload, { dispatch }) => {
      const result = await changeCartProduct(productPayLoad);
      if (result?.success) {
         dispatch(setCart(result.cart));
         dispatch(setSelectedDeliverySlotWithCart(result.cart));
         dispatch(setAccountMessages(result.messages as IMessage[]));
         dispatch(setLoginMessages([]));
         dispatch(resetCartRuleError());
      }
      return result;
   },
);

export const postSubscribeAllAsync = createAsyncThunk('cart/postSubscribeall', async (_input: {}, { dispatch }) => {
   const result = await postSubscribeAll();
   if (result?.success) {
      dispatch(setCart(result.cart));
      dispatch(setSelectedDeliverySlotWithCart(result.cart));
   }

   return result;
});

export const cartSlice = createSlice({
   name: 'cart',
   initialState,
   reducers: {
      setSelectedCartItemIdx: (state, action: PayloadAction<number>) => {
         state.selectedCartItemIdx = action.payload;
      },
      setCart: (state, action: PayloadAction<ICart>) => {
         state.cart = action.payload;

         if (action.payload.deliveries.length) {
            state.orderMethod = action.payload.deliveries[0].shippingLocationType;
         }

         if (action.payload.recurrence) {
            state.orderFrequency = action.payload.recurrence;
         }
      },
      setOrderFrequency: (state, action: PayloadAction<IOrderFrequencyPayload>) => {
         state.orderFrequency = action.payload.orderRecurrence;
         if (!action.payload.dontUpdateSessionStorage) {
            updateMPTFrequency(action.payload.orderRecurrence);
         }
      },
      resetFetchCart: (state) => {
         state.fetchCartResponse = CART_DEFAULT;
         state.fetchCartStatus = LoadStatus.idle;
      },
      resetChangeCartLoadStatus: (state) => {
         state.changeCartLoadStatus = LoadStatus.idle;
      },
      resetPostAddRemoveStatus: (state) => {
         state.postAddRemoveStatus = LoadStatus.idle;
      },
      setEditCartProduct: (state, action: PayloadAction<ICartProduct | null>) => {
         state.editCartProduct = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchUserCartAsync.pending, (state) => {
            state.fetchCartStatus = LoadStatus.loading;
         })
         .addCase(fetchUserCartAsync.fulfilled, (state, action) => {
            state.fetchCartStatus = LoadStatus.complete;
            state.fetchCartResponse = action.payload;

            if (action.payload?.cart) {
               state.cart = action.payload.cart;

               if (action.payload.cart.deliveries.length) {
                  state.orderMethod = action.payload.cart.deliveries[0].shippingLocationType;
               }

               if (action.payload.deliveryLocations) {
                  state.deliveryLocations = filterByEmailModifierRule(
                     action.payload.deliveryLocations,
                     action.payload.user?.email,
                  );
               }
            }
         })
         .addCase(fetchUserCartAsync.rejected, (state) => {
            state.fetchCartStatus = LoadStatus.failed;
         })
         .addCase(postCartProductAsync.pending, (state) => {
            state.postCartStatus = LoadStatus.loading;
         })
         .addCase(postCartProductAsync.fulfilled, (state, action) => {
            state.postCartStatus = LoadStatus.complete;
            state.postCartResponse = action.payload;
            state.cart = state.postCartResponse.cart;
         })
         .addCase(postCartProductAsync.rejected, (state) => {
            state.postCartStatus = LoadStatus.failed;
         })
         .addCase(postAddRemoveCartProductAsync.pending, (state) => {
            state.postAddRemoveStatus = LoadStatus.loading;
         })
         .addCase(postAddRemoveCartProductAsync.fulfilled, (state, action) => {
            state.postAddRemoveStatus = LoadStatus.complete;
            state.cartResponse = action.payload;
            state.cart = state.cartResponse.cart;
         })
         .addCase(postAddRemoveCartProductAsync.rejected, (state) => {
            state.postAddRemoveStatus = LoadStatus.failed;
         })
         .addCase(postSubscribeAllAsync.pending, (state) => {
            state.postSubscribeAllStatus = LoadStatus.loading;
         })
         .addCase(postSubscribeAllAsync.fulfilled, (state, action) => {
            state.postSubscribeAllStatus = LoadStatus.complete;
            state.subscribeAllResponse = action.payload;
            state.cart = state.subscribeAllResponse.cart;
         })
         .addCase(postSubscribeAllAsync.rejected, (state) => {
            state.postSubscribeAllStatus = LoadStatus.failed;
         })
         .addCase(changeCartProductAsync.pending, (state) => {
            state.changeCartLoadStatus = LoadStatus.loading;
         })
         .addCase(changeCartProductAsync.fulfilled, (state, action) => {
            state.changeCartLoadStatus = LoadStatus.complete;
            state.cartResponse = action.payload;
            state.cart = state.cartResponse.cart;
         })
         .addCase(changeCartProductAsync.rejected, (state) => {
            state.changeCartLoadStatus = LoadStatus.failed;
         });
   },
});

export const {
   setSelectedCartItemIdx,
   setCart,
   setOrderFrequency,
   resetFetchCart,
   resetChangeCartLoadStatus,
   resetPostAddRemoveStatus,
   setEditCartProduct,
} = cartSlice.actions;

export const cartStore = (state: RootState) => state.cartStore;

export default cartSlice.reducer;
