export const MENU_ITEM_DEFAULT_VALUES = {
   id: '',
   base_price: 0,
   qty: 1,
   variations: [],
   total_price: 0,
};

export const formatter = new Intl.NumberFormat('en-US', {
   style: 'currency',
   currency: 'USD',
   minimumFractionDigits: 2,
});

export const NUM_VIEW_ITEMS = 6;
