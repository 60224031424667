export const COUNTRIES = [
   { value: 'US', name: 'United States', country: 'USD' },
   { value: 'CA', name: 'Canada', country: 'CAD' },
];

export const US_AND_CANADA_REGIONS = [
   { value: 'AL', name: 'Alabama', country: 'USD' },
   { value: 'AK', name: 'Alaska', country: 'USD' },
   { value: 'AZ', name: 'Arizona', country: 'USD' },
   { value: 'AR', name: 'Arkansas', country: 'USD' },
   { value: 'CA', name: 'California', country: 'USD' },
   { value: 'CO', name: 'Colorado', country: 'USD' },
   { value: 'CT', name: 'Connecticut', country: 'USD' },
   { value: 'DE', name: 'Delaware', country: 'USD' },
   { value: 'DC', name: 'District Of Columbia', country: 'USD' },
   { value: 'FL', name: 'Florida', country: 'USD' },
   { value: 'GA', name: 'Georgia', country: 'USD' },
   { value: 'HI', name: 'Hawaii', country: 'USD' },
   { value: 'ID', name: 'Idaho', country: 'USD' },
   { value: 'IL', name: 'Illinois', country: 'USD' },
   { value: 'IN', name: 'Indiana', country: 'USD' },
   { value: 'IA', name: 'Iowa', country: 'USD' },
   { value: 'KS', name: 'Kansas', country: 'USD' },
   { value: 'KY', name: 'Kentucky', country: 'USD' },
   { value: 'LA', name: 'Louisiana', country: 'USD' },
   { value: 'ME', name: 'Maine', country: 'USD' },
   { value: 'MD', name: 'Maryland', country: 'USD' },
   { value: 'MA', name: 'Massachusetts', country: 'USD' },
   { value: 'MI', name: 'Michigan', country: 'USD' },
   { value: 'MN', name: 'Minnesota', country: 'USD' },
   { value: 'MS', name: 'Mississippi', country: 'USD' },
   { value: 'MO', name: 'Missouri', country: 'USD' },
   { value: 'MT', name: 'Montana', country: 'USD' },
   { value: 'NE', name: 'Nebraska', country: 'USD' },
   { value: 'NV', name: 'Nevada', country: 'USD' },
   { value: 'NH', name: 'New Hampshire', country: 'USD' },
   { value: 'NJ', name: 'New Jersey', country: 'USD' },
   { value: 'NM', name: 'New Mexico', country: 'USD' },
   { value: 'NY', name: 'New York', country: 'USD' },
   { value: 'NC', name: 'North Carolina', country: 'USD' },
   { value: 'ND', name: 'North Dakota', country: 'USD' },
   { value: 'OH', name: 'Ohio', country: 'USD' },
   { value: 'OK', name: 'Oklahoma', country: 'USD' },
   { value: 'OR', name: 'Oregon', country: 'USD' },
   { value: 'PA', name: 'Pennsylvania', country: 'USD' },
   { value: 'RI', name: 'Rhode Island', country: 'USD' },
   { value: 'SC', name: 'South Carolina', country: 'USD' },
   { value: 'SD', name: 'South Dakota', country: 'USD' },
   { value: 'TN', name: 'Tennessee', country: 'USD' },
   { value: 'TX', name: 'Texas', country: 'USD' },
   { value: 'UT', name: 'Utah', country: 'USD' },
   { value: 'VT', name: 'Vermont', country: 'USD' },
   { value: 'VA', name: 'Virginia', country: 'USD' },
   { value: 'WA', name: 'Washington', country: 'USD' },
   { value: 'WV', name: 'West Virginia', country: 'USD' },
   { value: 'WI', name: 'Wisconsin', country: 'USD' },
   { value: 'WY', name: 'Wyoming', country: 'USD' },

   { value: 'AB', name: 'Alberta', country: 'CAD' },
   { value: 'BC', name: 'British Columbia', country: 'CAD' },
   { value: 'MB', name: 'Manitoba', country: 'CAD' },
   { value: 'NB', name: 'New Brunswick', country: 'CAD' },
   { value: 'NL', name: 'Newfoundland and Labrador', country: 'CAD' },
   { value: 'NS', name: 'Nova Scotia', country: 'CAD' },
   { value: 'ON', name: 'Ontario', country: 'CAD' },
   { value: 'PE', name: 'Prince Edward Island', country: 'CAD' },
   { value: 'QC', name: 'Quebec', country: 'CAD' },
   { value: 'SK', name: 'Saskatchewan', country: 'CAD' },
   { value: 'NT', name: 'Northwest Territories', country: 'CAD' },
   { value: 'NU', name: 'Nunavut', country: 'CAD' },
   { value: 'YT', name: 'Yukon', country: 'CAD' },
];
