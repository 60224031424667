import React from 'react';
import { Box, Button, Fab, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { IPackage } from '../types/package.types';

interface ISelectPackageBtnProps {
   item: IPackage;
   handlePackageSelection: (productId: string) => void;
   addToCartText?: string;
}

const SelectPackageBtn = ({ item, addToCartText, handlePackageSelection }: ISelectPackageBtnProps) => {
   const handleToggle = () => {
      handlePackageSelection(item.id);
   };

   return (
      <>
         {addToCartText ? (
            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleToggle}>
               <Stack direction="row" spacing={1}>
                  <Box>{addToCartText}</Box>
               </Stack>
            </Button>
         ) : (
            <Fab className="add-cart-fab" color="primary" size="medium" onClick={handleToggle}>
               <CheckIcon />
            </Fab>
         )}
      </>
   );
};

export default SelectPackageBtn;
