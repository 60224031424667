import { ICheckoutResponse, ICheckoutSettingsResponse, IUpdateTipResponse } from '../types/checkout.types';
import { apiServerUrl } from 'config/server';
import { expandApiServerHeader, prepareTokenPayload, updateMPTSession } from 'utils/mpt-session.helper';
import { IDeliveryChangeResponse } from 'features/order/types/order.types';
import { ICartResponse } from 'features/cart/types/cart.types';

const fetchCheckoutUrl = '/cart/checkout';
const checkoutSettingsUrl = '/user/settings';
const updateShippingProfileUrl = '/delivery/locations';
const updateBillingProfileUrl = '/cart/billing';
const offerCodeUrl = '/cart/offer';
const deliveryTipUrl = '/delivery/tip';

const prodUrls = {
   fetchCheckout: `${apiServerUrl}${fetchCheckoutUrl}`,
   checkoutSettings: `${apiServerUrl}${checkoutSettingsUrl}`,
   updateShippingProfile: `${apiServerUrl}${updateShippingProfileUrl}`,
   updateBillingProfile: `${apiServerUrl}${updateBillingProfileUrl}`,
   offerCode: `${apiServerUrl}${offerCodeUrl}`,
   updateTip: `${apiServerUrl}${deliveryTipUrl}`,
};

export function fetchCheckout() {
   return new Promise<ICheckoutResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(prodUrls.fetchCheckout, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICheckoutResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postCheckout(sessionId: string, activeSubscriptionId?: string) {
   return new Promise<ICheckoutResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({ sessionId, activeSubscriptionId })),
      };

      fetch(prodUrls.fetchCheckout, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICheckoutResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function addCheckoutSettings(settingId: string, settingValues: string[]) {
   return new Promise<ICheckoutSettingsResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({ settingId, settingValues })),
      };

      fetch(prodUrls.checkoutSettings, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICheckoutSettingsResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function updateShippingProfile(shippingLocationId: string, updatePresets: boolean, shippingProfileId: string) {
   return new Promise<IDeliveryChangeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               shippingLocationId,
               shippingProfileId,
               updatePresets,
            }),
         ),
      };

      fetch(`${prodUrls.updateShippingProfile}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function updateBillingProfile(billingProfileId: string) {
   return new Promise<IDeliveryChangeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               billingProfileId,
            }),
         ),
      };

      fetch(`${prodUrls.updateBillingProfile}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function addOfferCode(code: string) {
   return new Promise<IDeliveryChangeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               code,
            }),
         ),
      };

      fetch(`${prodUrls.offerCode}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function deleteOfferCode(code: string) {
   return new Promise<IDeliveryChangeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'DELETE',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               code,
            }),
         ),
      };

      fetch(`${prodUrls.offerCode}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function updateTip(tip: number) {
   return new Promise<IUpdateTipResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               tip,
            }),
         ),
      };

      fetch(`${prodUrls.updateTip}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IUpdateTipResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
