import * as yup from 'yup';
import { SelectMenuItem } from 'components/FormSelect';

export const enum Page {
   SIGNUP = 'signUp',
   LOGIN = 'logIn',
   PASSWORDRESET = 'passwordReset',
}
export const PASSWORD_RESET_DEFAULT_VALUES = {
   email: '',
};

export const LOG_IN_DEFAULT_VALUES = {
   email: '',
   password: '',
};

export const SIGN_UP_DEFAULT_VALUES = {
   first_name: '',
   last_name: '',
   email: '',
   phone: '',
   password: '',
   password_confirmation: '',
};

export const NEW_PASSWORD_RESET_DEFAULT_VALUES = {
   password: '',
   password_confirmation: '',
};

export const LOG_IN_SCHEMA = yup.object({
   email: yup.string().email('Email format is not valid').required('Email is required'),
   password: yup.string().required('Password is required'),
});

export const phoneRegex = RegExp(/^1?\s*\(?([2-9][0-9]{2})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/);

export const SIGN_UP_SCHEMA = yup.object({
   first_name: yup.string().required('First name is required'),
   last_name: yup.string().required('Last name is required'),
   email: yup.string().email('Email format is not valid').required('Email is required'),
   phone: yup.string().matches(phoneRegex, 'Invalid phone number').required('Phone number is required'),
   password: yup
      .string()
      .min(8, 'Password is too short - should be 8 characters minimum.')
      .matches(/[0-9]/, 'Need at least one number')
      .matches(/[A-Z]/, 'Need at least one upper case letter')
      .matches(/[a-z]/, 'Need at least one lower case letter')
      .required('Password is required'),
   password_confirmation: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
});

export const PASSWORD_RESET_SCHEMA = yup.object({
   email: yup.string().email('Email format is not valid').required('Email is required'),
});

export const NEW_PASSWORD_RESET_SCHEMA = yup.object({
   password: yup
      .string()
      .min(8, 'Password is too short - should be 8 characters minimum.')
      .matches(/[0-9]/, 'Need at least one number')
      .matches(/[A-Z]/, 'Need at least one upper case letter')
      .matches(/[a-z]/, 'Need at least one lower case letter')
      .required('Password is required'),
   password_confirmation: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
});

export const HEAR_ABOUT_US: SelectMenuItem[] = [
   {
      name: '',
      value: '',
   },
   {
      name: 'Facebook',
      value: 'Facebook',
   },
   {
      name: 'Instagram',
      value: 'Instagram',
   },
   {
      name: 'X',
      value: 'X',
   },
   {
      name: 'Search engine (Google, Yahoo, etc.)',
      value: 'Search engine (Google, Yahoo, etc.)',
   },
   {
      name: 'Recommended by friend or colleague',
      value: 'Recommended by friend or colleague',
   },
   {
      name: 'Others',
      value: 'Others',
   },
];
