import React from 'react';
import { CircularProgress } from '@mui/material';

interface ICommonProgressLoaderProps {
   size?: number;
   thickness?: number;
   color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
}

const CommonProgressLoader = ({ size, thickness, color }: ICommonProgressLoaderProps) => {
   return (
      <CircularProgress
         size={size ? size : 24}
         thickness={thickness ? thickness : 8}
         color={color ? color : 'inherit'}
         sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: `-${size ? size / 2 : 12}px`,
            marginLeft: `-${size ? size / 2 : 12}px`,
         }}
      />
   );
};

export default CommonProgressLoader;
