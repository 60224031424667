import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IProduct, IProductCategory } from 'features/order/types/product.types';
import { LoadStatus } from 'config/utils';
import { fetchSelectorProductList, fetchShopTasks, fetchTasksCheck } from '../services/product.api';
import { IShopTasksResponse } from '../types/order.types';
import { SHOP_TASKS_RESPONSE_DEFAULT } from './default.const';
import { setAccountDialogOpen, setAccountMessages, setSeverityMessage } from 'features/account/stores/account.slice';
import { IMessage } from 'features/account/types/account.types';
import { setSystemError } from 'features/company/stores/company.slice';
import { getSortedProductImages } from 'features/menuItem/utils/menu-item.helper';
import { IPackage } from 'features/package/types/package.types';

export interface ProductState {
   status: LoadStatus;
   shopTasksStatus: LoadStatus;
   fetchSelectorProductListStatus: LoadStatus;
   shopTasksResponse: IShopTasksResponse;
   productList: IProduct[];
   productCategoryList: IProductCategory[];
   productDetail: IProduct | undefined;
   selectorProductList: IProduct[];
   quickAddPopperOpen: boolean;
   weeklyProducts: string[];
}

const initialState: ProductState = {
   status: LoadStatus.idle,
   shopTasksStatus: LoadStatus.idle,
   fetchSelectorProductListStatus: LoadStatus.idle,
   shopTasksResponse: SHOP_TASKS_RESPONSE_DEFAULT,
   productList: [],
   productCategoryList: [],
   productDetail: {
      id: '',
      name: '',
      sku: '',
      description: '',
      shippingExempt: false,
      taxable: false,
      categories: [],
      tags: [],
      variations: [],
      addons: [],
      images: [],
      isLeaseOrLoan: false,
      userChoice: false,
      price: 0,
   },
   quickAddPopperOpen: false,
   selectorProductList: [],
   weeklyProducts: [],
};

export const fetchShopTasksAsync = createAsyncThunk(
   'product/fetchShopTasks',
   async (
      input: {
         packages: IPackage[] | null;
         deliveryId?: string | null;
         packageId?: string | null;
         productIds?: string | null;
         filters?: string | null;
         activateToken?: string | null;
      },
      { dispatch },
   ) => {
      const result = await fetchShopTasks(
         input.deliveryId,
         input.packageId,
         input.productIds,
         input.filters,
         input.activateToken,
      );
      if (result.success) {
         let weeklyProductsToLoad: string[] = [];

         if (result.filters?.recurrenceTask && input.packages !== null && input.packages.length > 0) {
            const taskResult = await fetchTasksCheck(result.filters.recurrenceTask);

            if (taskResult.success && taskResult.taskResult?.length > 0) {
               const foundWeeklyResult = taskResult.taskResult.find((task) => task.name === 'weekly');
               if (foundWeeklyResult && foundWeeklyResult.productIds) {
                  weeklyProductsToLoad = foundWeeklyResult.productIds;
               }
            }
         }

         dispatch(setWeeklyProducts(weeklyProductsToLoad));
         dispatch(setAccountMessages(result.messages as IMessage[]));
         if (result.responseToken) {
            dispatch(setAccountDialogOpen(true));
            if (result.responseToken.token) {
               dispatch(
                  setSeverityMessage({
                     severity: 'success',
                     message: 'Your account has been activated',
                  }),
               );
            } else if (result.responseToken.error) {
               dispatch(
                  setSeverityMessage({
                     severity: 'error',
                     message: 'Could not activate your account',
                  }),
               );
            }
         }
      } else {
         dispatch(setSystemError(null));
      }
      return result;
   },
);

export const fetchSelectorProductListAsync = createAsyncThunk(
   'product/fetchSelectorProductList',
   async (_input: {}, { dispatch }) => {
      const result = await fetchSelectorProductList();
      if (!result.success) {
         dispatch(setSystemError(null));
      }
      return result;
   },
);

function parseProductCategoryList(shopTasks: IShopTasksResponse) {
   const productCategories: IProductCategory[] = [];
   if (shopTasks?.products) {
      shopTasks.products.forEach((product) => {
         if (product.categories) {
            product.categories.forEach((category) => {
               if (
                  !productCategories.find((productCategory) => {
                     return productCategory.id === category.id;
                  })
               ) {
                  productCategories.push(category);
               }
            });
         }
      });
   }
   return productCategories;
}

export const productSlice = createSlice({
   name: 'product',
   initialState,
   reducers: {
      setSelectedProduct: (state, action: PayloadAction<IProduct | undefined>) => {
         state.productDetail = action.payload;
      },
      resetProductDetail: (state) => {
         state.productDetail = initialState.productDetail;
      },
      resetProductList: (state) => {
         state.productList = initialState.productList;
      },
      setQuickAddPopperOpen: (state, action: PayloadAction<boolean>) => {
         state.quickAddPopperOpen = action.payload;
      },
      setWeeklyProducts: (state, action: PayloadAction<string[]>) => {
         state.weeklyProducts = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchShopTasksAsync.pending, (state) => {
            state.shopTasksStatus = LoadStatus.loading;
         })
         .addCase(fetchShopTasksAsync.fulfilled, (state, action) => {
            state.shopTasksStatus = LoadStatus.complete;
            state.shopTasksResponse = action.payload;
            state.productCategoryList = parseProductCategoryList(state.shopTasksResponse);
            state.productList = action.payload.products.map((product) => {
               return {
                  ...product,
                  images: product?.images ? getSortedProductImages(product.images) : product.images,
               };
            });
         })
         .addCase(fetchShopTasksAsync.rejected, (state) => {
            state.shopTasksStatus = LoadStatus.failed;
         })
         .addCase(fetchSelectorProductListAsync.pending, (state) => {
            state.fetchSelectorProductListStatus = LoadStatus.loading;
         })
         .addCase(fetchSelectorProductListAsync.fulfilled, (state, action) => {
            state.fetchSelectorProductListStatus = LoadStatus.complete;
            state.selectorProductList = [...action.payload.products];
         })
         .addCase(fetchSelectorProductListAsync.rejected, (state) => {
            state.fetchSelectorProductListStatus = LoadStatus.failed;
         });
   },
});

export const { setSelectedProduct, resetProductDetail, resetProductList, setQuickAddPopperOpen, setWeeklyProducts } =
   productSlice.actions;

export const productStore = (state: RootState) => state.productStore;

export default productSlice.reducer;
