import { apiServerUrl } from 'config/server';
import {
   ICartResponse,
   IPostCartProductPayload,
   IPostAddRemoveCartProductPayload,
   IChangeCartProductPayload,
   ISubscribeAllResponse,
} from '../types/cart.types';
import { expandApiServerHeader, prepareTokenPayload, updateMPTSession } from 'utils/mpt-session.helper';
import { IProduct } from 'features/order/types/product.types';
import {
   convertAddToCartEventData,
   convertAddToCartPixelEventData,
   trackGaEvent,
   trackMptEvent,
   trackPixelEvent,
} from 'features/order/utils/event.helper';
import { TrackEventType, TrackGTagType, TrackPixelType } from 'features/order/types/event.types';

const userCartDataUrl = `/cart`;
const subscribeAllUrl = `/cart/subscribeall`;
const addCartProductUrl = '/delivery/products';
const changeCartProductUrl = '/delivery/products';

const prodUrls = {
   fetchUserCart: `${apiServerUrl}${userCartDataUrl}`,
   postCartProduct: `${apiServerUrl}${addCartProductUrl}`,
   postSubscribeAll: `${apiServerUrl}${subscribeAllUrl}`,
   changeCartProduct: `${apiServerUrl}${changeCartProductUrl}`,
};

export function fetchUserCart() {
   return new Promise<ICartResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(prodUrls.fetchUserCart, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postCartProduct(
   productPayLoad: IPostCartProductPayload,
   product?: IProduct,
   gtagEvent?: TrackGTagType,
) {
   return new Promise<ICartResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(productPayLoad)),
      };

      fetch(prodUrls.postCartProduct, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            product &&
               trackMptEvent(TrackEventType.addToCart, convertAddToCartEventData(productPayLoad, product, resultData));
            product && trackPixelEvent(TrackPixelType.addToCart, convertAddToCartPixelEventData(product));
            product && gtagEvent && trackGaEvent(gtagEvent);
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function changeCartProduct(productPayLoad: IChangeCartProductPayload) {
   return new Promise<ICartResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(productPayLoad)),
      };

      fetch(prodUrls.changeCartProduct, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postAddRemoveCartProduct(productPayLoad: IPostAddRemoveCartProductPayload) {
   return new Promise<ICartResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(productPayLoad)),
      };

      fetch(prodUrls.postCartProduct, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postSubscribeAll() {
   return new Promise<ISubscribeAllResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(prodUrls.postSubscribeAll, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ISubscribeAllResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
