import { styled } from '@mui/material/styles';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';

interface StyledToggleButtonGroupProps extends ToggleButtonGroupProps {
   useDefault?: boolean;
   hasError?: boolean;
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
   shouldForwardProp: (prop) => prop !== 'useDefault', // Prevent `usePrimary` from being forwarded to the DOM
})<StyledToggleButtonGroupProps>(({ theme, useDefault, hasError }) => ({
   '& .MuiToggleButton-root': {
      borderColor: hasError ? theme.palette.error.main : theme.palette.action,
      '&.Mui-selected': {
         border: '1px solid',
         borderColor: useDefault ? 'rgba(0, 0, 0, 0.87)' : theme.palette.primary.main,
      },
   },
}));
