import React from 'react';
import 'features/account/styles/Account.scss';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { accountDeleteShippingAsync, accountStore } from 'features/account/stores/account.slice';
import { LoadStatus } from 'config/utils';
import {
   checkoutShippingStore,
   setDeleteAddressDialogOpen,
   setDeliveryDialogOpen,
} from '../../stores/checkout-shipping.slice';

const DeleteDeliveryAddressDialog = () => {
   const dispatch = useAppDispatch();
   const { deleteAddressDialogOpen, addressToDelete } = useAppSelector(checkoutShippingStore);
   const { deleteShippingStatus } = useAppSelector(accountStore);

   const onAddressDeleteClose = () => {
      dispatch(setDeleteAddressDialogOpen(false));
      dispatch(setDeliveryDialogOpen(true));
   };

   const handleAddressDelete = () => {
      addressToDelete && dispatch(accountDeleteShippingAsync({ deleteAddressId: addressToDelete.id }));
   };

   return (
      <ConfirmationDialog
         open={deleteAddressDialogOpen}
         onClose={onAddressDeleteClose}
         confirmContent={
            <>
               {addressToDelete ? (
                  <Box>
                     <span className="shipping-address-name">
                        {addressToDelete.firstName}, {addressToDelete.lastName}
                     </span>
                     <br />
                     {addressToDelete.address}
                     {addressToDelete.address2 ? (
                        <>
                           <br />
                           {addressToDelete.address2}
                        </>
                     ) : null}
                     <br />
                     {addressToDelete.city}, {addressToDelete.region}, {addressToDelete.postal}
                  </Box>
               ) : null}
            </>
         }
         title={'Delete Delivery Address?'}
         confirmBtnText={'Delete'}
         cancelBtnText={'Cancel'}
         isLoading={deleteShippingStatus === LoadStatus.loading}
         onConfirm={() => handleAddressDelete()}
         onCancel={() => onAddressDeleteClose()}
      />
   );
};

export default DeleteDeliveryAddressDialog;
