import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'features/account/styles/Account.scss';
import CommonDialog from 'components/CommonDialog';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadStatus } from 'config/utils';
import {
   accountStore,
   accountUpdateShippingAsync,
   resetUpdateShippingAddressStatus,
} from 'features/account/stores/account.slice';
import EditAddressDialogContent from 'features/profile/components/EditAddressDialogContent';
import { ADD_DELIVERY_DEFAULT_VALUES, ADD_DELIVERY_SCHEMA } from '../../checkout/config/checkout-form.const';
import { IShippingProfileForm } from '../../checkout/types/checkout.types';
import { ISubmitShippingPayload } from 'features/account/types/account.types';
import { checkoutShippingStore, setOverrideWithDefault } from '../../checkout/stores/checkout-shipping.slice';
import { Typography } from '@mui/material';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

interface IEditAddressDialogProps {
   open: boolean;
   editAddressId: string;
   setEditAddressDialogOpen: (open: boolean) => void;
}

const EditAddressDialog: React.FC<IEditAddressDialogProps> = ({ open, editAddressId, setEditAddressDialogOpen }) => {
   const dispatch = useAppDispatch();
   const { updateShippingProfileStatus } = useAppSelector(checkoutShippingStore);
   const { updateShippingAddressStatus } = useAppSelector(accountStore);

   const [confirmationOpen, setConfirmationOpen] = React.useState(false);
   const [dataChanged, setDataChanged] = React.useState(false);

   const editAddressMethods = useForm<IShippingProfileForm>({
      defaultValues: ADD_DELIVERY_DEFAULT_VALUES,
      resolver: yupResolver(ADD_DELIVERY_SCHEMA),
   });

   const isLoading =
      updateShippingProfileStatus === LoadStatus.loading || updateShippingAddressStatus === LoadStatus.loading;

   const onUpdateShippingAddressStatus = () => {
      if (updateShippingAddressStatus === LoadStatus.complete) {
         dispatch(resetUpdateShippingAddressStatus());
         handleCancelEdit();
         setEditAddressDialogOpen(false);
      }
   };

   const onEditSubmit = (data: IShippingProfileForm) => {
      const { address, address2, city, firstName, lastName, instructions, phone, postal, region, country } =
         data as ISubmitShippingPayload;

      dispatch(
         accountUpdateShippingAsync({
            bodyInput: {
               address,
               address2,
               city,
               country,
               firstName,
               lastName,
               instructions,
               phone,
               postal,
               region,
            },
            editAddressId,
         }),
      );
   };

   const handleClose = () => {
      editAddressMethods.reset();
      dispatch(setOverrideWithDefault(false));
      setEditAddressDialogOpen(false);
   };

   const handleCancelEdit = () => {
      dataChanged ? setConfirmationOpen(true) : handleClose();
   };

   useEffect(() => {
      onUpdateShippingAddressStatus();
   }, [updateShippingAddressStatus]);

   useEffect(() => {
      if (editAddressMethods.formState.isDirty) {
         setDataChanged(true);
      }
   }, [editAddressMethods.formState.isDirty]);

   useEffect(() => {
      changeCustomModalOpen(confirmationOpen);
   }, [confirmationOpen]);

   return (
      <>
         <CommonDialog
            open={open}
            title={'Edit Delivery Address'}
            onClose={handleCancelEdit}
            onSubmit={editAddressMethods.handleSubmit(onEditSubmit)}
            isLoading={isLoading}
            submitLabel={'Save'}
            onCancel={handleCancelEdit}
         >
            <FormProvider {...editAddressMethods}>
               <form onSubmit={editAddressMethods.handleSubmit(onEditSubmit)}>
                  <EditAddressDialogContent {...{ editAddressId }} />
               </form>
            </FormProvider>
         </CommonDialog>
         <ConfirmationDialog
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            confirmContent={
               <Typography variant={'h5'}>
                  Are you sure you want to cancel this edit? All changes will be lost.
               </Typography>
            }
            title={'Cancel Delivery Address Edit?'}
            confirmBtnText={'Confirm'}
            cancelBtnText={'Cancel'}
            onConfirm={handleClose}
            onCancel={() => setConfirmationOpen(false)}
         />
      </>
   );
};

export default EditAddressDialog;
