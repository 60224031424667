import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Link, Route, RouterProvider } from 'react-router-dom';
import OrderPage from './pages/OrderPage';
import MainLayout from './pages/MainLayout';
import CheckoutPage from './pages/CheckoutPage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import 'styles/override-old.scss';
import ProtectedRoute from './components/ProtectedRoute';
import ConfirmationPage from './pages/ConfirmationPage';
import PackagePage from './pages/PackagePage';
import AccountPage from './pages/AccountPage';

export default function App() {
   const router = createBrowserRouter(
      createRoutesFromElements(
         <Route path="/" element={<MainLayout />}>
            <Route index element={<OrderPage />} />
            <Route path="order" element={<OrderPage />} />
            <Route
               path="checkout"
               element={
                  <ProtectedRoute>
                     <CheckoutPage />
                  </ProtectedRoute>
               }
            />
            <Route
               path="user"
               element={
                  <ProtectedRoute>
                     <AccountPage />
                  </ProtectedRoute>
               }
            />
            <Route path="subscriptions" element={<SubscriptionsPage />} />
            <Route
               path="confirmation"
               element={
                  <ProtectedRoute>
                     <ConfirmationPage />
                  </ProtectedRoute>
               }
            />
            <Route path="*" element={<NoMatch />} />
            <Route path="shop" element={<PackagePage />} />
         </Route>,
      ),
   );

   return <RouterProvider router={router} />;
}

function NoMatch() {
   return (
      <div>
         <h2>Page not found!</h2>
         <p>
            <Link to="/order">Go to the order page</Link>
         </p>
      </div>
   );
}
