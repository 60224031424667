import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'features/account/styles/Account.scss';
import CommonDialog from 'components/CommonDialog';
import { Box } from '@mui/material';
import { IShippingProfileForm } from '../../types/checkout.types';
import { ADD_DELIVERY_DEFAULT_VALUES, ADD_DELIVERY_SCHEMA } from '../../config/checkout-form.const';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Grid from '@mui/material/Grid';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { companyStore } from 'features/company/stores/company.slice';
import { US_AND_CANADA_REGIONS } from 'config/regions.const';
import LoadingOverlay from 'components/LoadingOverlay';
import { accountAddShippingAsync, accountStore } from 'features/account/stores/account.slice';
import { ISubmitShippingPayload } from 'features/account/types/account.types';
import { LoadStatus } from 'config/utils';
import { checkoutShippingStore, setAddDeliveryDialogOpen } from '../../stores/checkout-shipping.slice';

interface IDeliveryDialogProps {
   onCancel?: () => void;
}

const AddDeliveryDialog: React.FC<IDeliveryDialogProps> = ({ onCancel }) => {
   const dispatch = useAppDispatch();
   const { companyInfo } = useAppSelector(companyStore);
   const { addShippingStatus } = useAppSelector(accountStore);
   const { addDeliveryDialogOpen } = useAppSelector(checkoutShippingStore);
   const country = companyInfo.currency === 'CAD' ? 'CA' : 'US';

   const regionList = US_AND_CANADA_REGIONS.filter((filterRegion) => filterRegion.country === companyInfo.currency).map(
      (region) => {
         return {
            name: region.name,
            value: region.value,
         };
      },
   );

   const methods = useForm<IShippingProfileForm>({
      defaultValues: ADD_DELIVERY_DEFAULT_VALUES,
      resolver: yupResolver(ADD_DELIVERY_SCHEMA),
   });

   function handleClose() {
      if (addDeliveryDialogOpen) {
         dispatch(setAddDeliveryDialogOpen(false));
      }

      methods.reset();
   }

   const onSubmit = (data: any) => {
      const { address, address2, city, firstName, lastName, instructions, phone, postal, region } =
         data as ISubmitShippingPayload;

      dispatch(
         accountAddShippingAsync({
            bodyInput: {
               address,
               address2,
               city,
               country,
               firstName,
               lastName,
               instructions,
               phone,
               postal,
               region,
            },
         }),
      );
   };

   return (
      <CommonDialog
         open={addDeliveryDialogOpen}
         onClose={handleClose}
         onCancel={onCancel}
         title="Add Delivery Address"
         onSubmit={methods.handleSubmit(onSubmit)}
         isLoading={addShippingStatus === LoadStatus.loading}
         submitLabel="Add"
      >
         <LoadingOverlay loading={addShippingStatus === LoadStatus.loading} message="Loading, please wait...">
            <div className="field-container">
               <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                     <Box sx={{ p: 0, mt: 1, mb: 1 }}>
                        <Grid container spacing={2}>
                           <Grid item xs={12} sm={6}>
                              <FormInput name="firstName" label="First Name" autoComplete="given-name" />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <FormInput name="lastName" label="Last Name" autoComplete="family-name" />
                           </Grid>
                           <Grid item xs={12}>
                              <FormInput name="address" label="Address Line 1" autoComplete="shipping address-line1" />
                           </Grid>
                           <Grid item xs={12}>
                              <FormInput name="address2" label="Address Line 2" autoComplete="shipping address-line2" />
                           </Grid>
                           <Grid item xs={12}>
                              <FormInput name="city" label="City" autoComplete="shipping address-level2" />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <FormSelect
                                 name="region"
                                 label="State/Province/Region"
                                 autoComplete="shipping address-level1"
                                 menuItems={regionList}
                              />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <FormInput name="postal" label="Zip / Postal Code" autoComplete="shipping postal-code" />
                           </Grid>
                           <Grid item xs={12}>
                              <FormInput name="phone" label="Phone" type={'tel'} autoComplete="tel" />
                           </Grid>
                           <Grid item xs={12}>
                              <FormInput name="instructions" label="Delivery Instructions" multiline={true} />
                           </Grid>
                        </Grid>
                     </Box>
                  </form>
               </FormProvider>
            </div>
         </LoadingOverlay>
      </CommonDialog>
   );
};

export default AddDeliveryDialog;
