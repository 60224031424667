import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, DialogActions, DialogContent, Typography, MenuItem, Fab, Stack } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import '../styles/Subscriptions.scss';
import { getTotals } from '../utils/subscriptions.helper';
import dayjs from 'dayjs';
import { ISubscription } from '../types/subscriptions.types';
import SubscriptionFreeze from './SubscriptionFreeze';
import SubscriptionCancellation from './SubscriptionCancellation';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import {
   fetchSubscriptionCancelAsync,
   fetchSubscriptionFreezeAsync,
   fetchUserSubscriptionsAsync,
   postSubscriptionCancelAsync,
   postSubscriptionFreezeAsync,
   resetPostSubscriptionCancelStatus,
   resetPostSubscriptionFreezeStatus,
   setCancelDate,
   setEditFromDate,
   setEditToDate,
   subscriptionStore,
} from '../stores/subscriptions.slice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { LoadStatus } from '../../../config/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { Simulate } from 'react-dom/test-utils';
import change = Simulate.change;
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

const SubscriptionEdit = (props: {
   edit: 'freeze' | 'cancel' | null;
   setEdit: (edit: 'freeze' | 'cancel' | null) => void;
   subscription: ISubscription;
}) => {
   const localizedFormat = require('dayjs/plugin/localizedFormat');
   dayjs.extend(localizedFormat);

   const dispatch = useAppDispatch();
   const {
      postSubscriptionFreezeStatus,
      postSubscriptionCancelStatus,
      subscriptionFreezeOptions,
      subscriptionCancelOptions,
      editFromDate,
      editToDate,
      cancelDate,
   } = useAppSelector(subscriptionStore);
   const isLoading =
      postSubscriptionFreezeStatus === LoadStatus.loading || postSubscriptionCancelStatus === LoadStatus.loading;
   const freezeOptionIsSelected = editFromDate !== null && editToDate !== null;
   const cancelOptionIsSelected = cancelDate !== null;
   const [deliveryId, setDeliveryId] = useState<string>('');

   const handleDeliveryIdChange = (event: SelectChangeEvent<string>) => {
      setDeliveryId(event.target.value);
   };
   const getSubscriptionDeliveries = (sub: ISubscription) => {
      if (!sub?.deliveries[0]?.id) return;
      return (
         <Select
            value={deliveryId}
            defaultValue={sub.deliveries[0].id}
            onChange={handleDeliveryIdChange}
            sx={{ maxWidth: '100%' }}
         >
            {sub.deliveries.map((delivery) => {
               return (
                  <MenuItem key={delivery.id} value={delivery.id ?? ''}>
                     <Typography sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {`${delivery.deliveryDayName}, ${dayjs(delivery.nextDeliveryFrom).format('h:mm A')} - ${dayjs(delivery.nextDeliveryTo).format('h:mm A')} (${delivery.shippingLocationName})`}
                     </Typography>
                  </MenuItem>
               );
            })}
         </Select>
      );
   };

   const handleClose = () => {
      props.setEdit(null);
      dispatch(setEditFromDate(null));
      dispatch(setEditToDate(null));
      dispatch(setCancelDate(null));
      dispatch(resetPostSubscriptionFreezeStatus());
      dispatch(resetPostSubscriptionCancelStatus());
   };

   const handleEditSubmit = () => {
      if (props.edit === 'freeze') {
         dispatch(
            postSubscriptionFreezeAsync({
               subscriptionId: props.subscription.id,
               bodyInput: { fromDate: editFromDate, toDate: editToDate },
            }),
         );
      }
      if (props.edit === 'cancel') {
         dispatch(
            postSubscriptionCancelAsync({
               subscriptionId: props.subscription.id,
               bodyInput: { cancelDate: cancelDate, cancelText: null },
            }),
         );
      }
   };

   useEffect(() => {
      if (props.subscription?.deliveries[0]?.id) {
         setDeliveryId(props.subscription.deliveries[0].id);
      }
   }, [props.subscription?.deliveries]);

   useEffect(() => {
      if (props.subscription?.id) {
         if (props.edit === 'freeze') {
            dispatch(fetchSubscriptionFreezeAsync({ subscriptionId: props.subscription.id }));
         }
         if (props.edit === 'cancel') {
            dispatch(fetchSubscriptionCancelAsync({ subscriptionId: props.subscription.id }));
         }
      }
   }, [props.subscription, props.edit]);

   useEffect(() => {
      if (
         postSubscriptionFreezeStatus === LoadStatus.complete ||
         postSubscriptionCancelStatus === LoadStatus.complete
      ) {
         handleClose();
         dispatch(fetchUserSubscriptionsAsync({}));
      }
   }, [postSubscriptionFreezeStatus, postSubscriptionCancelStatus]);

   useEffect(() => {
      changeCustomModalOpen(props.edit === 'freeze' || props.edit === 'cancel');
   }, [props.edit]);

   return props.subscription ? (
      <Dialog
         className="menu-item-dialog"
         onClose={handleClose}
         open={props.edit === 'freeze' || props.edit === 'cancel'}
         maxWidth={'xl'}
         PaperProps={{ className: 'menu-item-style' }}
         disableScrollLock
      >
         <Box className={'menu-item-header'}>
            <DialogTitle className={'title'}>
               {props.edit === 'freeze'
                  ? 'Freeze Subscription'
                  : props.edit === 'cancel'
                    ? 'Cancel Subscription'
                    : null}
            </DialogTitle>
            <Fab size="small" color="primary" onClick={handleClose}>
               <CloseIcon />
            </Fab>
         </Box>
         <Box className={'subscription-summary'}>
            <DialogContent>
               <Box marginRight={'50px'} className="section-header" sx={{ flexDirection: 'column' }}>
                  <Typography variant={'h5'}>ORDER PLACED</Typography>
                  <Typography variant={'h6'}>
                     {dayjs(props.subscription.nextPaymentDate).format('MMM DD, YYYY')}
                  </Typography>
               </Box>
               <Box className={'section-content'}>
                  <Grid container>
                     <Grid xs={12} md={7} item>
                        <Typography variant={'h6'} className={'title'}>
                           Deliveries
                        </Typography>
                        <Box display="flex" alignItems="center" marginBottom={'30px'}>
                           {getSubscriptionDeliveries(props.subscription)}
                        </Box>
                     </Grid>
                     <Grid xs={12} md={5} item>
                        <Typography variant={'h6'} className={'title'}>
                           Total
                        </Typography>
                        {getTotals(props.subscription)}
                     </Grid>
                     <Grid xs={12} md={8} item marginTop={'30px'} marginBottom={'30px'}>
                        <Typography variant={'h6'} className={'title'}>
                           {props.edit === 'freeze'
                              ? 'Freeze Options'
                              : props.edit === 'cancel'
                                ? 'Cancellation Options'
                                : null}
                        </Typography>
                        {props.edit === 'freeze' ? (
                           <SubscriptionFreeze freezeOptions={subscriptionFreezeOptions} />
                        ) : null}
                        {props.edit === 'cancel' ? (
                           <SubscriptionCancellation cancellationOptions={subscriptionCancelOptions} />
                        ) : null}
                        <Box display={'flex'} height={'30px'} alignItems={'center'} width={'auto'} gap={1}></Box>
                     </Grid>
                  </Grid>
               </Box>
            </DialogContent>
         </Box>
         <DialogActions className="menu-item-dialog-footer">
            <Stack direction="row" spacing={2}>
               <Button
                  className="common-btn subscription-btn"
                  variant="contained"
                  startIcon={
                     isLoading ? null : props.edit === 'freeze' ? (
                        <AcUnitIcon />
                     ) : props.edit === 'cancel' ? (
                        <NotInterestedIcon />
                     ) : null
                  }
                  sx={{ whiteSpace: 'nowrap' }}
                  onClick={handleEditSubmit}
                  disabled={
                     isLoading ||
                     (props.edit === 'freeze' && !freezeOptionIsSelected) ||
                     (props.edit === 'cancel' && !cancelOptionIsSelected)
                  }
               >
                  {isLoading ? (
                     <CircularProgress size={14} thickness={8} color="inherit" />
                  ) : (
                     <Typography variant={'h6'} className={'title'}>
                        {props.edit === 'freeze'
                           ? 'Freeze Subscription'
                           : props.edit === 'cancel'
                             ? 'Cancel Subscription'
                             : null}
                     </Typography>
                  )}
               </Button>
            </Stack>
         </DialogActions>
      </Dialog>
   ) : null;
};

export default SubscriptionEdit;
