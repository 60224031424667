import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NutritionFacts from './NutritionFacts';
import { useAppSelector } from '../../../app/hooks';
import { productStore } from '../../order/stores/product.slice';

const ProductAccordion = () => {
   const [expanded, setExpanded] = useState<string | false>(false);

   const { productDetail } = useAppSelector(productStore);
   const attributes =
      productDetail?.attributes &&
      productDetail.attributes.length > 0 &&
      productDetail.attributes[0] &&
      productDetail.attributes[0].data;

   const nutritionLabel = attributes ? attributes.nutritionLabel : null;
   const ingredients = attributes ? attributes.ingredients : null;
   const allergens = attributes ? attributes.allergens : null;

   const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
   };

   return (
      <div className="order-accordion-content">
         {nutritionLabel ? (
            <Accordion expanded={expanded === 'panelNutritionLabel'} onChange={handleChange('panelNutritionLabel')}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-nutrition-facts"
                  sx={{ fontWeight: 'bold' }}
               >
                  Nutrition Facts
               </AccordionSummary>
               <AccordionDetails>
                  <NutritionFacts />
               </AccordionDetails>
            </Accordion>
         ) : null}
         {ingredients ? (
            <Accordion expanded={expanded === 'panelIngredients'} onChange={handleChange('panelIngredients')}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-ingredients"
                  sx={{ fontWeight: 'bold' }}
               >
                  Ingredients
               </AccordionSummary>
               <AccordionDetails>{ingredients}</AccordionDetails>
            </Accordion>
         ) : null}
         {allergens ? (
            <Accordion expanded={expanded === 'panelAllergens'} onChange={handleChange('panelAllergens')}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-allergens"
                  sx={{ fontWeight: 'bold' }}
               >
                  Allergens
               </AccordionSummary>
               <AccordionDetails>{allergens}</AccordionDetails>
            </Accordion>
         ) : null}
      </div>
   );
};

export default ProductAccordion;
