import { styled } from '@mui/material';

export const CardDescriptionContainer = styled('div')(({ theme }) => ({
   fontFamily: theme.typography.fontFamily,
   '& h1, & h2, & h3, & h4, & h5, & h6, & h7, & p, & strong': {
      fontFamily: theme.typography.fontFamily,
      marginTop: 0,
      marginBottom: 0,
      fontSize: '0.875rem',
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary,
      lineHeight: '1.3rem',
   },
   fontSize: '0.875rem',
   lineHeight: '1.3rem',
   marginTop: 0,
   marginBottom: 0,
   color: theme.palette.text.secondary,
}));
