import { Typography } from '@mui/material';
import React from 'react';

export const getTotals = (sub: any) => {
   if (sub) {
      const subscriptionTotals = [
         { name: 'Shipping', amount: sub.shipping },
         { name: 'Subtotal', amount: sub.subtotal },
         { name: 'Tax', amount: sub.tax },
         { name: 'Total', amount: sub.total },
      ];
      return subscriptionTotals.map((total) => {
         return (
            <div key={total.name + total.amount} className="subscription-totals">
               <Typography fontWeight={'bold'} marginRight={'auto'}>
                  {total.name}
               </Typography>
               <Typography fontWeight={'bold'}>
                  {' '}
                  {total.amount.toLocaleString('en-US', {
                     style: 'currency',
                     currency: 'USD',
                  })}
               </Typography>
            </div>
         );
      });
   }
   return null;
};
