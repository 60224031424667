import React, { useEffect, useState } from 'react';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   CircularProgress,
   Box,
   Stack,
   Fab,
   useTheme,
   useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ConfirmationDialogProps {
   open: boolean;
   onClose: () => void;
   title?: string;
   confirmContent: React.ReactNode;
   confirmBtnText: string;
   cancelBtnText: string;
   isLoading?: boolean;
   onConfirm: () => void;
   onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
   open,
   onClose,
   title,
   confirmContent,
   confirmBtnText,
   cancelBtnText,
   isLoading,
   onConfirm,
   onCancel,
}) => {
   const [cancelSubmitted, setCancelSubmitted] = useState(false);
   const [confirmSubmitted, setConfirmSubmitted] = useState(false);
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const handleClose = (_event: React.SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick') {
         return;
      }
   };

   const handleCancel = () => {
      onCancel();
      setCancelSubmitted(true);
   };

   const handleConfirm = () => {
      onConfirm();
      setConfirmSubmitted(true);
   };

   useEffect(() => {
      setConfirmSubmitted(false);
      setCancelSubmitted(false);
   }, [open]);

   return (
      <Dialog
         open={open}
         onClose={handleClose}
         className="base-dialog"
         fullScreen={isMobileScreen}
         disableScrollLock
         PaperProps={{
            className: 'base-style',
         }}
         disableEscapeKeyDown
      >
         <div className="base-dialog-header">
            <DialogTitle className="title">{title}</DialogTitle>
            <Fab size="small" color="primary" onClick={onClose}>
               <CloseIcon />
            </Fab>
         </div>
         <DialogContent className="base-dialog-content">
            <Box style={{ padding: '20px' }}>{confirmContent}</Box>
         </DialogContent>
         <DialogActions className="base-dialog-footer">
            <Stack direction="row" spacing={3}>
               <Button variant="outlined" onClick={handleCancel} disabled={isLoading} size="large">
                  {cancelSubmitted && isLoading ? (
                     <CircularProgress size={14} thickness={8} color="inherit" />
                  ) : (
                     cancelBtnText
                  )}
               </Button>
               <Button variant="contained" onClick={handleConfirm} disabled={isLoading} size={'large'}>
                  {confirmSubmitted && isLoading ? (
                     <CircularProgress size={14} thickness={8} color="inherit" />
                  ) : (
                     confirmBtnText
                  )}
               </Button>
            </Stack>
         </DialogActions>
      </Dialog>
   );
};

export default ConfirmationDialog;
