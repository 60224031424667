import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { Alert, Box, Button, DialogActions, DialogContent, Fab, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import MessageDialog from 'components/MessageDialog';
import {
   accountStore,
   submitLoginAsync,
   setSeverityMessage,
   setAccountDialogOpen,
   submitRegisterAsync,
   resetRegisterStatus,
   resetRegisterResponse,
   submitPasswordResetAsync,
} from './stores/account.slice';
import { IAccountFormValues } from './types/account.types';
import './styles/Account.scss';
import LogInFields from './components/LogInFields';
import SignUpFields from './components/SignUpFields';
import PasswordResetFields from './components/PasswordResetFields';
import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@mui/material/CircularProgress';
import {
   LOG_IN_DEFAULT_VALUES,
   LOG_IN_SCHEMA,
   SIGN_UP_DEFAULT_VALUES,
   SIGN_UP_SCHEMA,
   PASSWORD_RESET_DEFAULT_VALUES,
   PASSWORD_RESET_SCHEMA,
   Page,
} from './config/account-form.const';
import { LoadStatus } from 'config/utils';
import { loadMPTSession } from 'utils/mpt-session.helper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { turnStileSiteKey } from 'config/server';
import { MptLink } from 'components/MptLink';
import { cartStore } from '../cart/stores/cart.slice';
import { setDeliverySlots } from '../order/config/order.helper';
import { orderStore } from '../order/stores/order.slice';
import { packageStore } from '../package/stores/package.slice';
import { changeCustomModalOpen } from '../../components/utils/dialog.helper';

const Account = () => {
   const loadedMptSession = loadMPTSession();
   const [accountPage, setAccountPage] = useState(Page.LOGIN);
   const [messageModalOpen, setMessageModalOpen] = useState(false);
   const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
   const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
   const [turnstileFailed, setTurnstileFailed] = useState<string | null>(null);

   const {
      severityMessage,
      loginStatus,
      passwordResetStatus,
      isLoggedIn,
      registerStatus,
      passwordResetResponse,
      registerResponse,
      accountDialogOpen,
      noCloseBtn,
   } = useAppSelector(accountStore);
   const { fetchCartStatus, cart } = useAppSelector(cartStore);
   const { deliveryOptions, selectedDeliverySlot } = useAppSelector(orderStore);
   const { packageId, packages } = useAppSelector(packageStore);
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const isLoginLoading = loginStatus === LoadStatus.loading;
   const isRegisterLoading = registerStatus === LoadStatus.loading;
   const isPasswordResetLoading = passwordResetStatus === LoadStatus.loading;
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const getDefaultValues = useMemo(() => {
      switch (accountPage) {
         case Page.LOGIN:
            return LOG_IN_DEFAULT_VALUES;
         case Page.SIGNUP:
            return SIGN_UP_DEFAULT_VALUES;
         case Page.PASSWORDRESET:
            return PASSWORD_RESET_DEFAULT_VALUES;
         default:
            return LOG_IN_DEFAULT_VALUES;
      }
   }, [accountPage]);

   const getSchema = useMemo(() => {
      switch (accountPage) {
         case Page.LOGIN:
            return LOG_IN_SCHEMA;
         case Page.SIGNUP:
            return SIGN_UP_SCHEMA;
         case Page.PASSWORDRESET:
            return PASSWORD_RESET_SCHEMA;
         default:
            return LOG_IN_SCHEMA;
      }
   }, [accountPage]);

   const methods = useForm<IAccountFormValues>({
      defaultValues: getDefaultValues,
      // @ts-ignore
      resolver: yupResolver(getSchema),
   });

   const resetTurnStile = () => {
      const widgetContainer = document.getElementById('turnstile-widget');

      if (widgetContainer && window.turnstile) {
         window.turnstile.remove(widgetContainer);
      }

      setTurnstileToken(null);
      setTurnstileFailed(null);

      if (widgetContainer) {
         widgetContainer.innerHTML = '';
      }
   };

   const renderTurnStile = () => {
      setIsRegisterDisabled(true);
      const widgetContainer = document.getElementById('turnstile-widget');
      if (!widgetContainer) {
         console.error('Turnstile widget container not found.');
         return;
      }

      if (window.turnstile) {
         window.turnstile.remove(widgetContainer);
      }

      widgetContainer.innerHTML = '';

      window.turnstile.render(widgetContainer, {
         sitekey: turnStileSiteKey,
         callback: (token: string) => {
            setTurnstileToken(token);
            setIsRegisterDisabled(false);
            setTurnstileFailed(null);
         },
         'error-callback': () => {
            console.error('Turnstile verification failed.');
            setTurnstileToken(null);
            setIsRegisterDisabled(true);
            setTurnstileFailed('Error with human verification, please refresh page and try again.');
         },
         'expired-callback': () => {
            console.warn('Turnstile token expired. Re-rendering Turnstile widget.');
            setTurnstileToken(null);
            renderTurnStile();
         },
      });
   };

   const handleClose = () => {
      resetTurnStile();
      setAccountPage(Page.LOGIN);
      dispatch(setAccountDialogOpen(false));
      methods.reset();
   };

   const onBackToMenu = () => {
      navigate('/order');
      handleClose();
   };

   const handlePageSwitch = (toPage: Page) => {
      resetTurnStile();
      setAccountPage(toPage);
      dispatch(setSeverityMessage(null));
      methods.reset();
   };

   const getPageDesc = (page: Page) => {
      switch (page) {
         case Page.LOGIN:
            return 'Log In';
         case Page.SIGNUP:
            return 'Sign Up';
         case Page.PASSWORDRESET:
            return 'Password Reset';
         default:
            return 'Log In';
      }
   };

   const getFields = (page: Page) => {
      switch (page) {
         case Page.LOGIN:
            return <LogInFields handlePageSwitch={handlePageSwitch} />;
         case Page.SIGNUP:
            return <SignUpFields {...{ setIsRegisterDisabled, resetTurnStile, renderTurnStile }} />;
         case Page.PASSWORDRESET:
            return <PasswordResetFields />;
         default:
            return <LogInFields handlePageSwitch={handlePageSwitch} />;
      }
   };

   const getPageInstruction = (page: Page) => {
      switch (page) {
         case Page.LOGIN:
            return (
               <>
                  Don't have an account?{' '}
                  <MptLink onClick={() => handlePageSwitch(Page.SIGNUP)} fontWeight="700">
                     Register Now
                  </MptLink>
               </>
            );
         case Page.SIGNUP:
            return (
               <>
                  Already have an account?{' '}
                  <MptLink onClick={() => handlePageSwitch(Page.LOGIN)} fontWeight="700">
                     Login Now
                  </MptLink>
               </>
            );
         case Page.PASSWORDRESET:
            return (
               <>
                  <MptLink onClick={() => handlePageSwitch(Page.LOGIN)} fontWeight="700">
                     Back to Log In
                  </MptLink>
               </>
            );
         default:
            return <></>;
      }
   };

   const onSubmit = (data: any) => {
      dispatch(setSeverityMessage(null));
      if (accountPage === Page.LOGIN) {
         dispatch(
            submitLoginAsync({
               bodyInput: {
                  login_pass: data.password,
                  login_user: data.email,
               },
            }),
         );
      }
      if (accountPage === Page.SIGNUP) {
         setIsRegisterDisabled(true);

         dispatch(
            submitRegisterAsync({
               bodyInput: {
                  clientCartId: loadedMptSession.clientCartId,
                  firstName: data.first_name,
                  ip: loadedMptSession.ip,
                  lastName: data.last_name,
                  password: data.password,
                  username: data.email,
                  phoneNumber: data.phone,
                  turnstileToken,
               },
            }),
         );
      }
      if (accountPage === Page.PASSWORDRESET) {
         dispatch(
            submitPasswordResetAsync({
               bodyInput: {
                  ip: loadedMptSession.ip,
                  username: data.email,
               },
            }),
         );
      }
   };

   const onError = (errors: any) => {};

   const handleMessageModalClose = () => {
      dispatch(setSeverityMessage(null));
      dispatch(resetRegisterStatus());
      dispatch(resetRegisterResponse());
      setMessageModalOpen(false);
   };

   useEffect(() => {
      if (!isPasswordResetLoading && passwordResetStatus === LoadStatus.complete) {
         if (passwordResetResponse?.success) {
            setMessageModalOpen(true);
            handleClose();
         }
      }
   }, [passwordResetStatus]);

   useEffect(() => {
      if (!isRegisterLoading && registerStatus === LoadStatus.complete) {
         if (registerResponse?.success) {
            resetTurnStile();
            setMessageModalOpen(true);
            setIsRegisterDisabled(false);
            handleClose();
         } else {
            setIsRegisterDisabled(true);
            renderTurnStile();
         }
      }
   }, [registerStatus]);

   useEffect(() => {
      isLoggedIn && handleClose();
   }, [isLoggedIn]);

   useEffect(() => {
      if (accountDialogOpen) {
         dispatch(setSeverityMessage(null));
         setAccountPage(Page.LOGIN);
      }
   }, [accountDialogOpen]);

   useEffect(() => {
      if (fetchCartStatus === LoadStatus.complete && packages !== null) {
         setDeliverySlots(cart, deliveryOptions, selectedDeliverySlot, packageId);
      }
   }, [fetchCartStatus, packages]);

   useEffect(() => {
      changeCustomModalOpen(messageModalOpen);
   }, [messageModalOpen]);

   return (
      <>
         <Dialog
            className="base-dialog"
            fullScreen={isMobileScreen}
            disableScrollLock
            onClose={handleClose}
            open={accountDialogOpen}
            PaperProps={{
               className: 'base-style',
            }}
         >
            <div className="base-dialog-header">
               <DialogTitle className={'title'}>{getPageDesc(accountPage)}</DialogTitle>
               {noCloseBtn ? (
                  <Button startIcon={<ArrowBackIcon />} variant="contained" onClick={onBackToMenu}>
                     Back to menu
                  </Button>
               ) : (
                  <Fab size="small" color="primary" onClick={handleClose}>
                     <CloseIcon />
                  </Fab>
               )}
            </div>
            <DialogContent className="base-dialog-content">
               <div className="fields-container">
                  <div className={'account-instruction'}>{getPageInstruction(accountPage)}</div>
                  <FormProvider {...methods}>
                     <form id="account-popup" onSubmit={methods.handleSubmit(onSubmit, onError)}>
                        {getFields(accountPage)}
                     </form>
                  </FormProvider>
                  {turnstileFailed && (
                     <Box marginTop={1.5}>
                        <Alert severity={'error'}>{turnstileFailed}</Alert>
                     </Box>
                  )}
               </div>
            </DialogContent>
            <DialogActions
               className="base-dialog-footer"
               style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
               {severityMessage && (
                  <Alert sx={{ width: '100%' }} severity={severityMessage.severity}>
                     {severityMessage.message}
                  </Alert>
               )}
               <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  form="account-popup"
                  disabled={isLoginLoading || isRegisterLoading || isPasswordResetLoading || isRegisterDisabled}
               >
                  {isLoginLoading || isRegisterLoading || isPasswordResetLoading ? (
                     <CircularProgress size={14} thickness={8} color={'inherit'} />
                  ) : (
                     getPageDesc(accountPage)
                  )}
               </Button>
            </DialogActions>
         </Dialog>
         <MessageDialog
            open={messageModalOpen}
            onClose={handleMessageModalClose}
            severity={severityMessage?.severity}
            message={severityMessage?.message ?? ''}
         />
      </>
   );
};

export default Account;
