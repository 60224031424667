import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Box, DialogActions, DialogContent, Stack, styled } from '@mui/material';
import 'features/menuItem/styles/MenuItem.scss';
import { IMenuItemFormValues } from 'features/menuItem/types/menu-item-form.types';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import Grid from '@mui/material/Grid';
import ProductGallery from 'features/menuItem/components/ProductGallery';
import ProductAccordion from 'features/menuItem/components/ProductAccordion';
import { packageStore, setOpenPackageItem, setPackageId } from '../stores/package.slice';
import { setOrderDialogOpen, setOrderDialogOption } from '../../order/stores/order-bar.slice';
import SelectPackageBtn from './SelectPackageBtn';

export const StyledDescriptionContainer = styled('div')(({ theme }) => ({
   fontFamily: theme.typography.fontFamily,
   '& h1, & h2, & h3, & h4, & h5, & h6, & h7': {
      fontFamily: theme.typography.fontFamily,
   },
}));

const PackageMenu = () => {
   const dispatch = useAppDispatch();
   const methods = useFormContext<IMenuItemFormValues>();
   const { packageDetail } = useAppSelector(packageStore);
   const packageImages = packageDetail?.image
      ? [
           [
              {
                 size: 'large',
                 url: packageDetail?.image,
              },
              {
                 size: 'small',
                 url: packageDetail?.image,
              },
           ],
        ]
      : [];

   const registerFormFields = () => {
      methods.register('id');
      methods.register('base_price');
      methods.register('total_price');
   };

   const handlePackageSelection = () => {
      if (packageDetail) {
         dispatch(setOpenPackageItem(false));
         dispatch(setPackageId(packageDetail.id));
         dispatch(setOrderDialogOption('change-package'));
         dispatch(setOrderDialogOpen(true));
      }
   };

   useEffect(() => {
      if (packageDetail) {
         registerFormFields();
      }
   }, [packageDetail]);

   return typeof packageDetail !== 'undefined' ? (
      <>
         <DialogContent className="menu-item-dialog-content">
            <Grid container>
               <Grid item xs={12} md={6} className={'menu-item-grid'}>
                  {packageImages ? <ProductGallery images={packageImages} /> : null}
                  <ProductAccordion />
               </Grid>
               <Grid item xs={12} md={6} className={'menu-item-grid'}>
                  <StyledDescriptionContainer className="item-description">
                     {packageDetail.description && (
                        <Markdown remarkPlugins={[remarkGfm]}>
                           {packageDetail.description.replace(/\t/g, '   ')}
                        </Markdown>
                     )}
                  </StyledDescriptionContainer>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions className="menu-item-dialog-footer">
            <Stack direction="row" spacing={2}>
               <Box>
                  <SelectPackageBtn {...{ item: packageDetail, handlePackageSelection, addToCartText: 'Select' }} />
               </Box>
            </Stack>
         </DialogActions>
      </>
   ) : null;
};

export default PackageMenu;
