import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoadStatus } from 'config/utils';
import { setSystemError } from 'features/company/stores/company.slice';
import {
   fetchUserSubscription,
   fetchSubscriptionFreeze,
   fetchUserSubscriptions,
   postSubscriptionFreeze,
   postSubscriptionUnfreeze,
   fetchSubscriptionCancel,
   postSubscriptionCancel,
   postUserSubscription,
   postSubscriptionBilling,
} from '../services/subscription.api';
import {
   ISubmitSubscriptionBillingPayload,
   ISubmitSubscriptionCancelPayload,
   ISubmitSubscriptionFreezePayload,
   ISubscription,
   ISubsEditOption,
} from '../types/subscriptions.types';
import { setCart } from 'features/cart/stores/cart.slice';
import { setSelectedDeliverySlot } from '../../order/stores/order.slice';
import { IError } from '../../../components/types/base.types';
import { setPackageId } from '../../package/stores/package.slice';

export interface SubscriptionsState {
   fetchUserSubscriptionsStatus: LoadStatus;
   fetchUserSubscriptionStatus: LoadStatus;
   postUserSubscriptionStatus: LoadStatus;
   fetchSubscriptionFreezeStatus: LoadStatus;
   postSubscriptionFreezeStatus: LoadStatus;
   postSubscriptionUnfreezeStatus: LoadStatus;
   fetchSubscriptionCancelStatus: LoadStatus;
   postSubscriptionCancelStatus: LoadStatus;
   postSubscriptionBillingStatus: LoadStatus;
   selectedSubscription: ISubscription | null;
   subscriptionModActive: boolean;
   userSubscriptions: ISubscription[];
   subscriptionFreezeOptions: ISubsEditOption[];
   subscriptionCancelOptions: ISubsEditOption[];
   editFromDate: string | null;
   editToDate: string | null;
   cancelDate: string | null;
   error: IError[];
}

const initialState: SubscriptionsState = {
   fetchUserSubscriptionsStatus: LoadStatus.idle,
   fetchUserSubscriptionStatus: LoadStatus.idle,
   postUserSubscriptionStatus: LoadStatus.idle,
   fetchSubscriptionFreezeStatus: LoadStatus.idle,
   postSubscriptionFreezeStatus: LoadStatus.idle,
   postSubscriptionUnfreezeStatus: LoadStatus.idle,
   fetchSubscriptionCancelStatus: LoadStatus.idle,
   postSubscriptionCancelStatus: LoadStatus.idle,
   postSubscriptionBillingStatus: LoadStatus.idle,
   selectedSubscription: null,
   subscriptionModActive: false,
   userSubscriptions: [],
   subscriptionFreezeOptions: [],
   subscriptionCancelOptions: [],
   editFromDate: null,
   editToDate: null,
   cancelDate: null,
   error: [],
};

export const fetchUserSubscriptionsAsync = createAsyncThunk(
   'subscription/fetchUserSubscriptions',
   async (_input: {}, { dispatch }) => {
      const result = await fetchUserSubscriptions();
      if (!result.success) {
         dispatch(setSystemError(null));
      }
      return result;
   },
);

export const fetchUserSubscriptionAsync = createAsyncThunk(
   'subscription/fetchUserSubscription',
   async (input: { subscriptionId: string }, { dispatch }) => {
      const result = await fetchUserSubscription(input.subscriptionId);

      if (result.success) {
         const delivery = result.cart.deliveries[0];
         dispatch(
            setSelectedDeliverySlot({
               deliveryId: delivery.id ?? '',
               dayId: delivery.deliveryDayId,
               timeSlotId: delivery.timeSlotId,
               deliveryDayName: delivery.deliveryDayName,
               packageId: delivery.packageid,
               start: '',
               end: '',
            }),
         );

         dispatch(setPackageId(delivery ? delivery.packageid : null));
         dispatch(setCart(result.cart));
      } else {
         dispatch(setSystemError(null));
      }
      return result;
   },
);

export const postUserSubscriptionAsync = createAsyncThunk(
   'subscription/postUserSubscription',
   async (input: { subscriptionId: string }, { dispatch }) => {
      const result = await postUserSubscription(input.subscriptionId);
      if (result.success) {
         dispatch(setSubscriptionModActive(false));
      } else {
         dispatch(subscriptionsSlice.actions.setError(result.error ?? []));
      }
   },
);

export const fetchSubscriptionFreezeAsync = createAsyncThunk(
   'subscription/fetchSubscriptionFreeze',
   async (input: { subscriptionId: string }) => await fetchSubscriptionFreeze(input.subscriptionId),
);

export const postSubscriptionFreezeAsync = createAsyncThunk(
   'subscription/postSubscriptionFreeze',
   async (input: { subscriptionId: string; bodyInput: ISubmitSubscriptionFreezePayload }) =>
      await postSubscriptionFreeze(input.subscriptionId, input.bodyInput),
);

export const postSubscriptionUnfreezeAsync = createAsyncThunk(
   'subscription/postSubscriptionUnfreeze',
   async (input: { subscriptionId: string; bodyInput: ISubmitSubscriptionFreezePayload }) =>
      await postSubscriptionUnfreeze(input.subscriptionId, input.bodyInput),
);

export const fetchSubscriptionCancelAsync = createAsyncThunk(
   'subscription/fetchSubscriptionCancel',
   async (input: { subscriptionId: string }) => await fetchSubscriptionCancel(input.subscriptionId),
);

export const postSubscriptionCancelAsync = createAsyncThunk(
   'subscription/postSubscriptionCancel',
   async (input: { subscriptionId: string; bodyInput: ISubmitSubscriptionCancelPayload }) =>
      await postSubscriptionCancel(input.subscriptionId, input.bodyInput),
);

export const postSubscriptionBillingAsync = createAsyncThunk(
   'subscription/postSubscriptionBilling',
   async (input: { subscriptionId: string; bodyInput: ISubmitSubscriptionBillingPayload }) =>
      await postSubscriptionBilling(input.subscriptionId, input.bodyInput),
);

export const subscriptionsSlice = createSlice({
   name: 'subscription',
   initialState,
   reducers: {
      setSelectedSubscription: (state, action: PayloadAction<ISubscription | null>) => {
         state.selectedSubscription = action.payload;
      },
      setSubscriptionModActive: (state, action: PayloadAction<boolean>) => {
         state.subscriptionModActive = action.payload;
      },
      resetFetchUserSubscriptionStatus: (state) => {
         state.fetchUserSubscriptionStatus = LoadStatus.idle;
      },
      resetPostUserSubscriptionStatus: (state) => {
         state.postUserSubscriptionStatus = LoadStatus.idle;
      },
      resetPostSubscriptionFreezeStatus: (state) => {
         state.postSubscriptionFreezeStatus = LoadStatus.idle;
      },
      resetPostSubscriptionUnfreezeStatus: (state) => {
         state.postSubscriptionUnfreezeStatus = LoadStatus.idle;
      },
      resetPostSubscriptionCancelStatus: (state) => {
         state.postSubscriptionCancelStatus = LoadStatus.idle;
      },
      resetPostSubscriptionBillingStatus: (state) => {
         state.postSubscriptionBillingStatus = LoadStatus.idle;
      },
      setEditFromDate: (state, action) => {
         state.editFromDate = action.payload;
      },
      setEditToDate: (state, action) => {
         state.editToDate = action.payload;
      },
      setCancelDate: (state, action) => {
         state.cancelDate = action.payload;
      },
      setError(state, action: PayloadAction<IError[]>) {
         state.error = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchUserSubscriptionsAsync.pending, (state) => {
            state.fetchUserSubscriptionsStatus = LoadStatus.loading;
         })
         .addCase(fetchUserSubscriptionsAsync.fulfilled, (state, action) => {
            state.fetchUserSubscriptionsStatus = LoadStatus.complete;

            if (action.payload.success) {
               state.userSubscriptions = action.payload.subscriptions;
            }
         })
         .addCase(fetchUserSubscriptionsAsync.rejected, (state) => {
            state.fetchUserSubscriptionsStatus = LoadStatus.failed;
         })
         .addCase(fetchUserSubscriptionAsync.pending, (state) => {
            state.fetchUserSubscriptionStatus = LoadStatus.loading;
         })
         .addCase(fetchUserSubscriptionAsync.fulfilled, (state) => {
            state.fetchUserSubscriptionStatus = LoadStatus.complete;
            state.subscriptionModActive = true;
         })
         .addCase(fetchUserSubscriptionAsync.rejected, (state) => {
            state.fetchUserSubscriptionStatus = LoadStatus.failed;
         })
         .addCase(postUserSubscriptionAsync.pending, (state) => {
            state.postUserSubscriptionStatus = LoadStatus.loading;
         })
         .addCase(postUserSubscriptionAsync.fulfilled, (state) => {
            state.postUserSubscriptionStatus = LoadStatus.complete;
         })
         .addCase(postUserSubscriptionAsync.rejected, (state) => {
            state.postUserSubscriptionStatus = LoadStatus.failed;
         })
         .addCase(fetchSubscriptionFreezeAsync.pending, (state) => {
            state.fetchSubscriptionFreezeStatus = LoadStatus.loading;
         })
         .addCase(fetchSubscriptionFreezeAsync.fulfilled, (state, action) => {
            state.fetchSubscriptionFreezeStatus = LoadStatus.complete;
            if (action.payload.success && action.payload.freezeOptions) {
               state.subscriptionFreezeOptions = action.payload.freezeOptions;
            }
         })
         .addCase(fetchSubscriptionFreezeAsync.rejected, (state) => {
            state.fetchSubscriptionFreezeStatus = LoadStatus.failed;
         })
         .addCase(postSubscriptionFreezeAsync.pending, (state) => {
            state.postSubscriptionFreezeStatus = LoadStatus.loading;
         })
         .addCase(postSubscriptionFreezeAsync.fulfilled, (state) => {
            state.postSubscriptionFreezeStatus = LoadStatus.complete;
         })
         .addCase(postSubscriptionFreezeAsync.rejected, (state) => {
            state.postSubscriptionFreezeStatus = LoadStatus.failed;
         })
         .addCase(postSubscriptionUnfreezeAsync.pending, (state) => {
            state.postSubscriptionUnfreezeStatus = LoadStatus.loading;
         })
         .addCase(postSubscriptionUnfreezeAsync.fulfilled, (state) => {
            state.postSubscriptionUnfreezeStatus = LoadStatus.complete;
         })
         .addCase(postSubscriptionUnfreezeAsync.rejected, (state) => {
            state.postSubscriptionUnfreezeStatus = LoadStatus.failed;
         })
         .addCase(fetchSubscriptionCancelAsync.pending, (state) => {
            state.fetchSubscriptionCancelStatus = LoadStatus.loading;
         })
         .addCase(fetchSubscriptionCancelAsync.fulfilled, (state, action) => {
            state.fetchSubscriptionCancelStatus = LoadStatus.complete;
            if (action.payload.success && action.payload.cancelOptions) {
               state.subscriptionCancelOptions = action.payload.cancelOptions;
            }
         })
         .addCase(fetchSubscriptionCancelAsync.rejected, (state) => {
            state.fetchSubscriptionCancelStatus = LoadStatus.failed;
         })
         .addCase(postSubscriptionCancelAsync.pending, (state) => {
            state.postSubscriptionCancelStatus = LoadStatus.loading;
         })
         .addCase(postSubscriptionCancelAsync.fulfilled, (state) => {
            state.postSubscriptionCancelStatus = LoadStatus.complete;
         })
         .addCase(postSubscriptionCancelAsync.rejected, (state) => {
            state.postSubscriptionCancelStatus = LoadStatus.failed;
         })
         .addCase(postSubscriptionBillingAsync.pending, (state) => {
            state.postSubscriptionBillingStatus = LoadStatus.loading;
         })
         .addCase(postSubscriptionBillingAsync.fulfilled, (state) => {
            state.postSubscriptionBillingStatus = LoadStatus.complete;
         })
         .addCase(postSubscriptionBillingAsync.rejected, (state) => {
            state.postSubscriptionBillingStatus = LoadStatus.failed;
         });
   },
});

export const {
   setSelectedSubscription,
   setSubscriptionModActive,
   resetFetchUserSubscriptionStatus,
   resetPostUserSubscriptionStatus,
   resetPostSubscriptionUnfreezeStatus,
   resetPostSubscriptionFreezeStatus,
   resetPostSubscriptionCancelStatus,
   resetPostSubscriptionBillingStatus,
   setEditFromDate,
   setEditToDate,
   setCancelDate,
   setError,
} = subscriptionsSlice.actions;

export const subscriptionStore = (state: RootState) => state.subscriptionStore;

export default subscriptionsSlice.reducer;
