import { Dialog, DialogActions, DialogContent, DialogTitle, Fab, useMediaQuery, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFinixFormModalValues } from '../../types/checkout.types';
import { companyStore } from '../../../company/stores/company.slice';
import { FINIX_API } from '../../config/finix-api.const';
import { finixStore, setFinixError, setFinixFormModalOpen } from '../../stores/finix.slice';
import { accountAddBillingAsync } from 'features/account/stores/account.slice';
import './FinixFormModal.scss';

declare global {
   interface Window {
      Finix: any;
   }
}

const FinixFormModal = () => {
   const dispatch = useAppDispatch();
   const { finixFormModalOpen } = useAppSelector(finixStore);
   const { companyInfo } = useAppSelector(companyStore);
   const finixAPI = { ...FINIX_API, options: { ...FINIX_API.options, onSubmit: onFinixSubmit } };
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const handleClose = () => {
      dispatch(setFinixFormModalOpen(false));
   };

   const deliverySchema = yup.object({});

   const methods = useForm<IFinixFormModalValues>({
      defaultValues: {
         name: '',
         cardNumber: 0,
         expirationDate: new Date(),
         securityCode: 0,
         addressLine1: '',
         addressLine2: '',
         addressCity: '',
         addressRegion: '',
         addressPostalCode: '',
         addressCountry: '',
      },
      // @ts-ignore
      resolver: yupResolver(deliverySchema),
   });

   const onSubmit = (data: IFinixFormModalValues) => {
      handleClose();
   };

   useEffect(() => {
      setTimeout(() => {
         initFinixForm();
      }, 0);
   }, []);

   function initFinixForm() {
      if (companyInfo.currency === 'CAD') {
         finixAPI.options.defaultCountry = 'CAN';
         finixAPI.options.placeholders = {
            ...finixAPI.options.placeholders,
            address_postal_code: 'Postal Code',
            address_region: 'Region',
         };
         finixAPI.options.labels = { ...finixAPI.options.labels, address_postal_code: 'Postal Code' };
      } else {
         finixAPI.options.defaultCountry = 'USA';
         finixAPI.options.hideFields.push('address_country');
         finixAPI.options.placeholders = { ...finixAPI.options.placeholders, address_postal_code: 'Zip Code' };
      }

      if (companyInfo?.finixObject?.env) {
         finixAPI.finixEnv = companyInfo.finixObject.env;
      }

      if (companyInfo?.finixObject?.applicationId) {
         finixAPI.applicationId = companyInfo.finixObject.applicationId;
      }

      if (companyInfo?.finixObject?.mid) {
         finixAPI.form = window.Finix.CardTokenForm('checkout-form', finixAPI.options);
      }
   }

   function onFinixSubmit() {
      const finixSubmitButton = document.getElementById('finix-submit-button');

      if (finixSubmitButton) {
         (finixSubmitButton as HTMLButtonElement).disabled = true;
      }

      dispatch(setFinixError(null));

      finixAPI?.form?.submit &&
         finixAPI.form.submit(finixAPI.finixEnv, finixAPI.applicationId, function (err, res) {
            if (err) {
               dispatch(setFinixError(err));
            } else {
               const tokenData = res.data || {};

               if (tokenData?.id) {
                  dispatch(
                     accountAddBillingAsync({
                        bodyInput: {
                           ccToken: tokenData.id,
                           acctType: 'credit',
                           onCheckout: true,
                        },
                     }),
                  );
               } else {
                  setFinixError('Error adding payment method');
               }
            }
         });
   }

   return (
      <Dialog
         className="base-dialog"
         onClose={handleClose}
         open={finixFormModalOpen}
         fullScreen={isMobileScreen}
         disableScrollLock
         PaperProps={{ className: 'base-style schedule' }}
      >
         <div className="base-dialog-header">
            <DialogTitle className={'title'}>Add New Payment</DialogTitle>
            <Fab size="small" color="primary" onClick={handleClose}>
               <CloseIcon />
            </Fab>
         </div>
         <DialogContent className="base-dialog-content">
            <div className="fields-container">
               <FormProvider {...methods}>
                  <form id="finix-popup">
                     <div id="checkout-form" />
                  </form>
               </FormProvider>
            </div>
         </DialogContent>
         <DialogActions className="base-dialog-footer finix-dialog-footer"></DialogActions>
      </Dialog>
   );
};

export default FinixFormModal;
