import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, IconButton, ListItemText, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import { IBillingProfile } from '../../checkout/types/checkout.types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import StarIcon from '@mui/icons-material/Star';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { accountStore, resetDefaultBillingStatus, resetDeleteBillingStatus } from '../../account/stores/account.slice';

import { LoadStatus } from '../../../config/utils';
import {
   checkoutPaymentStore,
   setDefaultPaymentDialogOpen,
   setDeletePaymentDialogOpen,
   setPaymentToDefault,
   setPaymentToDelete,
} from '../../checkout/stores/checkout-payment.slice';
import DeletePaymentDialog from '../../checkout/components/paymentDetails/DeletePayementDialog';
import DefaultPaymentDialog from '../../checkout/components/paymentDetails/DefaultPaymentDialog';
import EditPaymentDialog from './EditPaymentDialog';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

const PaymentBook = () => {
   const { billingProfiles, deleteBillingStatus, defaultBillingStatus } = useAppSelector(accountStore);
   const { deletePaymentDialogOpen, defaultPaymentDialogOpen } = useAppSelector(checkoutPaymentStore);
   const dispatch = useAppDispatch();
   const [anchorEl, setAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});
   const [editPaymentDialogOpen, setEditPaymentDialogOpen] = useState(false);
   const [editPaymentId, setEditPaymentId] = useState<string>('');

   const onDeletePaymentStatus = () => {
      if (deleteBillingStatus === LoadStatus.complete) {
         dispatch(resetDeleteBillingStatus());
         dispatch(setPaymentToDelete(null));
         dispatch(setDeletePaymentDialogOpen(false));
      }
   };

   const onDefaultPaymentStatus = () => {
      if (defaultBillingStatus === LoadStatus.complete) {
         dispatch(resetDefaultBillingStatus());
         dispatch(setPaymentToDefault(null));
         dispatch(setDefaultPaymentDialogOpen(false));
      }
   };

   const handleOptionClick = (event: React.MouseEvent<HTMLButtonElement>, paymentId: string) => {
      setAnchorEl((prev) => ({ ...prev, [paymentId]: event.currentTarget }));
   };

   const handleOptionClose = (paymentId: string) => {
      setAnchorEl((prev) => ({ ...prev, [paymentId]: null }));
   };

   const handlePaymentEdit = (id: string) => {
      handleOptionClose(id);
      setEditPaymentId(id);
      setEditPaymentDialogOpen(true);
   };

   const handlePaymentDelete = (payment: IBillingProfile) => {
      handleOptionClose(payment.id);
      dispatch(setDeletePaymentDialogOpen(true));
      dispatch(setPaymentToDelete(payment));
   };

   const handleSetDefault = (payment: IBillingProfile) => {
      handleOptionClose(payment.id);
      dispatch(setDefaultPaymentDialogOpen(true));
      dispatch(setPaymentToDefault(payment));
   };

   useEffect(() => {
      onDeletePaymentStatus();
   }, [deleteBillingStatus]);

   useEffect(() => {
      onDefaultPaymentStatus();
   }, [defaultBillingStatus]);

   useEffect(() => {
      changeCustomModalOpen(editPaymentDialogOpen);
   }, [editPaymentDialogOpen]);

   return (
      <>
         <Box mt={2} mb={3} width={'100%'}>
            <div className="addresses-list">
               <Stack direction="column">
                  {billingProfiles.map((payment, index) => (
                     <Box key={`payment-method-${index}`}>
                        <Box display="flex">
                           <Box>
                              <span className="shipping-address-name">
                                 {payment.firstName}, {payment.lastName}
                              </span>
                              <br />
                              {payment.address}
                              {payment.address2 ? (
                                 <>
                                    <br />
                                    {payment.address2}
                                 </>
                              ) : null}
                              <br />
                              {payment.city}, {payment.region}, {payment.postal}
                              <br />
                              {`Card ending in ${payment.accountNum}`}
                           </Box>
                           <Box ml="auto">
                              <Stack direction="column">
                                 {payment.default ? <Chip label={'Default'} sx={{ marginBottom: '5px' }} /> : null}
                                 <Box ml="auto">
                                    <Tooltip title="More Options">
                                       <IconButton onClick={(event) => handleOptionClick(event, payment.id)}>
                                          <MoreVertIcon />
                                       </IconButton>
                                    </Tooltip>
                                 </Box>
                              </Stack>

                              <Menu
                                 anchorEl={anchorEl[payment.id]}
                                 anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                 }}
                                 transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                 }}
                                 open={Boolean(anchorEl[payment.id])}
                                 onClose={() => handleOptionClose(payment.id)}
                              >
                                 <MenuItem onClick={() => handlePaymentEdit(payment.id)}>
                                    <ListItemIcon>
                                       <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText>Edit</ListItemText>
                                 </MenuItem>

                                 <MenuItem onClick={() => handlePaymentDelete(payment)}>
                                    <ListItemIcon>
                                       <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                 </MenuItem>
                                 {!payment.default ? (
                                    <MenuItem onClick={() => handleSetDefault(payment)}>
                                       <ListItemIcon>
                                          <StarIcon />
                                       </ListItemIcon>
                                       <ListItemText>Set Default</ListItemText>
                                    </MenuItem>
                                 ) : null}
                              </Menu>
                           </Box>
                        </Box>
                        {index < billingProfiles.length - 1 && (
                           <Divider orientation="horizontal" flexItem sx={{ borderWidth: 1, margin: '0.5rem 20px' }} />
                        )}
                     </Box>
                  ))}
               </Stack>
            </div>
         </Box>
         {deletePaymentDialogOpen && <DeletePaymentDialog />}
         {defaultPaymentDialogOpen && <DefaultPaymentDialog />}
         {editPaymentDialogOpen && (
            <EditPaymentDialog
               open={editPaymentDialogOpen}
               editPaymentId={editPaymentId}
               setEditPaymentDialogOpen={setEditPaymentDialogOpen}
            />
         )}
      </>
   );
};

export default PaymentBook;
