import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { HEAR_ABOUT_US } from '../config/account-form.const';
import FormInput from 'components/FormInput';
import Grid from '@mui/material/Grid';
import FormSelect from 'components/FormSelect';
import { turnStileSiteKey } from 'config/server';

interface ISignUpFieldsProps {
   setIsRegisterDisabled: (isRegisterDisabled: boolean) => void;
   resetTurnStile: () => void;
   renderTurnStile: () => void;
}

const SignUpFields: React.FC<ISignUpFieldsProps> = ({ setIsRegisterDisabled, resetTurnStile, renderTurnStile }) => {
   const turnStileDivRef = React.useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (turnStileDivRef.current) {
         setIsRegisterDisabled(true);
         renderTurnStile();
      }
   }, [turnStileDivRef]);

   useEffect(() => {
      return () => {
         resetTurnStile();
      };
   }, []);

   return (
      <Box sx={{ p: 0 }}>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
               <FormInput name="first_name" label="First Name" autoComplete={'given-name'} />
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormInput name="last_name" label="Last Name" autoComplete={'family-name'} />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="email" label="Email" type={'email'} autoComplete={'email'} />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="phone" label="Phone" type={'tel'} autoComplete={'tel-national'} />
            </Grid>
            <Grid item xs={12}>
               <FormSelect name="checkedOption" label="How did you hear about us?" menuItems={HEAR_ABOUT_US} />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="password" label="Password" type={'password'} />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="password_confirmation" label="Confirm Password" type={'password'} />
            </Grid>
         </Grid>
         <Box
            id="turnstile-widget"
            ref={turnStileDivRef}
            display="flex"
            flexDirection="column"
            alignItems="center"
            pt={2}
            data-sitekey={turnStileSiteKey}
         ></Box>
      </Box>
   );
};

export default SignUpFields;
