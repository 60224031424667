import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
   IFetchUserOrdersResponse,
   IPaymentDetails,
   IUserAddressData,
   IUserPassword,
   IUserProfile,
} from '../types/profile.types';
import { LoadStatus } from 'config/utils';
import { fetchUserOrders } from '../services/profile.api';
import { RootState } from 'app/store';
import { COMPANY_STATE_COMPANY_INFO_DEFAULT } from 'features/company/stores/default.const';

export interface ProfileState {
   personalInfo: IUserProfile;
   password: IUserPassword;
   status: LoadStatus;
   orderStatus: IFetchUserOrdersResponse;
   paymentMethods: IPaymentDetails[];
   deliveryAddresses: IUserAddressData[];
   navListIndex: number;
}

const initialState: ProfileState = {
   personalInfo: {
      email: '',
      firstName: '',
      lastName: '',
      company: '',
      address: '',
      address2: '',
      city: '',
      zip: '',
      state: '',
      country: '',
      phone: '',
      cell: '',
   },
   status: LoadStatus.idle,
   password: {
      currentPassword: '',
      newPassword: '',
      oldPassword: '',
   },
   orderStatus: {
      success: false,
      orderHistory: [],
      companyInfo: COMPANY_STATE_COMPANY_INFO_DEFAULT,
   },
   paymentMethods: [],
   deliveryAddresses: [],
   navListIndex: 0,
};

export const fetchUserOrdersAsync = createAsyncThunk('profile/fetchUserOrders', async () => {
   return await fetchUserOrders();
});

export const profileSlice = createSlice({
   name: 'profile',
   initialState,
   reducers: {
      setNavListIndex: (state, action: PayloadAction<number>) => {
         state.navListIndex = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchUserOrdersAsync.pending, (state) => {
            state.status = LoadStatus.loading;
         })
         .addCase(fetchUserOrdersAsync.fulfilled, (state, action) => {
            state.status = LoadStatus.complete;
            state.orderStatus = action.payload;
         })
         .addCase(fetchUserOrdersAsync.rejected, (state) => {
            state.status = LoadStatus.failed;
         });
   },
});

export const { setNavListIndex } = profileSlice.actions;

export const profileStore = (state: RootState) => state.profileStore;

export default profileSlice.reducer;
