import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'normalize.css';
import './index.scss';
import { CssBaseline } from '@mui/material';
import 'utils/polyfills';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-slab/300.css';
import '@fontsource/roboto-slab/400.css';
import '@fontsource/roboto-slab/500.css';
import '@fontsource/roboto-slab/600.css';
import '@fontsource/roboto-slab/700.css';
import FeatureItemsPage from './standalone/feature-item/FeatureItemsPage';

const featureItemsRoot = document.getElementById('feature-items-root');
const mainRoot = document.getElementById('root');

if (!featureItemsRoot && !mainRoot) {
   throw new Error('No valid root element found.');
}

const container = (featureItemsRoot || mainRoot)!;
const root = createRoot(container);

if (featureItemsRoot) {
   root.render(
      <Provider store={store}>
         <CssBaseline />
         <FeatureItemsPage />
      </Provider>,
   );
} else {
   root.render(
      <Provider store={store}>
         <CssBaseline />
         <App />
      </Provider>,
   );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
