export interface ITrackEventData {
   productId?: string | null;
   productName?: string;
   price?: string;
   categories?: string;
   imageURL?: string;
   productURL?: string;
   qty?: string;
   page_path?: string;
   cartObj?: string;
   cartTotal?: number;
   selectedShippingObj?: string;
   selectedBillingObj?: string;
}

export enum TrackEventType {
   productView = 'product-view',
   addToCart = 'add-to-cart',
   removeFromCart = 'remove-from-cart',
   pageView = 'page-view',
   initCheckout = 'init-checkout',
   completeCheckout = 'complete-checkout',
}

export enum TrackGTagType {
   addToCart1 = 'add_to_cart_1',
   addToCart2 = 'add_to_cart_2',
   loginForm = 'login_form',
   registerForm = 'register_form',
   purchase = 'purchase',
   conversion = 'conversion',
}

export enum TrackPixelType {
   addToCart = 'AddToCart',
   initiateCheckout = 'InitiateCheckout',
   completeRegistration = 'CompleteRegistration',
   purchase = 'Purchase',
}
