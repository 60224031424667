import { alpha, Card, CardContent, CardHeader, CardActions, styled } from '@mui/material';
import ListItem from '@mui/material/ListItem/ListItem';

interface StyledSectionCardProps {
   hasError?: boolean;
   hide?: boolean;
}

export const SectionCard = styled(Card, {
   shouldForwardProp: (prop) => prop !== 'hasError' && prop !== 'hide',
})<StyledSectionCardProps>(({ theme, hasError, hide }) => ({
   boxShadow: 'none',
   borderWidth: hide ? '0px' : hasError ? '2px' : '1px',
   borderColor: hasError ? alpha(theme.palette.error.main, 1) : 'rgba(0, 0, 0, 0.12)',
   borderStyle: 'solid',
   [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
   },
   [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
   },
}));

export const SectionCardHeader = styled(CardHeader)(({ theme }) => ({
   [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
   },
   [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
   },
}));

export const SectionCardContent = styled(CardContent)(({ theme }) => ({
   padding: theme.spacing(0),
   [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
   },
   [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
   },
}));

export const SectionCardActions = styled(CardActions)(({ theme }) => ({
   padding: theme.spacing(2),
   [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
   },
   [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
   },
   justifyContent: 'center',
}));

export const SectionCardListItem = styled(ListItem)(({ theme }) => ({
   paddingLeft: theme.spacing(0),
   paddingRight: theme.spacing(0),
   paddingTop: theme.spacing(0),
   paddingBottom: theme.spacing(0),
}));

export const SectionCardSlim = styled(Card, {
   shouldForwardProp: (prop) => prop !== 'hasError',
})<StyledSectionCardProps>(({ theme, hasError }) => ({
   boxShadow: 'none',
   marginTop: '0px !important',
   border: hasError ? `2px solid ${alpha(theme.palette.error.main, 1)}` : 'none',
   [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
   },
   [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
   },
}));

export const SectionCardSlimContent = styled(CardContent)(({ theme }) => ({
   padding: theme.spacing(0),
   [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
   },
   [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
   },
}));
