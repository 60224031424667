import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, IconButton, ListItemText, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import { IShippingProfile } from '../../checkout/types/checkout.types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import StarIcon from '@mui/icons-material/Star';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
   accountStore,
   resetDefaultShippingStatus,
   resetDeleteShippingStatus,
} from '../../account/stores/account.slice';
import {
   checkoutShippingStore,
   setAddressToDefault,
   setAddressToDelete,
   setDefaultAddressDialogOpen,
   setDeleteAddressDialogOpen,
} from '../../checkout/stores/checkout-shipping.slice';
import DeleteDeliveryAddressDialog from '../../checkout/components/orderDetails/DeleteDeliveryAddressDialog';
import { LoadStatus } from '../../../config/utils';
import DefaultDeliveryAddressDialog from '../../checkout/components/orderDetails/DefaultDeliveryAddressDialog';
import EditAddressDialog from './EditAddressDialog';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

const AddressBook = () => {
   const { shippingProfiles, deleteShippingStatus, defaultShippingStatus } = useAppSelector(accountStore);
   const { deleteAddressDialogOpen, defaultAddressDialogOpen } = useAppSelector(checkoutShippingStore);
   const dispatch = useAppDispatch();
   const [anchorEl, setAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});
   const [editAddressDialogOpen, setEditAddressDialogOpen] = useState(false);
   const [editAddressId, setEditAddressId] = useState<string>('');

   const onDeleteShippingStatus = () => {
      if (deleteShippingStatus === LoadStatus.complete) {
         dispatch(resetDeleteShippingStatus());
         dispatch(setAddressToDelete(null));
         dispatch(setDeleteAddressDialogOpen(false));
      }
   };

   const onDefaultShippingStatus = () => {
      if (defaultShippingStatus === LoadStatus.complete) {
         dispatch(resetDefaultShippingStatus());
         dispatch(setAddressToDefault(null));
         dispatch(setDefaultAddressDialogOpen(false));
      }
   };

   const handleOptionClick = (event: React.MouseEvent<HTMLButtonElement>, addressId: string) => {
      setAnchorEl((prev) => ({ ...prev, [addressId]: event.currentTarget }));
   };

   const handleOptionClose = (addressId: string) => {
      setAnchorEl((prev) => ({ ...prev, [addressId]: null }));
   };

   const handleAddressEdit = (id: string) => {
      handleOptionClose(id);
      setEditAddressId(id);
      setEditAddressDialogOpen(true);
   };

   const handleAddressDelete = (address: IShippingProfile) => {
      handleOptionClose(address.id);
      dispatch(setDeleteAddressDialogOpen(true));
      dispatch(setAddressToDelete(address));
   };

   const handleSetDefault = (address: IShippingProfile) => {
      handleOptionClose(address.id);
      dispatch(setDefaultAddressDialogOpen(true));
      dispatch(setAddressToDefault(address));
   };

   useEffect(() => {
      onDeleteShippingStatus();
   }, [deleteShippingStatus]);

   useEffect(() => {
      onDefaultShippingStatus();
   }, [defaultShippingStatus]);

   useEffect(() => {
      changeCustomModalOpen(editAddressDialogOpen);
   }, [editAddressDialogOpen]);

   return (
      <>
         <Box mt={2} mb={3} width={'100%'}>
            <div className="addresses-list">
               <Stack direction="column">
                  {shippingProfiles.map((address, index) => (
                     <Box key={`delivery-address-${index}`}>
                        <Box display="flex">
                           <Box>
                              <span className="shipping-address-name">
                                 {address.firstName}, {address.lastName}
                              </span>
                              <br />
                              {address.address}
                              {address.address2 ? (
                                 <>
                                    <br />
                                    {address.address2}
                                 </>
                              ) : null}
                              <br />
                              {address.city}, {address.region}, {address.postal}
                           </Box>
                           <Box ml="auto">
                              <Stack direction="column">
                                 {address.default ? <Chip label={'Default'} sx={{ marginBottom: '5px' }} /> : null}

                                 <Box ml="auto">
                                    <Tooltip title="More Options">
                                       <IconButton onClick={(event) => handleOptionClick(event, address.id)}>
                                          <MoreVertIcon />
                                       </IconButton>
                                    </Tooltip>
                                 </Box>
                              </Stack>

                              <Menu
                                 anchorEl={anchorEl[address.id]}
                                 anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                 }}
                                 transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                 }}
                                 open={Boolean(anchorEl[address.id])}
                                 onClose={() => handleOptionClose(address.id)}
                              >
                                 <MenuItem onClick={() => handleAddressEdit(address.id)}>
                                    <ListItemIcon>
                                       <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText>Edit</ListItemText>
                                 </MenuItem>

                                 <MenuItem onClick={() => handleAddressDelete(address)}>
                                    <ListItemIcon>
                                       <DeleteIcon />
                                    </ListItemIcon>
                                    <ListItemText>Delete</ListItemText>
                                 </MenuItem>

                                 {!address.default ? (
                                    <MenuItem onClick={() => handleSetDefault(address)}>
                                       <ListItemIcon>
                                          <StarIcon />
                                       </ListItemIcon>
                                       <ListItemText>Set Default</ListItemText>
                                    </MenuItem>
                                 ) : null}
                              </Menu>
                           </Box>
                        </Box>
                        {index < shippingProfiles.length - 1 && (
                           <Divider orientation="horizontal" flexItem sx={{ borderWidth: 1, margin: '0.5rem 20px' }} />
                        )}
                     </Box>
                  ))}
               </Stack>
            </div>
         </Box>
         {deleteAddressDialogOpen && <DeleteDeliveryAddressDialog />}
         {defaultAddressDialogOpen && <DefaultDeliveryAddressDialog />}
         {editAddressDialogOpen && (
            <EditAddressDialog
               open={editAddressDialogOpen}
               editAddressId={editAddressId}
               setEditAddressDialogOpen={setEditAddressDialogOpen}
            />
         )}
      </>
   );
};

export default AddressBook;
