import React from 'react';
import { Box } from '@mui/material';
import OrderTable from './OrderTable';
import ProfileHeader from './ProfileHeader';

const OrderStatus = () => {
   return (
      <>
         <ProfileHeader headerTitle="Your Orders" />
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' }}>
            <OrderTable />
         </Box>
      </>
   );
};

export default OrderStatus;
