import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoadStatus } from '../../../config/utils';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

interface CheckoutState {
   status: LoadStatus;
   finixFormModalOpen: boolean;
   finixError: string | null;
}

const initialState: CheckoutState = {
   status: LoadStatus.idle,
   finixFormModalOpen: false,
   finixError: null,
};

const finixSlice = createSlice({
   name: 'finix',
   initialState,
   reducers: {
      setFinixFormModalOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.finixFormModalOpen = action.payload;
      },
      setFinixError: (state, action: PayloadAction<string | null>) => {
         state.finixError = action.payload;
      },
   },
   extraReducers: (builder) => {},
});

export const { setFinixFormModalOpen, setFinixError } = finixSlice.actions;

export const finixStore = (state: RootState) => state.finixStore;

export default finixSlice.reducer;
