import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoadStatus } from 'config/utils';
import { ICompanyInfo } from '../types/company.types';
import { COMPANY_STATE_COMPANY_INFO_DEFAULT } from './default.const';

export interface CompanyState {
   status: LoadStatus;
   appbarSmall: boolean;
   companyInfo: ICompanyInfo;
   systemError: string | null;
}

const initialState: CompanyState = {
   status: LoadStatus.idle,
   appbarSmall: false,
   companyInfo: COMPANY_STATE_COMPANY_INFO_DEFAULT,
   systemError: null,
};

export const companySlice = createSlice({
   name: 'company',
   initialState,
   reducers: {
      setAppbarSmall: (state, action: PayloadAction<boolean>) => {
         state.appbarSmall = action.payload;
      },
      setCompanyInfo: (state, action: PayloadAction<ICompanyInfo>) => {
         state.companyInfo = action.payload;
      },
      setSystemError: (state, action: PayloadAction<string | null>) => {
         state.systemError = action.payload;
      },
   },
   extraReducers: (builder) => {},
});

export const { setAppbarSmall, setCompanyInfo, setSystemError } = companySlice.actions;

export const companyStore = (state: RootState) => state.companyStore;

export default companySlice.reducer;
