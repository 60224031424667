import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { accountStore, setAccountDialogOpen, setNoCloseBtn } from 'features/account/stores/account.slice';
import NewPasswordResetDialog from 'features/account/components/NewPasswordResetDialog';
import { changeCustomModalOpen } from './utils/dialog.helper';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   const location = useLocation();
   const navigate = useNavigate();
   const [resetData, setResetData] = useState({
      token: '',
      username: '',
   });
   const dispatch = useAppDispatch();
   const { isLoggedIn, accountDialogOpen } = useAppSelector(accountStore);
   const [newPasswordResetOpen, setNewPasswordResetOpen] = useState(false);

   useEffect(() => {
      if (location.hash && location.hash.startsWith('#resetpassword')) {
         const rawHash = location.hash.substring(1);
         let decodedHash;
         try {
            decodedHash = decodeURIComponent(rawHash);
         } catch (e) {
            decodedHash = rawHash;
         }

         const params = decodedHash.split('|');
         const data: any = {};

         params.forEach((param) => {
            const [key, value] = param.split(':');
            data[key] = value;
         });

         setResetData({
            token: data['token'] || '',
            username: data['username'] || '',
         });
         setNewPasswordResetOpen(true);
      }

      if (location.hash?.startsWith('#ghost')) {
         const [, token] = location.hash.split('|token:');
         const existingMPTSession = JSON.parse(localStorage.MPTSessionV2 || '{}');
         const expirationUTC = new Date();
         expirationUTC.setTime(expirationUTC.getTime() + 4 * 60 * 60 * 1000);

         existingMPTSession.authToken = {
            expirationUTC: expirationUTC,
            token,
         };
         localStorage.setItem('MPTSessionV2', JSON.stringify(existingMPTSession));
         const newLocation = {
            pathname: location.pathname,
         };
         navigate(newLocation, { replace: true });
         window.location.replace(window.location.href);
      }
   }, [location]);

   useEffect(() => {
      if (isLoggedIn !== null && !isLoggedIn && !accountDialogOpen && !newPasswordResetOpen) {
         dispatch(setNoCloseBtn(true));
         dispatch(setAccountDialogOpen(true));
      }
      if (newPasswordResetOpen) {
         dispatch(setNoCloseBtn(true));
         dispatch(setAccountDialogOpen(false));
      }
   }, [isLoggedIn, accountDialogOpen, newPasswordResetOpen]);

   useEffect(() => {
      changeCustomModalOpen(newPasswordResetOpen);
   }, [newPasswordResetOpen]);

   return (
      <>
         {isLoggedIn ? children : null}
         {resetData.username && resetData.token ? (
            <NewPasswordResetDialog
               username={resetData.username}
               token={resetData.token}
               newPasswordResetOpen={newPasswordResetOpen}
               setNewPasswordResetOpen={setNewPasswordResetOpen}
            />
         ) : null}
      </>
   );
};

export default ProtectedRoute;
