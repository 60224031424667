import { useState, useRef, useEffect, RefObject, Dispatch, SetStateAction } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { useMediaQuery, useTheme } from '@mui/material';

interface Size {
   width: number;
   height: number;
   top: number;
   left: number;
   right: number;
   bottom: number;
   x: number;
   y: number;
}

const useHeaderSize = (): [
   Size | undefined,
   RefObject<HTMLDivElement>,
   number,
   Dispatch<SetStateAction<number>>,
   boolean,
] => {
   const theme = useTheme();
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));
   const headerContainerRef = useRef<HTMLDivElement | null>(null);
   const [headerSize, setHeaderSize] = useState<Size | undefined>();
   const [headerMainHeight, setHeaderMainHeight] = useState(getSiteHeaderHeight());
   const [hasFixedHeader, setHasFixedHeader] = useState(false);

   function getSiteHeaderHeight() {
      const className = isLargeScreenDown ? 'app-bar' : 'header-main';
      let headerElements = document.getElementsByClassName(className);

      if (!isLargeScreenDown && headerElements.length === 0) {
         headerElements = document.getElementsByClassName('app-bar');
      }

      return headerElements.length > 0 ? headerElements[0].clientHeight : 0;
   }

   useEffect(() => {
      setHeaderMainHeight(getSiteHeaderHeight());
   }, [isLargeScreenDown]);

   useResizeObserver(headerContainerRef, (entry) => {
      setHeaderMainHeight(getSiteHeaderHeight());
      setHasFixedHeader(document.getElementsByClassName('fixed-header').length > 0);

      setHeaderSize({
         width: entry.contentRect.width,
         height: entry.contentRect.height,
         top: entry.contentRect.top,
         left: entry.contentRect.left,
         right: entry.contentRect.right,
         bottom: entry.contentRect.bottom,
         x: entry.contentRect.x,
         y: entry.contentRect.y,
      });
   });

   return [headerSize, headerContainerRef, headerMainHeight, setHeaderMainHeight, hasFixedHeader];
};

export default useHeaderSize;
