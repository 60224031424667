import { Box, Typography } from '@mui/material';
import React from 'react';
import { MptLink } from 'components/MptLink';
import HomeIcon from '@mui/icons-material/Home';

interface AddDeliveryAddressProps {
   text?: string;
   onOpen: () => void;
}

const AddDeliveryAddressLink: React.FC<AddDeliveryAddressProps> = ({ onOpen, text }) => {
   return (
      <>
         <MptLink underline="none" color="inherit" sx={{ cursor: 'pointer' }} onClick={onOpen}>
            <Box display="flex" alignItems="center" component="span">
               <HomeIcon fontSize="medium" />
               <Typography ml={1} fontSize="1.2rem">
                  {text ? text : 'Add delivery address'}
               </Typography>
            </Box>
         </MptLink>
      </>
   );
};

export default AddDeliveryAddressLink;
