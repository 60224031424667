import React, { useEffect } from 'react';
import Profile from 'features/profile/Profile';
import { handleFooterElementDisplay, handleMainElementDisplay } from '../config/utils';

export default function AccountPage() {
   useEffect(() => {
      handleMainElementDisplay(false);
      handleFooterElementDisplay(true);
   }, []);

   return <Profile />;
}
