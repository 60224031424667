import React from 'react';
import 'features/account/styles/Account.scss';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { accountDeleteBillingAsync, accountStore } from 'features/account/stores/account.slice';
import { LoadStatus } from 'config/utils';

import {
   checkoutPaymentStore,
   setDeletePaymentDialogOpen,
   setPaymentDialogOpen,
} from '../../stores/checkout-payment.slice';

const DeletePaymentDialog = () => {
   const dispatch = useAppDispatch();
   const { deletePaymentDialogOpen, paymentToDelete } = useAppSelector(checkoutPaymentStore);
   const { deleteBillingStatus, fetchBillingProfilesStatus } = useAppSelector(accountStore);
   const isLoading = deleteBillingStatus === LoadStatus.loading || fetchBillingProfilesStatus === LoadStatus.loading;

   const onPaymentDeleteClose = () => {
      dispatch(setDeletePaymentDialogOpen(false));
      dispatch(setPaymentDialogOpen(true));
   };

   const handlePaymentDelete = () => {
      paymentToDelete && dispatch(accountDeleteBillingAsync({ deletePaymentId: paymentToDelete.id }));
   };

   return (
      <ConfirmationDialog
         open={deletePaymentDialogOpen}
         onClose={onPaymentDeleteClose}
         confirmContent={
            <>
               {paymentToDelete ? (
                  <Box>
                     <span className="shipping-address-name">
                        {paymentToDelete.firstName}, {paymentToDelete.lastName}
                     </span>
                     <br />
                     {paymentToDelete.address}
                     {paymentToDelete.address2 ? (
                        <>
                           <br />
                           {paymentToDelete.address2}
                        </>
                     ) : null}
                     <br />
                     {paymentToDelete.city}, {paymentToDelete.region}, {paymentToDelete.postal}
                     <br />
                     {`Card ending in ${paymentToDelete.accountNum}`}
                  </Box>
               ) : null}
            </>
         }
         title={'Delete Payment Method?'}
         confirmBtnText={'Delete'}
         cancelBtnText={'Cancel'}
         isLoading={isLoading}
         onConfirm={() => handlePaymentDelete()}
         onCancel={() => onPaymentDeleteClose()}
      />
   );
};

export default DeletePaymentDialog;
