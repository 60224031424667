import React, { useState, useEffect } from 'react';
import { FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { formatter, NUM_VIEW_ITEMS } from '../utils/menu-item.const';
import { Common } from '../../../config/utils';
import { IMenuItemFormValues } from '../types/menu-item-form.types';
import { IProductVariation } from '../../order/types/product.types';
interface IFormCheckboxProps {
   variation: IProductVariation;
   fieldIndex: number;
   control: any;
   updateSelectionProps: (index: number, item: any, type: string, event?: any) => void;
   errorFields: Number[];
   setErrorFields: (errorFields: Number[]) => void;
}

const FormCheckbox = ({
   variation,
   fieldIndex,
   control,
   updateSelectionProps,
   errorFields,
   setErrorFields,
}: IFormCheckboxProps) => {
   const { getValues } = useFormContext<IMenuItemFormValues>();

   const getPrevSelectedOptions = () => {
      if (!variation || !variation.options) return;
      const selectedCheckedItems: Record<string, boolean> = {};
      variation.options.forEach((option: any) => {
         if (getValues(`variations.${fieldIndex}.option_id`).includes(option.id)) {
            selectedCheckedItems[option.id] = true;
         }
      });
      return Object.keys(selectedCheckedItems).length > 0 ? selectedCheckedItems : null;
   };

   const [checkedItems, setCheckedItems] = useState(getPrevSelectedOptions() ?? {});

   const [viewMore, setViewMore] = useState(false);

   const getNumItemChecked = () => {
      let numItemChecked = 0;
      for (let [key, value] of Object.entries(checkedItems)) {
         if (value) {
            numItemChecked++;
         }
      }
      return numItemChecked;
   };

   const shouldDisableCheckbox = (value: string): boolean => {
      const maxAllowed = variation.maxAllowed;
      return !!(maxAllowed && maxAllowed !== 0 && getNumItemChecked() >= maxAllowed && !checkedItems[value]);
   };

   const handleOptionChange = (optionId: string, event: any) => {
      setCheckedItems({ ...checkedItems, [optionId]: event.target.checked });
   };

   useEffect(() => {
      //sets the default selection
      variation.options.forEach((option: any) => {
         if (option.default) updateSelectionProps(fieldIndex, option, 'multiselect', { target: { checked: true } });
      });
   }, []);

   useEffect(() => {
      const minRequired = (getValues(`variations.${fieldIndex}.variation`) as IProductVariation)?.minRequired;
      const newErrorFields = !!(minRequired && getNumItemChecked() < minRequired)
         ? [...errorFields].concat(errorFields.includes(fieldIndex) ? [] : [fieldIndex])
         : errorFields.filter((item) => item !== fieldIndex);
      setErrorFields(newErrorFields);
   }, [getNumItemChecked()]);

   return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
         <Controller
            name={`variations.${fieldIndex}` as const}
            control={control}
            render={() => (
               <FormGroup className="item-options" aria-label={'option-' + variation.id}>
                  {variation.options.map((option: any, i: number) => {
                     return (
                        <div
                           className="option"
                           key={option.id}
                           style={!viewMore && i >= NUM_VIEW_ITEMS ? { display: 'none' } : {}}
                        >
                           <FormControlLabel
                              value={option}
                              control={
                                 <Checkbox
                                    checked={checkedItems[option.id]}
                                    disabled={shouldDisableCheckbox(option.id)}
                                 />
                              }
                              label={Common.renderHtml(option.text)}
                              onChange={(event: { target: any }) => {
                                 handleOptionChange(option.id, event);
                                 updateSelectionProps(fieldIndex, option, 'multiselect', event);
                              }}
                           />
                           {formatter.format(option.price)}
                        </div>
                     );
                  })}
               </FormGroup>
            )}
         />
         {variation.options.length > NUM_VIEW_ITEMS ? (
            viewMore ? (
               <div
                  className="item-options"
                  style={{ textDecorationLine: 'underline', color: 'red', cursor: 'pointer', width: 'fit-content' }}
                  onClick={() => setViewMore(false)}
               >
                  View less
               </div>
            ) : (
               <div
                  className="item-options"
                  style={{ textDecorationLine: 'underline', color: 'red', cursor: 'pointer', width: 'fit-content' }}
                  onClick={() => setViewMore(true)}
               >
                  View {variation.options.length - NUM_VIEW_ITEMS} more
               </div>
            )
         ) : null}
      </div>
   );
};

export default FormCheckbox;
