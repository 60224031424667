import React from 'react';
import { Stack, Typography, ToggleButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore, postSubscribeAllAsync, setOrderFrequency } from 'features/cart/stores/cart.slice';
import EventIcon from '@mui/icons-material/Event';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import { LoadStatus } from 'config/utils';
import { companyStore } from 'features/company/stores/company.slice';
import { TOrderRecurrence } from 'features/cart/types/cart.types';
import { StyledToggleButtonGroup } from './StyledToggleButtonGroup';
import useIsSmallMobileScreen from 'app/useIsSmallMobileScreen';
import CommonProgressLoader from 'components/CommonLoader';

interface IOrderFrequencyProps {
   standard?: boolean;
}

const OrderFrequency = ({ standard }: IOrderFrequencyProps) => {
   const dispatch = useAppDispatch();
   const isSmallMobileScreen = useIsSmallMobileScreen();
   const { orderFrequency, postSubscribeAllStatus } = useAppSelector(cartStore);
   const { companyInfo } = useAppSelector(companyStore);

   const handleChangeFrequency = (_event: React.MouseEvent<HTMLElement>, newFrequency: TOrderRecurrence) => {
      if (newFrequency !== null) {
         dispatch(postSubscribeAllAsync({}));
         dispatch(setOrderFrequency({ orderRecurrence: newFrequency }));
      }
   };

   const renderButtonContent = (label: string, icon?: React.ReactNode) => (
      <>
         {postSubscribeAllStatus === LoadStatus.loading && <CommonProgressLoader />}
         {icon ? icon : null}
         <span>{label}</span>
      </>
   );

   return companyInfo.uiSettings.subscribeAllOnlyMode ? (
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
         <Typography sx={{ fontWeight: '600' }} color={standard ? 'initial' : 'primary'} variant="body1">
            Frequency
         </Typography>
         <StyledToggleButtonGroup
            useDefault={standard}
            color={standard ? 'standard' : 'primary'}
            value={orderFrequency}
            exclusive
            onChange={handleChangeFrequency}
            aria-label="order-frequency"
         >
            <ToggleButton value="onetime" disabled={postSubscribeAllStatus === LoadStatus.loading}>
               {renderButtonContent('One-time', !isSmallMobileScreen ? <EventIcon sx={{ mr: 1 }} /> : null)}
            </ToggleButton>
            <ToggleButton value="weekly" disabled={postSubscribeAllStatus === LoadStatus.loading}>
               {renderButtonContent('Weekly', !isSmallMobileScreen ? <EventRepeatOutlinedIcon sx={{ mr: 1 }} /> : null)}
            </ToggleButton>
         </StyledToggleButtonGroup>
      </Stack>
   ) : null;
};

export default OrderFrequency;
