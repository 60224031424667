import React, { RefObject, useEffect } from 'react';
import { Stack, Typography, Grid, CircularProgress } from '@mui/material';
import DeliveryDialog from './DeliveryDialog';
import { setPickupDialogOpen } from '../../stores/checkout.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IDeliveryLocation } from '../../types/checkout.types';
import 'features/order-bar/styles/OrderBar.scss';
import { OrderType } from 'features/order/config/order.const';
import { cartStore } from 'features/cart/stores/cart.slice';
import AddDeliveryDialog from './AddDeliveryDialog';
import {
   accountStore,
   resetAddShippingStatus,
   resetDefaultShippingStatus,
   resetDeleteShippingStatus,
} from 'features/account/stores/account.slice';
import { LoadStatus } from 'config/utils';
import { orderStore, resetChangeLocationStatus } from 'features/order/stores/order.slice';
import { getHomeDeliveryShippingProfile } from '../../utils/checkout.helper';
import DeleteDeliveryAddressDialog from './DeleteDeliveryAddressDialog';
import DefaultDeliveryAddressDialog from './DefaultDeliveryAddressDialog';
import {
   checkoutShippingStore,
   setAddDeliveryDialogOpen,
   setAddressToDefault,
   setAddressToDelete,
   setDefaultAddressDialogOpen,
   setDeleteAddressDialogOpen,
   setDeliveryDialogOpen,
   updateShippingProfileAsync,
} from '../../stores/checkout-shipping.slice';
import DeliveryNameAddressDisplay from './DeliveryNameAddressDisplay';
import SkeletonLoader from 'components/SkeletonLoader';
import { SectionCard, SectionCardContent, SectionCardHeader } from '../SectionCard';
import { MptLink } from 'components/MptLink';
import PickUpDialog from './PickUpDialog';
import { checkoutLoadStore } from '../../stores/checkout-load.slice';

interface IOrderDetailsProps {
   orderLocationCardRef: RefObject<HTMLDivElement>;
   emptyDeliveryAddress: boolean;
   missingDeliverInformation: boolean;
}

const DeliveryLocationDisplay = () => {
   const { cart, deliveryLocations } = useAppSelector(cartStore);

   const shippingLocationId = cart.deliveries.length ? cart.deliveries[0].shippingLocationId : '';
   const deliveryLocation = deliveryLocations.find((deliveryLocation) => deliveryLocation.id === shippingLocationId);

   return (
      <Stack direction="row" display="block">
         <Typography variant="body1" fontWeight="bold">
            {deliveryLocation?.name}
         </Typography>
         <Typography variant="body1">{deliveryLocation?.description}</Typography>
         {deliveryLocation?.attributes?.hours && (
            <Typography variant="body1">Hours: {deliveryLocation.attributes.hours}</Typography>
         )}
      </Stack>
   );
};

const OrderLocation = ({
   orderLocationCardRef,
   emptyDeliveryAddress,
   missingDeliverInformation,
}: IOrderDetailsProps) => {
   const dispatch = useAppDispatch();
   const { cardLoading } = useAppSelector(checkoutLoadStore);
   const { changeLocationStatus } = useAppSelector(orderStore);
   const { shippingProfiles, deleteShippingStatus, defaultShippingStatus, addShippingStatus } =
      useAppSelector(accountStore);
   const { cart, deliveryLocations } = useAppSelector(cartStore);
   const {
      addressRuleError,
      hasSelectedAddressRuleError,
      deliveryDialogOpen,
      addDeliveryDialogOpen,
      deleteAddressDialogOpen,
      defaultAddressDialogOpen,
   } = useAppSelector(checkoutShippingStore);

   const pickUpLocations = deliveryLocations
      ? deliveryLocations.filter((location: IDeliveryLocation) => location.type === OrderType.pickup)
      : [];

   const [pickUpAddress, setPickUpAddress] = React.useState<IDeliveryLocation>();

   const orderMethod = cart.deliveries.length ? cart.deliveries[0].shippingLocationType : OrderType.homeDelivery;

   const shippingLocationId =
      cart?.deliveries && cart.deliveries.length > 0 && cart.deliveries[0].shippingLocationId
         ? cart.deliveries[0].shippingLocationId
         : '';

   const onDeleteShippingStatus = () => {
      if (deleteShippingStatus === LoadStatus.complete) {
         dispatch(resetDeleteShippingStatus());
         dispatch(setAddressToDelete(null));
         dispatch(setDeliveryDialogOpen(true));
         dispatch(setDeleteAddressDialogOpen(false));
      }
   };

   const onDefaultShippingStatus = () => {
      if (defaultShippingStatus === LoadStatus.complete) {
         dispatch(resetDefaultShippingStatus());
         dispatch(setAddressToDefault(null));
         dispatch(setDeliveryDialogOpen(true));
         dispatch(setDefaultAddressDialogOpen(false));
      }
   };

   const onAddAddressCancel = () => {
      dispatch(setAddDeliveryDialogOpen(false));
   };

   const onAddShippingStatus = () => {
      if (addShippingStatus === LoadStatus.complete) {
         dispatch(resetAddShippingStatus());

         const defaultShippingProfiles = shippingProfiles.filter((profile) => profile.default);
         const firstProfile = shippingProfiles.length > 0 ? shippingProfiles[0] : null;
         const shippingProfileId = defaultShippingProfiles.length > 0 ? defaultShippingProfiles[0] : firstProfile;

         shippingProfileId &&
            dispatch(
               updateShippingProfileAsync({
                  shippingLocationId,
                  shippingProfileId: shippingProfileId.id,
                  updatePresets: true,
               }),
            );
      }
   };

   useEffect(() => {
      if (!pickUpAddress && pickUpLocations.length > 0) {
         setPickUpAddress(pickUpLocations[0]);
      }
   }, [pickUpLocations, pickUpAddress]);

   useEffect(() => {
      if (changeLocationStatus === LoadStatus.complete) {
         dispatch(resetChangeLocationStatus());

         if (cart?.deliveries.length) {
            const shippingProfileToUse = getHomeDeliveryShippingProfile(cart, shippingProfiles);
            if (shippingProfileToUse !== null) {
               dispatch(
                  updateShippingProfileAsync({
                     shippingLocationId: shippingProfileToUse.shippingLocationId,
                     shippingProfileId: shippingProfileToUse.id,
                     updatePresets: true,
                  }),
               );
            }
         }
      }
   }, [changeLocationStatus]);

   useEffect(() => {
      onDefaultShippingStatus();
   }, [defaultShippingStatus]);

   useEffect(() => {
      onDeleteShippingStatus();
   }, [deleteShippingStatus]);

   useEffect(() => {
      onAddShippingStatus();
   }, [addShippingStatus]);

   return (
      <>
         <SectionCard
            ref={orderLocationCardRef}
            hasError={
               ((addressRuleError.length > 0 && hasSelectedAddressRuleError) ||
                  emptyDeliveryAddress ||
                  missingDeliverInformation) &&
               !cardLoading
            }
         >
            <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
               <SectionCardHeader
                  title={
                     <Grid item xs={12}>
                        <div className="bar-box">
                           <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                              2. {orderMethod === OrderType.homeDelivery ? 'Delivery' : 'Pick up'} Location
                           </Typography>
                           {!cardLoading ? (
                              <Typography variant={'subtitle1'} fontWeight="bold" mb={1} mt={1}>
                                 {orderMethod === OrderType.homeDelivery ? (
                                    <>
                                       <MptLink
                                          sx={{ marginRight: '5px', cursor: 'pointer', fontWeight: 600 }}
                                          onClick={() => dispatch(setAddDeliveryDialogOpen(true))}
                                       >
                                          Add
                                       </MptLink>
                                       {shippingProfiles && shippingProfiles.length > 0 ? (
                                          <>
                                             /
                                             <MptLink
                                                sx={{ marginLeft: '5px', cursor: 'pointer', fontWeight: 600 }}
                                                onClick={() => dispatch(setDeliveryDialogOpen(true))}
                                             >
                                                Change
                                             </MptLink>
                                          </>
                                       ) : null}
                                    </>
                                 ) : (
                                    <MptLink
                                       sx={{ marginLeft: '10px', cursor: 'pointer', fontWeight: 600 }}
                                       onClick={() => dispatch(setPickupDialogOpen(true))}
                                    >
                                       Change{' '}
                                    </MptLink>
                                 )}
                              </Typography>
                           ) : (
                              <p>
                                 <CircularProgress size={14} thickness={8} color="inherit" />
                              </p>
                           )}
                        </div>
                     </Grid>
                  }
               ></SectionCardHeader>
            </SkeletonLoader>
            <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '160px' }}>
               <SectionCardContent>
                  <Stack direction="column" gap={2} width="100%" minHeight={160}>
                     <DeliveryLocationDisplay />
                     <DeliveryNameAddressDisplay
                        emptyDeliveryAddress={emptyDeliveryAddress}
                        missingDeliverInformation={missingDeliverInformation}
                     />
                  </Stack>
               </SectionCardContent>
            </SkeletonLoader>
         </SectionCard>
         {deliveryDialogOpen && <DeliveryDialog />}
         {addDeliveryDialogOpen && <AddDeliveryDialog onCancel={onAddAddressCancel} />}
         {deleteAddressDialogOpen && <DeleteDeliveryAddressDialog />}
         {defaultAddressDialogOpen && <DefaultDeliveryAddressDialog />}
         {pickUpLocations.length ? <PickUpDialog missingDeliverInformation={missingDeliverInformation} /> : null}
      </>
   );
};

export default OrderLocation;
