import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardContent, Stack, Typography } from '@mui/material';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AccordionDetails from '@mui/material/AccordionDetails';
import CartItemList from './CartItemList';
import React, { useEffect, useState } from 'react';
import { IDelivery } from '../types/cart.types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
   deleteDeliveryDatesAsync,
   orderStore,
   resetDeleteDeliveryDatesStatus,
   setSelectedDeliverySlot,
} from 'features/order/stores/order.slice';
import { cartStore } from '../stores/cart.slice';
import { loadMPTSession } from 'utils/mpt-session.helper';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { LoadStatus } from 'config/utils';
import { setPackageId } from 'features/package/stores/package.slice';
import { setOrderDialogOpen, setOrderDialogOption } from '../../order/stores/order-bar.slice';
import { subscriptionStore } from 'features/subscription/stores/subscriptions.slice';
import { MptLink } from 'components/MptLink';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

interface ICartItemsAccordionProps {
   cartDeliveries: IDelivery[];
   viewOnlyMode?: boolean;
   checkoutMode?: boolean;
}

const CartItemsAccordion = ({ cartDeliveries, viewOnlyMode, checkoutMode }: ICartItemsAccordionProps) => {
   const dispatch = useAppDispatch();
   const { selectedDeliverySlot, deliveryOptions, deleteDeliveryDatesStatus } = useAppSelector(orderStore);
   const { subscriptionModActive } = useAppSelector(subscriptionStore);
   const { cart } = useAppSelector(cartStore);
   const [expand, setExpand] = useState<number | false>(-1);
   const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
   const [clearCartDeliveryId, setClearCartDeliveryId] = useState('');
   const loadedMptSession = loadMPTSession();
   const singleDelivery = !!loadedMptSession?.companyInfo?.uiSettings?.singleDelivery && !checkoutMode;

   const getDeliveryFromDeliveryId = () => {
      return cart?.deliveries?.find((delivery) => {
         return delivery.id === clearCartDeliveryId;
      });
   };

   const getDeliveryOptionLink = (delivery: IDelivery) => {
      if (viewOnlyMode) return;
      if (!subscriptionModActive && delivery?.id === selectedDeliverySlot.deliveryId) {
         return (
            <MptLink
               marginLeft="5px"
               fontWeight={600}
               sx={{ cursor: 'pointer', pointerEvents: 'all' }}
               onClick={(event) => handleChangeOrderDate(event, delivery)}
            >
               Change
            </MptLink>
         );
      } else if (delivery.id !== selectedDeliverySlot.deliveryId && cart.deliveries.length !== 1) {
         return (
            <MptLink
               marginLeft="5px"
               fontWeight={600}
               sx={{ cursor: 'pointer', pointerEvents: 'all' }}
               onClick={() => handleChangeDeliveryOption(delivery)}
            >
               Continue
            </MptLink>
         );
      }
      return;
   };

   useEffect(() => {
      if (cart?.deliveries.length) {
         const expandIndex = cart.deliveries.findIndex((delivery) => {
            return delivery.id === selectedDeliverySlot.deliveryId;
         });

         if (!checkoutMode) {
            setExpand(cart?.deliveries.length === 1 ? 0 : expandIndex);
         } else {
            setExpand(0);
         }
      }
   }, [cart]);

   const handleChangeDeliveryOption = (delivery: IDelivery) => {
      const foundTimeslot = deliveryOptions
         .find((deliveryOption) => {
            return deliveryOption.id === delivery.deliveryDayId;
         })
         ?.timeSlots.find((timeslot) => {
            return timeslot.id === delivery.timeSlotId;
         });
      dispatch(
         setSelectedDeliverySlot({
            deliveryId: delivery.id ?? '',
            dayId: delivery.deliveryDayId,
            timeSlotId: delivery.timeSlotId,
            deliveryDayName: delivery.deliveryDayName,
            packageId: delivery.packageid,
            start: foundTimeslot?.start ?? '',
            end: foundTimeslot?.end ?? '',
         }),
      );

      if (delivery.packageid) {
         dispatch(setPackageId(delivery.packageid));
      }
   };

   const handleClearCartClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, deliveryId: string) => {
      event.stopPropagation();
      setClearCartDeliveryId(deliveryId);
      setConfirmDialogOpen(true);
   };

   const handleDeleteDeliveryOption = () => {
      dispatch(deleteDeliveryDatesAsync({ deliveryId: clearCartDeliveryId }));
   };

   const handleChangeOrderDate = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, delivery: IDelivery) => {
      event.stopPropagation();
      handleChangeDeliveryOption(delivery);
      dispatch(setOrderDialogOption('change'));
      dispatch(setOrderDialogOpen(true));
   };

   useEffect(() => {
      if (deleteDeliveryDatesStatus === LoadStatus.complete) {
         setConfirmDialogOpen(false);
         dispatch(resetDeleteDeliveryDatesStatus());
      }
   }, [deleteDeliveryDatesStatus]);

   useEffect(() => {
      changeCustomModalOpen(confirmDialogOpen);
   }, [confirmDialogOpen]);

   return (
      <>
         {cartDeliveries?.map((delivery, index) => {
            return (
               <div key={delivery.id + index} className={'order-accordion-content'}>
                  <Accordion key={delivery.id} square expanded={expand === index}>
                     <AccordionSummary
                        aria-controls="panel-delivery-option"
                        sx={{ fontWeight: 'bold', padding: '0px 10px', pointerEvents: singleDelivery ? 'none' : null }}
                        expandIcon={
                           !singleDelivery && (
                              <ExpandMoreIcon
                                 sx={{
                                    pointerEvents: 'visiblePainted',
                                 }}
                              />
                           )
                        }
                        onClick={() => !singleDelivery && setExpand(expand === index ? false : index)}
                     >
                        <div
                           style={{
                              pointerEvents: 'none',
                              textAlign: 'left',
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                           }}
                        >
                           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography
                                 sx={
                                    delivery.id === selectedDeliverySlot.deliveryId || checkoutMode
                                       ? { color: 'text.primary', fontWeight: 'bold' }
                                       : { color: 'text.secondary' }
                                 }
                              >
                                 {delivery.deliveryDayName} {getDeliveryOptionLink(delivery)}
                              </Typography>
                              {!viewOnlyMode && !subscriptionModActive && (
                                 <RemoveShoppingCartIcon
                                    sx={{
                                       cursor: 'pointer',
                                       pointerEvents: 'auto',
                                       fontWeight: 400,
                                       marginRight: '20px',
                                    }}
                                    onClick={(event) => handleClearCartClick(event, delivery.id)}
                                 />
                              )}
                           </div>
                        </div>
                     </AccordionSummary>
                     <AccordionDetails
                        className={
                           delivery.id === selectedDeliverySlot.deliveryId && !checkoutMode ? 'cart-selected' : ''
                        }
                        sx={{ padding: '8px 0 16px 0' }}
                     >
                        {cartDeliveries[index]?.products?.length > 0 ? (
                           <CartItemList
                              cartProducts={cartDeliveries[index]?.products}
                              viewOnlyMode={viewOnlyMode}
                              checkoutMode={checkoutMode}
                           />
                        ) : (
                           <CardContent sx={{ textAlign: 'center' }}>
                              <Typography variant="body2" color="text.secondary">
                                 Your cart is empty.
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                 Choose an item to get started.
                              </Typography>
                           </CardContent>
                        )}
                     </AccordionDetails>
                  </Accordion>
               </div>
            );
         })}
         {getDeliveryFromDeliveryId()?.deliveryDayName ? (
            <ConfirmationDialog
               open={confirmDialogOpen}
               onClose={() => setConfirmDialogOpen(false)}
               confirmContent={
                  <Stack direction="column" spacing={2}>
                     <Typography variant={'h5'}>Remove all items from cart?</Typography>
                     <Typography variant={'h5'} fontWeight="bold">
                        {getDeliveryFromDeliveryId()?.deliveryDayName}
                     </Typography>
                  </Stack>
               }
               confirmBtnText={'Confirm'}
               cancelBtnText={'Cancel'}
               isLoading={deleteDeliveryDatesStatus === LoadStatus.loading}
               onConfirm={handleDeleteDeliveryOption}
               onCancel={() => setConfirmDialogOpen(false)}
            />
         ) : null}
      </>
   );
};

export default CartItemsAccordion;
