import { useEffect, useRef, useState } from 'react';
import { IProduct } from '../types/product.types';
import { getProductByProductId } from '../utils/prduct-list.helper';
import { CategoryProductMapping } from '../types/category-product.types';

interface VisibleEntry {
   productId: string;
   top: number;
}

interface useProductVisibilityObserverProps {
   productList: IProduct[];
   dataToShow: CategoryProductMapping;
   productView: string;
}

const useProductVisibilityObserver = ({ productList, dataToShow, productView }: useProductVisibilityObserverProps) => {
   const productRefs = useRef<Record<string, HTMLDivElement | null>>({});
   const [topProductId, setTopProductId] = useState<string | null>(null);
   const orderHeader = document.querySelector('#order-sticky-header') as HTMLElement | null;
   // Get the height of the headers
   const headersOffset = orderHeader ? orderHeader.offsetTop + orderHeader.offsetHeight : 0;

   useEffect(() => {
      const observerOptions = {
         root: null, // The viewport
         rootMargin: `-${headersOffset}px 0px 0px 0px`,
         threshold: 0.8,
      };

      let visibleEntries: VisibleEntry[] = [];

      const observerCallback = (entries: IntersectionObserverEntry[]) => {
         const entriesId = entries.map((entry) => {
            if (entry.target.getAttribute('data-product-id')) {
               return getProductByProductId(entry.target.getAttribute('data-product-id') ?? '', productList)?.id;
            }
         });

         entries.forEach((entry) => {
            const productId = entry.target.getAttribute('data-product-id');

            if (productId) {
               if (entry.isIntersecting) {
                  // Check if the product is already in visibleEntries
                  const existingEntryIndex = visibleEntries.findIndex((item) => item.productId === productId);

                  if (existingEntryIndex !== -1) {
                     // Update the top value
                     visibleEntries[existingEntryIndex].top = entry.boundingClientRect.top;
                  } else {
                     // Add new entry
                     visibleEntries.push({
                        productId: productId,
                        top: entry.boundingClientRect.top,
                     });
                  }
               } else {
                  // Remove the entry if it's no longer intersecting
                  visibleEntries = visibleEntries.filter((item) => item.productId !== productId);
               }
            }
         });

         //This updates the top position of each product that is not part of new entries
         visibleEntries.forEach((item, index) => {
            if (!entriesId.includes(item.productId)) {
               visibleEntries[index].top =
                  document
                     .querySelector(`[data-product-id="${visibleEntries[index].productId}"]`)
                     ?.getBoundingClientRect().top ?? 0;
            }
         });

         if (visibleEntries.length > 0) {
            // Sort the entries based on the 'top' value
            visibleEntries.sort((a, b) => a.top - b.top);

            const topProduct = visibleEntries[0];
            setTopProductId(topProduct.productId);
         }
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      Object.values(productRefs.current).forEach((ref) => {
         if (ref) observer.observe(ref);
      });

      return () => {
         observer.disconnect();
      };
   }, [productRefs, dataToShow]);

   useEffect(() => {
      if (topProductId) {
         setTimeout(() => {
            const topProductElement = document.querySelector(
               `[data-product-id="${topProductId}"]`,
            ) as HTMLElement | null;

            if (topProductElement) {
               const elementPosition = topProductElement.getBoundingClientRect().top;
               const offsetPosition = elementPosition + window.pageYOffset - headersOffset;

               window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth',
               });
            }
         }, 0);
      }
   }, [productView]);

   return productRefs;
};

export default useProductVisibilityObserver;
