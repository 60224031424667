import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge, Box, Fab, Tooltip, Menu, Alert } from '@mui/material';
import './styles/Appbar/Appbar.scss';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IMessage } from 'features/account/types/account.types';

interface INotificationButtonProps {
   filteredMessages: IMessage[];
}

const NotificationButton = ({ filteredMessages }: INotificationButtonProps) => {
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const fabRef = useRef<HTMLButtonElement | null>(null);
   const prevMessagesRef = useRef<IMessage[]>([]);

   const convertMessageType = useCallback((type: string | null) => {
      if (type === 'danger') {
         return 'error';
      } else if (type === null) {
         return undefined;
      }
      return type as 'error' | 'warning' | 'success' | 'info';
   }, []);

   const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   }, []);

   const handleMenuClose = useCallback(() => {
      setAnchorEl(null);
   }, []);

   useEffect(() => {
      const messagesChanged = JSON.stringify(filteredMessages) !== JSON.stringify(prevMessagesRef.current);

      if (messagesChanged && filteredMessages.length > 0 && fabRef.current) {
         setAnchorEl(fabRef.current);
      }

      prevMessagesRef.current = filteredMessages;
   }, [filteredMessages]);

   return (
      <Box sx={{ position: 'relative' }}>
         <Tooltip title={'Notifications'}>
            <Fab
               ref={fabRef}
               size="medium"
               className="cart-btn"
               color="inherit"
               onClick={(event) => handleMenuOpen(event)}
            >
               <Badge badgeContent={filteredMessages.length} color="error">
                  <NotificationsIcon color="action" />
               </Badge>
            </Fab>
         </Tooltip>
         <Menu
            id={'notification-menu'}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            slotProps={{
               paper: {
                  elevation: 0,
                  sx: {
                     overflow: 'visible',
                     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                     mt: 1.5,
                     paddingLeft: 1,
                     paddingRight: 1,
                  },
               },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            autoFocus={false}
            disableScrollLock={true}
         >
            {filteredMessages.map((message, index) => {
               return (
                  <Alert
                     key={message.message ?? 'no message' + index}
                     className={'notification-alert'}
                     severity={convertMessageType(message.type)}
                  >
                     {message.message}
                  </Alert>
               );
            })}
         </Menu>
      </Box>
   );
};

export default NotificationButton;
