import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

export type TOrderDialogOption = 'change' | 'add' | 'change-package';

export interface OrderBarState {
   orderDialogOpen: boolean;
   orderDialogOption: TOrderDialogOption;
}

export const initialOrderBarState: OrderBarState = {
   orderDialogOpen: false,
   orderDialogOption: 'change',
};

export const orderBarSlice = createSlice({
   name: 'orderBar',
   initialState: initialOrderBarState,
   reducers: {
      setOrderDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.orderDialogOpen = action.payload;
      },
      setOrderDialogOption: (state, action: PayloadAction<TOrderDialogOption>) => {
         state.orderDialogOption = action.payload;
      },
   },
   extraReducers: (builder) => {},
});

export const { setOrderDialogOpen, setOrderDialogOption } = orderBarSlice.actions;

export const orderBarStore = (state: RootState) => state.orderBarStore;

export default orderBarSlice.reducer;
