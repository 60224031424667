import React, { useEffect, useState } from 'react';
import { Grid, Typography, Pagination, CardContent, Box, Button, useTheme } from '@mui/material';
import '../../styles/style-vars.module.scss';
import Card from '@mui/material/Card';
import './styles/Subscriptions.scss';
import dayjs from 'dayjs';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PaymentIcon from '@mui/icons-material/Payment';
import CancelIcon from '@mui/icons-material/Cancel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import AccordionDetails from '@mui/material/AccordionDetails';
import DeliveryItemList from './components/DeliveryItemList';
import SubscriptionEdit from './components/SubscriptionEdit';
import { getTotals } from './utils/subscriptions.helper';
import {
   fetchUserSubscriptionAsync,
   fetchUserSubscriptionsAsync,
   postSubscriptionUnfreezeAsync,
   resetPostSubscriptionUnfreezeStatus,
   setSelectedSubscription,
   subscriptionStore,
} from './stores/subscriptions.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import RequestChangeDialog from './components/RequestChangeDialog';
import { ISubscription } from './types/subscriptions.types';
import { LoadStatus } from 'config/utils';
import { IDelivery } from '../cart/types/cart.types';
import CircularProgress from '@mui/material/CircularProgress';
import { setPaymentDialogOpen } from '../checkout/stores/checkout-payment.slice';
import PaymentDetailsDialog from '../checkout/components/paymentDetails/PaymentDetailsDialog';
import { changeCustomModalOpen } from '../../components/utils/dialog.helper';
const SUBS_PER_PAGE = 5;

const Subscriptions = () => {
   const dispatch = useAppDispatch();
   const {
      userSubscriptions,
      selectedSubscription,
      fetchUserSubscriptionStatus,
      fetchUserSubscriptionsStatus,
      postSubscriptionUnfreezeStatus,
   } = useAppSelector(subscriptionStore);
   const numPages = userSubscriptions?.length > 0 ? Math.ceil(userSubscriptions.length / SUBS_PER_PAGE) : 0;
   const [currentPage, setCurrentPage] = useState(1);
   const [subEdit, setSubEdit] = useState<'freeze' | 'cancel' | null>(null);
   const [subscription, setSubscription] = useState<any | null>(null);
   const [requestChangeOpen, setRequestChangeOpen] = useState<boolean>(false);
   const localizedFormat = require('dayjs/plugin/localizedFormat');
   const navigate = useNavigate();
   dayjs.extend(localizedFormat);
   const theme = useTheme();
   const isUnfreezeLoading = postSubscriptionUnfreezeStatus === LoadStatus.loading;

   const getDeliveriesAccordion = (subDeliveries: IDelivery[]) => {
      if (!subDeliveries) return null;
      return (
         <Box>
            {subDeliveries.map((delivery) => (
               <Accordion key={delivery.id} square>
                  <AccordionSummary
                     aria-controls="panel-delivery-option"
                     sx={{ fontWeight: 'bold' }}
                     expandIcon={<ExpandMoreIcon />}
                  >
                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                           sx={{ fontWeight: 'bold' }}
                        >{`${delivery.deliveryDayName}, ${dayjs(delivery.nextDeliveryFrom).format('h:mm A')} - ${dayjs(delivery.nextDeliveryTo).format('h:mm A')} (${delivery.shippingLocationName})`}</Typography>
                     </div>
                  </AccordionSummary>
                  <AccordionDetails>
                     <DeliveryItemList cartProducts={delivery.products} />
                  </AccordionDetails>
               </Accordion>
            ))}
         </Box>
      );
   };

   const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
   };

   const handleSubEdit = (edit: 'freeze' | 'cancel', sub: any) => {
      setSubEdit(edit);
      setSubscription(sub);
   };

   const handlePickNewMeals = (sub: ISubscription) => {
      dispatch(setSelectedSubscription(sub));
      dispatch(fetchUserSubscriptionAsync({ subscriptionId: sub.id }));
   };

   const handleUnfreezeSub = (sub: ISubscription) => {
      dispatch(
         postSubscriptionUnfreezeAsync({
            subscriptionId: sub.id,
            bodyInput: { fromDate: sub.freezes[0].fromDate, toDate: sub.freezes[0].toDate },
         }),
      );
   };

   const handleSubscriptionBilling = (sub: ISubscription) => {
      dispatch(setPaymentDialogOpen(true));
      dispatch(setSelectedSubscription(sub));
   };

   const handleRequestChange = () => {
      setRequestChangeOpen(true);
   };

   const onFetchUserSubscriptionStatus = () => {
      if (fetchUserSubscriptionStatus === LoadStatus.complete) {
         navigate('/order');
      }
   };

   useEffect(() => {
      onFetchUserSubscriptionStatus();
   }, [fetchUserSubscriptionStatus]);

   useEffect(() => {
      dispatch(fetchUserSubscriptionsAsync({}));
      if (postSubscriptionUnfreezeStatus === LoadStatus.complete) {
         dispatch(resetPostSubscriptionUnfreezeStatus());
      }
   }, [postSubscriptionUnfreezeStatus]);

   useEffect(() => {
      changeCustomModalOpen(requestChangeOpen);
   }, [requestChangeOpen]);

   return userSubscriptions.length ? (
      <>
         <Pagination
            count={numPages}
            shape="rounded"
            size={'large'}
            page={currentPage}
            showFirstButton
            showLastButton
            onChange={handlePageChange}
         />
         {userSubscriptions
            .filter((s, index) => {
               return index >= SUBS_PER_PAGE * (currentPage - 1) && index < SUBS_PER_PAGE * currentPage;
            })
            .map((sub) => {
               return (
                  <Card key={sub.id} className="subscription-summary" variant="outlined" sx={{ width: '100%' }}>
                     <CardContent className="section-header" style={{ flexDirection: 'column' }}>
                        <Box display={'flex'} flexDirection={'row'} width={'100%'}>
                           <Grid container>
                              <Grid xs={12} item className={'dates-container'}>
                                 <Box marginRight={'25px'} marginBottom={'10px'}>
                                    <Typography variant={'h5'}>ORDER PLACED</Typography>
                                    <Typography variant={'h6'}>
                                       {dayjs(sub.creationDate).format('MMM DD, YYYY')}
                                    </Typography>
                                 </Box>
                              </Grid>
                           </Grid>
                           <Grid container>
                              <Grid xs={12} item className={'dates-container'}>
                                 <Box marginLeft={'auto'} marginBottom={'10px'}>
                                    <Typography variant={'h5'}>NEXT PAYMENT DATE</Typography>
                                    <Typography variant={'h6'}>
                                       {dayjs(sub.nextPaymentDate).format('MMM DD, YYYY')}
                                    </Typography>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Box>
                        {sub?.freezes?.length ? (
                           <Typography variant={'h6'} color={theme.palette.warning.main} style={{ display: 'flex' }}>
                              <AcUnitIcon style={{ marginRight: '0.5rem' }} />
                              {`Scheduled Account Freeze From ${dayjs(sub.freezes[0].fromDate).format('L')} to ${dayjs(sub.freezes[0].toDate).format('L')}`}
                           </Typography>
                        ) : null}
                        {sub?.cancellation ? (
                           <Typography variant={'h6'} color={theme.palette.warning.main} style={{ display: 'flex' }}>
                              <CancelIcon style={{ marginRight: '0.5rem' }} />
                              {`Cancelling on ${dayjs(sub.cancellation).format('L')}`}
                           </Typography>
                        ) : null}
                     </CardContent>
                     <CardContent className="section-content">
                        <Grid container>
                           <Grid xs={12} md={6} item>
                              <Typography variant={'h6'} className={'title'}>
                                 Deliveries
                              </Typography>
                              <Box display="flex" alignItems="center" width={'90%'} marginBottom={'30px'}>
                                 {getDeliveriesAccordion(sub.deliveries)}
                              </Box>
                           </Grid>
                           <Grid xs={12} md={6} item>
                              <Typography variant={'h6'} className={'title'}>
                                 Total
                              </Typography>
                              {getTotals(sub)}
                              <Grid xs={12} item marginTop={'30px'} marginBottom={'30px'}>
                                 <Typography variant={'h6'} className={'title'}>
                                    Actions
                                 </Typography>
                                 <Grid container>
                                    <Grid xs={12} sm={6} md={12} lg={6} item>
                                       <Box display={'flex'} justifyContent={'center'}>
                                          {sub.isAfterCutoff ? (
                                             <Button
                                                className="common-btn subscription-btn"
                                                variant="contained"
                                                startIcon={<AutoFixHighIcon />}
                                                sx={{ whiteSpace: 'nowrap' }}
                                                onClick={handleRequestChange}
                                             >
                                                Request Change
                                             </Button>
                                          ) : (
                                             <Button
                                                className="common-btn subscription-btn"
                                                variant="contained"
                                                startIcon={<MenuBookIcon />}
                                                sx={{ whiteSpace: 'nowrap' }}
                                                onClick={() => handlePickNewMeals(sub)}
                                             >
                                                Pick New Meals
                                             </Button>
                                          )}
                                       </Box>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={12} lg={6} item>
                                       <Box display={'flex'} justifyContent={'center'}>
                                          <Button
                                             className="common-btn subscription-btn"
                                             variant="contained"
                                             startIcon={<PaymentIcon />}
                                             sx={{ whiteSpace: 'nowrap' }}
                                             onClick={() => handleSubscriptionBilling(sub)}
                                          >
                                             Payment Card {sub.billingProfile?.accountNum ?? null}
                                          </Button>
                                       </Box>
                                    </Grid>
                                 </Grid>
                                 <Grid container>
                                    <Grid xs={12} sm={6} md={12} lg={6} item>
                                       <Box display={'flex'} justifyContent={'center'}>
                                          {sub?.freezes?.length ? (
                                             <Button
                                                className="common-btn subscription-btn"
                                                variant="contained"
                                                startIcon={isUnfreezeLoading ? null : <WhatshotIcon />}
                                                sx={{ whiteSpace: 'nowrap' }}
                                                onClick={() => handleUnfreezeSub(sub)}
                                                disabled={isUnfreezeLoading}
                                             >
                                                {isUnfreezeLoading ? (
                                                   <CircularProgress size={14} thickness={8} color="inherit" />
                                                ) : (
                                                   'Unfreeze Subscription'
                                                )}
                                             </Button>
                                          ) : (
                                             <Button
                                                className="common-btn subscription-btn"
                                                variant="contained"
                                                startIcon={<AcUnitIcon />}
                                                sx={{ whiteSpace: 'nowrap' }}
                                                onClick={() => handleSubEdit('freeze', sub)}
                                             >
                                                Schedule Subscription Freeze
                                             </Button>
                                          )}
                                       </Box>
                                    </Grid>
                                    {sub.cancellation ? null : (
                                       <Grid xs={12} sm={6} md={12} lg={6} item>
                                          <Box display={'flex'} justifyContent={'center'}>
                                             <Button
                                                className="common-btn subscription-btn"
                                                variant="contained"
                                                startIcon={<CancelIcon />}
                                                sx={{ whiteSpace: 'nowrap' }}
                                                onClick={() => handleSubEdit('cancel', sub)}
                                             >
                                                Cancel Subscription
                                             </Button>
                                          </Box>
                                       </Grid>
                                    )}
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </CardContent>
                  </Card>
               );
            })}
         <Pagination
            count={numPages}
            shape="rounded"
            size={'large'}
            page={currentPage}
            showFirstButton
            showLastButton
            onChange={handlePageChange}
         />
         <RequestChangeDialog requestChangeOpen={requestChangeOpen} setRequestChangeOpen={setRequestChangeOpen} />
         <SubscriptionEdit edit={subEdit} setEdit={setSubEdit} subscription={subscription} />
         <PaymentDetailsDialog subscription={selectedSubscription} />
      </>
   ) : fetchUserSubscriptionsStatus === LoadStatus.loading ? (
      <Typography variant={'h5'}>Loading...</Typography>
   ) : (
      <Typography variant={'h5'}>No subscription found</Typography>
   );
};

export default Subscriptions;
