import { Box, Typography } from '@mui/material';
import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { MptLink } from 'components/MptLink';

interface AddPaymentLinkProps {
   onOpen: () => void;
}

const AddPaymentLink: React.FC<AddPaymentLinkProps> = ({ onOpen }) => {
   return (
      <MptLink underline="none" color="inherit" sx={{ cursor: 'pointer' }} onClick={onOpen}>
         <Box display="flex" alignItems="center" component="span">
            <CreditCardIcon fontSize="medium" />
            <Typography ml={1} fontSize="1.2rem">
               Add payment method
            </Typography>
         </Box>
      </MptLink>
   );
};

export default AddPaymentLink;
