import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { productStore } from '../stores/product.slice';
import { companyStore } from 'features/company/stores/company.slice';
import { MptLink } from 'components/MptLink';

interface ICategoryDescProps {
   categoryName: string;
}

const CategoryDesc = ({ categoryName }: ICategoryDescProps) => {
   const theme = useTheme();
   const { productCategoryList } = useAppSelector(productStore);
   const { companyInfo } = useAppSelector(companyStore);
   const foundCategory = productCategoryList.find((productCategory) => productCategory.name === categoryName);
   let foundCategoryDesc = null;

   if (foundCategory && companyInfo.uiSettings?.customCategoryDesc) {
      foundCategoryDesc = companyInfo.uiSettings?.customCategoryDesc.find(
         (categoryDesc) => categoryDesc.id === foundCategory.id,
      );
   }

   const scrollToFaq = () => {
      const element = document.getElementById('mpt-faq-row');
      if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
      }
   };

   return (
      <>
         {foundCategoryDesc && (
            <Box
               display="block"
               width="100%"
               justifyContent="center"
               alignItems="center"
               textAlign="center"
               pb={2}
               pr={1}
            >
               <Typography variant="body1" color={theme.palette.primary.contrastText} fontWeight={600} component="span">
                  {foundCategoryDesc.text}
               </Typography>
               <MptLink onClick={() => scrollToFaq()} fontWeight="700" sx={{ ml: 1, whiteSpace: 'nowrap' }}>
                  More info
               </MptLink>
            </Box>
         )}
      </>
   );
};

export default CategoryDesc;
