import dayjs from 'dayjs';
import { ICart, ISelectedDeliverySlot, TOrderRecurrence } from 'features/cart/types/cart.types';
import { IDeliveryOption, IDeliveryTimeSlot } from '../types/order.types';
import { setSelectedDeliverySlot } from '../stores/order.slice';
import { setPackageId } from 'features/package/stores/package.slice';
import { store } from 'app/store';
import fastEqual from 'fast-deep-equal';
import { setOrderFrequency } from 'features/cart/stores/cart.slice';
import { SKELETON_LOADING } from './order.const';
import { IProduct } from '../types/product.types';

export const getShortDayStr = (longDayStr: string) => {
   switch (longDayStr.toLowerCase()) {
      case 'sunday':
         return 'Sun';
      case 'monday':
         return 'Mon';
      case 'tuesday':
         return 'Tue';
      case 'wednesday':
         return 'Wed';
      case 'thursday':
         return 'Thu';
      case 'friday':
         return 'Fri';
      default:
         return 'Sat';
   }
};

export const getShortDateStr = (longDateStr: string) => {
   return dayjs(longDateStr?.replace(',', '')).format('MMM DD');
};

export const getTimeslotFromSelected = (
   selectedDeliverySlot: ISelectedDeliverySlot,
   deliveryOptions: IDeliveryOption[],
) => {
   return deliveryOptions
      .find((deliveryOption) => {
         return (
            deliveryOption.id === selectedDeliverySlot.dayId &&
            getShortDateStr(deliveryOption.date) === getShortDateStr(selectedDeliverySlot.deliveryDayName)
         );
      })
      ?.timeSlots.find((timeslot) => {
         return timeslot.id === selectedDeliverySlot.timeSlotId;
      });
};

export const getCartDeliveryIndexFromSelected = (cart: ICart, selectedDeliverySlot: ISelectedDeliverySlot) => {
   return cart?.deliveries.findIndex((delivery) => {
      return delivery.id === selectedDeliverySlot.deliveryId;
   });
};

export const getTimeslotObjFromTimeslotStr = (timeslotStr: string, timeSlots: IDeliveryTimeSlot[]) => {
   const timeslotId = timeslotStr.substring(timeslotStr.lastIndexOf('|') + 1, timeslotStr.length);
   return timeSlots.find((timeslot) => {
      return timeslot.id === timeslotId;
   });
};

export const getSelectedTimeslotDesc = (timeslotList: IDeliveryTimeSlot[], orderTimeslot: string) => {
   if (
      getShortDateStr(timeslotList[0]?.dateTime) ===
      getShortDateStr(orderTimeslot.substring(orderTimeslot.indexOf('|') + 1, orderTimeslot.lastIndexOf('|')))
   ) {
      return timeslotList.find((timeslot) => {
         return timeslot.id === getTimeslotObjFromTimeslotStr(orderTimeslot, timeslotList)?.id;
      })?.description;
   }
   return null;
};

export const setDeliverySlots = (
   cart: ICart,
   deliveryOptions: IDeliveryOption[],
   selectedDeliverySlot: ISelectedDeliverySlot,
   packageId: string | null,
) => {
   if (cart.deliveries.length === 0) return;
   const foundTimeslot = deliveryOptions
      .find((delivery) => delivery.id === cart.deliveries[0].deliveryDayId)
      ?.timeSlots.find((timeslot) => timeslot.id === cart.deliveries[0].timeSlotId);

   const toSetDeliverySlot = {
      deliveryId: cart.deliveries[0].id,
      dayId: cart.deliveries[0].deliveryDayId,
      timeSlotId: cart.deliveries[0].timeSlotId,
      deliveryDayName: cart.deliveries[0].deliveryDayName,
      start: foundTimeslot ? foundTimeslot.start : '',
      end: foundTimeslot ? foundTimeslot.end : '',
   };

   if (cart.deliveries[0].packageid && cart.deliveries[0].packageid !== packageId) {
      store.dispatch(setPackageId(cart.deliveries[0].packageid));
   }

   if (!fastEqual(selectedDeliverySlot, toSetDeliverySlot)) {
      store.dispatch(
         setSelectedDeliverySlot({
            deliveryId: cart.deliveries[0].id,
            dayId: cart.deliveries[0].deliveryDayId,
            timeSlotId: cart.deliveries[0].timeSlotId,
            deliveryDayName: cart.deliveries[0].deliveryDayName,
            start: foundTimeslot ? foundTimeslot.start : '',
            end: foundTimeslot ? foundTimeslot.end : '',
         }),
      );
   }
};

export const handleUpdateOrderFrequency = (
   cart: ICart,
   selectedDeliverySlot: ISelectedDeliverySlot,
   dontUpdateSessionStorage?: boolean,
) => {
   const companyInfo = store.getState().companyStore.companyInfo;

   const hasProduct = cart.deliveries.find((delivery) => {
      return delivery.id === selectedDeliverySlot.deliveryId;
   })?.products?.length;

   if (companyInfo.uiSettings.subscribeAllOnlyMode && hasProduct) {
      if (
         cart.deliveries
            .find((delivery) => {
               return delivery.id === selectedDeliverySlot.deliveryId;
            })
            ?.products?.find((product) => {
               return product.recurrence === 'weekly';
            })
      ) {
         store.dispatch(setOrderFrequency({ orderRecurrence: 'weekly', dontUpdateSessionStorage }));
      } else {
         store.dispatch(setOrderFrequency({ orderRecurrence: 'onetime', dontUpdateSessionStorage }));
      }
   }
};

export const buildCardSkeletons = (numCards: number) => {
   const cards: IProduct[] = [];

   for (let i = 0; i < numCards; i++) {
      cards.push({
         id: `${SKELETON_LOADING}-${i}`,
         name: `${SKELETON_LOADING}-${i}`,
      } as IProduct);
   }

   return cards;
};

export const isWeeklyOrder = (
   packageRecurrence: TOrderRecurrence | null,
   productDetail: IProduct | undefined,
   orderFrequency: TOrderRecurrence,
) => {
   return !!(
      (orderFrequency === 'weekly' && productDetail?.weeklyPrice !== null) ||
      (packageRecurrence && packageRecurrence === 'weekly')
   );
};
