import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface OrderLoadState {
   cardLoading: boolean;
   searchBarCardLoading: boolean;
   orderBarCardLoading: boolean;
}

export const initialOrderLoadState: OrderLoadState = {
   cardLoading: true,
   searchBarCardLoading: true,
   orderBarCardLoading: true,
};

export const orderLoadSlice = createSlice({
   name: 'orderLoad',
   initialState: initialOrderLoadState,
   reducers: {
      setCardLoading: (state, action: PayloadAction<boolean>) => {
         state.cardLoading = action.payload;
      },
      setSearchBarCardLoading: (state, action: PayloadAction<boolean>) => {
         state.searchBarCardLoading = action.payload;
      },
      setOrderBarCardLoading: (state, action: PayloadAction<boolean>) => {
         state.orderBarCardLoading = action.payload;
      },
   },
});

export const { setCardLoading, setSearchBarCardLoading, setOrderBarCardLoading } = orderLoadSlice.actions;

export const orderLoadStore = (state: RootState) => state.orderLoadStore;

export default orderLoadSlice.reducer;
