import { AnyAction, createAsyncThunk, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState, store } from 'app/store';
import {
   IBillingProfilesResponse,
   IDeleteBillingResponse,
   IFetchReferralCodeResponse,
   IFetchRewardsResponse,
   IMessage,
   IReferralCode,
   IRewards,
   ISeverityMessage,
   ISubmitBillingPayload,
   ISubmitBillingResponse,
   ISubmitLoginPayload,
   ISubmitLogoutPayload,
   ISubmitNewPasswordResetPayload,
   ISubmitNewPasswordResetResponse,
   ISubmitPasswordResetPayload,
   ISubmitPasswordResetResponse,
   ISubmitRegisterPayload,
   ISubmitRegisterResponse,
   ISubmitShippingPayload,
   ISubmitShippingResponse,
   IUpdateAccountPayload,
} from '../types/account.types';
import {
   accountAddBilling,
   accountAddShipping,
   accountDeleteBilling,
   accountDeleteShipping,
   accountEditBilling,
   accountFetchShipping,
   accountLogin,
   accountLogout,
   accountNewPasswordReset,
   accountPasswordReset,
   accountRegister,
   accountSetDefaultBilling,
   accountSetDefaultShipping,
   fetchAccount,
   fetchReferralCode,
   fetchRewards,
   fetchUserPaymentProfiles,
   updateAccount,
} from '../services/account.api';
import { LoadStatus } from 'config/utils';
import { IBillingProfile, IBillingProfileForm, IShippingProfile, IUser } from 'features/checkout/types/checkout.types';
import { initialOrderState, setSelectedDeliverySlot } from 'features/order/stores/order.slice';
import { initialState as cartInitialState, setCart } from 'features/cart/stores/cart.slice';
import { updateMPTFrequency, updateMPTSession } from 'utils/mpt-session.helper';
import { ICartResponse } from 'features/cart/types/cart.types';
import { IDeliveryChangeResponse } from 'features/order/types/order.types';
import { initialPackageState, setPackageId } from '../../package/stores/package.slice';
import { getCartTotal, trackGaEvent, trackPixelEvent } from 'features/order/utils/event.helper';
import { TrackGTagType, TrackPixelType } from 'features/order/types/event.types';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

export const InvalidAuthTokenMsgs = ['invalid authToken', 'clientCartId or authToken is required'];

export interface AccountState {
   status: LoadStatus;
   loginStatus: LoadStatus;
   logoutStatus: LoadStatus;
   fetchAccountStatus: LoadStatus;
   updateAccountStatus: LoadStatus;
   passwordResetStatus: LoadStatus;
   newPasswordResetStatus: LoadStatus;
   registerStatus: LoadStatus;
   fetchShippingStatus: LoadStatus;
   addShippingStatus: LoadStatus;
   updateShippingAddressStatus: LoadStatus;
   deleteShippingStatus: LoadStatus;
   defaultShippingStatus: LoadStatus;
   addBillingStatus: LoadStatus;
   updateBillingAddressStatus: LoadStatus;
   deleteBillingStatus: LoadStatus;
   defaultBillingStatus: LoadStatus;
   fetchBillingProfilesStatus: LoadStatus;
   fetchReferralCodeStatus: LoadStatus;
   fetchRewardsStatus: LoadStatus;
   user: IUser;
   severityMessage: ISeverityMessage | null;
   accountMessages: IMessage[];
   loginMessages: IMessage[];
   errorCode: number | null;
   accountUser: string;
   accountProfile: string;
   shippingProfiles: IShippingProfile[];
   billingProfiles: IBillingProfile[];
   isLoggedIn: boolean | null;
   accountDialogOpen: boolean;
   noCloseBtn: boolean;
   passwordResetResponse: ISubmitPasswordResetResponse | null;
   newPasswordResetResponse: ISubmitNewPasswordResetResponse | null;
   registerResponse: ISubmitRegisterResponse | null;
   gotInvalidAuthToken: boolean;
   referralCode: IReferralCode | null;
   fetchReferralCodeResponse: IFetchReferralCodeResponse | null;
   rewards: IRewards | null;
   fetchRewardsResponse: IFetchRewardsResponse | null;
}

const initialState: AccountState = {
   status: LoadStatus.idle,
   loginStatus: LoadStatus.idle,
   logoutStatus: LoadStatus.idle,
   fetchAccountStatus: LoadStatus.idle,
   updateAccountStatus: LoadStatus.idle,
   passwordResetStatus: LoadStatus.idle,
   newPasswordResetStatus: LoadStatus.idle,
   registerStatus: LoadStatus.idle,
   fetchShippingStatus: LoadStatus.idle,
   addShippingStatus: LoadStatus.idle,
   updateShippingAddressStatus: LoadStatus.idle,
   deleteShippingStatus: LoadStatus.idle,
   defaultShippingStatus: LoadStatus.idle,
   addBillingStatus: LoadStatus.idle,
   updateBillingAddressStatus: LoadStatus.idle,
   deleteBillingStatus: LoadStatus.idle,
   defaultBillingStatus: LoadStatus.idle,
   fetchBillingProfilesStatus: LoadStatus.idle,
   fetchReferralCodeStatus: LoadStatus.idle,
   fetchRewardsStatus: LoadStatus.idle,
   user: {
      email: '',
      displayName: '',
      firstName: '',
      lastName: '',
      phone: '',
      isGuestAcct: true,
      status: 'deleted',
   },
   severityMessage: null,
   accountMessages: [],
   loginMessages: [],
   errorCode: null,
   accountUser: '',
   accountProfile: '',
   shippingProfiles: [],
   billingProfiles: [],
   isLoggedIn: null,
   accountDialogOpen: false,
   noCloseBtn: false,
   passwordResetResponse: null,
   newPasswordResetResponse: null,
   fetchReferralCodeResponse: null,
   registerResponse: null,
   gotInvalidAuthToken: false,
   referralCode: null,
   rewards: null,
   fetchRewardsResponse: null,
};

export const submitLoginAsync = createAsyncThunk(
   'account/submitLogin',
   async (loginInput: { bodyInput: ISubmitLoginPayload }, { dispatch }) => {
      const result = await accountLogin(loginInput.bodyInput);

      if (result.authToken !== null && result.authToken !== undefined) {
         // Reset selected delivery slot on login
         trackGaEvent(TrackGTagType.loginForm);
         dispatch(setCart(result.cart));
         dispatch(setSelectedDeliverySlot(initialOrderState.selectedDeliverySlot));

         if (result?.cart?.deliveries && result.cart.deliveries.length > 0 && result.cart.deliveries[0].packageid) {
            store.dispatch(setPackageId(result.cart.deliveries[0].packageid));
         } else {
            dispatch(setPackageId(initialPackageState.packageId));
         }
         dispatch(setLoginMessages(result.messages as IMessage[]));
      }

      return result;
   },
);

export const submitLogoutAsync = createAsyncThunk(
   'account/submitLogout',
   async (logoutInput: { bodyInput: ISubmitLogoutPayload }, { dispatch }) => {
      const result = await accountLogout(logoutInput.bodyInput);
      // Reset selected delivery slot on logout
      dispatch(setSelectedDeliverySlot(initialOrderState.selectedDeliverySlot));
      dispatch(setPackageId(initialPackageState.packageId));
      dispatch(setCart({ ...cartInitialState.cart, init: false }));
      dispatch(setLoginMessages([]));
      dispatch(setAccountMessages([]));
      return result;
   },
);

export const submitPasswordResetAsync = createAsyncThunk(
   'account/submitPasswordReset',
   async (passwordResetInput: { bodyInput: ISubmitPasswordResetPayload }) =>
      await accountPasswordReset(passwordResetInput.bodyInput),
);

export const submitNewPasswordResetAsync = createAsyncThunk(
   'account/submitNewPasswordReset',
   async (newPasswordResetInput: { bodyInput: ISubmitNewPasswordResetPayload }) =>
      await accountNewPasswordReset(newPasswordResetInput.bodyInput),
);

export const submitRegisterAsync = createAsyncThunk(
   'account/submitRegister',
   async (registerInput: { bodyInput: ISubmitRegisterPayload }) => await accountRegister(registerInput.bodyInput),
);

// This function is meant to be global and used in any slice where the api call fails
// and there is an error. If one of the error has an adminMessage of 'invalid token',
// We will reset the selectedDeliverySlot and cart to the initial default values and
// set the localStorage authToken to null. Although we think that was null to begin with
// to create the error.
export const checkSuccessResult = (
   result: ISubmitShippingResponse | IDeliveryChangeResponse | ISubmitBillingResponse | IDeleteBillingResponse,
   dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
   if (!result.success && result.error) {
      const gotInvalidAuthToken = result.error.some(
         (e) => e.adminMessage && InvalidAuthTokenMsgs.includes(e.adminMessage),
      );
      if (gotInvalidAuthToken) {
         dispatch(setSelectedDeliverySlot(initialOrderState.selectedDeliverySlot));
         dispatch(setPackageId(initialPackageState.packageId));
         dispatch(setCart({ ...cartInitialState.cart, init: false }));
         updateMPTSession({ ...(result as unknown as ICartResponse), authToken: null });
         dispatch(setGotInvalidAuthToken());
      }
   }
};

export const fetchAccountAsync = createAsyncThunk('account/fetchAccount', async () => await fetchAccount());

export const updateAccountAsync = createAsyncThunk(
   'account/updateAccount',
   async (updateAccountInput: { bodyInput: IUpdateAccountPayload }) =>
      await updateAccount(updateAccountInput.bodyInput),
);

export const accountFetchShippingAsync = createAsyncThunk(
   'account/fetchShipping',
   async () => await accountFetchShipping(),
);

export const accountAddShippingAsync = createAsyncThunk(
   'account/addShipping',
   async (shippingInput: { bodyInput: ISubmitShippingPayload }, { dispatch }) => {
      const result = await accountAddShipping(shippingInput.bodyInput);
      checkSuccessResult(result, dispatch);
      return result;
   },
);

export const accountUpdateShippingAsync = createAsyncThunk(
   'account/updateShipping',
   async (shippingInput: { bodyInput: ISubmitShippingPayload; editAddressId?: string }, { dispatch }) => {
      const result = await accountAddShipping(shippingInput.bodyInput, shippingInput.editAddressId);
      checkSuccessResult(result, dispatch);
      return result;
   },
);

export const accountSetDefaultShippingAsync = createAsyncThunk(
   'account/setDefaultShipping',
   async (shippingInput: { defaultAddressId: string }, { dispatch }) => {
      const result = await accountSetDefaultShipping(shippingInput.defaultAddressId);
      checkSuccessResult(result, dispatch);
      return { ...result, defaultAddressId: shippingInput.defaultAddressId };
   },
);

export const accountDeleteShippingAsync = createAsyncThunk(
   'account/deleteShipping',
   async (shippingInput: { deleteAddressId: string }, { dispatch }) => {
      const result = await accountDeleteShipping(shippingInput.deleteAddressId);
      checkSuccessResult(result, dispatch);
      return { ...result, deleteAddressId: shippingInput.deleteAddressId };
   },
);

export const accountAddBillingAsync = createAsyncThunk(
   'account/addBilling',
   async (billingInput: { bodyInput: ISubmitBillingPayload }) => await accountAddBilling(billingInput.bodyInput),
);

export const accountUpdateBillingAsync = createAsyncThunk(
   'account/updateBilling',
   async (billingInput: { bodyInput: IBillingProfileForm; editPaymentId?: string }, { dispatch }) => {
      const result = await accountEditBilling(billingInput.bodyInput, billingInput.editPaymentId);
      checkSuccessResult(result, dispatch);
      return result;
   },
);

export const accountSetDefaultBillingAsync = createAsyncThunk(
   'account/setDefaultBilling',
   async (billingInput: { defaultPaymentId: string }, { dispatch }) => {
      const result = await accountSetDefaultBilling(billingInput.defaultPaymentId);
      checkSuccessResult(result, dispatch);
      return { ...result, defaultPaymentId: billingInput.defaultPaymentId };
   },
);

export const accountDeleteBillingAsync = createAsyncThunk(
   'account/deleteBilling',
   async (billingInput: { deletePaymentId: string }, { dispatch }) => {
      const result = await accountDeleteBilling(billingInput.deletePaymentId);
      checkSuccessResult(result, dispatch);
      return { ...result, deletePaymentId: billingInput.deletePaymentId };
   },
);

export const fetchUserPaymentProfilesAsync = createAsyncThunk(
   'account/fetchUserPaymentProfiles',
   async () => await fetchUserPaymentProfiles(),
);

export const fetchReferralCodeAsync = createAsyncThunk(
   'account/fetchReferralCode',
   async () => await fetchReferralCode(),
);

export const fetchRewardsAsync = createAsyncThunk('account/fetchRewards', async () => await fetchRewards());

export const accountSlice = createSlice({
   name: 'account',
   initialState,
   reducers: {
      setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
         state.isLoggedIn = action.payload;
      },
      setUser: (state, action: PayloadAction<IUser>) => {
         state.user = action.payload;
      },
      setSeverityMessage: (state, action: PayloadAction<ISeverityMessage | null>) => {
         state.severityMessage = action.payload;
      },
      setAccountMessages: (state, action: PayloadAction<IMessage[]>) => {
         state.accountMessages = action.payload.filter((filterMessage) => !!filterMessage.message);
      },
      setLoginMessages: (state, action: PayloadAction<IMessage[]>) => {
         state.loginMessages = action.payload.filter((filterMessage) => !!filterMessage.message);
      },
      setAccountDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.accountDialogOpen = action.payload;
      },
      setShippingProfiles: (state, action: PayloadAction<IShippingProfile[]>) => {
         state.shippingProfiles = action.payload;
      },
      setBillingProfiles: (state, action: PayloadAction<IBillingProfile[]>) => {
         state.billingProfiles = action.payload;
      },
      setNoCloseBtn: (state, action: PayloadAction<boolean>) => {
         state.noCloseBtn = action.payload;
      },
      resetLogoutStatus: (state) => {
         state.logoutStatus = LoadStatus.idle;
      },
      resetLoginStatus: (state) => {
         state.loginStatus = LoadStatus.idle;
      },
      resetRegisterStatus: (state) => {
         state.registerStatus = LoadStatus.idle;
      },
      resetRegisterResponse: (state) => {
         state.registerResponse = null;
      },
      resetAddShippingStatus: (state) => {
         state.addShippingStatus = LoadStatus.idle;
      },
      resetUpdateShippingAddressStatus: (state) => {
         state.updateShippingAddressStatus = LoadStatus.idle;
      },
      resetDeleteShippingStatus: (state) => {
         state.deleteShippingStatus = LoadStatus.idle;
      },
      resetDefaultShippingStatus: (state) => {
         state.defaultShippingStatus = LoadStatus.idle;
      },
      resetAddPaymentStatus: (state) => {
         state.addBillingStatus = LoadStatus.idle;
      },
      resetUpdateBillingAddressStatus: (state) => {
         state.updateBillingAddressStatus = LoadStatus.idle;
      },
      resetDeleteBillingStatus: (state) => {
         state.deleteBillingStatus = LoadStatus.idle;
      },
      resetDefaultBillingStatus: (state) => {
         state.defaultBillingStatus = LoadStatus.idle;
      },
      resetFetchBillingProfilesStatus: (state) => {
         state.fetchBillingProfilesStatus = LoadStatus.idle;
      },
      setGotInvalidAuthToken: (state) => {
         state.gotInvalidAuthToken = true;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(submitLoginAsync.pending, (state) => {
            state.loginStatus = LoadStatus.loading;
         })
         .addCase(submitLoginAsync.fulfilled, (state, action) => {
            if (action.payload.authToken !== null && action.payload.authToken !== undefined) {
               state.isLoggedIn = true;
               state.accountDialogOpen = false;
               state.severityMessage = null;
            }
            if (action?.payload?.error?.[0]?.errorMessage) {
               state.severityMessage = {
                  severity: 'warning',
                  message: action.payload.error[0].errorMessage,
               };
            }
            state.loginStatus = LoadStatus.complete;
         })
         .addCase(submitLoginAsync.rejected, (state) => {
            state.loginStatus = LoadStatus.failed;
         })
         .addCase(submitLogoutAsync.pending, (state) => {
            state.logoutStatus = LoadStatus.loading;
         })
         .addCase(submitLogoutAsync.fulfilled, (state) => {
            state.isLoggedIn = null;
            state.logoutStatus = LoadStatus.complete;
            state.referralCode = null;
            state.rewards = null;
            updateMPTFrequency('onetime');
         })
         .addCase(submitLogoutAsync.rejected, (state) => {
            state.logoutStatus = LoadStatus.failed;
         })
         .addCase(submitPasswordResetAsync.pending, (state) => {
            state.passwordResetStatus = LoadStatus.loading;
         })
         .addCase(submitPasswordResetAsync.fulfilled, (state, action) => {
            state.passwordResetResponse = action.payload;
            state.passwordResetStatus = LoadStatus.complete;
            if (action.payload.success) {
               state.accountDialogOpen = false;
               state.severityMessage = {
                  severity: 'success',
                  message: 'Check your email for further instructions',
               };
            } else {
               state.severityMessage = {
                  severity: 'error',
                  message: action.payload.error?.[0]?.errorMessage ?? 'Unable to reset your password',
               };
            }
         })
         .addCase(submitPasswordResetAsync.rejected, (state) => {
            state.passwordResetStatus = LoadStatus.failed;
         })
         .addCase(submitNewPasswordResetAsync.pending, (state) => {
            state.newPasswordResetStatus = LoadStatus.loading;
         })
         .addCase(submitNewPasswordResetAsync.fulfilled, (state, action) => {
            state.newPasswordResetResponse = action.payload;
            state.newPasswordResetStatus = LoadStatus.complete;
            if (action.payload.success) {
               state.accountDialogOpen = true;
               state.severityMessage = {
                  severity: 'success',
                  message: 'Password has been changed successfully',
               };
            } else {
               state.severityMessage = {
                  severity: 'error',
                  message:
                     action.payload?.error && action.payload?.error.length > 0 && action.payload?.error[0].errorMessage
                        ? action.payload.error[0].errorMessage
                        : 'Password reset failed',
               };
            }
         })
         .addCase(submitNewPasswordResetAsync.rejected, (state) => {
            state.newPasswordResetStatus = LoadStatus.failed;
         })
         .addCase(submitRegisterAsync.pending, (state) => {
            state.registerStatus = LoadStatus.loading;
         })
         .addCase(submitRegisterAsync.fulfilled, (state, action) => {
            state.registerResponse = action.payload;
            if (action.payload.success) {
               if (action.payload.authToken !== null && action.payload.authToken !== undefined) {
                  const cartTotal = getCartTotal(action.payload);
                  trackGaEvent(TrackGTagType.registerForm);
                  trackPixelEvent(TrackPixelType.completeRegistration, {
                     status: 'registered',
                     currency: 'USD',
                     value: cartTotal,
                  });
                  state.isLoggedIn = true;
                  state.accountDialogOpen = false;
                  state.severityMessage = {
                     severity: 'success',
                     message: 'Thank you, your account has been created',
                  };
               } else {
                  state.severityMessage = {
                     severity: 'warning',
                     message: `Please check your email for a link to activate your account (Check spam/junk folder if you don't see the email)`,
                  };
               }
            } else {
               if (action?.payload?.error?.[0]?.errorMessage) {
                  state.severityMessage = {
                     severity: 'warning',
                     message: action.payload.error[0].errorMessage,
                  };
               }
            }
            state.registerStatus = LoadStatus.complete;
         })
         .addCase(submitRegisterAsync.rejected, (state) => {
            state.registerStatus = LoadStatus.failed;
         })
         .addCase(fetchAccountAsync.pending, (state) => {
            state.fetchAccountStatus = LoadStatus.loading;
         })
         .addCase(fetchAccountAsync.fulfilled, (state, action) => {
            state.fetchAccountStatus = LoadStatus.complete;
            state.user = action.payload.user;
         })
         .addCase(fetchAccountAsync.rejected, (state) => {
            state.fetchAccountStatus = LoadStatus.failed;
         })
         .addCase(updateAccountAsync.pending, (state) => {
            state.updateAccountStatus = LoadStatus.loading;
         })
         .addCase(updateAccountAsync.fulfilled, (state, action) => {
            state.updateAccountStatus = LoadStatus.complete;
            state.user = action.payload.user;
         })
         .addCase(updateAccountAsync.rejected, (state) => {
            state.updateAccountStatus = LoadStatus.failed;
         })
         .addCase(accountFetchShippingAsync.pending, (state) => {
            state.fetchShippingStatus = LoadStatus.loading;
         })
         .addCase(accountFetchShippingAsync.fulfilled, (state, action) => {
            state.fetchShippingStatus = LoadStatus.complete;
            state.shippingProfiles = action.payload.shippingProfiles;
         })
         .addCase(accountFetchShippingAsync.rejected, (state) => {
            state.fetchShippingStatus = LoadStatus.failed;
         })
         .addCase(accountAddShippingAsync.pending, (state) => {
            state.addShippingStatus = LoadStatus.loading;
         })
         .addCase(accountAddShippingAsync.fulfilled, (state, action: PayloadAction<ISubmitShippingResponse>) => {
            state.addShippingStatus = LoadStatus.complete;
            if (
               action.payload.success &&
               action.payload.shippingProfiles &&
               Array.isArray(action.payload.shippingProfiles)
            ) {
               state.shippingProfiles = action.payload.shippingProfiles;
            } else {
               state.addShippingStatus = LoadStatus.failed;
               state.severityMessage = {
                  severity: 'error',
                  message: 'Error adding delivery information',
               };
            }
         })
         .addCase(accountAddShippingAsync.rejected, (state) => {
            state.addShippingStatus = LoadStatus.failed;
         })
         .addCase(accountUpdateShippingAsync.pending, (state) => {
            state.updateShippingAddressStatus = LoadStatus.loading;
         })
         .addCase(accountUpdateShippingAsync.fulfilled, (state, action: PayloadAction<ISubmitShippingResponse>) => {
            state.updateShippingAddressStatus = LoadStatus.complete;
            if (action.payload.success) {
               if (action.payload.shippingProfiles) {
                  if (Array.isArray(action.payload.shippingProfiles)) {
                     state.shippingProfiles = action.payload.shippingProfiles;
                  } else {
                     const newProfile = action.payload.shippingProfiles;
                     const foundIndex = state.shippingProfiles.findIndex((profile) => profile.id === newProfile.id);

                     if (foundIndex !== -1) {
                        state.shippingProfiles = state.shippingProfiles.map((profile, index) =>
                           index === foundIndex ? newProfile : profile,
                        );
                     } else {
                        state.shippingProfiles = [...state.shippingProfiles, newProfile];
                     }
                  }
               }
            } else {
               state.updateShippingAddressStatus = LoadStatus.failed;
               state.severityMessage = {
                  severity: 'error',
                  message: 'Error updating delivery information',
               };
            }
         })
         .addCase(accountUpdateShippingAsync.rejected, (state) => {
            state.updateShippingAddressStatus = LoadStatus.failed;
         })
         .addCase(accountDeleteShippingAsync.pending, (state) => {
            state.deleteShippingStatus = LoadStatus.loading;
         })
         .addCase(accountDeleteShippingAsync.fulfilled, (state, action: PayloadAction<ISubmitShippingResponse>) => {
            state.deleteShippingStatus = LoadStatus.complete;
            if (action.payload.success) {
               state.shippingProfiles = state.shippingProfiles.filter(
                  (profile) => profile.id !== action.payload.deleteAddressId,
               );
            } else {
               state.deleteShippingStatus = LoadStatus.failed;
               state.severityMessage = {
                  severity: 'error',
                  message: 'Error deleting delivery information',
               };
            }
         })
         .addCase(accountDeleteShippingAsync.rejected, (state) => {
            state.deleteShippingStatus = LoadStatus.failed;
         })
         .addCase(accountSetDefaultShippingAsync.pending, (state) => {
            state.defaultShippingStatus = LoadStatus.loading;
         })
         .addCase(accountSetDefaultShippingAsync.fulfilled, (state, action: PayloadAction<ISubmitShippingResponse>) => {
            state.defaultShippingStatus = LoadStatus.complete;
            if (action.payload.success) {
               state.shippingProfiles = state.shippingProfiles.map((profile) => {
                  profile.default = profile.id === action.payload.defaultAddressId;
                  return profile;
               });
            } else {
               state.defaultShippingStatus = LoadStatus.failed;
               state.severityMessage = {
                  severity: 'error',
                  message: 'Error setting default delivery information',
               };
            }
         })
         .addCase(accountSetDefaultShippingAsync.rejected, (state) => {
            state.defaultShippingStatus = LoadStatus.failed;
         })
         .addCase(accountAddBillingAsync.pending, (state) => {
            state.addBillingStatus = LoadStatus.loading;
         })
         .addCase(accountAddBillingAsync.fulfilled, (state) => {
            state.addBillingStatus = LoadStatus.complete;
         })
         .addCase(accountAddBillingAsync.rejected, (state) => {
            state.addBillingStatus = LoadStatus.failed;
         })
         .addCase(accountUpdateBillingAsync.pending, (state) => {
            state.updateBillingAddressStatus = LoadStatus.loading;
         })
         .addCase(accountUpdateBillingAsync.fulfilled, (state, action: PayloadAction<ISubmitShippingResponse>) => {
            state.updateBillingAddressStatus = LoadStatus.complete;
            if (!action.payload.success) {
               state.updateBillingAddressStatus = LoadStatus.failed;
               state.severityMessage = {
                  severity: 'error',
                  message: 'Error updating payment method',
               };
            }
         })
         .addCase(accountUpdateBillingAsync.rejected, (state) => {
            state.updateBillingAddressStatus = LoadStatus.failed;
         })
         .addCase(accountDeleteBillingAsync.pending, (state) => {
            state.deleteBillingStatus = LoadStatus.loading;
         })
         .addCase(accountDeleteBillingAsync.fulfilled, (state, action: PayloadAction<IDeleteBillingResponse>) => {
            state.deleteBillingStatus = LoadStatus.complete;
            if (action.payload.success) {
               state.billingProfiles = state.billingProfiles.filter(
                  (profile) => profile.id !== action.payload.deletePaymentId,
               );
            } else {
               state.deleteBillingStatus = LoadStatus.failed;
               state.severityMessage = {
                  severity: 'error',
                  message: 'Error deleting payment',
               };
            }
         })
         .addCase(accountDeleteBillingAsync.rejected, (state) => {
            state.deleteBillingStatus = LoadStatus.failed;
         })
         .addCase(accountSetDefaultBillingAsync.pending, (state) => {
            state.defaultBillingStatus = LoadStatus.loading;
         })
         .addCase(accountSetDefaultBillingAsync.fulfilled, (state, action: PayloadAction<ISubmitBillingResponse>) => {
            state.defaultBillingStatus = LoadStatus.complete;
            if (action.payload.success) {
               state.billingProfiles = state.billingProfiles.map((profile) => {
                  profile.default = profile.id === action.payload.defaultPaymentId;
                  return profile;
               });
            } else {
               state.defaultBillingStatus = LoadStatus.failed;
               state.severityMessage = {
                  severity: 'error',
                  message: 'Error setting default payment option',
               };
            }
         })
         .addCase(accountSetDefaultBillingAsync.rejected, (state) => {
            state.defaultBillingStatus = LoadStatus.failed;
         })
         .addCase(fetchUserPaymentProfilesAsync.pending, (state) => {
            state.fetchBillingProfilesStatus = LoadStatus.loading;
         })
         .addCase(fetchUserPaymentProfilesAsync.fulfilled, (state, action: PayloadAction<IBillingProfilesResponse>) => {
            state.fetchBillingProfilesStatus = LoadStatus.complete;
            if (action.payload.success) {
               state.billingProfiles = action.payload.billingProfiles;
            }
         })
         .addCase(fetchUserPaymentProfilesAsync.rejected, (state) => {
            state.fetchBillingProfilesStatus = LoadStatus.failed;
         })
         .addCase(fetchReferralCodeAsync.pending, (state) => {
            state.fetchReferralCodeStatus = LoadStatus.loading;
         })
         .addCase(fetchReferralCodeAsync.fulfilled, (state, action) => {
            state.fetchReferralCodeStatus = LoadStatus.complete;
            state.referralCode = action.payload.referralCode;
         })
         .addCase(fetchReferralCodeAsync.rejected, (state) => {
            state.fetchReferralCodeStatus = LoadStatus.failed;
         })
         .addCase(fetchRewardsAsync.pending, (state) => {
            state.fetchRewardsStatus = LoadStatus.loading;
         })
         .addCase(fetchRewardsAsync.fulfilled, (state, action) => {
            state.fetchRewardsStatus = LoadStatus.complete;
            state.rewards = action.payload.rewards;
         })
         .addCase(fetchRewardsAsync.rejected, (state) => {
            state.fetchRewardsStatus = LoadStatus.failed;
         });
   },
});

export const {
   setIsLoggedIn,
   setUser,
   setSeverityMessage,
   setAccountMessages,
   setLoginMessages,
   setAccountDialogOpen,
   setShippingProfiles,
   setBillingProfiles,
   resetLoginStatus,
   resetLogoutStatus,
   resetRegisterStatus,
   resetAddShippingStatus,
   resetRegisterResponse,
   resetDeleteShippingStatus,
   resetDefaultShippingStatus,
   resetUpdateShippingAddressStatus,
   resetAddPaymentStatus,
   resetUpdateBillingAddressStatus,
   resetDeleteBillingStatus,
   resetDefaultBillingStatus,
   resetFetchBillingProfilesStatus,
   setNoCloseBtn,
   setGotInvalidAuthToken,
} = accountSlice.actions;

export const accountStore = (state: RootState) => state.accountStore;

export default accountSlice.reducer;
