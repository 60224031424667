import { CompanyDefaults } from './company.const';

export const isDev = window.location.host.startsWith('localhost');

const serverLink = process.env.REACT_APP_SERVER ? `${process.env.REACT_APP_SERVER}` : 'http://localhost:3005';
export const turnStileSiteKey = process.env.REACT_APP_TURNSTILE_SITE_KEY
   ? `${process.env.REACT_APP_TURNSTILE_SITE_KEY}`
   : '0x4AAAAAAASjNsUtOgBHYFNt';

const getApiServerUrl = () => {
   const mptLocalStorage = JSON.parse(localStorage.getItem('MPTSessionV2') as string);
   return mptLocalStorage?.url ? mptLocalStorage.url : serverLink;
};

export const apiServerUrl = getApiServerUrl();

const companyVars = CompanyDefaults['sprout'];

export const mptSettings = (window as unknown as any)['mptsettings']
   ? (window as unknown as any)['mptsettings']
   : {
        '0': null,
        '1': '1',
        '2': '',
        url: 'http://localhost:52313/',
        ip: '::1',
        requestHistory: [],
        router: null,
        debug: true,
        locked: false,
        options: {
           api_environment: 'production',
           checkout_disabled: 'false',
           client_api_key: companyVars.client_api_key,
           slugs: {
              shop: 'shop',
              store: '',
              order: 'order',
              customproduct: '',
              agreement: '',
              user: 'user',
              checkout: 'checkout',
              buy: 'buy',
              apply: '',
           },
           show_only_on_mptpages: false,
           order_flow: [
              { name: 'packages', text: 'Packages' },
              { name: 'deliveries', text: 'Deliveries' },
              { name: 'shop', text: 'Shop' },
           ],
           customize: {
              align_shop: 'responsive',
              cart_message_fadeout: true,
              enable_parallax_plugin: false,
              'extra-registration-fields': false,
              show_product_savings: false,
              show_price_in_alert_shop: true,
              show_price_in_alert: true,
              disable_comments: false,
              select_meals_message: null,
           },
           customTemplates: [],
           customScripts: { globalScripts: '', deliveryPageScripts: '', shopPageScripts: '', checkoutPageScripts: '' },
           customRoutes: [],
           customizations: {
              cart: { template: null, scripts: null },
              color: {
                 buttons: { primary: null, success: null, info: null, warning: null },
                 shop: { header: null, icons: null },
              },
           },
           colors: {
              success: '',
              info: '',
              warning: '',
              danger: '',
              light: '',
              dark: '',
              ...companyVars.colors,
           },
           fonts: {
              primary_font_family: 'Roboto',
           },
           scripts: { global: '' },
           version: '2.0.0',
           api_version: 'staging',
           enable_header_links: 'true',
           header_element_override: '',
           max_cart_height: '',
           user_custom_colors: 'false',
           debug_enabled: 'false',
           adword: { checkout: '' },
        },
        ajaxurl: 'http://localhost/wp-admin/admin-ajax.php',
        baseurl: 'http://localhost/wp-content/plugins/mptwpplugin/',
        ghostId: null,
        resetPasswordToken: null,
     };

export const apiServerHeader = {
   Authorization: 'Basic ' + mptSettings.options.client_api_key,
   'Content-Type': 'application/json',
   'X-Forwarded-For': mptSettings.ip,
};

export const serverUrls: Record<string, string> = {
   production: 'https://mptapi20170804072902.azurewebsites.net',
   staging: 'https://mptapiv2staging.azurewebsites.net',
   dev: 'https://mptapiv2dev.azurewebsites.net',
   local: 'http://localhost:52313',
};

export type ApiEnvironment = 'production' | 'staging' | 'dev' | 'local';
export const overrideURL = localStorage && localStorage.overrideURL ? localStorage.overrideURL : null;
