import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import { accountStore } from 'features/account/stores/account.slice';
import { Stack, Typography } from '@mui/material';
import AddDeliveryAddressLink from '../orderDetails/AddDeliveryAddressLink';
import { setFinixFormModalOpen } from '../../stores/finix.slice';

const PaymentNameAddressDisplay = () => {
   const dispatch = useAppDispatch();
   const { cart } = useAppSelector(cartStore);
   const { billingProfiles } = useAppSelector(accountStore);

   const foundBillingProfile = billingProfiles?.find((findProfile) => findProfile.id === cart?.billingProfileId);

   const onAddPaymentOpen = () => {
      dispatch(setFinixFormModalOpen(true));
   };

   if (cart?.billingProfileId === null || !foundBillingProfile) {
      return <AddDeliveryAddressLink onOpen={onAddPaymentOpen} text={'Add payment method'} />;
   }

   return (
      <Stack direction="row" display="block">
         <Typography variant="body1" fontWeight="bold">
            {foundBillingProfile?.firstName} {foundBillingProfile?.lastName}
         </Typography>
         <Typography variant="body1">{foundBillingProfile?.address}</Typography>
         <Typography variant="body1">
            {foundBillingProfile?.city}, {foundBillingProfile?.region} {foundBillingProfile?.postal}
         </Typography>
         <Typography variant="body1">Card ending in {foundBillingProfile?.accountNum}</Typography>
      </Stack>
   );
};

export default PaymentNameAddressDisplay;
