import { ApiEnvironment, apiServerHeader, mptSettings, overrideURL, serverUrls } from '../config/server';
import { ICartResponse, TOrderRecurrence } from '../features/cart/types/cart.types';
import { ISubmitLoginResponse, ISubmitRegisterResponse } from '../features/account/types/account.types';
import { IMptSettings } from '../types/mpt-session.types';
import { IDeliveryChangeResponse } from '../features/order/types/order.types';
import { IUpdateTipResponse } from '../features/checkout/types/checkout.types';
import { store } from '../app/store';
import { submitLogoutAsync } from '../features/account/stores/account.slice';

const mptSettingsDefaults: IMptSettings = {
   url: serverUrls.production,
   authToken: null,
   clientCartId: null,
   ip: null,
   requestHistory: [],
   router: null,
   debug: true,
   locked: false,
};

export const determineUrl = (serverSideMPTSession: any): string => {
   const apiEnvironment = serverSideMPTSession.options.api_environment;

   if (typeof apiEnvironment === 'string' && apiEnvironment in serverUrls && overrideURL == null) {
      return serverUrls[apiEnvironment as ApiEnvironment];
   } else if (overrideURL == null) {
      return serverUrls.production;
   } else {
      return overrideURL;
   }
};

export const loadMPTSession = () => {
   let serverSideMPTSession: any = {};
   let url = serverUrls.production;

   if (process.env.REACT_APP_DEV_MPT_SESSION) {
      serverSideMPTSession = mptSettings;
      url = determineUrl(serverSideMPTSession);
   } else if (typeof (window.mptsettings as unknown) !== 'undefined') {
      serverSideMPTSession = window.mptsettings;
      url = determineUrl(serverSideMPTSession);
   }

   if (localStorage.hasOwnProperty('MPTSessionV2')) {
      const existingMPTSession = Object.assign({ ...mptSettingsDefaults }, JSON.parse(localStorage.MPTSessionV2), {
         ...serverSideMPTSession,
         url,
      });

      if (existingMPTSession.authToken !== null) {
         if (new Date() > new Date(existingMPTSession.authToken.expirationUTC)) {
            existingMPTSession.authToken = null;
            existingMPTSession.clientCartId = null;
            localStorage.setItem('MPTSessionV2', JSON.stringify(existingMPTSession));
            store.dispatch(
               submitLogoutAsync({
                  bodyInput: {
                     allSessions: true,
                  },
               }),
            );
            alert("Session expired. You've been logged out.");
         }
      }

      return existingMPTSession;
   } else {
      const newMPTSession = Object.assign({ ...mptSettingsDefaults, url }, serverSideMPTSession);
      localStorage.setItem('MPTSessionV2', JSON.stringify(newMPTSession));
      return newMPTSession;
   }
};

export const updateMPTFrequency = (frequency: TOrderRecurrence) => {
   const mptLocalStorage = JSON.parse(localStorage.getItem('MPTSessionV2') as string);
   mptLocalStorage.MPTOrderFrequency = frequency;
   localStorage.setItem('MPTSessionV2', JSON.stringify(mptLocalStorage));
};

export const updateMPTPackageId = (packageId: string | null) => {
   const mptLocalStorage = JSON.parse(localStorage.getItem('MPTSessionV2') as string);
   mptLocalStorage.packageId = packageId;
   localStorage.setItem('MPTSessionV2', JSON.stringify(mptLocalStorage));
};

export const updateMPTSession = (
   serverResponse:
      | ICartResponse
      | ISubmitLoginResponse
      | ISubmitRegisterResponse
      | IDeliveryChangeResponse
      | IUpdateTipResponse
      | null,
) => {
   const mptLocalStorage = JSON.parse(localStorage.getItem('MPTSessionV2') as string);

   if (serverResponse !== null) {
      if (serverResponse.hasOwnProperty('authToken')) {
         mptLocalStorage.authToken = serverResponse.authToken;
      } else if (serverResponse.hasOwnProperty('clientCartId')) {
         mptLocalStorage.clientCartId = serverResponse.clientCartId;
      }

      if (serverResponse.hasOwnProperty('cart') && serverResponse.cart?.deliveries?.length > 0) {
         mptLocalStorage.cart = serverResponse.cart;
         mptLocalStorage.packageId = serverResponse.cart.deliveries[0].packageid;
         // We got a cart without an id, but we think we are still authenticated. We will assume we are no longer authenticated
         if (!serverResponse.cart.id && mptLocalStorage.authToken) {
            mptLocalStorage.authToken = null;
         }
      }

      if (serverResponse.hasOwnProperty('companyInfo')) {
         mptLocalStorage.companyInfo = serverResponse.companyInfo;
      }

      if (mptLocalStorage.hasOwnProperty('displayName') && serverResponse.hasOwnProperty('displayName')) {
         mptLocalStorage.displayName = serverResponse.displayName;
      }

      if (mptLocalStorage.hasOwnProperty('user') && serverResponse.hasOwnProperty('user')) {
         mptLocalStorage.user = serverResponse.user;
      }
   } else {
      mptLocalStorage.authToken = null;
      mptLocalStorage.clientCartId = null;
   }

   localStorage.setItem('MPTSessionV2', JSON.stringify(mptLocalStorage));

   return mptLocalStorage;
};

export const expandApiServerHeader = () => {
   const mptLocalStorage = JSON.parse(localStorage.getItem('MPTSessionV2') as string);
   return {
      ...apiServerHeader,
      Authorization: `Basic ${mptLocalStorage.options.client_api_key}`,
      'X-Forwarded-For': mptLocalStorage.ip,
   };
};

export const prepareTokenPayload = (payload: Record<string, any>) => {
   let overwriteMode = false;
   // TODO What is this for?
   // try {
   //    if (mptapi.router && mptapi.router.data && mptapi.router.data.editingSubscription) {
   //       overwriteMode = mptapi.router.data.editingSubscription;
   //    }
   // } catch(err){ console.error(err); }

   const loadedMptSession = loadMPTSession();

   const inputPayload: Record<string, any> = Object.fromEntries(
      Object.entries(payload).filter(([_, value]) => value !== undefined),
   );

   if (loadedMptSession.authToken != null) {
      inputPayload.authToken = {
         token: loadedMptSession.authToken.token,
         overwriteMode,
         ip: loadedMptSession.ip,
      };
   } else if (loadedMptSession.clientCartId != null) {
      inputPayload.clientCartId = loadedMptSession.clientCartId;
   }

   return inputPayload;
};
