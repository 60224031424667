import React, { useEffect, useState } from 'react';
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Card,
   CardContent,
   Grid,
   Pagination,
   Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import DeliveryItemList from 'features/subscription/components/DeliveryItemList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from 'app/hooks';
import { profileStore } from '../stores/profile.slice';
import { LoadStatus } from 'config/utils';
import { Link } from 'react-router-dom';

const OrderTable = () => {
   const [currentPage, setCurrentPage] = useState(1);
   const { orderStatus, status } = useAppSelector(profileStore);
   const localizedFormat = require('dayjs/plugin/localizedFormat');
   dayjs.extend(localizedFormat);
   const [numPages, setNumPages] = useState(1);

   useEffect(() => {
      if (orderStatus && orderStatus.orderHistory) {
         setNumPages(Math.ceil(orderStatus.orderHistory.length / 5));
      } else {
         setNumPages(1);
      }
   }, [orderStatus]);

   const getDeliveriesAccordion = (subDeliveries: any[]) => {
      if (!subDeliveries) return;
      return subDeliveries.map((delivery) => {
         return (
            <Accordion key={delivery.id} square>
               <AccordionSummary
                  aria-controls="panel-delivery-option"
                  sx={{ fontWeight: 'bold' }}
                  expandIcon={<ExpandMoreIcon />}
               >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                     <Typography sx={{ fontWeight: 'bold' }}>
                        {`${delivery.deliveryDayName}, ${dayjs(delivery.nextDeliveryFrom).format('h:mm A')} - ${dayjs(delivery.nextDeliveryTo).format('h:mm A')} (${delivery.shippingLocationName})`}
                     </Typography>
                  </div>
               </AccordionSummary>
               <AccordionDetails>
                  <DeliveryItemList cartProducts={delivery.products} />
               </AccordionDetails>
            </Accordion>
         );
      });
   };

   const getTotals = (order: any) => {
      if (order) {
         return order.totals.map((total: any) => {
            return (
               <div key={total.name + total.amount} className="subscription-totals">
                  <Typography fontWeight={'bold'} marginRight={'auto'}>
                     {total.name}
                  </Typography>
                  <Typography fontWeight={'bold'}>
                     {' '}
                     {total.amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                     })}
                  </Typography>
               </div>
            );
         });
      }
      return null;
   };

   const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
   };

   if (status === LoadStatus.loading) {
      return <Typography variant={'h5'}>Loading...</Typography>;
   }

   if (status === LoadStatus.failed) {
      return <Typography variant={'h5'}>Loading...</Typography>;
   }

   return (
      <>
         <Pagination
            count={numPages}
            shape="rounded"
            size={'large'}
            page={currentPage}
            showFirstButton
            showLastButton
            onChange={handlePageChange}
         />
         {orderStatus.orderHistory.length > 0 ? (
            orderStatus.orderHistory
               .filter((_order, index) => {
                  return index >= 5 * (currentPage - 1) && index < 5 * currentPage;
               })
               .map((order) => {
                  return (
                     <>
                        <Card key={order.id} className="subscription-summary" variant="outlined" sx={{ width: '100%' }}>
                           <CardContent className="section-header">
                              <Grid container>
                                 <Grid xs={12} md={6} item className={'dates-container'}>
                                    <Box marginRight={'25px'} marginBottom={'10px'}>
                                       <Typography variant={'h5'}>ORDER PLACED</Typography>
                                       <Typography variant={'h6'}>
                                          {dayjs(order.lastUpdate).format('MMM DD, YYYY')}
                                       </Typography>
                                    </Box>
                                    <Box marginRight={'25px'} marginBottom={'10px'}>
                                       <Typography variant={'h5'}>ORDER CONFIRMATION</Typography>
                                       <Typography variant={'h6'}>
                                          <Link to={`/confirmation?cartId=${order.id}`}> {order.id} </Link>
                                       </Typography>
                                    </Box>
                                 </Grid>
                              </Grid>

                              <Grid xs={12} md={6} item>
                                 <Box flexDirection={'row'}>
                                    <Typography variant={'h5'}>Status Complete</Typography>
                                 </Box>
                              </Grid>
                           </CardContent>
                           <CardContent className="section-content">
                              <Grid container>
                                 <Grid xs={12} md={9} item>
                                    <Typography variant={'h6'} fontWeight="bold" margin={'5px 0'}>
                                       Deliveries
                                    </Typography>
                                    <Box display="flex" alignItems="center" width={'90%'} marginBottom={'30px'}>
                                       {getDeliveriesAccordion(order.deliveries)}
                                    </Box>
                                 </Grid>
                                 <Grid xs={12} md={3} item>
                                    <Typography variant={'h6'} fontWeight="bold" margin={'5px 0'}>
                                       Total
                                    </Typography>
                                    {getTotals(order)}
                                 </Grid>
                              </Grid>
                           </CardContent>
                        </Card>
                     </>
                  );
               })
         ) : (
            <Typography>No records</Typography>
         )}
         <Pagination
            count={numPages}
            shape="rounded"
            size={'large'}
            page={currentPage}
            showFirstButton
            showLastButton
            onChange={handlePageChange}
         />
      </>
   );
};

export default OrderTable;
