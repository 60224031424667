import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'features/account/styles/Account.scss';
import CommonDialog from 'components/CommonDialog';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadStatus } from 'config/utils';
import {
   accountStore,
   accountUpdateBillingAsync,
   fetchUserPaymentProfilesAsync,
   resetUpdateBillingAddressStatus,
} from 'features/account/stores/account.slice';
import { ADD_BILLING_DEFAULT_VALUES, ADD_BILLING_SCHEMA } from '../../checkout/config/checkout-form.const';
import { IBillingProfileForm } from '../../checkout/types/checkout.types';
import { setOverrideWithDefault } from '../../checkout/stores/checkout-shipping.slice';
import { checkoutStore } from '../../checkout/stores/checkout.slice';
import EditPaymentDialogContent from './EditPaymentDialogContent';
import { Typography } from '@mui/material';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

interface IEditPaymentDialogProps {
   open: boolean;
   editPaymentId: string;
   setEditPaymentDialogOpen: (open: boolean) => void;
}

const EditPaymentDialog: React.FC<IEditPaymentDialogProps> = ({ open, editPaymentId, setEditPaymentDialogOpen }) => {
   const dispatch = useAppDispatch();
   const { updateBillingProfileStatus } = useAppSelector(checkoutStore);
   const { updateBillingAddressStatus } = useAppSelector(accountStore);

   const [confirmationOpen, setConfirmationOpen] = React.useState(false);
   const [dataChanged, setDataChanged] = React.useState(false);

   const editPaymentMethods = useForm<IBillingProfileForm>({
      defaultValues: ADD_BILLING_DEFAULT_VALUES,
      resolver: yupResolver(ADD_BILLING_SCHEMA),
   });

   const isLoading =
      updateBillingProfileStatus === LoadStatus.loading || updateBillingAddressStatus === LoadStatus.loading;

   const onUpdateBillingAddressStatus = () => {
      if (updateBillingAddressStatus === LoadStatus.complete) {
         dispatch(fetchUserPaymentProfilesAsync());
         handleClose();
         setEditPaymentDialogOpen(false);
         dispatch(resetUpdateBillingAddressStatus());
      }
   };

   const onEditSubmit = (data: IBillingProfileForm) => {
      const { address, address2, city, firstName, lastName, postal, region, country } = data as IBillingProfileForm;

      dispatch(
         accountUpdateBillingAsync({
            bodyInput: {
               address,
               address2,
               city,
               country,
               firstName,
               lastName,
               postal,
               region,
            },
            editPaymentId,
         }),
      );
   };

   const handleClose = () => {
      editPaymentMethods.reset();
      dispatch(setOverrideWithDefault(false));
      setEditPaymentDialogOpen(false);
   };

   const handleCancelEdit = () => {
      dataChanged ? setConfirmationOpen(true) : handleClose();
   };

   useEffect(() => {
      onUpdateBillingAddressStatus();
   }, [updateBillingAddressStatus]);

   useEffect(() => {
      if (editPaymentMethods.formState.isDirty) {
         setDataChanged(true);
      }
   }, [editPaymentMethods.formState.isDirty]);

   useEffect(() => {
      changeCustomModalOpen(confirmationOpen);
   }, [confirmationOpen]);

   return (
      <>
         <CommonDialog
            open={open}
            title={'Edit Payment Address'}
            onClose={handleCancelEdit}
            onSubmit={editPaymentMethods.handleSubmit(onEditSubmit)}
            isLoading={isLoading}
            submitLabel={'Save'}
            onCancel={handleCancelEdit}
         >
            <FormProvider {...editPaymentMethods}>
               <form onSubmit={editPaymentMethods.handleSubmit(onEditSubmit)}>
                  <EditPaymentDialogContent {...{ editPaymentId }} />
               </form>
            </FormProvider>
         </CommonDialog>
         <ConfirmationDialog
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            confirmContent={
               <Typography variant={'h5'}>
                  Are you sure you want to cancel this edit? All changes will be lost.
               </Typography>
            }
            title={'Cancel Payment Address Edit?'}
            confirmBtnText={'Confirm'}
            cancelBtnText={'Cancel'}
            onConfirm={handleClose}
            onCancel={() => setConfirmationOpen(false)}
         />
      </>
   );
};

export default EditPaymentDialog;
