import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

export enum LoadStatus {
   idle = 'idle',
   loading = 'loading',
   failed = 'failed',
   loaded = 'loaded',
   searching = 'searching',
   complete = 'complete',
}

export const Common = {
   renderHtml: (data: string) => {
      const sanitizedData = DOMPurify.sanitize(data);
      return parse(sanitizedData);
   },
};

export const handleMainElementDisplay = (display: boolean) => {
   const mainElem = document.getElementById('main');
   if (mainElem) {
      mainElem.style.display = display ? 'block' : 'none';
   }
};

export const handleFooterElementDisplay = (display: boolean) => {
   const mainElem = document.getElementById('footer');
   if (mainElem) {
      mainElem.style.display = display ? 'block' : 'none';
   }
};
