import React, { useEffect, useState } from 'react';
import '../styles/Gallery.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getSortedProductImages } from '../utils/menu-item.helper';
import { IProductImage } from '../../order/types/product.types';

interface IPhotoGalleryProps {
   images: Array<IProductImage[]> | undefined;
}

const ProductGallery = ({ images }: IPhotoGalleryProps) => {
   const productImages = images && images.length ? images : [];
   const [nav1, setNav1] = useState();
   const [slider1, setSlider1] = useState<any>(null);

   const customArrows = {
      color: 'white',
      height: '50px',
      '&:hover': {
         color: 'gray',
      },
      zIndex: 10,
   };

   const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'product-gallery-container',
      asNavFor: nav1,
      nextArrow: <ArrowForwardIosIcon sx={{ ...customArrows, right: '-5px' }} />,
      prevArrow: <ArrowBackIosNewIcon sx={{ ...customArrows, left: '-5px' }} />,
      speed: 0,
   };

   const listProducts = productImages.map((productImage: any, index: number) => (
      <div
         className="product-slide"
         key={productImage[0].url + index}
         onMouseEnter={() => {
            slider1?.slickGoTo(index);
         }}
      >
         <img width="100%" src={productImage[0].url} />
      </div>
   ));

   const getGalleryImages = () => {
      const sortedImages = getSortedProductImages(productImages);
      if (sortedImages.length > 1) {
         return (
            <>
               <Slider {...settings} ref={(slider) => setSlider1(slider)}>
                  {listProducts}
               </Slider>
               <div className="thumb-wrapper">{listProducts}</div>
            </>
         );
      }
      return (
         <img
            className={'item-image'}
            src={sortedImages ? `${sortedImages[0][1].url}` : 'item-no-image'}
            alt={'no image'}
         />
      );
   };

   useEffect(() => {
      setNav1(slider1);
   }, [slider1]);

   return productImages && productImages.length > 0 ? getGalleryImages() : null;
};

export default ProductGallery;
