import React, { useEffect } from 'react';
import { IPersonalInfoFormValues } from '../types/profile.types';
import ProfileCard from './ProfileCard';
import { Box, useMediaQuery } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PERSONAL_INFO_DEFAULT_VALUES, PERSONAL_INFO_SCHEMA } from '../config/profile-form.const';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { accountStore, updateAccountAsync } from '../../account/stores/account.slice';
import { IUpdateAccountPayload } from '../../account/types/account.types';

const ProfileForm = () => {
   const isMobileScreen = useMediaQuery('(max-width:900px)');
   const dispatch = useAppDispatch();
   const { user } = useAppSelector(accountStore);

   let personalInfoDefaultValues = { ...PERSONAL_INFO_DEFAULT_VALUES };

   const personalInfoMethods = useForm<IPersonalInfoFormValues>({
      defaultValues: { ...personalInfoDefaultValues },
      resolver: yupResolver(PERSONAL_INFO_SCHEMA),
   });

   const onPersonalInfoSubmit = (data: IUpdateAccountPayload) => {
      dispatch(
         updateAccountAsync({
            bodyInput: data,
         }),
      );
   };

   useEffect(() => {
      if (user) {
         personalInfoDefaultValues = {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            phone: user.phone || '',
         };
         personalInfoMethods.reset(personalInfoDefaultValues);
      }
   }, [user]);

   return (
      <Box className={isMobileScreen ? 'profile-form-column' : 'profile-form'} pb={20}>
         <ProfileCard
            fields={[
               { label: 'First Name', name: 'firstName', required: true },
               { label: 'Last Name', name: 'lastName', required: true },
               { label: 'Email', name: 'email', required: true },
               { label: 'Phone #', name: 'phone', required: true },
            ]}
            profileTitle="Personal Information"
            methods={personalInfoMethods}
            onSubmit={onPersonalInfoSubmit}
         />
      </Box>
   );
};

export default ProfileForm;
