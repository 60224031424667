import React, { useEffect } from 'react';
import { Stack, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
   addOfferCodeAsync,
   checkoutStore,
   resetAddOfferCodeStatus,
   resetOfferError,
} from '../../stores/checkout.slice';
import { LoadStatus } from 'config/utils';
import { useWatch } from 'react-hook-form';
import FormInput from 'components/FormInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { IOfferCodeRewardValues } from 'features/checkout/types/checkout.types';
import { StyledApplyButton } from '../customFields/ApplyButton';

const OfferPromoCode = (props: { methods: any }) => {
   const theme = useTheme();
   const { addOfferCodeStatus, offerError } = useAppSelector(checkoutStore);
   const dispatch = useAppDispatch();
   const offerErrorMessage = offerError?.[0]?.errorMessage || '';
   const isUpdatingOffer = addOfferCodeStatus === LoadStatus.loading;
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const isTabletUpScreen = useMediaQuery(theme.breakpoints.up('md'));

   const { resetField, control } = props.methods;

   const offerCodeValue = useWatch({
      control,
      name: 'code',
   });

   const onAddOfferCodeStatus = () => {
      if (addOfferCodeStatus === LoadStatus.complete) {
         dispatch(resetAddOfferCodeStatus());

         if (offerError.length === 0) {
            props.methods.setValue('code', '');
         }
      }
   };

   const onResetOfferCodeError = () => {
      dispatch(resetOfferError());
   };

   const onOfferCodeSubmit = (data: IOfferCodeRewardValues) => {
      if (data?.code) {
         dispatch(addOfferCodeAsync({ code: data.code.trim() }));
      }
   };

   const handleOfferCodeClear = () => {
      resetField('code');
      onResetOfferCodeError();
   };

   useEffect(() => {
      onResetOfferCodeError();
   }, [offerCodeValue]);

   useEffect(() => {
      onAddOfferCodeStatus();
   }, [addOfferCodeStatus]);

   return (
      <Stack direction={isMobileScreen ? 'column' : 'row'} spacing={2} sx={{ width: '100%' }} alignItems="center">
         <FormInput
            name="code"
            label="Offer or Promotional Code"
            errorMessage={offerErrorMessage}
            InputProps={{
               endAdornment: offerCodeValue ? (
                  <InputAdornment position="end">
                     <IconButton onClick={handleOfferCodeClear} edge="end" aria-label="clear input">
                        <ClearIcon />
                     </IconButton>
                  </InputAdornment>
               ) : null,
            }}
         />

         <StyledApplyButton
            style={{ ...(isMobileScreen && { marginLeft: 'auto' }) }}
            sx={{ maxHeight: '42.25px', ...(isTabletUpScreen && { minWidth: '100px' }) }}
            variant="outlined"
            color="inherit"
            onClick={props.methods.handleSubmit(onOfferCodeSubmit)}
            disabled={isUpdatingOffer}
         >
            {isUpdatingOffer ? <CircularProgress size={14} thickness={8} color="inherit" /> : 'Apply'}
         </StyledApplyButton>
      </Stack>
   );
};

export default OfferPromoCode;
