import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';

interface FormInputProps {
   name: string;
   label: string;
   type?: string;
   multiline?: boolean;
   autoComplete?: string;
   InputProps?: any;
   errorMessage?: string;
   InputLabelProps?: any;
}

const FormInput: React.FC<FormInputProps> = ({
   name,
   label,
   type,
   multiline,
   autoComplete,
   errorMessage,
   InputProps,
   InputLabelProps,
}) => {
   const {
      register,
      formState: { errors },
   } = useFormContext();

   const displayErrorMessage = errors[name] ? String(errors[name]?.message) : '';
   const hasError = !!errors[name] || !!errorMessage;

   return (
      <TextField
         {...register(name)}
         label={label}
         fullWidth
         type={type}
         multiline={multiline}
         error={hasError}
         helperText={errorMessage ? errorMessage : displayErrorMessage}
         autoComplete={autoComplete}
         InputProps={InputProps}
         InputLabelProps={InputLabelProps}
      />
   );
};

export default FormInput;
