import FormDropdown from '../components/FormDropdown';
import FormCheckbox from '../components/FormCheckbox';
import React from 'react';
import { IProduct, IProductImage, IProductVariation, IProductVariationOption } from '../../order/types/product.types';
import { ITagOverride } from '../types/menu-item.types';

export const getVariationType = (variation: IProductVariation) => {
   if ((variation.type === 'drop-down' || variation.type === null) && !variation.multiselect) {
      return 'drop-down';
   } else if (variation.multiselect) {
      return 'checkbox';
   }
   return null;
};

export const getVariationFormField = (
   variation: IProductVariation,
   fieldIndex: number,
   control: any,
   updateSelectionProps: any,
   errorFields: Number[],
   setErrorFields: (errorFields: Number[]) => void,
) => {
   if (!variation) return null;
   if (getVariationType(variation) === 'drop-down') {
      return (
         <FormDropdown
            variation={variation}
            fieldIndex={fieldIndex}
            control={control}
            updateSelectionProps={updateSelectionProps}
         />
      );
   } else if (getVariationType(variation) === 'checkbox') {
      return (
         <FormCheckbox
            variation={variation}
            fieldIndex={fieldIndex}
            control={control}
            updateSelectionProps={updateSelectionProps}
            errorFields={errorFields}
            setErrorFields={setErrorFields}
         />
      );
   }
   return null;
};

export const getOptionImg = (
   option: IProductVariationOption,
   productList: IProduct[],
   size: 'small' | 'medium' | 'large' | 'full',
) => {
   let imgUrl;
   if (productList) {
      const foundProduct = productList.find((product) => {
         return option.associations && option.associations[0] && product.id === option.associations[0].productId;
      });
      if (foundProduct?.images) {
         imgUrl = foundProduct.images[0].find((img) => {
            return img.size === size;
         })?.url;
      }
   }
   return imgUrl;
};

export const getSortedProductImages = (productImages: IProductImage[][]) => {
   const newProductImages = [...productImages];
   return [...newProductImages].sort((aImage, bImage) => {
      let aImageTimestamp = null;
      let bImageTimestamp = null;
      if (aImage[0]?.url) {
         const aSearchParams = new URLSearchParams(aImage[0]?.url);
         if (aSearchParams.has('se')) {
            aImageTimestamp = decodeURIComponent(aSearchParams.get('se') ?? '');
         }
      }

      if (bImage[0]?.url) {
         const bSearchParams = new URLSearchParams(bImage[0]?.url);
         if (bSearchParams.has('se')) {
            bImageTimestamp = decodeURIComponent(bSearchParams.get('se') ?? '');
         }
      }

      if (bImageTimestamp && aImageTimestamp) {
         try {
            const aDatetime = new Date(aImageTimestamp).getTime();
            const bDatetime = new Date(bImageTimestamp).getTime();

            if (aDatetime > bDatetime) {
               return 1;
            } else if (bDatetime > aDatetime) {
               return -1;
            }
         } catch {
            return 0;
         }
      }

      return 0;
   });
};

export const getReplacedTag = (name: string, tagOverrides: ITagOverride[] | null) => {
   if (!tagOverrides) return { name: '', color: '' };
   const foundTag = tagOverrides.find((tag) => {
      return tag.tag === name;
   });
   return foundTag
      ? { name: foundTag.replace, color: foundTag.color, fontColor: foundTag.fontColor ?? null }
      : { name: '', color: '' };
};
