import { IProduct } from '../types/product.types';
import { RefObject } from 'react';

export const scrollIntoViewWithOffset = (selector: HTMLElement, offset: number) => {
   window.scrollTo({
      behavior: 'smooth',
      top: selector.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
   });
};

export const getProductByProductId = (productId: string, productList: IProduct[]) => {
   return productList.find((product) => {
      return product.id === productId;
   });
};

export const adjustHeaderTop = (headerContainerRef: RefObject<HTMLDivElement>, isLargeScreenDown: boolean) => {
   if (headerContainerRef.current) {
      const headerElement = document.getElementsByClassName(isLargeScreenDown ? 'app-bar' : 'header-main');

      if (headerElement.length > 0) {
         const headerHeight = headerElement[0].clientHeight > 0 ? headerElement[0].clientHeight : 80;
         headerContainerRef.current.style.top = `${headerHeight - 1}px`;
      }
   }
};
