import React, { useEffect, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { CategoryProductMapping } from '../types/category-product.types';
import { IconButton } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import TabsList from './TabsList';

type Orientation = 'vertical' | 'horizontal' | undefined;

const CategoryPopUp = (props: {
   dataToShow: CategoryProductMapping;
   categoryTabValue: string;
   setCategoryTabValue: (mapping: string) => void;
   scrollToCategory: (event: React.SyntheticEvent, categoryName: string) => void;
   style: Orientation;
   categoryContainerHeight: string;
}) => {
   const [open, setOpen] = React.useState(false);
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const anchorRef = React.useRef<HTMLDivElement>(null);

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event: Event | React.SyntheticEvent) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
         return;
      }

      setOpen(false);
   };

   // return focus to the button when we transitioned from !open -> open
   const prevOpen = useRef(open);
   useEffect(() => {
      if (prevOpen.current && !open) {
         anchorRef.current!.focus();
      }

      prevOpen.current = open;
   }, [open]);

   return (
      <Stack direction="row" spacing={2}>
         <div>
            <IconButton
               aria-label="more"
               id="long-button"
               aria-controls={open ? 'long-menu' : undefined}
               aria-expanded={open ? 'true' : undefined}
               aria-haspopup="true"
               onClick={handleClick}
            >
               <ListIcon />
            </IconButton>
            <Popper
               open={open}
               ref={anchorRef}
               anchorEl={anchorEl}
               role={undefined}
               placement="bottom"
               className="long-menu-tabs"
               transition
               disablePortal
               sx={{ zIndex: 101 }}
            >
               {({ TransitionProps }) => (
                  <Grow
                     {...TransitionProps}
                     style={{
                        transformOrigin: 'bottom',
                     }}
                  >
                     <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                           <Paper>
                              <TabsList
                                 dataToShow={props.dataToShow}
                                 scrollToCategory={props.scrollToCategory}
                                 style={props.style}
                                 categoryTabValue={props.categoryTabValue}
                                 categoryContainerHeight={props.categoryContainerHeight}
                              />
                           </Paper>
                        </ClickAwayListener>
                     </Paper>
                  </Grow>
               )}
            </Popper>
         </div>
      </Stack>
   );
};

export default CategoryPopUp;
