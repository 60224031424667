import { apiServerUrl } from 'config/server';
import { expandApiServerHeader, prepareTokenPayload } from 'utils/mpt-session.helper';
import {
   ISubmitSubscriptionBillingPayload,
   ISubmitSubscriptionCancelPayload,
   ISubmitSubscriptionFreezePayload,
   ISubsCancelResponse,
   ISubscriptionBillingResponse,
   ISubscriptionModResponse,
   ISubscriptionsResponse,
   ISubsFreezeResponse,
} from '../types/subscriptions.types';

const subscriptionsUrl = `/user/subscriptions`;

const prodUrls = {
   fetchUserSubscriptions: `${apiServerUrl}${subscriptionsUrl}`,
};

export function fetchUserSubscriptions() {
   return new Promise<ISubscriptionsResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(`${prodUrls.fetchUserSubscriptions}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ISubscriptionsResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchUserSubscription(subscriptionId: string) {
   return new Promise<ISubscriptionModResponse>((resolve, reject) => {
      const postData = {
         action: 'init',
      };

      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(
         `${prodUrls.fetchUserSubscriptions}/${subscriptionId}`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ISubscriptionModResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postUserSubscription(subscriptionId: string) {
   return new Promise<ISubscriptionModResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(
         `${prodUrls.fetchUserSubscriptions}/${subscriptionId}`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ISubscriptionModResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchSubscriptionFreeze(subscriptionId: string) {
   return new Promise<ISubsFreezeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(
         `${prodUrls.fetchUserSubscriptions}/${subscriptionId}/freeze`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ISubsFreezeResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postSubscriptionFreeze(subscriptionId: string, bodyInput: ISubmitSubscriptionFreezePayload) {
   return new Promise<ISubsFreezeResponse>((resolve, reject) => {
      const postData = {
         fromDate: bodyInput.fromDate,
         toDate: bodyInput.toDate,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(
         `${prodUrls.fetchUserSubscriptions}/${subscriptionId}/freeze`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ISubsFreezeResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postSubscriptionUnfreeze(subscriptionId: string, bodyInput: ISubmitSubscriptionFreezePayload) {
   return new Promise<ISubsFreezeResponse>((resolve, reject) => {
      const postData = {
         fromDate: bodyInput.fromDate,
         toDate: bodyInput.toDate,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(
         `${prodUrls.fetchUserSubscriptions}/${subscriptionId}/unfreeze`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ISubsFreezeResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchSubscriptionCancel(subscriptionId: string) {
   return new Promise<ISubsCancelResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(
         `${prodUrls.fetchUserSubscriptions}/${subscriptionId}/cancel`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ISubsCancelResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postSubscriptionCancel(subscriptionId: string, bodyInput: ISubmitSubscriptionCancelPayload) {
   return new Promise<ISubsCancelResponse>((resolve, reject) => {
      const postData = {
         cancelDate: bodyInput.cancelDate,
         cancelText: bodyInput.cancelText,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(
         `${prodUrls.fetchUserSubscriptions}/${subscriptionId}/cancel`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ISubsCancelResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postSubscriptionBilling(subscriptionId: string, bodyInput: ISubmitSubscriptionBillingPayload) {
   return new Promise<ISubscriptionBillingResponse>((resolve, reject) => {
      const postData = {
         billingProfileId: bodyInput.billingProfileId,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(
         `${prodUrls.fetchUserSubscriptions}/${subscriptionId}/billing`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ISubscriptionBillingResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
