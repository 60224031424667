import React, { useState } from 'react';
import 'features/account/styles/Account.scss';
import {
   Alert,
   Box,
   Chip,
   FormControlLabel,
   IconButton,
   ListItemText,
   Menu,
   MenuItem,
   Radio,
   RadioGroup,
   Stack,
   Tooltip,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { accountStore } from 'features/account/stores/account.slice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { IShippingProfile } from '../../types/checkout.types';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import {
   checkoutShippingStore,
   setAddressToDefault,
   setAddressToDelete,
   setDefaultAddressDialogOpen,
   setDeleteAddressDialogOpen,
   setDeliveryDialogOpen,
} from '../../stores/checkout-shipping.slice';

interface ISelectAddressDialogContentProps {
   handleSwitchToEdit: (addressId: string) => void;
   selectedAddressId: string | null;
   setSelectedAddressId: (addressId: string) => void;
}

const SelectAddressDialogContent: React.FC<ISelectAddressDialogContentProps> = ({
   handleSwitchToEdit,
   selectedAddressId,
   setSelectedAddressId,
}) => {
   const dispatch = useAppDispatch();
   const { addressRuleError } = useAppSelector(checkoutShippingStore);
   const { shippingProfiles } = useAppSelector(accountStore);
   const [anchorEl, setAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});

   const handleOptionClick = (event: React.MouseEvent<HTMLButtonElement>, addressId: string) => {
      setAnchorEl((prev) => ({ ...prev, [addressId]: event.currentTarget }));
   };

   const handleOptionClose = (addressId: string) => {
      setAnchorEl((prev) => ({ ...prev, [addressId]: null }));
   };

   const onAddressDeleteClicked = (address: IShippingProfile) => {
      dispatch(setDeliveryDialogOpen(false));
      dispatch(setDeleteAddressDialogOpen(true));
      dispatch(setAddressToDelete(address));
   };

   const onSetDefaultClicked = (address: IShippingProfile) => {
      dispatch(setDeliveryDialogOpen(false));
      dispatch(setDefaultAddressDialogOpen(true));
      dispatch(setAddressToDefault(address));
   };

   const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedAddressId(event.target.value);
   };

   return (
      <>
         <Box>
            {addressRuleError
               .filter((filterError) => filterError.id === selectedAddressId)
               .map((checkoutError, errorIndex) => (
                  <Alert key={`delivery-error-${errorIndex}`} severity="error">
                     {checkoutError.errorMessage}
                  </Alert>
               ))}
            <Box mt={2} mb={3}>
               <div className="addresses-list">
                  <RadioGroup
                     aria-labelledby="delivery-list-group"
                     name="delivery-list-group"
                     value={selectedAddressId}
                     onChange={handleAddressChange}
                  >
                     <Stack direction="column" gap={2}>
                        {shippingProfiles.map((address, index) => (
                           <Box key={`delivery-address-${index}`} display="flex">
                              <FormControlLabel
                                 value={address.id}
                                 control={<Radio />}
                                 style={{ marginRight: '8px' }}
                                 label={
                                    <Box
                                       className={`address-label ${selectedAddressId === address.id ? 'selected' : ''}`}
                                    >
                                       <span className="shipping-address-name">
                                          {address.firstName}, {address.lastName}
                                       </span>
                                       <br />
                                       {address.address}
                                       {address.address2 ? (
                                          <>
                                             <br />
                                             {address.address2}
                                          </>
                                       ) : null}
                                       <br />
                                       {address.city}, {address.region}, {address.postal}
                                    </Box>
                                 }
                                 sx={{ textWrap: 'wrap' }}
                              />
                              <Box ml="auto">
                                 <Stack direction="column">
                                    {address.default ? <Chip label={'Default'} sx={{ marginBottom: '5px' }} /> : null}
                                    {selectedAddressId === address.id ? (
                                       <Box ml="auto">
                                          <Tooltip title="More Options">
                                             <IconButton onClick={(event) => handleOptionClick(event, address.id)}>
                                                <MoreVertIcon />
                                             </IconButton>
                                          </Tooltip>
                                       </Box>
                                    ) : null}
                                 </Stack>

                                 <Menu
                                    anchorEl={anchorEl[address.id]}
                                    anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl[address.id])}
                                    onClose={() => handleOptionClose(address.id)}
                                 >
                                    <MenuItem onClick={() => handleSwitchToEdit(address.id)}>
                                       <ListItemIcon>
                                          <EditIcon />
                                       </ListItemIcon>
                                       <ListItemText>Edit</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => onAddressDeleteClicked(address)}>
                                       <ListItemIcon>
                                          <DeleteIcon />
                                       </ListItemIcon>
                                       <ListItemText>Delete</ListItemText>
                                    </MenuItem>
                                    {!address.default ? (
                                       <MenuItem onClick={() => onSetDefaultClicked(address)}>
                                          <ListItemIcon>
                                             <StarIcon />
                                          </ListItemIcon>
                                          <ListItemText>Set Default</ListItemText>
                                       </MenuItem>
                                    ) : null}
                                 </Menu>
                              </Box>
                           </Box>
                        ))}
                     </Stack>
                  </RadioGroup>
               </div>
            </Box>
         </Box>
      </>
   );
};

export default SelectAddressDialogContent;
