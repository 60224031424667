import { createMptTheme } from '../styles/custom-theme';

export const loadMptTheme = (loadedMptSession: any) => {
   const mptTheme = createMptTheme({
      companyPrimary: loadedMptSession.options?.colors?.primary,
      companyPrimaryFontColor: loadedMptSession.options?.colors?.primary_font,
      companyFontFamily: loadedMptSession.options?.fonts?.primary_font_family,
      companyPrimaryIcon: loadedMptSession.options?.colors?.primary_icon,
      companyPrimaryTypography: loadedMptSession.options?.colors?.primary_typography,
      companyBackground: loadedMptSession.options?.colors?.primary_background,
      companySecondary: loadedMptSession.options?.colors?.secondary,
      orderBar: loadedMptSession.options?.colors?.order_bar,
   });

   loadedMptSession.options?.colors?.primary &&
      document.documentElement.style.setProperty('--company-primary', loadedMptSession.options.colors.primary);

   loadedMptSession.options?.fonts?.primary_font_family &&
      document.documentElement.style.setProperty(
         '--company-primary-font-family',
         loadedMptSession.options.fonts.primary_font_family || 'sans-serif',
      );

   loadedMptSession.options?.colors?.primary_font &&
      document.documentElement.style.setProperty(
         '--company-primary-font-color',
         loadedMptSession.options.colors.primary_font,
      );

   loadedMptSession.options?.colors?.primary_typography &&
      document.documentElement.style.setProperty(
         '--company-primary-typography-color',
         loadedMptSession.options.colors.primary_typography,
      );

   loadedMptSession.options?.colors?.primary_background &&
      document.documentElement.style.setProperty(
         '--company-primary-background',
         loadedMptSession.options.colors.primary_background,
      );

   loadedMptSession.options?.colors?.secondary &&
      document.documentElement.style.setProperty('--company-secondary', loadedMptSession.options.colors.secondary);

   loadedMptSession.options?.fonts?.primary_font_family &&
      document.documentElement.style.setProperty(
         '--company-secondary-font-family',
         loadedMptSession.options.fonts.primary_font_family,
      );

   return mptTheme;
};
