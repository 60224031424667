import { apiServerUrl } from 'config/server';
import { IFetchUserOrdersResponse } from '../types/profile.types';
import { expandApiServerHeader, prepareTokenPayload } from 'utils/mpt-session.helper';

const userOrdersUrl = `/user/orders`;

const prodUrls = {
   fetchUserOrdersUrl: `${apiServerUrl}${userOrdersUrl}`,
};

export function fetchUserOrders() {
   return new Promise<IFetchUserOrdersResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };
      fetch(`${prodUrls.fetchUserOrdersUrl}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IFetchUserOrdersResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
