import React from 'react';
import {
   Avatar,
   Box,
   Divider,
   Grid,
   List,
   ListItem,
   ListItemAvatar,
   ListItemButton,
   ListItemText,
} from '@mui/material';

import '../../cart/styles/CartItemList.scss';
import { ICartProduct } from '../../cart/types/cart.types';
import { parseVariationSelections } from '../../order/utils/product-card.helper';
import { formatter } from '../../menuItem/utils/menu-item.const';

interface ICartItemListProps {
   cartProducts: ICartProduct[] | null;
}

const DeliveryItemList: React.FC<ICartItemListProps> = ({ cartProducts }) => {
   return (
      <List className="cart-item-list-container" dense={true}>
         {cartProducts?.map((cartProduct, pIdx) => {
            const selectedVariations = parseVariationSelections(cartProduct.selectedVariations);
            return (
               <div key={`cart-item-${pIdx}`}>
                  <ListItemButton>
                     <ListItem className="cart-item" disablePadding>
                        <Grid container>
                           <Grid xs={3} item={true}>
                              <ListItemAvatar className="cart-item-image">
                                 <Avatar alt={cartProduct.name} src={cartProduct.image ? cartProduct.image : ''} />
                              </ListItemAvatar>
                           </Grid>
                           <Grid xs={6} item={true}>
                              <ListItemText className="cart-item-name" primary={cartProduct.name} />
                              <Box className="cart-variation-row" mb={'1rem'}>
                                 <span className="cart-variation-label">Qty: </span>{' '}
                                 <span className="cart-variation-value">{`${cartProduct.qty} x ${formatter.format(cartProduct.price)}`}</span>
                              </Box>
                              {selectedVariations.length
                                 ? selectedVariations.map((selectedVariation, index) => {
                                      return (
                                         <Box key={selectedVariation.name + index} className="cart-variation-row">
                                            <span className="cart-variation-label">{selectedVariation.name}:</span>{' '}
                                            <span className="cart-variation-value">
                                               {selectedVariation.options.join(', ')}
                                            </span>
                                         </Box>
                                      );
                                   })
                                 : null}
                           </Grid>
                           <Grid xs={3} item={true} sx={{ display: 'grid' }}>
                              <ListItemText
                                 sx={{ textAlign: 'center' }}
                                 primary={`${formatter.format(cartProduct.productTotal)}`}
                              />
                           </Grid>
                        </Grid>
                     </ListItem>
                  </ListItemButton>
                  <Divider />
               </div>
            );
         })}
      </List>
   );
};

export default DeliveryItemList;
