import React from 'react';
import 'features/account/styles/Account.scss';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { accountSetDefaultShippingAsync, accountStore } from 'features/account/stores/account.slice';
import { LoadStatus } from 'config/utils';
import {
   checkoutShippingStore,
   setDefaultAddressDialogOpen,
   setDeliveryDialogOpen,
} from '../../stores/checkout-shipping.slice';

const DefaultDeliveryAddressDialog = () => {
   const dispatch = useAppDispatch();
   const { defaultAddressDialogOpen, addressToDefault } = useAppSelector(checkoutShippingStore);
   const { defaultShippingStatus } = useAppSelector(accountStore);

   const onAddressDefaultClose = () => {
      dispatch(setDefaultAddressDialogOpen(false));
      dispatch(setDeliveryDialogOpen(true));
   };

   const handleAddressDefault = () => {
      addressToDefault && dispatch(accountSetDefaultShippingAsync({ defaultAddressId: addressToDefault.id }));
   };

   return (
      <ConfirmationDialog
         open={defaultAddressDialogOpen}
         onClose={onAddressDefaultClose}
         confirmContent={
            <>
               {addressToDefault ? (
                  <Box>
                     <span className="shipping-address-name">
                        {addressToDefault.firstName}, {addressToDefault.lastName}
                     </span>
                     <br />
                     {addressToDefault.address}
                     {addressToDefault.address2 ? (
                        <>
                           <br />
                           {addressToDefault.address2}
                        </>
                     ) : null}
                     <br />
                     {addressToDefault.city}, {addressToDefault.region}, {addressToDefault.postal}
                  </Box>
               ) : null}
            </>
         }
         title={'Set Default Delivery Address?'}
         confirmBtnText={'Save'}
         cancelBtnText={'Cancel'}
         isLoading={defaultShippingStatus === LoadStatus.loading}
         onConfirm={() => handleAddressDefault()}
         onCancel={() => onAddressDefaultClose()}
      />
   );
};

export default DefaultDeliveryAddressDialog;
