import { Link, styled } from '@mui/material';

interface IMptLinkProps {
   customColor?: string;
}

export const MptLink = styled(Link)<IMptLinkProps>(({ theme, customColor }) => ({
   textDecoration: 'underline 2px !important',
   textDecorationColor: customColor ? `${customColor} !important` : `${theme.palette.primary.main} !important`,
   textUnderlineOffset: '3px',
   ...(customColor && { color: `${customColor} !important` }),
}));
