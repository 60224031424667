import React, { useEffect, useState } from 'react';
import {
   alpha,
   Backdrop,
   Box,
   Button,
   Drawer,
   DrawerProps,
   Fab,
   Stack,
   styled,
   Typography,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import { drawerWidth } from 'styles/custom-theme';
import CartOrderSummary from './CartOrderSummary';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import '../styles/CartOrderSummary/CartOrderSummary.scss';
import { companyStore } from 'features/company/stores/company.slice';
import { orderStore } from 'features/order/stores/order.slice';
import { getCartItemCount } from '../utils/cart.helper';
import { cartStore } from '../stores/cart.slice';
import { subscriptionStore } from 'features/subscription/stores/subscriptions.slice';
import { accountStore, setAccountDialogOpen, setNoCloseBtn } from 'features/account/stores/account.slice';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { handleUpdateOrderFrequency } from 'features/order/config/order.helper';
import { handleSubscriptionSave } from '../../order-bar/utils/order-bar.helper';

interface ICartDrawerProps {
   cartOpen: boolean;
   handleCartClose: () => void;
}

interface IDrawHeaderProps {
   appbarSmall: boolean;
}

export const DrawerHeader = styled('div')<IDrawHeaderProps>(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   marginTop: '10px',
   padding: theme.spacing(0, 1),
   // necessary for content to be below app bar
   ...theme.mixins.toolbar,
   justifyContent: 'flex-start',
}));

export const DrawerContainer = styled(Drawer)<DrawerProps>(({ theme, open }) => ({
   width: open ? drawerWidth : 0,
   color: theme.palette.success.main,
   flexShrink: 0,
   '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: theme.shadows[8],
   },
   '& .MuiSlider-thumb': {
      '&:hover, &.Mui-focusVisible': {
         boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
      },
      '&.Mui-active': {
         boxShadow: `0px 0px 0px 12px ${alpha(theme.palette.success.main, 0.16)}`,
      },
   },
   [theme.breakpoints.down('sm')]: {
      width: 'auto',
      '& .MuiDrawer-paper': { width: '100%', boxSizing: 'border-box' },
   },
}));

const CartDrawer: React.FC<ICartDrawerProps> = ({ cartOpen, handleCartClose }) => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const { appbarSmall } = useAppSelector(companyStore);
   const { cart } = useAppSelector(cartStore);
   const { isLoggedIn } = useAppSelector(accountStore);
   const { subscriptionModActive, selectedSubscription } = useAppSelector(subscriptionStore);
   const { selectedDeliverySlot } = useAppSelector(orderStore);
   const [waitingCheckoutLogin, setWaitingCheckoutLogin] = useState(false);
   const foundCartTotal = cart?.totals?.find((cartTotalItem) => cartTotalItem.name === 'Total');
   const cartTotal = foundCartTotal ? foundCartTotal.amount : 0;
   const foundCartWeeklyTotal = cart?.totals?.find((cartTotalItem) => cartTotalItem.name === 'Weekly Total');
   const cartWeeklyTotal = foundCartWeeklyTotal ? foundCartWeeklyTotal.amount : 0;

   const shouldUseWeeklyAmount = () => {
      return cartTotal === 0 && cartWeeklyTotal > 0;
   };

   const getCheckoutAmount = () => {
      const total = shouldUseWeeklyAmount() ? cartWeeklyTotal : cartTotal;
      return total.toLocaleString('en-US', {
         style: 'currency',
         currency: 'USD',
      });
   };

   const handleCheckout = () => {
      if (isLoggedIn) {
         handleCartClose();
         navigate('/checkout');
      } else {
         dispatch(setNoCloseBtn(false));
         dispatch(setAccountDialogOpen(true));
         setWaitingCheckoutLogin(true);
      }
   };

   const handleCartSubscriptionSave = () => {
      handleSubscriptionSave(selectedSubscription, dispatch, navigate);
      handleCartClose();
   };

   useEffect(() => {
      handleUpdateOrderFrequency(cart, selectedDeliverySlot);
   }, [selectedDeliverySlot]);

   useEffect(() => {
      if (waitingCheckoutLogin && isLoggedIn) {
         handleCartClose();
         navigate('/checkout');
         setWaitingCheckoutLogin(false);
      }
   }, [waitingCheckoutLogin, isLoggedIn]);

   return (
       <>
           {cartOpen && (
               <Backdrop
                   open={cartOpen}
                   onClick={() => handleCartClose()}
                   sx={{ zIndex: (theme) => theme.zIndex.drawer - 1, backgroundColor: 'rgba(0, 0, 0, 0)' }}
               />
           )}
          <DrawerContainer variant="persistent" anchor="right" open={cartOpen}>
             <DrawerHeader appbarSmall={appbarSmall}>
                <Stack spacing={2} alignItems="flex-end" direction="column" width="100%" p={1}>
                   <Fab size="small" color="primary" onClick={() => handleCartClose()}>
                      <CloseIcon />
                   </Fab>
                   {getCartItemCount(cart) > 0 &&
                      (subscriptionModActive ? (
                         <Button
                            className="common-btn"
                            variant="contained"
                            disableElevation
                            onClick={handleCartSubscriptionSave}
                         >
                            Save Changes
                         </Button>
                      ) : (
                         <Button className="common-btn" variant="contained" disableElevation onClick={handleCheckout}>
                            <Stack spacing={1} alignItems="center" width="100%" justifyContent="center" direction="row">
                               <Typography variant="body1" component="span">
                                  Checkout {shouldUseWeeklyAmount() ? '(Weekly)' : ''}
                               </Typography>
                               <Typography variant="body1" component="span">
                                  {getCheckoutAmount()}
                               </Typography>
                            </Stack>
                         </Button>
                      ))}
                </Stack>
             </DrawerHeader>
             <Box overflow="hidden" pt={1} pb={2} pl={isMobileScreen ? 0.65 : 2} pr={isMobileScreen ? 0.65 : 2}>
                <CartOrderSummary />
             </Box>
          </DrawerContainer>
       </>
   );
};
export default CartDrawer;
