import React, { useEffect, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import ListIcon from '@mui/icons-material/List';

const ProfileTabPopUp = (props: { tabsList: React.ReactElement }) => {
   const [open, setOpen] = React.useState(false);
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const anchorRef = React.useRef<HTMLDivElement>(null);

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event: Event | React.SyntheticEvent) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
         return;
      }
      setOpen(false);
   };

   // return focus to the button when we transitioned from !open -> open
   const prevOpen = useRef(open);
   useEffect(() => {
      if (prevOpen.current && !open) {
         anchorRef.current!.focus();
      }

      prevOpen.current = open;
   }, [open]);

   return (
      <Stack direction="row" spacing={2}>
         <div>
            <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick}>
               <ListIcon />
            </IconButton>
            <Popper
               open={open}
               ref={anchorRef}
               anchorEl={anchorEl}
               role={undefined}
               placement="bottom"
               transition
               disablePortal
               sx={{ zIndex: 2 }}
            >
               {({ TransitionProps }) => (
                  <Grow
                     {...TransitionProps}
                     style={{
                        transformOrigin: 'bottom',
                     }}
                  >
                     <Paper>
                        <ClickAwayListener onClickAway={handleClose}>{props.tabsList}</ClickAwayListener>
                     </Paper>
                  </Grow>
               )}
            </Popper>
         </div>
      </Stack>
   );
};

export default ProfileTabPopUp;
