import { IProduct, IProductVariation, IProductVariationDisplay } from '../types/product.types';
import { getVariationType } from '../../menuItem/utils/menu-item.helper';
import { loadMPTSession } from '../../../utils/mpt-session.helper';
import { ICompanyInfo } from '../../company/types/company.types';

const loadedMptSession = loadMPTSession();

const DEFAULT_MAX_QUICK_ADD_OPTIONS = 5;

export const checkQuickVariation = (item: IProduct, companyInfo: ICompanyInfo) => {
   if (!item?.variations || item.variations.length === 0) {
      return 0;
   }

   if (item?.variations.length === 1) {
      if (
         ((!companyInfo.uiSettings?.maxQuickVariation &&
            item.variations[0].options.length <= DEFAULT_MAX_QUICK_ADD_OPTIONS) ||
            (companyInfo.uiSettings?.maxQuickVariation &&
               item.variations[0].options.length <= companyInfo.uiSettings.maxQuickVariation)) &&
         item.variations[0].options.every((option) => option.variations === null)
      ) {
         return item.variations[0].options.length > 1 ? 1 : 0;
      }
   }

   return 2;
};

export const parseVariationSelections = (selectedVariations: IProductVariation[]) => {
   let variationDisplays: IProductVariationDisplay[];

   variationDisplays = selectedVariations.map((selectedVariation) => {
      return {
         name: selectedVariation.name,
         options: selectedVariation.options.map((option) => option.text),
      } as IProductVariationDisplay;
   });

   return variationDisplays;
};

export const getDefaultItemPrice = (item: IProduct) => {
   if (loadedMptSession?.companyInfo?.uiSettings?.showBasePrice) {
      return item.price;
   }
   let totalVariationPrice = 0;

   const getVariationsPrice = (variations: IProductVariation[]) => {
      let variationsPrice = 0;

      variations.forEach((variation) => {
         let thisVariationPrice = 0;
         const defaultOptionIndex: number[] = [];
         variation.options.forEach((option, index) => {
            if (option.default) {
               defaultOptionIndex.push(index);
            }
         });
         if (getVariationType(variation) === 'drop-down' && defaultOptionIndex.length === 0) {
            defaultOptionIndex.push(0);
         }
         defaultOptionIndex.forEach((optionIndex) => {
            thisVariationPrice += variation.options[optionIndex].price;
         });
         // thisVariationPrice += variation.options[defaultOptionIndex].price;
         if (getVariationType(variation) === 'drop-down') {
            if (variation?.options?.[defaultOptionIndex[0]]?.variations) {
               thisVariationPrice += getVariationsPrice(variation.options[defaultOptionIndex[0]].variations ?? []);
            }
         }

         if (thisVariationPrice === 0) {
            thisVariationPrice = getVariationType(variation) === 'drop-down' ? variation.options[0].price : 0;
         }

         variationsPrice += thisVariationPrice;
      });

      return variationsPrice;
   };

   if (item.variations?.length) {
      totalVariationPrice = getVariationsPrice(item.variations);
   }

   return (item.price ?? 0) + totalVariationPrice;
};
