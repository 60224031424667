import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoadStatus } from 'config/utils';
import { IDeliveryChangeResponse, IDeliveryOption, IDeliveryTimeSlot } from '../types/order.types';
import {
   changeDelivery,
   changeDeliveryLocation,
   deleteDeliveryDates,
   fetchAutoAddDate,
   fetchDeliveryDates,
   postDeliveryDate,
   updateReward,
} from '../services/delivery.api';
import { ICart, ICartProduct, ISelectedDeliverySlot } from 'features/cart/types/cart.types';
import { setCart } from 'features/cart/stores/cart.slice';
import {
   setAccountMessages,
   setBillingProfiles,
   setLoginMessages,
   setShippingProfiles,
} from 'features/account/stores/account.slice';
import { ICheckoutResponse } from 'features/checkout/types/checkout.types';
import { IError } from 'components/types/base.types';
import { fetchUserOrder } from 'features/account/services/account.api';
import { IMessage } from 'features/account/types/account.types';
import { setPackageId } from 'features/package/stores/package.slice';
import { resetCartRuleError, resetDeliveryRuleError } from '../../checkout/stores/checkout.slice';
import {
   resetAddressRuleError,
   setHasSelectedAddressRuleError,
} from 'features/checkout/stores/checkout-shipping.slice';
import { loadMPTSession, updateMPTPackageId } from 'utils/mpt-session.helper';
import { convertUpdateToCartEventData, trackMptEvent } from '../utils/event.helper';
import { TrackEventType } from '../types/event.types';
import fastEqual from 'fast-deep-equal';

export interface OrderState {
   status: LoadStatus;
   fetchDeliveryDatesStatus: LoadStatus;
   changeDeliveryDateStatus: LoadStatus;
   postDeliveryDateStatus: LoadStatus;
   autoAddDateStatus: LoadStatus;
   changeLocationStatus: LoadStatus;
   deleteDeliveryDatesStatus: LoadStatus;
   updateRewardStatus: LoadStatus;
   error: IError[];
   deliveryOptions: IDeliveryOption[];
   selectedDeliverySlot: ISelectedDeliverySlot;
   userOrder: any;
}

export const initialOrderState: OrderState = {
   status: LoadStatus.idle,
   fetchDeliveryDatesStatus: LoadStatus.idle,
   changeDeliveryDateStatus: LoadStatus.idle,
   postDeliveryDateStatus: LoadStatus.idle,
   autoAddDateStatus: LoadStatus.idle,
   changeLocationStatus: LoadStatus.idle,
   deleteDeliveryDatesStatus: LoadStatus.idle,
   updateRewardStatus: LoadStatus.idle,
   error: [],
   selectedDeliverySlot: {
      deliveryId: '',
      dayId: '',
      timeSlotId: '',
      deliveryDayName: '',
      start: '',
      end: '',
   },
   userOrder: {},
   deliveryOptions: [],
};

export const fetchDeliveryDatesAsync = createAsyncThunk(
   'order/fetchDeliveryDates',
   async (packageId?: string) => await fetchDeliveryDates(packageId),
);

export const fetchAutoAddDateAsync = createAsyncThunk(
   'order/fetchAutoAddDate',
   async (input: { packageId?: string | null }, { dispatch }) => {
      const mptLocalStorage = JSON.parse(localStorage.getItem('MPTSessionV2') as string);

      const result = await fetchAutoAddDate(
         input.packageId ? input.packageId : mptLocalStorage.packageId ? mptLocalStorage.packageId : '',
      );

      if (result?.success) {
         const loadedMptSession = loadMPTSession();
         if (loadedMptSession.authToken != null) {
            trackMptEvent(
               TrackEventType.addToCart,
               convertUpdateToCartEventData({ productId: '', name: '' } as ICartProduct),
            );
         }
         dispatch(setCart(result.cart));
         dispatch(setAccountMessages(result.messages as IMessage[]));
      }

      return result;
   },
);

export const postDeliveryDateAsync = createAsyncThunk(
   'order/postDelivery',
   async (
      input: {
         dateTime: string;
         deliveryDayId: string;
         timeSlotId: string;
         timeSlot: IDeliveryTimeSlot | undefined;
         packageId?: string | null;
      },
      { dispatch },
   ) => {
      const result = await postDeliveryDate(input.dateTime, input.deliveryDayId, input.timeSlotId, input.packageId);
      if (result?.success) {
         const deliveryDayName =
            result.cart.deliveries.find((delivery) => {
               return delivery.id === (result.newDeliveryIds?.length ? result.newDeliveryIds[0] : '');
            })?.deliveryDayName ?? '';
         dispatch(
            setSelectedDeliverySlot({
               deliveryId: result.newDeliveryIds?.length ? result.newDeliveryIds[0] : '',
               dayId: input.deliveryDayId,
               timeSlotId: input.timeSlotId,
               deliveryDayName: deliveryDayName,
               start: input.timeSlot?.start ?? '',
               end: input.timeSlot?.end ?? '',
            }),
         );
         dispatch(setCart(result.cart));
         dispatch(setAccountMessages(result.messages as IMessage[]));
         dispatch(setLoginMessages([]));
         dispatch(resetDeliveryRuleError());
         dispatch(resetCartRuleError());
      }
      return result;
   },
);

export const changeDeliveryAsync = createAsyncThunk(
   'order/changeDelivery',
   async (
      input: {
         dateTime: string;
         deliveryDayId: string;
         timeSlotId: string;
         timeSlot: IDeliveryTimeSlot | undefined;
         id?: string;
         packageId?: string | null;
      },
      { dispatch },
   ) => {
      const result = await changeDelivery(
         input.dateTime,
         input.deliveryDayId,
         input.timeSlotId,
         input.id,
         input.packageId,
      );

      if (result?.success) {
         const foundDelivery = result.cart.deliveries.find((delivery) => {
            return delivery.id === input.id;
         });
         const deliveryDayName = foundDelivery?.deliveryDayName ?? '';

         dispatch(
            setSelectedDeliverySlot({
               deliveryId: input.id || '',
               dayId: input.deliveryDayId,
               timeSlotId: input.timeSlotId,
               deliveryDayName,
               start: input.timeSlot?.start ?? '',
               end: input.timeSlot?.end ?? '',
            }),
         );

         dispatch(setAccountMessages(result.messages as IMessage[]));
         dispatch(setLoginMessages([]));
         dispatch(setCart(result.cart));
         dispatch(setPackageId(foundDelivery ? foundDelivery.packageid : null));
         dispatch(resetDeliveryRuleError());
         dispatch(resetCartRuleError());
      }
      return result;
   },
);

export const changeDeliveryLocationAsync = createAsyncThunk(
   'order/changeDeliveryLocation',
   async (
      input: {
         shippingLocationId: string;
         updatePresets: boolean;
         shippingProfileId?: string;
         shippingFirstName?: string;
         shippingLastName?: string;
         shippingPhone?: string;
      },
      { dispatch },
   ) => {
      const result = await changeDeliveryLocation(
         input.shippingLocationId,
         input.updatePresets,
         input.shippingProfileId,
         input.shippingFirstName,
         input.shippingLastName,
         input.shippingPhone,
      );

      if (result?.success) {
         const { billingProfiles, shippingProfiles, cart } = result;
         cart && dispatch(setCart(cart));
         shippingProfiles && dispatch(setShippingProfiles(shippingProfiles));
         billingProfiles && dispatch(setBillingProfiles(billingProfiles));
         dispatch(setAccountMessages(result.messages as IMessage[]));
         dispatch(setLoginMessages([]));
         dispatch(resetAddressRuleError());
         dispatch(setHasSelectedAddressRuleError(false));
         dispatch(resetDeliveryRuleError());
         dispatch(resetCartRuleError());
      }

      return result;
   },
);

export const deleteDeliveryDatesAsync = createAsyncThunk(
   'order/deleteDelivery',
   async (input: { deliveryId: string }, { dispatch }) => {
      const result = await deleteDeliveryDates(input.deliveryId);

      if (result?.success) {
         dispatch(setSelectedDeliverySlot(initialOrderState.selectedDeliverySlot));
         dispatch(setCart(result.cart));
         dispatch(setPackageId(null));
         updateMPTPackageId(null);
         dispatch(setAccountMessages(result.messages as IMessage[]));
         dispatch(setLoginMessages([]));
         dispatch(resetCartRuleError());
      }
      return result;
   },
);

export const updateRewardAsync = createAsyncThunk(
   'order/updateReward',
   async (input: { reward: number }, { dispatch }) => {
      const result = await updateReward(input.reward);
      if (result?.success) {
         dispatch(setCart(result.cart));
      }
      return result;
   },
);

export const fetchUserOrderAsync = createAsyncThunk('order/userOrder', async (input: { cartId: string }) => {
   return await fetchUserOrder(input.cartId);
});

export const orderSlice = createSlice({
   name: 'order',
   initialState: initialOrderState,
   reducers: {
      setSelectedDeliverySlot: (state, action: PayloadAction<ISelectedDeliverySlot>) => {
         state.selectedDeliverySlot = action.payload;
      },
      setSelectedDeliverySlotWithCart: (state, action: PayloadAction<ICart>) => {
         const cart = action.payload;

         //TODO need to check active delivery if we go back to multiple deliveries per cart
         if (cart.deliveries && cart.deliveries.length > 0) {
            const delivery = cart.deliveries[0];
            const deliveryTimeslot = state.deliveryOptions
               .find((deliveryOption) => {
                  return deliveryOption.id === delivery?.deliveryDayId;
               })
               ?.timeSlots.find((timeslot) => timeslot.id === delivery?.timeSlotId);
            if (delivery) {
               const newSelectedDeliverySlot = {
                  deliveryId: delivery.id,
                  dayId: delivery.deliveryDayId,
                  timeSlotId: delivery.timeSlotId,
                  deliveryDayName: delivery.deliveryDayName,
                  start: deliveryTimeslot?.start ?? '',
                  end: deliveryTimeslot?.end ?? '',
               };

               if (!fastEqual(state.selectedDeliverySlot, newSelectedDeliverySlot)) {
                  state.selectedDeliverySlot = {
                     deliveryId: delivery.id,
                     dayId: delivery.deliveryDayId,
                     timeSlotId: delivery.timeSlotId,
                     deliveryDayName: delivery.deliveryDayName,
                     start: deliveryTimeslot?.start ?? '',
                     end: deliveryTimeslot?.end ?? '',
                  };
               }
            }
         }
      },
      resetFetchDeliveryDatesStatus: (state) => {
         state.fetchDeliveryDatesStatus = LoadStatus.idle;
      },
      resetChangeDeliveryDateStatus: (state) => {
         state.changeDeliveryDateStatus = LoadStatus.idle;
      },
      resetPostDeliveryDateStatus: (state) => {
         state.postDeliveryDateStatus = LoadStatus.idle;
      },
      resetChangeLocationStatus: (state) => {
         state.changeLocationStatus = LoadStatus.idle;
      },
      resetDeleteDeliveryDatesStatus: (state) => {
         state.deleteDeliveryDatesStatus = LoadStatus.idle;
      },
      resetUpdateRewardStatus: (state) => {
         state.updateRewardStatus = LoadStatus.idle;
      },
      setOrderError(state, action: PayloadAction<IError[]>) {
         state.error = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchDeliveryDatesAsync.pending, (state) => {
            state.fetchDeliveryDatesStatus = LoadStatus.loading;
         })
         .addCase(fetchDeliveryDatesAsync.fulfilled, (state, action) => {
            state.fetchDeliveryDatesStatus = LoadStatus.complete;
            state.deliveryOptions = action.payload.deliveryOptions;
         })
         .addCase(fetchDeliveryDatesAsync.rejected, (state) => {
            state.fetchDeliveryDatesStatus = LoadStatus.failed;
         })
         .addCase(fetchAutoAddDateAsync.pending, (state) => {
            state.autoAddDateStatus = LoadStatus.loading;
         })
         .addCase(fetchAutoAddDateAsync.fulfilled, (state, action) => {
            state.autoAddDateStatus = LoadStatus.complete;
            const newDeliveryId = action?.payload?.deliveryIds?.[0];
            const newDelivery = action.payload.cart.deliveries.find((delivery) => {
               return delivery.id === newDeliveryId;
            });
            const newDeliveryTimeslot = state.deliveryOptions
               .find((deliveryOption) => {
                  return deliveryOption.id === newDelivery?.deliveryDayId;
               })
               ?.timeSlots.find((timeslot) => timeslot.id === newDelivery?.timeSlotId);
            if (newDeliveryId && newDelivery) {
               state.selectedDeliverySlot = {
                  deliveryId: newDeliveryId,
                  dayId: newDelivery.deliveryDayId,
                  timeSlotId: newDelivery.timeSlotId,
                  deliveryDayName: newDelivery.deliveryDayName,
                  start: newDeliveryTimeslot?.start ?? '',
                  end: newDeliveryTimeslot?.end ?? '',
               };
            }
         })
         .addCase(fetchAutoAddDateAsync.rejected, (state) => {
            state.autoAddDateStatus = LoadStatus.failed;
         })
         .addCase(changeDeliveryAsync.pending, (state) => {
            state.changeDeliveryDateStatus = LoadStatus.loading;
         })
         .addCase(changeDeliveryAsync.fulfilled, (state) => {
            state.changeDeliveryDateStatus = LoadStatus.complete;
         })
         .addCase(changeDeliveryAsync.rejected, (state) => {
            state.changeDeliveryDateStatus = LoadStatus.failed;
         })
         .addCase(changeDeliveryLocationAsync.pending, (state) => {
            state.changeLocationStatus = LoadStatus.loading;
            state.error = [];
         })
         .addCase(changeDeliveryLocationAsync.fulfilled, (state, action: PayloadAction<IDeliveryChangeResponse>) => {
            state.changeLocationStatus = LoadStatus.complete;

            if (action.payload.error) {
               state.error = action.payload.error;
            }
         })
         .addCase(changeDeliveryLocationAsync.rejected, (state) => {
            state.changeLocationStatus = LoadStatus.failed;
         })
         .addCase(postDeliveryDateAsync.pending, (state) => {
            state.postDeliveryDateStatus = LoadStatus.loading;
         })
         .addCase(postDeliveryDateAsync.fulfilled, (state) => {
            state.postDeliveryDateStatus = LoadStatus.complete;
         })
         .addCase(postDeliveryDateAsync.rejected, (state) => {
            state.postDeliveryDateStatus = LoadStatus.failed;
         })
         .addCase(deleteDeliveryDatesAsync.pending, (state) => {
            state.deleteDeliveryDatesStatus = LoadStatus.loading;
         })
         .addCase(deleteDeliveryDatesAsync.fulfilled, (state) => {
            state.deleteDeliveryDatesStatus = LoadStatus.complete;
         })
         .addCase(deleteDeliveryDatesAsync.rejected, (state) => {
            state.deleteDeliveryDatesStatus = LoadStatus.failed;
         })
         .addCase(updateRewardAsync.pending, (state) => {
            state.updateRewardStatus = LoadStatus.loading;
         })
         .addCase(updateRewardAsync.fulfilled, (state) => {
            state.updateRewardStatus = LoadStatus.complete;
         })
         .addCase(updateRewardAsync.rejected, (state) => {
            state.updateRewardStatus = LoadStatus.failed;
         })
         .addCase(fetchUserOrderAsync.pending, (state) => {
            state.status = LoadStatus.loading;
         })
         .addCase(fetchUserOrderAsync.fulfilled, (state, action: PayloadAction<ICheckoutResponse>) => {
            state.status = LoadStatus.complete;
            state.userOrder = action.payload.cart;
         })
         .addCase(fetchUserOrderAsync.rejected, (state) => {
            state.status = LoadStatus.failed;
         });
   },
});

export const {
   setSelectedDeliverySlot,
   setSelectedDeliverySlotWithCart,
   resetFetchDeliveryDatesStatus,
   resetChangeDeliveryDateStatus,
   resetPostDeliveryDateStatus,
   resetChangeLocationStatus,
   resetDeleteDeliveryDatesStatus,
   resetUpdateRewardStatus,
} = orderSlice.actions;

export const orderStore = (state: RootState) => state.orderStore;

export default orderSlice.reducer;
