import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'features/account/styles/Account.scss';
import CommonDialog from 'components/CommonDialog';
import * as yup from 'yup';
import { IBillingProfileForm } from '../../types/checkout.types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import { checkoutStore, resetUpdateBillingProfileStatus, updateBillingProfileAsync } from '../../stores/checkout.slice';
import { LoadStatus } from 'config/utils';
import { checkoutPaymentStore, setPaymentDialogOpen } from '../../stores/checkout-payment.slice';
import EditPaymentDialogContent from 'features/profile/components/EditPaymentDialogContent';
import SelectPaymentDialogContent from './SelectPaymentDialogContent';
import { ADD_BILLING_DEFAULT_VALUES, ADD_BILLING_SCHEMA } from '../../config/checkout-form.const';
import {
   accountStore,
   accountUpdateBillingAsync,
   fetchUserPaymentProfilesAsync,
   resetFetchBillingProfilesStatus,
   resetUpdateBillingAddressStatus,
} from 'features/account/stores/account.slice';
import {
   fetchUserSubscriptionsAsync,
   postSubscriptionBillingAsync,
   resetPostSubscriptionBillingStatus,
   subscriptionStore,
} from '../../../subscription/stores/subscriptions.slice';
import { ISubscription } from '../../../subscription/types/subscriptions.types';

const schema = yup.object().shape({});

const PaymentDetailsDialog = (props: { subscription?: ISubscription | null }) => {
   const dispatch = useAppDispatch();
   const { cart } = useAppSelector(cartStore);
   const { updateBillingProfileStatus } = useAppSelector(checkoutStore);
   const { billingProfiles, updateBillingAddressStatus, fetchBillingProfilesStatus } = useAppSelector(accountStore);
   const { paymentDialogOpen } = useAppSelector(checkoutPaymentStore);
   const { postSubscriptionBillingStatus } = useAppSelector(subscriptionStore);

   const currentSelectedPaymentId = () => props.subscription?.billingProfile?.id ?? cart?.billingProfileId ?? null;

   const [selectedPaymentId, setSelectedPaymentId] = useState(currentSelectedPaymentId);
   const [editPaymentId, setEditPaymentId] = useState('');

   const editPaymentMethods = useForm<IBillingProfileForm>({
      defaultValues: ADD_BILLING_DEFAULT_VALUES,
      resolver: yupResolver(ADD_BILLING_SCHEMA),
   });

   const selectPaymentMethods = useForm({
      defaultValues: {},
      resolver: yupResolver(schema),
   });

   const isLoading =
      updateBillingProfileStatus === LoadStatus.loading ||
      updateBillingAddressStatus === LoadStatus.loading ||
      fetchBillingProfilesStatus === LoadStatus.loading;

   const presetSelectedBilling = () => {
      const pickedBilling =
         props.subscription?.billingProfile?.id ||
         cart?.billingProfileId ||
         billingProfiles.find((profile) => profile.default)?.id ||
         (billingProfiles.length ? billingProfiles[0].id : '');
      setSelectedPaymentId(pickedBilling);
   };

   const handleClose = () => {
      selectPaymentMethods.reset();
      dispatch(setPaymentDialogOpen(false));
   };

   const onUpdateBillingProfileStatus = () => {
      if (updateBillingProfileStatus === LoadStatus.complete) {
         dispatch(resetUpdateBillingProfileStatus());
         dispatch(setPaymentDialogOpen(false));
         selectPaymentMethods.reset();
      }
   };

   const onUpdateBillingAddressStatus = () => {
      if (updateBillingAddressStatus === LoadStatus.complete) {
         dispatch(fetchUserPaymentProfilesAsync());
      }
   };

   const onFetchBillingProfilesStatus = () => {
      if (fetchBillingProfilesStatus === LoadStatus.complete && updateBillingAddressStatus === LoadStatus.complete) {
         dispatch(resetUpdateBillingAddressStatus());
         dispatch(resetFetchBillingProfilesStatus());

         setSelectedPaymentId(editPaymentId);
         dispatch(
            updateBillingProfileAsync({
               billingProfileId: editPaymentId,
            }),
         );
         handleCancelEdit();
      }
   };

   const onPostSubscriptionBillingStatus = () => {
      if (postSubscriptionBillingStatus === LoadStatus.complete) {
         dispatch(resetPostSubscriptionBillingStatus());
         dispatch(setPaymentDialogOpen(false));
         dispatch(fetchUserSubscriptionsAsync({}));
         selectPaymentMethods.reset();
      }
   };

   const onEditSubmit = (data: IBillingProfileForm) => {
      const { address, address2, city, firstName, lastName, postal, region, country } = data as IBillingProfileForm;

      dispatch(
         accountUpdateBillingAsync({
            bodyInput: {
               address,
               address2,
               city,
               country,
               firstName,
               lastName,
               postal,
               region,
            },
            editPaymentId,
         }),
      );
   };

   const onSelectSubmit = () => {
      if (selectedPaymentId) {
         if (props.subscription?.id) {
            dispatch(
               postSubscriptionBillingAsync({
                  subscriptionId: props.subscription?.id,
                  bodyInput: {
                     billingProfileId: selectedPaymentId,
                  },
               }),
            );
         } else {
            dispatch(
               updateBillingProfileAsync({
                  billingProfileId: selectedPaymentId,
               }),
            );
         }
      }
   };

   const handleSwitchToEdit = (paymentId: string) => {
      setEditPaymentId(paymentId);
   };

   const handleCancelEdit = () => {
      setEditPaymentId('');
      editPaymentMethods.reset();
   };

   useEffect(() => {
      presetSelectedBilling();
   }, [cart, billingProfiles, props.subscription?.billingProfile?.id]);

   useEffect(() => {
      onUpdateBillingProfileStatus();
   }, [updateBillingProfileStatus]);

   useEffect(() => {
      onUpdateBillingAddressStatus();
   }, [updateBillingAddressStatus]);

   useEffect(() => {
      onFetchBillingProfilesStatus();
   }, [fetchBillingProfilesStatus]);

   useEffect(() => {
      onPostSubscriptionBillingStatus();
   }, [postSubscriptionBillingStatus]);

   return (
      <CommonDialog
         open={paymentDialogOpen}
         title={editPaymentId ? 'Edit Payment Method' : 'Select Payment Method'}
         onClose={handleClose}
         onSubmit={
            editPaymentId
               ? editPaymentMethods.handleSubmit(onEditSubmit)
               : selectPaymentMethods.handleSubmit(onSelectSubmit)
         }
         isLoading={isLoading}
         submitLabel={editPaymentId ? 'Save' : 'Select'}
         onCancel={editPaymentId ? handleCancelEdit : null}
      >
         {editPaymentId ? (
            <FormProvider {...editPaymentMethods}>
               <form onSubmit={editPaymentMethods.handleSubmit(onEditSubmit)}>
                  <EditPaymentDialogContent {...{ editPaymentId }} />
               </form>
            </FormProvider>
         ) : (
            <FormProvider {...selectPaymentMethods}>
               <form onSubmit={selectPaymentMethods.handleSubmit(onSelectSubmit)}>
                  <SelectPaymentDialogContent {...{ handleSwitchToEdit, selectedPaymentId, setSelectedPaymentId }} />
               </form>
            </FormProvider>
         )}
      </CommonDialog>
   );
};

export default PaymentDetailsDialog;
