import { useEffect } from 'react';
import { cartStore } from 'features/cart/stores/cart.slice';
import {
   fetchAutoAddDateAsync,
   fetchDeliveryDatesAsync,
   orderStore,
   resetChangeDeliveryDateStatus,
   resetFetchDeliveryDatesStatus,
   resetPostDeliveryDateStatus,
} from 'features/order/stores/order.slice';
import { useAppDispatch, useAppSelector } from './hooks';
import { LoadStatus } from 'config/utils';
import { fetchShopTasksAsync, productStore, resetProductList } from 'features/order/stores/product.slice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchPackagesAsync, packageStore } from 'features/package/stores/package.slice';
import { orderBarStore, setOrderDialogOpen } from 'features/order/stores/order-bar.slice';
import { setDeliverySlots } from 'features/order/config/order.helper';
import { updateMPTSession } from '../utils/mpt-session.helper';

const useUserCart = (input: { fetchShopTasks: boolean; fetchCart: boolean }) => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { cart } = useAppSelector(cartStore);
   const {
      deliveryOptions,
      autoAddDateStatus,
      selectedDeliverySlot,
      fetchDeliveryDatesStatus,
      postDeliveryDateStatus,
      changeDeliveryDateStatus,
   } = useAppSelector(orderStore);
   const { packageId, packages } = useAppSelector(packageStore);
   const { orderDialogOption } = useAppSelector(orderBarStore);
   const { shopTasksStatus, shopTasksResponse } = useAppSelector(productStore);
   const [searchParams, setSearchParams] = useSearchParams();
   const activateToken = searchParams.get('activateToken');

   const onDeliveryDateStatus = () => {
      if (postDeliveryDateStatus === LoadStatus.complete || changeDeliveryDateStatus === LoadStatus.complete) {
         dispatch(resetChangeDeliveryDateStatus());
         dispatch(resetPostDeliveryDateStatus());
         dispatch(setOrderDialogOpen(false));

         if (orderDialogOption === 'change-package') {
            dispatch(resetProductList());
            navigate('/order');
         }
      }
   };

   const updateCartAndDelivery = () => {
      if (!cart?.init && deliveryOptions.length > 0) {
         if (
            cart?.deliveries.length &&
            !cart?.deliveries[0]?.deliveryDayId &&
            autoAddDateStatus !== LoadStatus.loading &&
            packages !== null &&
            input.fetchCart
         ) {
            updateMPTSession(null);
            dispatch(fetchAutoAddDateAsync({ packageId: packageId }));
         } else if (cart?.deliveries.length && !selectedDeliverySlot.deliveryId && packages !== null) {
            setDeliverySlots(cart, deliveryOptions, selectedDeliverySlot, packageId);
         } else if (
            (cart?.id === null || (cart?.id && !cart?.deliveries.length)) &&
            autoAddDateStatus !== LoadStatus.loading &&
            packages !== null &&
            input.fetchCart
         ) {
            dispatch(fetchAutoAddDateAsync({ packageId: packageId }));
         }
      }
   };

   useEffect(() => {
      dispatch(fetchPackagesAsync({ fetchCart: input.fetchCart }));
      dispatch(fetchDeliveryDatesAsync());
   }, []);

   useEffect(() => {
      if (activateToken && selectedDeliverySlot.deliveryId) {
         handleActivateAccount();
      }
   }, [activateToken, selectedDeliverySlot.deliveryId]);

   useEffect(() => {
      if (shopTasksResponse.responseToken) setSearchParams('');
   }, [shopTasksResponse]);

   useEffect(() => {
      if (fetchDeliveryDatesStatus === LoadStatus.complete) {
         dispatch(resetFetchDeliveryDatesStatus());
      }
   }, [fetchDeliveryDatesStatus]);

   useEffect(() => {
      updateCartAndDelivery();
   }, [cart, deliveryOptions, packages]);

   useEffect(() => {
      if (selectedDeliverySlot.deliveryId && input.fetchShopTasks) {
         handleFetchShopTasks();
      }
   }, [selectedDeliverySlot]);

   useEffect(() => {
      onDeliveryDateStatus();
   }, [postDeliveryDateStatus, changeDeliveryDateStatus]);

   function handleFetchShopTasks() {
      if (cart.deliveries.length && shopTasksStatus !== LoadStatus.loading) {
         let packageId = selectedDeliverySlot.packageId ? selectedDeliverySlot.packageId : cart.deliveries[0].packageid;
         let deliveryId = selectedDeliverySlot.deliveryId ? selectedDeliverySlot.deliveryId : cart.deliveries[0].id;

         dispatch(
            fetchShopTasksAsync({
               deliveryId: deliveryId,
               ...(packageId && { packageId: packageId }),
               packages,
            }),
         );
      }
   }

   const handleActivateAccount = () => {
      if (activateToken) {
         dispatch(
            fetchShopTasksAsync({
               activateToken,
               deliveryId: selectedDeliverySlot.deliveryId,
               ...(packageId && { packageId: packageId }),
               packages,
            }),
         );
      }
   };
};

export default useUserCart;
