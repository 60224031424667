import React, { useMemo, useState } from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { DEFAULT_IMAGE } from '../../../config/restaurant.const';
import { IProduct } from '../types/product.types';
import AddToCartBtn from './AddToCartBtn';
import clsx from 'clsx';
import { checkQuickVariation, getDefaultItemPrice } from '../utils/product-card.helper';
import Tag from '../../menuItem/components/Tag';
import { formatter } from '../../menuItem/utils/menu-item.const';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import SkeletonLoader from 'components/SkeletonLoader';
import { useAppSelector } from 'app/hooks';
import { orderLoadStore } from '../stores/order-load.slice';
import { Variant } from '@mui/material/styles/createTypography';
import { CardDescriptionContainer } from './CardDescriptionContainer';
import { companyStore } from '../../company/stores/company.slice';

interface IProductCardVerticalProps {
   item: IProduct;
   handleProductSelection: (productId: string) => void;
   addToCartText?: string;
   countInCart: number;
   titleVariant?: Variant;
}

const ProductCardVertical: React.FC<IProductCardVerticalProps> = ({
   item,
   countInCart,
   addToCartText,
   handleProductSelection,
   titleVariant,
}) => {
   const [numVariations, setNumVariations] = useState(2);
   const cardRef = React.useRef<HTMLDivElement>(null);
   const { cardLoading } = useAppSelector(orderLoadStore);
   const { companyInfo } = useAppSelector(companyStore);

   useMemo(() => {
      setNumVariations(checkQuickVariation(item, companyInfo));
   }, [item]);

   return (
      <>
         <Card
            ref={cardRef}
            className={clsx('product-card product-card-vertical', { 'with-add-text': addToCartText })}
            sx={{ justifyContent: 'space-between', alignItems: 'center', borderRadius: '1rem' }}
         >
            <SkeletonLoader
               loading={cardLoading}
               skeletonProps={{ width: '100%', height: cardRef.current ? cardRef.current.offsetHeight : '500px' }}
            >
               <Box className="product-image" sx={{ position: 'relative' }}>
                  <Box position="absolute" zIndex={2} top={0} left={0}>
                     <Tag product={item} />
                  </Box>
                  <CardMedia
                     component="img"
                     image={item?.images && item.images.length ? `${item.images[0][2].url}` : DEFAULT_IMAGE}
                     alt={item.name}
                     onClick={() => {
                        handleProductSelection(item.id);
                     }}
                     sx={{ position: 'relative', zIndex: 1 }}
                  />
               </Box>
               <Box className={clsx('product-detail', { 'with-add-text': addToCartText })}>
                  <CardContent sx={{ flex: '1 0 auto', padding: '10px' }}>
                     <Typography
                        sx={{ fontWeight: 600, textAlign: 'center' }}
                        gutterBottom
                        variant={titleVariant ? titleVariant : 'h5'}
                        component="div"
                        className="card-content card-title"
                     >
                        {item.name}
                     </Typography>
                     <CardDescriptionContainer className="card-content">
                        <Markdown remarkPlugins={[remarkGfm]}>
                           {item.specifications?.length ? item.specifications : item.description}
                        </Markdown>
                     </CardDescriptionContainer>
                     <Typography sx={{ fontWeight: 600, textAlign: 'center', marginTop: '15px' }} variant="h6">
                        {getDefaultItemPrice(item) ? formatter.format(getDefaultItemPrice(item) as number) : null}
                     </Typography>
                  </CardContent>
               </Box>
               <AddToCartBtn
                  {...{ addToCartText, item, numVariations, handleProductSelection, itemsInCart: countInCart }}
               />
            </SkeletonLoader>
         </Card>
      </>
   );
};

export default ProductCardVertical;
