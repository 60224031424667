import React, { useEffect } from 'react';
import { convertPageViewEventData, trackMptEvent } from '../features/order/utils/event.helper';
import { TrackEventType } from '../features/order/types/event.types';
import useUserCart from '../app/useUserCart';
import { handleFooterElementDisplay, handleMainElementDisplay, LoadStatus } from '../config/utils';
import { cartStore, resetFetchCart } from '../features/cart/stores/cart.slice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchSelectorProductListAsync, productStore } from '../features/order/stores/product.slice';
import {
   resetFetchUserSubscriptionStatus,
   subscriptionStore,
} from '../features/subscription/stores/subscriptions.slice';
import FullPageLoader from '../components/FullPageLoader';
import { orderStore } from '../features/order/stores/order.slice';
import { accountStore, setAccountDialogOpen } from '../features/account/stores/account.slice';
import Order from 'features/order/Order';
import {
   setCardLoading,
   setOrderBarCardLoading,
   setSearchBarCardLoading,
} from '../features/order/stores/order-load.slice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { companyStore } from '../features/company/stores/company.slice';
import { packageStore, resetFetchPackagesStatus } from '../features/package/stores/package.slice';

export default function OrderPage() {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { companyInfo } = useAppSelector(companyStore);
   const { selectedDeliverySlot } = useAppSelector(orderStore);
   const { packageId, fetchPackagesStatus } = useAppSelector(packageStore);
   const { shopTasksStatus } = useAppSelector(productStore);
   const { subscriptionModActive, fetchUserSubscriptionStatus } = useAppSelector(subscriptionStore);
   const { fetchCartResponse, fetchCartStatus, postSubscribeAllStatus } = useAppSelector(cartStore);
   const { logoutStatus, loginStatus, isLoggedIn } = useAppSelector(accountStore);
   const {
      changeLocationStatus,
      fetchDeliveryDatesStatus,
      deleteDeliveryDatesStatus,
      autoAddDateStatus,
      changeDeliveryDateStatus,
   } = useAppSelector(orderStore);
   useUserCart({ fetchCart: !subscriptionModActive, fetchShopTasks: true });
   const [searchParams] = useSearchParams();
   const loginParam = searchParams.get('login');

   const isLoading =
      fetchPackagesStatus === LoadStatus.loading ||
      fetchCartStatus === LoadStatus.loading ||
      fetchDeliveryDatesStatus === LoadStatus.loading ||
      deleteDeliveryDatesStatus === LoadStatus.loading ||
      autoAddDateStatus === LoadStatus.loading ||
      changeDeliveryDateStatus === LoadStatus.loading ||
      changeLocationStatus === LoadStatus.loading ||
      postSubscribeAllStatus === LoadStatus.loading ||
      logoutStatus === LoadStatus.loading ||
      loginStatus === LoadStatus.loading;

   useEffect(() => {
      if (fetchCartStatus === LoadStatus.complete && fetchCartResponse.success) {
         dispatch(resetFetchCart());
         dispatch(setOrderBarCardLoading(false));
      }
   }, [fetchCartResponse]);

   useEffect(() => {
      if (companyInfo.uiSettings?.packageOnly && !packageId && selectedDeliverySlot.deliveryId) {
         navigate('/shop');
      }
   }, [selectedDeliverySlot]);

   useEffect(() => {
      if (fetchUserSubscriptionStatus === LoadStatus.complete) {
         dispatch(resetFetchUserSubscriptionStatus());
         dispatch(setOrderBarCardLoading(false));
      }
   }, [fetchUserSubscriptionStatus]);

   useEffect(() => {
      if (shopTasksStatus === LoadStatus.complete) {
         dispatch(setSearchBarCardLoading(false));
      }
   }, [shopTasksStatus]);

   useEffect(() => {
      if (fetchPackagesStatus === LoadStatus.complete) {
         dispatch(resetFetchPackagesStatus());
      }
   }, [fetchPackagesStatus]);

   useEffect(() => {
      dispatch(setCardLoading(true));
      dispatch(setSearchBarCardLoading(true));
      if (fetchUserSubscriptionStatus !== LoadStatus.complete) {
         dispatch(setOrderBarCardLoading(true));
      }
      dispatch(fetchSelectorProductListAsync({}));
      trackMptEvent(TrackEventType.pageView, convertPageViewEventData());
      handleMainElementDisplay(true);
      handleFooterElementDisplay(true);
   }, []);

   useEffect(() => {
      if (loginParam) {
         if (!isLoggedIn) {
            dispatch(setAccountDialogOpen(true));
         } else {
            navigate('/order');
         }
      }
   }, [loginParam]);

   return (
      <>
         <FullPageLoader loading={isLoading} />
         <Order />
      </>
   );
}
