import { IUser } from 'features/checkout/types/checkout.types';
import { IMessage } from 'features/account/types/account.types';
import { ICompanyInfo } from 'features/company/types/company.types';

export enum ErrorCode {
   UnauthorizedAPI = 0,
   InvalidLogin = 1,
   UnathorizedUser = 2,
   InvalidFormat = 3,
   OperationFailed = 4,
   BadRequest = 5,
   UncaughtException = 6,
   NoBilling = 7,
   InvalidCartNoLocation = 8,
   InvalidCartBadHomeAddress = 9,
   InvalidCartViolatedDeliveryRule = 10,
   InvalidCartEmpty = 11,
   InvalidCartModified = 12,
   InvalidCartViolatedCartRule = 13,
   InvalidAddress = 14,
   InvalidAddress2 = 15,
   InvalidCity = 16,
   InvalidRegion = 17,
   InvalidPostal = 18,
   PaymentDeclined = 19,
   FinanceAppDeclined = 20,
   FinanceAppIncomeInvalid = 101,
   FinanceAppEmployerInvalid = 102,
   FinanceAppBirthdateInvalid = 103,
   FinanceAppSSNInvalid = 104,
   InvalidBillingFirstName = 105,
   InvalidBillingLastName = 106,
   InvalidCVV = 107,
   InvalidExpiration = 108,
   InvalidAccountNumber = 109,
   InvalidRoutingNumber = 110,
   MerchantAppAlreadyComplete = 120,
   InventoryLimit = 130,
}

export interface IError {
   adminMessage: string | null;
   displayToUser?: boolean;
   errorCode: ErrorCode;
   errorMessage: string | null;
   redirectToLogin?: boolean;
   data?: any;
   referenceNumber?: any;
   route?: any;
   routeMessage?: any;
   title?: any;
   id?: string;
}

export interface IAuthToken {
   expirationUTC?: string;
   ip?: string;
   overwriteMode?: boolean;
   token: string;
}

export interface IServerResponse {
   authToken?: IAuthToken | null;
   clientCartId?: string;
   companyInfo?: ICompanyInfo;
   displayName?: string;
   messages?: IMessage[];
   success: boolean;
   user?: IUser;
}
