import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { DEFAULT_IMAGE } from 'config/restaurant.const';
import { IPackage } from '../types/package.types';
import SelectPackageBtn from './SelectPackageBtn';
import clsx from 'clsx';

interface IPackageCardHorizontalProps {
   item: IPackage;
   handlePackageSelection: (productId: string) => void;
   handlePackageView: (packageId: string) => void;
}

const PackageCardHorizontal: React.FC<IPackageCardHorizontalProps> = ({
   item,
   handlePackageSelection,
   handlePackageView,
}) => {
   return (
      <Card
         className="product-card"
         sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
         style={{ borderRadius: '1rem', cursor: 'pointer' }}
      >
         <Box display="flex" flexDirection="column" margin="auto">
            <CardContent
               sx={{ flex: '1 0 auto', padding: '10px', ':last-child': { paddingBottom: '0px' } }}
               onClick={() => {
                  handlePackageView(item.id);
               }}
            >
               <Typography
                  sx={{ fontWeight: 600 }}
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  className="card-content"
               >
                  {item.name}
               </Typography>
               <Typography variant="body2" color="text.secondary" className="card-content">
                  {item.description}
               </Typography>
            </CardContent>
         </Box>
         <CardMedia
            sx={{ width: 128 }}
            component="img"
            height="140"
            image={item?.image ? item.image : DEFAULT_IMAGE}
            alt={item.name}
            onClick={() => {
               handlePackageView(item.id);
            }}
         />
         <div className={clsx('product-quick-add-fab')}>
            <SelectPackageBtn {...{ item, handlePackageSelection }} />
         </div>
      </Card>
   );
};

export default PackageCardHorizontal;
