import { Chip, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { RefObject, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CategoryProductMapping } from '../types/category-product.types';
import ProductCardVertical from './ProductCardVertical';
import ProductCardHorizontal from './ProductCardHorizontal';
import { productStore, setSelectedProduct, setQuickAddPopperOpen } from '../stores/product.slice';
import { IProduct } from '../types/product.types';
import { cartStore } from 'features/cart/stores/cart.slice';
import { getCartDeliveryIndexFromSelected } from '../config/order.helper';
import { orderStore } from '../stores/order.slice';
import { convertProductViewEventData, trackMptEvent } from '../utils/event.helper';
import { TrackEventType } from '../types/event.types';
import { setMenuMode, setOpenMenuItem } from 'features/menuItem/stores/menuItem.slice';
import { subscriptionStore } from '../../subscription/stores/subscriptions.slice';
import SkeletonLoader from 'components/SkeletonLoader';
import { orderLoadStore, setCardLoading } from '../stores/order-load.slice';
import { SKELETON_LOADING } from '../config/order.const';
import useProductVisibilityObserver from '../hooks/useProductVisibilityObserver';
import CategoryDesc from './CategoryDesc';

interface IProductListProps {
   dataToShow: CategoryProductMapping;
   searchTerm: string;
   selectedOptions: string[];
   setSelectedOptions: (selectedOptions: string[]) => void;
   categoryRefs: RefObject<{ [key: string]: RefObject<HTMLDivElement> }>;
   productView: string;
   addToCartText?: string;
   resultDisplayRef: RefObject<HTMLDivElement>;
}

const ProductList = ({
   dataToShow,
   searchTerm,
   selectedOptions,
   setSelectedOptions,
   productView,
   categoryRefs,
   addToCartText,
   resultDisplayRef,
}: IProductListProps) => {
   const dispatch = useAppDispatch();
   const theme = useTheme();
   const { cardLoading } = useAppSelector(orderLoadStore);
   const { productList, quickAddPopperOpen } = useAppSelector(productStore);
   const { cart } = useAppSelector(cartStore);
   const { selectedDeliverySlot } = useAppSelector(orderStore);
   const { subscriptionModActive } = useAppSelector(subscriptionStore);
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const handleProductSelection = (itemId: string) => {
      if (!quickAddPopperOpen) {
         const selectedProduct = productList.find((product) => product.id === itemId);

         if (selectedProduct) {
            trackMptEvent(TrackEventType.productView, convertProductViewEventData(selectedProduct));
            if (!subscriptionModActive) {
               const url = new URL(window.location.href);
               url.searchParams.set('viewProduct', selectedProduct.id);
               window.history.pushState({}, '', url);
            }
         }

         dispatch(setSelectedProduct(selectedProduct));
         dispatch(setMenuMode('main'));
         dispatch(setOpenMenuItem(true));
      } else {
         dispatch(setQuickAddPopperOpen(false));
      }
   };

   const findCountInCart = (product: IProduct) => {
      let count = 0;
      cart?.deliveries[getCartDeliveryIndexFromSelected(cart, selectedDeliverySlot)]?.products
         .filter((cartProduct) => {
            return cartProduct.productId === product.id;
         })
         ?.forEach((cartProduct) => {
            count += cartProduct.qty;
         });

      return count;
   };

   const getNumOfItemToShow = () => {
      let count = 0;
      Object.keys(dataToShow).forEach((category) => {
         count += dataToShow[category].length;
      });
      return count;
   };

   const onRemoveTagOption = (tagOption: string) => {
      setSelectedOptions([...selectedOptions].filter((tag) => tag !== tagOption));
   };

   useEffect(() => {
      const dataKeys = Object.keys(dataToShow);
      if (dataKeys.length > 0 && !dataKeys[0].startsWith(SKELETON_LOADING)) {
         dispatch(setCardLoading(false));
      }
   }, [dataToShow]);

   const productRefs = useProductVisibilityObserver({ productList, dataToShow, productView });

   return (
      <>
         {(searchTerm || selectedOptions.length > 0) && (
            <Grid
               ref={resultDisplayRef}
               item
               xs={12}
               position="fixed"
               width="100%"
               zIndex={11}
               mt={isMobileScreen ? 0 : -1.5}
               p={1}
               pl={2}
               pb={searchTerm || selectedOptions.length > 0 ? 2 : 0}
               sx={{ backgroundColor: theme.palette.background.default }}
            >
               <Stack spacing={1} alignItems="flex-start" direction="column">
                  <Stack spacing={1} direction="row">
                     <Typography>{`Displaying ${getNumOfItemToShow()} results for `}</Typography>
                     <Typography fontWeight="bold">{`${searchTerm ? `"${searchTerm}"` : ''}`}</Typography>
                  </Stack>
                  <Stack
                     direction="row"
                     flexWrap="wrap"
                     justifyContent="flex-start"
                     alignItems="flex-start"
                     sx={{
                        rowGap: 1,
                        columnGap: 1,
                     }}
                  >
                     {selectedOptions.map((tagOption) => (
                        <Chip
                           key={tagOption}
                           label={tagOption}
                           onDelete={() => onRemoveTagOption(tagOption)}
                           sx={{ cursor: 'pointer' }}
                        />
                     ))}
                  </Stack>
               </Stack>
            </Grid>
         )}
         {Object.entries(dataToShow).map(([categoryName, products], dataIndex: number) => {
            if (categoryRefs?.current && !(categoryName in categoryRefs.current)) {
               categoryRefs.current[categoryName] = React.createRef();
            }
            const resultDisplayHeight = resultDisplayRef?.current?.clientHeight
               ? resultDisplayRef.current.clientHeight
               : 0;
            const slug = categoryName.replace(/\s+/g, '-').toLowerCase();
            return (
               <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  pt={1}
                  ml={-1}
                  pl={1}
                  pr={1}
                  mt={dataIndex == 0 ? `${resultDisplayHeight}px` : 0}
                  key={categoryName}
                  id={categoryName}
                  ref={categoryRefs?.current ? categoryRefs.current[categoryName] : null}
               >
                  <SkeletonLoader
                     loading={cardLoading}
                     skeletonProps={{ width: '100%', height: '0px' }}
                     sx={{ marginLeft: '4px' }}
                  >
                     <Grid item xs={12} key={slug}>
                        <Typography className="category-header" variant="h4" color={theme.palette.primary.contrastText}>
                           {categoryName}
                        </Typography>
                        <CategoryDesc categoryName={categoryName} />
                     </Grid>
                  </SkeletonLoader>
                  <Grid sx={{ padding: '10px 10px 30px 10px' }} container display="flex" spacing={2}>
                     {products.map((item) => {
                        const countInCart = findCountInCart(item);
                        return isLargeScreen ? (
                           <Grid
                              item
                              xs={12}
                              sm={productView === 'card' ? 3 : 4}
                              md={productView === 'card' ? 3 : 4}
                              key={item.id}
                              ref={(el) => {
                                 productRefs.current[item.id] = el;
                              }}
                              data-product-id={item.id}
                           >
                              {productView === 'card' ? (
                                 <ProductCardVertical
                                    {...{ item, countInCart, handleProductSelection, addToCartText }}
                                 />
                              ) : (
                                 <ProductCardHorizontal {...{ item, countInCart, handleProductSelection }} />
                              )}
                           </Grid>
                        ) : (
                           <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={item.id}
                              ref={(el) => {
                                 productRefs.current[item.id] = el;
                              }}
                              data-product-id={item.id}
                           >
                              {productView === 'card' ? (
                                 <ProductCardVertical
                                    {...{ item, countInCart, handleProductSelection, addToCartText }}
                                 />
                              ) : (
                                 <ProductCardHorizontal {...{ item, countInCart, handleProductSelection }} />
                              )}
                           </Grid>
                        );
                     })}
                  </Grid>
               </Grid>
            );
         })}
      </>
   );
};

export default ProductList;
