import * as yup from 'yup';
import { phoneRegex } from 'features/account/config/account-form.const';

export const ADD_DELIVERY_DEFAULT_VALUES = {
   firstName: '',
   lastName: '',
   phone: '',
   address: '',
   address2: '',
   country: '',
   city: '',
   region: '',
   postal: '',
   instructions: '',
};

export const ADD_BILLING_DEFAULT_VALUES = {
   firstName: '',
   lastName: '',
   address: '',
   address2: '',
   country: '',
   city: '',
   region: '',
   postal: '',
};

export const PICK_UP_DEFAULT_VALUES = {
   firstName: '',
   lastName: '',
   phone: '',
};

export const PICK_UP_SCHEMA = yup.object({
   firstName: yup.string().required('First name is required'),
   lastName: yup.string().required('Last name is required'),
   phone: yup.string().matches(phoneRegex, 'Invalid phone number').required('Phone number is required'),
});

export const ADD_DELIVERY_SCHEMA = yup.object({
   firstName: yup.string().required('First name is required'),
   lastName: yup.string().required('Last name is required'),
   phone: yup.string().matches(phoneRegex, 'Invalid phone number').required('Phone number is required'),
   address: yup.string().required('Address is required'),
   address2: yup.string(),
   city: yup.string().required('City is required'),
   region: yup.string().required('Region is required'),
   postal: yup.string().required('Postal code is required'),
   instructions: yup.string(),
});

export const ADD_BILLING_SCHEMA = yup.object({
   firstName: yup.string().required('First name is required'),
   lastName: yup.string().required('Last name is required'),
   address: yup.string().required('Address is required'),
   address2: yup.string(),
   city: yup.string().required('City is required'),
   country: yup.string().required('Country is required'),
   region: yup.string().required('Region is required'),
   postal: yup.string().required('Postal code is required'),
});

export const MISSING_PICKUP_INFO_MSG = 'Pickup information is required.';
