import React, { useState } from 'react';
import {
   Avatar,
   Box,
   Chip,
   Divider,
   Grid,
   List,
   ListItem,
   ListItemAvatar,
   ListItemButton,
   ListItemText,
   FormGroup,
   FormControlLabel,
   Switch,
   Typography,
} from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import '../styles/CartItemList.scss';
import { ICartProduct, IChangeCartProductPayload, IPostAddRemoveCartProductPayload } from '../types/cart.types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
   cartStore,
   changeCartProductAsync,
   postAddRemoveCartProductAsync,
   setEditCartProduct,
} from '../stores/cart.slice';
import { parseVariationSelections } from 'features/order/utils/product-card.helper';
import { getProductByProductId } from 'features/order/utils/prduct-list.helper';
import { productStore, setSelectedProduct } from 'features/order/stores/product.slice';
import CircularProgress from '@mui/material/CircularProgress';
import { convertUpdateToCartEventData, trackMptEvent } from 'features/order/utils/event.helper';
import { TrackEventType } from 'features/order/types/event.types';
import { loadMPTSession } from 'utils/mpt-session.helper';
import { setMenuMode, setOpenMenuItem } from 'features/menuItem/stores/menuItem.slice';
import { LoadStatus } from 'config/utils';
import { StyledAddCircleIcon, StyledDeleteIcon, StyledRemoveCircleIcon } from './StyledCartIcons';
import { MptLink } from '../../../components/MptLink';
import { subscriptionStore } from '../../subscription/stores/subscriptions.slice';

interface ICartItemListProps {
   cartProducts: ICartProduct[] | null;
   viewOnlyMode?: boolean;
   checkoutMode?: boolean;
}

const NUM_VIEW_ITEMS = 3;

const CartItemList: React.FC<ICartItemListProps> = ({ cartProducts, viewOnlyMode, checkoutMode }) => {
   const dispatch = useAppDispatch();
   const { productList } = useAppSelector(productStore);
   const { postAddRemoveStatus, changeCartLoadStatus } = useAppSelector(cartStore);
   const { subscriptionModActive } = useAppSelector(subscriptionStore);
   const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
   const [viewMore, setViewMore] = useState(false);
   const loadedMptSession = loadMPTSession();

   const addItem = (
      cartProduct: ICartProduct,
      itemIndex: number,
      event: React.MouseEvent<SVGSVGElement, MouseEvent>,
   ) => {
      event.stopPropagation();
      const data: IPostAddRemoveCartProductPayload = {
         recordId: cartProduct.id,
         qty: cartProduct.qty + 1,
      };
      handleItemSelection(itemIndex);
      trackMptEvent(TrackEventType.addToCart, convertUpdateToCartEventData(cartProduct));
      dispatch(postAddRemoveCartProductAsync(data));
   };

   const subtractItem = (
      cartProduct: ICartProduct,
      itemIndex: number,
      event: React.MouseEvent<SVGSVGElement, MouseEvent>,
   ) => {
      event.stopPropagation();
      const data: IPostAddRemoveCartProductPayload = {
         recordId: cartProduct.id,
         qty: cartProduct.qty - 1,
      };
      handleItemSelection(itemIndex);
      trackMptEvent(TrackEventType.removeFromCart, convertUpdateToCartEventData(cartProduct));
      dispatch(postAddRemoveCartProductAsync(data));
   };

   const handleRecurrenceChange = (event: React.ChangeEvent<HTMLInputElement>, cartProduct: ICartProduct) => {
      const payload: IChangeCartProductPayload = {
         recordId: cartProduct.id,
         recurrence: event.target.checked ? 'weekly' : null,
      };
      dispatch(changeCartProductAsync(payload));
   };

   const handleItemSelection = (itemIndex: number) => {
      setSelectedItemIndex(itemIndex);
   };

   const handleItemEdit = (cartProduct: ICartProduct) => {
      dispatch(setSelectedProduct(getProductByProductId(cartProduct.productId, productList)));
      dispatch(setEditCartProduct(cartProduct));
      dispatch(setMenuMode('edit'));
      dispatch(setOpenMenuItem(true));
   };

   return (
      <List className="cart-item-list-container" dense={true}>
         {cartProducts?.map((cartProduct, pIdx) => {
            const selectedVariations = parseVariationSelections(cartProduct.selectedVariations);
            return viewMore || (!viewMore && pIdx < NUM_VIEW_ITEMS) || !checkoutMode ? (
               <div key={`cart-item-${pIdx}`}>
                  <ListItemButton
                     onClick={() => handleItemEdit(cartProduct)}
                     disabled={[postAddRemoveStatus, changeCartLoadStatus].includes(LoadStatus.loading)}
                  >
                     <div
                        style={{
                           position: 'absolute',
                           width: '100%',
                           textAlign: 'center',
                           zIndex: 10,
                        }}
                     >
                        {pIdx === selectedItemIndex &&
                        [postAddRemoveStatus, changeCartLoadStatus].includes(LoadStatus.loading) ? (
                           <CircularProgress size={24} thickness={8} color={'primary'} />
                        ) : null}
                     </div>
                     <ListItem className="cart-item" disablePadding>
                        <Grid container>
                           <Grid xs={3} item={true}>
                              <ListItemAvatar className="cart-item-image">
                                 <Avatar alt={cartProduct.name} src={cartProduct.image ? cartProduct.image : ''} />
                              </ListItemAvatar>
                           </Grid>
                           <Grid xs={6} item={true} pl={0.3}>
                              <ListItemText className="cart-item-name" primary={cartProduct.name} />
                              {selectedVariations.length
                                 ? selectedVariations.map((selectedVariation, index) => {
                                      return (
                                         <Box key={selectedVariation.name + index} className="cart-variation-row">
                                            <span className="cart-variation-label">{selectedVariation.name}:</span>{' '}
                                            <span className="cart-variation-value">
                                               {selectedVariation.options.join(', ')}
                                            </span>
                                         </Box>
                                      );
                                   })
                                 : null}
                              {loadedMptSession.companyInfo.uiSettings.subscribeAllOnlyMode || subscriptionModActive ? (
                                 cartProduct.recurrence === 'weekly' ? (
                                    <div style={{ display: 'flex' }}>
                                       <AutorenewIcon
                                          color="primary"
                                          className="count-control-ico"
                                          style={{ fontSize: '18px', marginRight: '2px' }}
                                       />{' '}
                                       <span className="product-recurrence">weekly</span>
                                    </div>
                                 ) : null
                              ) : getProductByProductId(cartProduct.productId, productList)?.weeklyPrice ? (
                                 <FormGroup>
                                    <FormControlLabel
                                       control={
                                          <Switch
                                             size={'small'}
                                             sx={{ marginLeft: '5px' }}
                                             checked={cartProduct.recurrence === 'weekly'}
                                             onClick={(event) => event.stopPropagation()}
                                             onChange={(event) => handleRecurrenceChange(event, cartProduct)}
                                          />
                                       }
                                       label={
                                          <span
                                             className={
                                                cartProduct.recurrence === 'weekly'
                                                   ? 'product-recurrence'
                                                   : 'product-recurrence deselected'
                                             }
                                          >
                                             Subscribe Weekly
                                          </span>
                                       }
                                    />
                                 </FormGroup>
                              ) : null}
                           </Grid>
                           <Grid xs={3} item={true} sx={{ display: 'grid', justifyContent: 'center' }}>
                              <Box display={'flex'} flexDirection={'column'}>
                                 <ListItemText
                                    sx={{ textAlign: 'center', flex: 'none' }}
                                    primary={`$${cartProduct.productTotal}`}
                                 />
                                 {!viewOnlyMode && (
                                    <Chip
                                       className="count-control-chip"
                                       icon={
                                          cartProduct.qty < 2 ? (
                                             <StyledDeleteIcon
                                                checkoutMode={checkoutMode}
                                                className="count-control-ico"
                                                color={checkoutMode ? 'inherit' : 'primary'}
                                                fontSize="small"
                                                onClick={(event) => subtractItem(cartProduct, pIdx, event)}
                                             />
                                          ) : (
                                             <StyledRemoveCircleIcon
                                                checkoutMode={checkoutMode}
                                                className="count-control-ico"
                                                color={checkoutMode ? 'inherit' : 'primary'}
                                                style={{ marginLeft: '2px' }}
                                                onClick={(event) => subtractItem(cartProduct, pIdx, event)}
                                             />
                                          )
                                       }
                                       label={cartProduct.qty}
                                       onDelete={(event) => addItem(cartProduct, pIdx, event)}
                                       deleteIcon={
                                          <StyledAddCircleIcon
                                             checkoutMode={checkoutMode}
                                             color={checkoutMode ? 'inherit' : 'primary'}
                                             className="count-control-ico"
                                             style={{ marginRight: '2px' }}
                                          />
                                       }
                                    />
                                 )}
                              </Box>
                           </Grid>
                        </Grid>
                     </ListItem>
                  </ListItemButton>
                  <Divider />
               </div>
            ) : null;
         })}
         {checkoutMode && cartProducts?.length && cartProducts.length > NUM_VIEW_ITEMS && (
            <Typography variant={'subtitle1'} fontWeight="bold" mb={1} mt={1}>
               <MptLink
                  sx={{ marginLeft: '5px', cursor: 'pointer', fontWeight: 600 }}
                  onClick={() => setViewMore(!viewMore)}
               >
                  {viewMore ? 'View less' : `View ${cartProducts.length - NUM_VIEW_ITEMS} more`}
               </MptLink>
            </Typography>
         )}
      </List>
   );
};

export default CartItemList;
