import { Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import '../styles/OrderDialog.scss';
import clsx from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IDeliveryTimeSlot } from '../../order/types/order.types';
import { getSelectedTimeslotDesc } from '../../order/config/order.helper';

interface IOrderDateCardProps {
   orderDay: string;
   orderDate: string;
   cardIndex: number;
   orderDateIndex: number;
   orderTimeslot: string;
   timeslotList: IDeliveryTimeSlot[];
   handleChangeDate: (cardIndex: number) => void;
   handleChangeTimeslot: (timeslot: string) => void;
}

const OrderDateCard = ({
   orderDay,
   orderDate,
   cardIndex,
   orderDateIndex,
   orderTimeslot,
   timeslotList,
   handleChangeDate,
   handleChangeTimeslot,
}: IOrderDateCardProps) => {
   const isSelected = cardIndex === orderDateIndex;

   const timeSlotListMapping = useMemo(() => {
      return [...timeslotList]
         .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
         .map(({ id, textOverride, start, end }, index: number) => {
            const slotValue = `${orderDay}|${orderDate}|${id}`;
            const textOverrideAry = textOverride ? textOverride.split(': ') : null;

            return (
               <FormControlLabel
                  key={slotValue}
                  value={slotValue}
                  control={<Radio />}
                  label={
                     textOverrideAry ? (
                        textOverrideAry.length > 1 ? (
                           <Box mt={index !== 0 ? 1 : 0}>
                              <div>{textOverrideAry[0]}:</div>
                              <div>{textOverrideAry[1]}</div>
                           </Box>
                        ) : (
                           <Box mt={index !== 0 ? 1 : 0}>{textOverrideAry[0]}</Box>
                        )
                     ) : (
                        `${start} - ${end}`
                     )
                  }
                  sx={{ textWrap: 'wrap' }}
               />
            );
         });
   }, [timeslotList, orderDay, orderDate]);

   return (
      <Box
         className={clsx('order-dialog-card', { selected: isSelected })}
         onClick={(event) => {
            if (
               timeslotList.length &&
               !['[object HTMLSpanElement]', '[object HTMLInputElement]'].includes(event.target.toString())
            ) {
               const defaultSlotValue = `${orderDay}|${orderDate}|${timeslotList[0].id}`;
               handleChangeTimeslot(defaultSlotValue);
            }

            handleChangeDate(cardIndex);
         }}
      >
         <Stack display="flex" spacing={1} alignItems="center" direction="column" width="100%">
            <Box display="flex" alignItems="center" margin="auto" gap={0.5}>
               <Typography className="order-day" variant="h6">
                  {orderDay}
               </Typography>
               ,
               <Typography className="order-date" variant="h6">
                  {orderDate}
               </Typography>
               {isSelected ? <CheckCircleIcon className="order-date" /> : null}
            </Box>
            <Divider className="order-date-divider" variant="middle" orientation="vertical" flexItem />
            <FormControl>
               <RadioGroup
                  aria-labelledby="order-timeslot-group-label"
                  name="order-timeslot-group"
                  value={orderTimeslot}
                  onChange={(event) => {
                     handleChangeTimeslot((event.target as HTMLInputElement).value);
                  }}
               >
                  {timeSlotListMapping}
               </RadioGroup>
            </FormControl>
         </Stack>
         <Box mt={'auto'} pt={'20px'} style={{ textWrap: 'wrap' }}>
            <Typography variant="body1" fontWeight="500">
               {getSelectedTimeslotDesc(timeslotList, orderTimeslot)}
            </Typography>
         </Box>
      </Box>
   );
};

export default OrderDateCard;
