import React from 'react';
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Alert,
   Fab,
   Stack,
   useTheme,
   useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormButton from './FormButton';

interface CommonDialogProps {
   open: boolean;
   title: string;
   children?: React.ReactNode;
   onSubmit: () => void;
   isLoading?: boolean;
   submitLabel: string;
   onClose?: () => void;
   onCancel?: (() => void) | null;
   alertMessage?: string;
   alertSeverity?: 'error' | 'warning' | 'info' | 'success';
}

const CommonDialog: React.FC<CommonDialogProps> = ({
   open,
   onClose,
   title,
   children,
   onSubmit,
   isLoading,
   submitLabel,
   alertMessage,
   alertSeverity = 'warning',
   onCancel,
}) => {
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   return (
      <Dialog
         open={open}
         onClose={() => {
            onClose && onClose();
         }}
         className="base-dialog"
         fullScreen={isMobileScreen}
         disableScrollLock
         PaperProps={{
            className: 'base-style',
         }}
      >
         <div className="base-dialog-header">
            <DialogTitle className="title">{title}</DialogTitle>
            {onClose && (
               <Fab size="small" color="primary" onClick={onClose}>
                  <CloseIcon />
               </Fab>
            )}
         </div>
         <DialogContent className="base-dialog-content">
            <div className="fields-container">
               {alertMessage && <Alert severity={alertSeverity}>{alertMessage}</Alert>}
               {children}
            </div>
         </DialogContent>
         <DialogActions className="base-dialog-footer">
            <Stack direction="row" spacing={3}>
               {onCancel && <FormButton variant="outlined" label="Cancel" onClick={onCancel} />}
               <FormButton label={submitLabel} onClick={onSubmit} isLoading={isLoading} />
            </Stack>
         </DialogActions>
      </Dialog>
   );
};

export default CommonDialog;
