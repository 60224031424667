import React from 'react';
import '../styles/NutritionFacts.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppSelector } from '../../../app/hooks';
import { productStore } from '../../order/stores/product.slice';

const NutritionFacts = () => {
   const { productDetail } = useAppSelector(productStore);
   const nutritionFacts =
      productDetail?.attributes &&
      productDetail.attributes.length > 0 &&
      productDetail.attributes[0] &&
      productDetail.attributes[0].data
         ? productDetail.attributes[0].data.nutritionLabel
         : null;
   return nutritionFacts ? (
      <div className={'nutrition-facts-container'}>
         <div className={'nutrition-facts-title'}>{nutritionFacts.title}</div>
         <TableContainer>
            <Table>
               <TableHead>
                  <TableRow sx={{ borderBottom: 'black 3px solid' }}>
                     {nutritionFacts.headers.map((header: string, index: number) => {
                        return <TableCell key={header + index}>{index === 0 ? '' : header}</TableCell>;
                     })}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {nutritionFacts.data.map((rows: any, index: number) => (
                     <TableRow key={rows[0].value + index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        {rows.map((row: any, rowIndex: number) => {
                           return (
                              <TableCell
                                 key={row.value + rowIndex}
                                 component="th"
                                 scope="row"
                                 sx={{ borderBottom: 'black 3px solid' }}
                              >
                                 {row.value}
                              </TableCell>
                           );
                        })}
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </div>
   ) : null;
};

export default NutritionFacts;
