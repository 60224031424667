import React, { useEffect, useState } from 'react';
import { Typography, Stack, Button, Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore, fetchUserCartAsync, resetFetchCart } from 'features/cart/stores/cart.slice';
import { LoadStatus } from 'config/utils';
import { useWatch } from 'react-hook-form';
import FormInput from 'components/FormInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { orderStore, resetUpdateRewardStatus, updateRewardAsync } from '../../../order/stores/order.slice';
import { checkoutStore } from '../../stores/checkout.slice';
import { StyledApplyButton } from '../customFields/ApplyButton';

const Reward = (props: { methods: any }) => {
   const theme = useTheme();
   const { cart, postAddRemoveStatus, changeCartLoadStatus, fetchCartStatus } = useAppSelector(cartStore);
   const { updateRewardStatus } = useAppSelector(orderStore);
   const { addOfferCodeStatus } = useAppSelector(checkoutStore);
   const dispatch = useAppDispatch();
   const rewardBalance: number | null = cart?.rewards?.account?.included?.balance?.points;
   const isUpdatingReward = updateRewardStatus === LoadStatus.loading;
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const isTabletUpScreen = useMediaQuery(theme.breakpoints.up('md'));
   const [rewardError, setRewardError] = useState('');
   const [showPointsApplied, setShowPointsApplied] = useState(false);

   const toBeApplied: number | null = props.methods.getValues('reward');
   const redeemed = cart?.totals?.find((total) => {
      return total.name.toLowerCase() === 'reward';
   })?.amount;

   const totalAmount = cart?.totals?.find((total) => total.name.toLowerCase() === 'total')?.amount ?? 0;
   const cartTotal = redeemed !== undefined && redeemed < 0 ? -redeemed + totalAmount : totalAmount;

   const { resetField, control } = props.methods;

   const rewardValue = useWatch({
      control,
      name: 'reward',
   });

   const adjustedRewardAmount = () => {
      const hasRewardPoints = !!cart?.rewards?.account?.included?.balance?.points;
      if (hasRewardPoints) {
         const myRewardDollar = +(cart?.rewards?.account?.included?.balance?.points / 100).toFixed(2);
         if (!redeemed) {
            //Has not applied any reward points
            if (myRewardDollar > cartTotal) {
               props.methods.setValue('reward', cartTotal.toFixed(2));
            } else {
               props.methods.setValue('reward', myRewardDollar);
            }
         } else if (redeemed < 0) {
            //Has applied reward points
            props.methods.setValue('reward', redeemed * -1);
            setShowPointsApplied(true);
         } else {
            props.methods.setValue('reward', myRewardDollar);
         }
      }
   };

   const onResetRewardError = () => {
      setRewardError('');
   };

   const onRewardSubmit = () => {
      if (cartTotal !== undefined) {
         if (Number(toBeApplied) > cartTotal) {
            setRewardError(
               'You have entered a points amount greater than the order total. Please enter a lower amount.',
            );
         } else if (toBeApplied && toBeApplied * 100 > Number(rewardBalance)) {
            setRewardError(
               'You have enter a reward amount greater than what is available. Please enter a lower amount.',
            );
         } else if (toBeApplied !== null && toBeApplied !== undefined) {
            dispatch(updateRewardAsync({ reward: toBeApplied }));
         }
      }
   };

   const handleRewardClear = () => {
      resetField('reward');
      onResetRewardError();
      setShowPointsApplied(false);
   };

   useEffect(() => {
      onResetRewardError();
      setShowPointsApplied(false);
   }, [rewardValue]);

   useEffect(() => {
      if (updateRewardStatus === LoadStatus.complete) {
         setShowPointsApplied(true);
         dispatch(resetUpdateRewardStatus());
      }
   }, [updateRewardStatus]);

   useEffect(() => {
      if (
         postAddRemoveStatus === LoadStatus.complete ||
         changeCartLoadStatus === LoadStatus.complete ||
         addOfferCodeStatus === LoadStatus.complete
      ) {
         dispatch(updateRewardAsync({ reward: 0 }));
         dispatch(fetchUserCartAsync());
      }
   }, [postAddRemoveStatus, changeCartLoadStatus, addOfferCodeStatus]);

   useEffect(() => {
      adjustedRewardAmount();
      if (fetchCartStatus === LoadStatus.complete) {
         dispatch(resetFetchCart());
      }
   }, [fetchCartStatus]);

   return (
      <>
         <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Stack direction={'row'} spacing={2} width={'100%'} sx={{ width: '100%' }} alignItems="center">
               <FormInput
                  name="reward"
                  label={
                     `Apply My Rewards` +
                     (rewardBalance ? ` $${rewardBalance / 100} (${rewardBalance} points) available` : '')
                  }
                  errorMessage={rewardError}
                  InputProps={{
                     inputProps: {
                        style: {
                           paddingLeft: '0',
                        },
                     },
                     startAdornment: (
                        <InputAdornment position="start">
                           <AttachMoneyIcon />
                        </InputAdornment>
                     ),
                     endAdornment: rewardValue ? (
                        <InputAdornment position="end">
                           <IconButton onClick={handleRewardClear} edge="end" aria-label="clear input">
                              <ClearIcon />
                           </IconButton>
                        </InputAdornment>
                     ) : null,
                  }}
               />
               {!isMobileScreen ? (
                  !showPointsApplied ? (
                     <Button
                        sx={{
                           maxHeight: '42.25px',
                           ...(isTabletUpScreen && { minWidth: '100px' }),
                        }}
                        variant="outlined"
                        onClick={props.methods.handleSubmit(onRewardSubmit)}
                        disabled={isUpdatingReward}
                     >
                        {isUpdatingReward ? <CircularProgress size={14} thickness={8} color="inherit" /> : 'Apply'}
                     </Button>
                  ) : (
                     <Box color={theme.palette.success.main} display={'flex'} whiteSpace={'nowrap'}>
                        <CheckCircleIcon /> <Typography ml={'0.2rem'}>Points applied</Typography>
                     </Box>
                  )
               ) : null}
            </Stack>
            <Box sx={{ width: '100%' }}>
               <Typography variant="subtitle1" color="textSecondary">
                  {`(${(Number(props.methods.getValues('reward')) * 100).toFixed(0)} points)`}
               </Typography>
            </Box>
         </Box>
         {isMobileScreen ? (
            !showPointsApplied ? (
               <StyledApplyButton
                  style={{ ...{ marginLeft: 'auto' } }}
                  sx={{ maxHeight: '42.25px', ...(isTabletUpScreen && { minWidth: '100px' }) }}
                  variant="outlined"
                  color="inherit"
                  onClick={props.methods.handleSubmit(onRewardSubmit)}
                  disabled={isUpdatingReward}
               >
                  {isUpdatingReward ? <CircularProgress size={14} thickness={8} color="inherit" /> : 'Apply'}
               </StyledApplyButton>
            ) : (
               <Box
                  style={{ ...{ marginLeft: 'auto' } }}
                  color={theme.palette.success.main}
                  display={'flex'}
                  whiteSpace={'nowrap'}
               >
                  <CheckCircleIcon /> <Typography ml={'0.2rem'}>Points applied</Typography>
               </Box>
            )
         ) : null}{' '}
      </>
   );
};

export default Reward;
