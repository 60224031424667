import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'features/account/styles/Account.scss';
import CommonDialog from 'components/CommonDialog';
import { IPickupFormModalValues } from '../../types/checkout.types';
import { MISSING_PICKUP_INFO_MSG, PICK_UP_DEFAULT_VALUES, PICK_UP_SCHEMA } from '../../config/checkout-form.const';
import { Alert, Box, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { checkoutStore, setPickupDialogOpen } from '../../stores/checkout.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore, resetChangeCartLoadStatus } from 'features/cart/stores/cart.slice';
import { OrderType } from 'features/order/config/order.const';
import Grid from '@mui/material/Grid';
import FormInput from 'components/FormInput';
import { changeDeliveryLocationAsync, orderStore } from 'features/order/stores/order.slice';
import { LoadStatus } from 'config/utils';

interface IPickUpDialogProps {
   missingDeliverInformation: boolean;
}

const PickUpDialog = ({ missingDeliverInformation }: IPickUpDialogProps) => {
   const dispatch = useAppDispatch();
   const { pickupDialogOpen } = useAppSelector(checkoutStore);
   const { cart, deliveryLocations } = useAppSelector(cartStore);
   const { changeLocationStatus } = useAppSelector(orderStore);

   const shippingLocationId = cart.deliveries.length ? cart.deliveries[0].shippingLocationId : null;
   const pickupLocations = deliveryLocations.filter((filterLocation) => filterLocation.type === OrderType.pickup);
   const foundPickupLocation = pickupLocations.find((findLocation) => findLocation.id === shippingLocationId);

   const [selectedLocationId, setSelectedLocationId] = useState(
      foundPickupLocation ? foundPickupLocation.id : pickupLocations[0].id,
   );

   const pickupDefaultValues = useMemo(() => {
      if (cart.deliveries.length) {
         const { shippingFirstName, shippingLastName, shippingPhone } = cart.deliveries[0];
         return {
            firstName: shippingFirstName || '',
            lastName: shippingLastName || '',
            phone: shippingPhone || '',
         };
      }
      return PICK_UP_DEFAULT_VALUES;
   }, [cart.deliveries]);

   const methods = useForm<IPickupFormModalValues>({
      defaultValues: pickupDefaultValues,
      resolver: yupResolver(PICK_UP_SCHEMA),
   });

   const handleClose = () => {
      methods.reset(pickupDefaultValues);
      dispatch(setPickupDialogOpen(false));
   };

   const onSubmit = (data: IPickupFormModalValues) => {
      if (selectedLocationId) {
         const { firstName, lastName, phone } = data;

         dispatch(
            changeDeliveryLocationAsync({
               shippingLocationId: selectedLocationId,
               updatePresets: true,
               shippingFirstName: firstName,
               shippingLastName: lastName,
               shippingPhone: phone,
            }),
         );
      }
   };

   const handlePickupLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedLocationId(event.target.value);
   };

   useEffect(() => {
      setSelectedLocationId(foundPickupLocation ? foundPickupLocation.id : pickupLocations[0].id);
   }, [foundPickupLocation]);

   useEffect(() => {
      if (changeLocationStatus === LoadStatus.complete) {
         handleClose();
         dispatch(resetChangeCartLoadStatus());
      }
   }, [changeLocationStatus]);

   return (
      <CommonDialog
         open={pickupDialogOpen}
         onClose={handleClose}
         title="Change Pickup Location"
         onSubmit={methods.handleSubmit(onSubmit)}
         isLoading={changeLocationStatus === LoadStatus.loading}
         submitLabel="Update"
      >
         <div className="field-container">
            <FormProvider {...methods}>
               <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="addresses-list">
                     <RadioGroup
                        aria-labelledby="pickup-list-group"
                        name="pickup-list-group"
                        value={selectedLocationId}
                        onChange={handlePickupLocationChange}
                     >
                        <Stack direction="column" gap={2}>
                           {pickupLocations.map((pickupLocation, pickupIndex) => {
                              const { address, city, region, postal } = pickupLocation.attributes;

                              return (
                                 <FormControlLabel
                                    key={`pickup-address-${pickupIndex}`}
                                    value={pickupLocation.id}
                                    control={<Radio />}
                                    label={
                                       <Box
                                          className={`address-label ${selectedLocationId === pickupLocation.id ? 'selected' : ''}`}
                                       >
                                          <span className="shipping-address-name">{pickupLocation.name}</span> <br />
                                          {address}, {city}, {region}, {postal}
                                       </Box>
                                    }
                                    sx={{ textWrap: 'wrap' }}
                                 />
                              );
                           })}
                        </Stack>
                     </RadioGroup>

                     <Box sx={{ p: 0, mt: 3, mb: 1 }}>
                        {missingDeliverInformation && (
                           <Box mb={2}>
                              <Alert key={`pickup-error`} severity="error">
                                 {MISSING_PICKUP_INFO_MSG}
                              </Alert>
                           </Box>
                        )}

                        <Grid container spacing={2}>
                           <Grid item xs={12} sm={6}>
                              <FormInput name="firstName" label="First Name" />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <FormInput name="lastName" label="Last Name" />
                           </Grid>
                           <Grid item xs={12}>
                              <FormInput name="phone" label="Phone" />
                           </Grid>
                        </Grid>
                     </Box>
                  </div>
               </form>
            </FormProvider>
         </div>
      </CommonDialog>
   );
};

export default PickUpDialog;
