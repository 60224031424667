import React, { useState } from 'react';
import 'features/account/styles/Account.scss';
import {
   Alert,
   Box,
   Chip,
   FormControlLabel,
   IconButton,
   ListItemText,
   Menu,
   MenuItem,
   Radio,
   RadioGroup,
   Stack,
   Tooltip,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { accountStore } from 'features/account/stores/account.slice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { IBillingProfile } from '../../types/checkout.types';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import {
   checkoutPaymentStore,
   setDefaultPaymentDialogOpen,
   setDeletePaymentDialogOpen,
   setPaymentDialogOpen,
   setPaymentToDefault,
   setPaymentToDelete,
} from '../../stores/checkout-payment.slice';

interface ISelectPaymentDialogContentProps {
   handleSwitchToEdit: (paymentId: string) => void;
   selectedPaymentId: string | null;
   setSelectedPaymentId: (paymentId: string) => void;
}

const SelectPaymentDialogContent: React.FC<ISelectPaymentDialogContentProps> = ({
   handleSwitchToEdit,
   selectedPaymentId,
   setSelectedPaymentId,
}) => {
   const dispatch = useAppDispatch();
   const { paymentError } = useAppSelector(checkoutPaymentStore);
   const { billingProfiles } = useAppSelector(accountStore);
   const [anchorEl, setAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});

   const handleOptionClick = (event: React.MouseEvent<HTMLButtonElement>, paymentId: string) => {
      setAnchorEl((prev) => ({ ...prev, [paymentId]: event.currentTarget }));
   };

   const handleOptionClose = (paymentId: string) => {
      setAnchorEl((prev) => ({ ...prev, [paymentId]: null }));
   };

   const onPaymentDeleteClicked = (payment: IBillingProfile) => {
      dispatch(setPaymentDialogOpen(false));
      dispatch(setDeletePaymentDialogOpen(true));
      dispatch(setPaymentToDelete(payment));
   };

   const onSetDefaultClicked = (payment: IBillingProfile) => {
      dispatch(setPaymentDialogOpen(false));
      dispatch(setDefaultPaymentDialogOpen(true));
      dispatch(setPaymentToDefault(payment));
   };

   const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedPaymentId(event.target.value);
   };

   return (
      <>
         <Box>
            {paymentError
               .filter((filterError) => filterError.id === selectedPaymentId)
               .map((checkoutError, errorIndex) => (
                  <Alert key={`delivery-error-${errorIndex}`} severity="error">
                     {checkoutError.errorMessage}
                  </Alert>
               ))}
            <Box mt={2} mb={3}>
               <div className="addresses-list">
                  <RadioGroup
                     aria-labelledby="delivery-list-group"
                     name="delivery-list-group"
                     value={selectedPaymentId}
                     onChange={handlePaymentChange}
                  >
                     <Stack direction="column" gap={2}>
                        {billingProfiles.map((payment, index) => (
                           <Box key={`payment-method-${index}`} display="flex">
                              <FormControlLabel
                                 value={payment.id}
                                 control={<Radio />}
                                 style={{ marginRight: '8px' }}
                                 label={
                                    <Box
                                       className={`address-label ${selectedPaymentId === payment.id ? 'selected' : ''}`}
                                    >
                                       <span className="shipping-address-name">
                                          {payment.firstName}, {payment.lastName}
                                       </span>
                                       <br />
                                       {payment.address}
                                       {payment.address2 ? (
                                          <>
                                             <br />
                                             {payment.address2}
                                          </>
                                       ) : null}
                                       <br />
                                       {payment.city}, {payment.region}, {payment.postal}
                                       <br />
                                       {`Card ending in ${payment.accountNum}`}
                                    </Box>
                                 }
                                 sx={{ textWrap: 'wrap' }}
                              />
                              <Box ml="auto">
                                 <Stack direction="column">
                                    {payment.default ? <Chip label={'Default'} sx={{ marginBottom: '5px' }} /> : null}
                                    {selectedPaymentId === payment.id ? (
                                       <Box ml="auto">
                                          <Tooltip title="More Options">
                                             <IconButton onClick={(event) => handleOptionClick(event, payment.id)}>
                                                <MoreVertIcon />
                                             </IconButton>
                                          </Tooltip>
                                       </Box>
                                    ) : null}
                                 </Stack>

                                 <Menu
                                    anchorEl={anchorEl[payment.id]}
                                    anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                       vertical: 'top',
                                       horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl[payment.id])}
                                    onClose={() => handleOptionClose(payment.id)}
                                 >
                                    <MenuItem onClick={() => handleSwitchToEdit(payment.id)}>
                                       <ListItemIcon>
                                          <EditIcon />
                                       </ListItemIcon>
                                       <ListItemText>Edit</ListItemText>
                                    </MenuItem>

                                    <MenuItem onClick={() => onPaymentDeleteClicked(payment)}>
                                       <ListItemIcon>
                                          <DeleteIcon />
                                       </ListItemIcon>
                                       <ListItemText>Delete</ListItemText>
                                    </MenuItem>
                                    {!payment.default ? (
                                       <MenuItem onClick={() => onSetDefaultClicked(payment)}>
                                          <ListItemIcon>
                                             <StarIcon />
                                          </ListItemIcon>
                                          <ListItemText>Set Default</ListItemText>
                                       </MenuItem>
                                    ) : null}
                                 </Menu>
                              </Box>
                           </Box>
                        ))}
                     </Stack>
                  </RadioGroup>
               </div>
            </Box>
         </Box>
      </>
   );
};

export default SelectPaymentDialogContent;
