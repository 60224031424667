import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoadStatus } from 'config/utils';

interface CheckoutErrorState {
   status: LoadStatus;
}

const initialState: CheckoutErrorState = {
   status: LoadStatus.idle,
};

const checkoutErrorSlice = createSlice({
   name: 'checkoutError',
   initialState,
   reducers: {},
   extraReducers: (builder) => {},
});

export const {} = checkoutErrorSlice.actions;

export const checkoutErrorStore = (state: RootState) => state.checkoutErrorStore;

export default checkoutErrorSlice.reducer;
