import { apiServerUrl } from 'config/server';
import { expandApiServerHeader } from 'utils/mpt-session.helper';
import { IPackagesResponse } from '../types/package.types';

const packagesUrl = `/products/packages`;

const prodUrls = {
   fetchPackages: `${apiServerUrl}${packagesUrl}`,
};

export function fetchPackages() {
   return new Promise<IPackagesResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'GET',
         headers: expandApiServerHeader(),
      };

      fetch(`${prodUrls.fetchPackages}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IPackagesResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
