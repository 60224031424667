import { apiServerUrl } from 'config/server';
import { expandApiServerHeader } from 'utils/mpt-session.helper';
import { IFeatureProductsResponse } from '../types/feature-item.types';

const featureProductsUrl = `/products/featured`;

const prodUrls = {
   featureProductsUrl: `${apiServerUrl}${featureProductsUrl}`,
};

export function fetchFeatureProducts() {
   return new Promise<IFeatureProductsResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'GET',
         headers: expandApiServerHeader(),
      };

      fetch(`${prodUrls.featureProductsUrl}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IFeatureProductsResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
