import React, { useState, ChangeEvent } from 'react';
import { Stack, CircularProgress, useMediaQuery, useTheme, FormGroup, FormControlLabel } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addCheckoutSettingsAsync, checkoutStore } from '../../stores/checkout.slice';
import { ICompanyAvailableSetting } from 'features/company/types/company.types';
import { companyStore } from 'features/company/stores/company.slice';
import Checkbox from '@mui/material/Checkbox';
import { StyledApplyButton } from './ApplyButton';

interface CheckboxProps {
   setting: ICompanyAvailableSetting;
}

const MultiSelect = (props: CheckboxProps) => {
   const theme = useTheme();
   const dispatch = useAppDispatch();
   const { companyInfo } = useAppSelector(companyStore);
   const { settings } = useAppSelector(checkoutStore);
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const settingsOptions = companyInfo.availableSettings
      ?.find((setting) => {
         return setting.id === props.setting.id;
      })
      ?.options?.map((option) => {
         return { label: option.text, value: option.id };
      });

   const defaultOption = settings
      ?.find((setting) => {
         return setting.id === props.setting.id;
      })
      ?.options?.map((option) => {
         return option.id;
      });

   const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultOption ?? []);
   const [isButtonLoading, setButtonLoading] = useState<boolean>(false);

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSelectedOptions((prevSelectedOptions) =>
         event.target.checked
            ? [...prevSelectedOptions, value]
            : prevSelectedOptions.filter((option) => option !== value),
      );
   };

   const handleSubmit = () => {
      setButtonLoading(true);
      dispatch(addCheckoutSettingsAsync({ settingId: props.setting.id, settingValues: selectedOptions }))
         .then(() => setButtonLoading(false))
         .catch(() => setButtonLoading(false));
   };

   return settingsOptions?.length && settingsOptions.length > 0 ? (
      <>
         <Stack direction={'column'} spacing={2} sx={{ width: '100%' }} alignItems="center">
            <FormGroup row>
               {settingsOptions.map((option) => (
                  <FormControlLabel
                     key={option.value}
                     control={
                        <Checkbox
                           value={option.value}
                           checked={selectedOptions.includes(option.value)}
                           onChange={handleChange}
                        />
                     }
                     label={option.label}
                  />
               ))}
            </FormGroup>
            <StyledApplyButton
               style={{ marginLeft: 'auto' }}
               sx={{ maxHeight: '42.25px', ...(!isMobileScreen && { minWidth: '100px' }) }}
               variant="outlined"
               color="inherit"
               onClick={handleSubmit}
               disabled={isButtonLoading}
            >
               {isButtonLoading ? <CircularProgress size={14} thickness={8} color="inherit" /> : 'Apply'}
            </StyledApplyButton>
         </Stack>
      </>
   ) : null;
};

export default MultiSelect;
