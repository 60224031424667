import React from 'react';
import { Box } from '@mui/material';
import '../styles/Account.scss';
import Grid from '@mui/material/Grid';
import FormInput from '../../../components/FormInput';

const PasswordResetFields = () => {
   return (
      <Box sx={{ p: 0 }}>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <FormInput name="email" label="Email" type="email" autoComplete="email" />
            </Grid>
         </Grid>
      </Box>
   );
};

export default PasswordResetFields;
