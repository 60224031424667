import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import 'features/account/styles/Account.scss';
import { IBillingProfile } from 'features/checkout/types/checkout.types';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { COUNTRIES, US_AND_CANADA_REGIONS } from 'config/regions.const';
import { useAppSelector } from 'app/hooks';
import { companyStore } from 'features/company/stores/company.slice';
import { accountStore } from 'features/account/stores/account.slice';
import CreditCardIcon from '@mui/icons-material/CreditCard';

interface IEditPaymentDialogContentProps {
   editPaymentId: string;
}

const EditPaymentDialogContent: React.FC<IEditPaymentDialogContentProps> = ({ editPaymentId }) => {
   const { companyInfo } = useAppSelector(companyStore);
   const { billingProfiles } = useAppSelector(accountStore);
   const [accountNum, setAccountNum] = useState('');
   const regionList = US_AND_CANADA_REGIONS.filter((region) => region.country === companyInfo.currency).map(
      (region) => ({ name: region.name, value: region.value }),
   );
   const countryList = COUNTRIES.map((country) => {
      return {
         name: country.name,
         value: country.value,
      };
   });

   const { setValue } = useFormContext();

   useEffect(() => {
      const profileToEdit = billingProfiles.find((billingProfile) => billingProfile.id === editPaymentId);
      if (profileToEdit) {
         setAccountNum(profileToEdit.accountNum);
         Object.keys(profileToEdit).forEach((key) => {
            const editValue =
               profileToEdit[key as keyof IBillingProfile] === null ? '' : profileToEdit[key as keyof IBillingProfile];
            setValue(key as keyof IBillingProfile, editValue);
         });
      }
   }, [editPaymentId, setValue, billingProfiles]);

   return (
      <Box sx={{ p: 0, mt: 1, mb: 1 }}>
         <Box display="flex" alignItems="center" mb={3} component="span">
            <CreditCardIcon fontSize="medium" />
            <Typography ml={1} fontSize="1.2rem">
               {`Card ending in  ${accountNum}`}
            </Typography>
         </Box>

         <Grid container spacing={2}>
            <Grid item xs={12}>
               <FormInput name="address" label="Address Line 1" autoComplete="billing address-line1" />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="address2" label="Address Line 2" autoComplete="billing address-line2" />
            </Grid>
            <Grid item xs={12}>
               <FormSelect name="country" label="Country" autoComplete="billing country" menuItems={countryList} />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="city" label="City" autoComplete="billing address-level2" />
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormSelect
                  name="region"
                  label="State/Province/Region"
                  autoComplete="billing address-level1"
                  menuItems={regionList}
               />
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormInput name="postal" label="Zip / Postal Code" autoComplete="billing postal-code" />
            </Grid>
         </Grid>
      </Box>
   );
};

export default EditPaymentDialogContent;
