import Subscriptions from 'features/subscription/Subscriptions';
import { Box } from '@mui/material';
import React from 'react';
import ProfileHeader from './ProfileHeader';

const SubscriptionsCart = () => {
   return (
      <>
         <ProfileHeader headerTitle="Your Subscriptions" />
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' }}>
            <Subscriptions />
         </Box>
      </>
   );
};

export default SubscriptionsCart;
