import React, { useEffect } from 'react';
import { Grid, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { featureItemStore, fetchFeatureProductsAsync } from './stores/feature-item.slice';
import { LoadStatus } from 'config/utils';
import { setCardLoading } from 'features/order/stores/order-load.slice';
import FeatureProductCardHorizontal from './components/FeatureProductCardHorizontal';
import FeatureProductCardVertical from './components/FeatureProductCardVertical';
import { loadMptTheme } from 'utils/theme.helper';
import { loadMPTSession } from 'utils/mpt-session.helper';
import 'features/order/styles/ProductList.scss';

export default function FeatureItemsPage() {
   const dispatch = useAppDispatch();
   const theme = useTheme();
   const loadedMptSession = loadMPTSession();
   const { featureProducts, fetchFeatureProductsStatus } = useAppSelector(featureItemStore);
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

   const handleProductSelection = (itemId: string) => {
      window.location.href = `/order?viewProduct=${itemId}`;
   };

   const mptTheme = React.useMemo(() => {
      return loadMptTheme(loadedMptSession);
   }, [loadedMptSession]);

   useEffect(() => {
      if (fetchFeatureProductsStatus === LoadStatus.complete) {
         dispatch(setCardLoading(false));
      }
   }, [fetchFeatureProductsStatus]);

   useEffect(() => {
      dispatch(fetchFeatureProductsAsync({}));
   }, []);

   return (
      <ThemeProvider theme={mptTheme}>
         <Grid
            container
            display="contents"
            sx={{ backgroundColor: theme.palette.background.default }}
            className="menu-container"
         >
            <Grid className="product-card-container" container item xs={12} spacing={2} pt={1} ml={-1} pl={1} pr={1}>
               <Grid
                  sx={{ padding: '10px 10px 30px 10px' }}
                  container
                  display="flex"
                  spacing={2}
                  justifyContent="center"
               >
                  {featureProducts.map((item) => {
                     return isLargeScreen ? (
                        <Grid
                           item
                           xs={12}
                           sm={!isMobileScreen ? 3 : 4}
                           md={!isMobileScreen ? 3 : 4}
                           key={item.id}
                           data-product-id={item.id}
                        >
                           {!isMobileScreen ? (
                              <FeatureProductCardVertical {...{ item, handleProductSelection }} />
                           ) : (
                              <FeatureProductCardHorizontal {...{ item, handleProductSelection }} />
                           )}
                        </Grid>
                     ) : (
                        <Grid item xs={12} sm={6} md={4} key={item.id} data-product-id={item.id}>
                           {!isMobileScreen ? (
                              <FeatureProductCardVertical {...{ item, handleProductSelection }} />
                           ) : (
                              <FeatureProductCardHorizontal {...{ item, handleProductSelection }} />
                           )}
                        </Grid>
                     );
                  })}
               </Grid>
            </Grid>
         </Grid>
      </ThemeProvider>
   );
}
