import React, { useState, useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Box, Button, DialogActions, DialogContent, Stack, styled, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import '../styles/MenuItem.scss';
import { IMenuItemFormValues } from '../types/menu-item-form.types';
import { formatter } from '../utils/menu-item.const';
import MenuOption from '../components/MenuOption';
import Tag from '../components/Tag';
import { productStore } from 'features/order/stores/product.slice';
import { cartStore, postCartProductAsync } from 'features/cart/stores/cart.slice';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import Grid from '@mui/material/Grid';
import ProductGallery from '../components/ProductGallery';
import ProductAccordion from '../components/ProductAccordion';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadStatus } from 'config/utils';
import { menuItemStore } from '../stores/menuItem.slice';
import { IPostCartProductPayload } from 'features/cart/types/cart.types';
import { orderStore } from 'features/order/stores/order.slice';
import { TrackGTagType } from 'features/order/types/event.types';
import { companyStore } from '../../company/stores/company.slice';
import { isWeeklyOrder } from 'features/order/config/order.helper';
import { packageStore } from 'features/package/stores/package.slice';

export const StyledDescriptionContainer = styled('div')(({ theme }) => ({
   fontFamily: theme.typography.fontFamily,
   '& h1, & h2, & h3, & h4, & h5, & h6, & h7': {
      fontFamily: theme.typography.fontFamily,
   },
}));

const MainMenu = () => {
   const [itemQty, setItemQty] = useState(1);
   const [orderTotal, setOrderTotal] = useState(0);
   const [errorVariationIndex, setErrorVariationIndex] = useState(-1);
   const [errorFields, setErrorFields] = useState<Number[]>([]);
   const [selectionsPrice, setSelectionPrice] = useState(0);
   const dispatch = useAppDispatch();
   const { productDetail } = useAppSelector(productStore);
   const { packageRecurrence } = useAppSelector(packageStore);
   const methods = useFormContext<IMenuItemFormValues>();
   const { postCartStatus, orderFrequency } = useAppSelector(cartStore);
   const { menuMode, openMenuItem } = useAppSelector(menuItemStore);
   const { selectedDeliverySlot } = useAppSelector(orderStore);
   const { companyInfo } = useAppSelector(companyStore);
   const productImages = productDetail?.images && productDetail.images.length ? productDetail.images : null;
   const hasCustomProductCopy = companyInfo.uiSettings?.customProductCopy
      ? !!companyInfo.uiSettings?.customProductCopy.find((customProductId) => customProductId === productDetail?.id)
      : false;

   const handleItemQuantityChange = (change: number) => {
      setItemQty(change);
      methods.setValue('qty', change, { shouldTouch: true, shouldDirty: true });
   };

   const adjustOrderTotal = () => {
      let total: number = Number(methods.getValues('base_price'));
      let qty: number = methods.getValues('qty');
      if (methods.getValues('variations')) {
         methods.getValues('variations').forEach((elem) => {
            total += Number(elem.price);
         });
      }
      setSelectionPrice(total);
      total = total * qty;
      setOrderTotal(total);
      methods.setValue('total_price', total);
   };

   const registerFormFields = () => {
      methods.register('id');
      methods.register('base_price');
      methods.register('total_price');
   };

   const inputValuesToFields = () => {
      if (productDetail) {
         methods.setValue('id', productDetail.id);
         methods.setValue('base_price', productDetail.price ? productDetail.price : 0);
         methods.setValue('total_price', productDetail.price ? productDetail.price : 0);
      }
   };

   const onSubmit: SubmitHandler<IMenuItemFormValues> = () => {
      const getVariationData = () => {
         if (methods.getValues(`variations`).length > 0) {
            return methods.getValues(`variations`).map((variation: any) => {
               return { id: variation.variation.id, options: variation.option_id };
            });
         } else if (productDetail && productDetail.variations.length > 0) {
            return [
               {
                  id: productDetail.variations[0].id,
                  options: [],
               },
            ];
         }
         return [];
      };

      const submitData: IPostCartProductPayload = {
         ...{
            deliveryId: selectedDeliverySlot.deliveryId,
            productId: productDetail ? productDetail.id : '',
            qty: itemQty,
            variations: getVariationData(),
            addons: productDetail && productDetail.addons ? productDetail.addons : [],
            preferences: [],
            ...(isWeeklyOrder(packageRecurrence, productDetail, orderFrequency) && { recurrence: 'weekly' }),
         },
      };

      dispatch(
         postCartProductAsync({
            productPayLoad: submitData,
            product: productDetail,
            gtagEvent: TrackGTagType.addToCart1,
         }),
      );
   };

   const onError: SubmitErrorHandler<IMenuItemFormValues> = (errors) => {
      if (
         errors.variations &&
         errors.variations.length &&
         errors.variations.findIndex !== undefined &&
         errors.variations.length > 0
      ) {
         const errorVariationNum = errors.variations.findIndex((element) => element !== undefined);
         const errorNode = document.querySelector(`#group-option-${errorVariationNum}`);

         if (errorNode) {
            errorNode.scrollIntoView({
               behavior: 'smooth',
               block: 'center',
               inline: 'start',
            });
         }
         setErrorVariationIndex(errorVariationNum);
      }
   };

   useEffect(() => {
      if (productDetail) {
         registerFormFields();
         inputValuesToFields();
         setOrderTotal(productDetail.price ? productDetail.price : 0);
      }
   }, [productDetail]);

   useEffect(() => {
      adjustOrderTotal();
   }, [itemQty, productDetail, menuMode]);

   useEffect(() => {
      if (!openMenuItem) {
         setItemQty(1);
         setOrderTotal(0);
         setErrorVariationIndex(-1);
      }
   }, [openMenuItem]);

   return typeof productDetail !== 'undefined' ? (
      <>
         <DialogContent className="menu-item-dialog-content">
            <Grid container>
               <Grid item xs={12} md={6} className={'menu-item-grid'}>
                  {productImages ? <ProductGallery images={productDetail.images} /> : null}
                  <ProductAccordion />
               </Grid>
               <Grid item xs={12} md={6} className={'menu-item-grid'}>
                  <Tag product={productDetail} />
                  {!hasCustomProductCopy && (
                     <StyledDescriptionContainer className="item-description">
                        {productDetail.description && (
                           <Markdown remarkPlugins={[remarkGfm]}>
                              {productDetail.description.replace(/\t/g, '   ')}
                           </Markdown>
                        )}
                     </StyledDescriptionContainer>
                  )}
                  <Box className="product-price">
                     <Typography variant="h6">{formatter.format(selectionsPrice)}</Typography>
                  </Box>
                  {productDetail?.variations ? (
                     <div className="menu-option">
                        <MenuOption
                           {...{
                              errorFields,
                              setErrorFields,
                              adjustOrderTotal,
                              errorVariationIndex,
                              setErrorVariationIndex,
                           }}
                        />
                     </div>
                  ) : null}
               </Grid>
            </Grid>
            {hasCustomProductCopy && (
               <Grid container>
                  <Grid item xs={12} className={'menu-item-grid'}>
                     <StyledDescriptionContainer className="item-description" sx={{ textAlign: 'center' }}>
                        {productDetail.description && (
                           <Markdown remarkPlugins={[remarkGfm]}>
                              {productDetail.description.replace(/\t/g, '   ')}
                           </Markdown>
                        )}
                     </StyledDescriptionContainer>
                  </Grid>
               </Grid>
            )}
         </DialogContent>
         <DialogActions className="menu-item-dialog-footer">
            <Stack direction="row" spacing={2}>
               <Box className="item-count-nav">
                  <IconButton
                     onClick={() => handleItemQuantityChange(itemQty - 1)}
                     className={'item-add-remove-icon'}
                     disabled={itemQty <= 1}
                  >
                     <RemoveIcon className={'add-remove-icon'} />
                  </IconButton>
                  <div className="item-count-frame">
                     <div className="item-count" {...methods.register('qty')}>
                        {itemQty}
                     </div>
                  </div>
                  <IconButton onClick={() => handleItemQuantityChange(itemQty + 1)} className={'item-add-remove-icon'}>
                     <AddIcon className={'add-remove-icon'} />
                  </IconButton>
               </Box>
               <Box>
                  <form id="menu-popup" onSubmit={methods.handleSubmit(onSubmit, onError)}>
                     <Button
                        variant="contained"
                        type="submit"
                        disabled={errorFields.length > 0 || postCartStatus === LoadStatus.loading}
                     >
                        {postCartStatus === LoadStatus.loading ? (
                           <CircularProgress size={14} thickness={8} color="inherit" />
                        ) : (
                           <Stack direction="row" spacing={1}>
                              <Box>{`Add to Cart`}</Box>
                              <Box>{formatter.format(orderTotal)}</Box>
                           </Stack>
                        )}
                     </Button>
                  </form>
               </Box>
            </Stack>
         </DialogActions>
      </>
   ) : null;
};

export default MainMenu;
