import React, { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IMenuItemFormValues } from '../types/menu-item-form.types';
import { IProductVariation, IProductVariationOption } from '../../order/types/product.types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { productStore } from 'features/order/stores/product.slice';
import { cartStore } from '../../cart/stores/cart.slice';
import { Common } from '../../../config/utils';
import { getVariationFormField, getVariationType } from '../utils/menu-item.helper';
import { Box, Button, useTheme } from '@mui/material';
import { setMenuMode, setSelectorField } from '../stores/menuItem.slice';
import { companyStore } from '../../company/stores/company.slice';
import '../styles/MenuItem.scss';
import { MptLink } from 'components/MptLink';

interface IMenuOptionProps {
   errorFields: Number[];
   setErrorFields: (errorFields: Number[]) => void;
   adjustOrderTotal: () => void;
   errorVariationIndex: number;
   setErrorVariationIndex: (errorVariationIndex: number) => void;
}

const MenuOption: React.FC<IMenuOptionProps> = ({
   setErrorFields,
   adjustOrderTotal,
   errorVariationIndex,
   setErrorVariationIndex,
   errorFields,
}: IMenuOptionProps) => {
   const { setValue, getValues, control } = useFormContext<IMenuItemFormValues>();
   const dispatch = useAppDispatch();
   const { productDetail } = useAppSelector(productStore);
   const { companyInfo } = useAppSelector(companyStore);
   const { fields, insert, remove } = useFieldArray({ name: 'variations' });
   const [showMoreFields, setShowMoreFields] = useState<string[]>([]);
   const { editCartProduct } = useAppSelector(cartStore);
   const theme = useTheme();

   const removeChildVariations = (fieldIndex: number) => {
      const parentVarId = (getValues(`variations.${fieldIndex}.variation`) as IProductVariation).id;
      for (let i = fields.length - 1; i >= 0; i--) {
         if ((getValues(`variations.${i}.variation`) as IProductVariation)?.parentVariation === parentVarId) {
            removeChildVariations(i);
            remove(i);
         }
      }
   };

   const getVariationNumFromSelectedOption = (fieldIndex: number) => {
      const variationNum = (fields[fieldIndex] as any).variation?.options?.find((option: IProductVariationOption) => {
         return option.id === (fields[fieldIndex] as any).option_id[0];
      })?.variations?.length;
      return variationNum ?? 0;
   };

   const updateSelectionProps = (fieldIndex: number, variationOption: any, type: string, event?: any) => {
      let variationPrice: number = getValues(`variations.${fieldIndex}.price`);

      if (type === 'drop-down') {
         removeChildVariations(fieldIndex);
         setValue(`variations.${fieldIndex}.option_id`, [variationOption.id]);
         variationPrice = variationOption.price;
         insertVariations(variationOption.variations, fieldIndex);
      } else if (type === 'multiselect') {
         const currOptionList = getValues(`variations.${fieldIndex}.option_id`);
         if (event.target.checked) {
            currOptionList.push(variationOption.id);
            variationPrice += parseFloat(variationOption.price);
         } else {
            const index = currOptionList.indexOf(variationOption.id);
            if (index > -1) currOptionList.splice(index, 1);
            variationPrice -= parseFloat(variationOption.price);
         }

         setValue(`variations.${fieldIndex}.option_id`, currOptionList);
      }
      setValue(`variations.${fieldIndex}.price`, variationPrice);
      fieldIndex === errorVariationIndex && setErrorVariationIndex(-1);
      adjustOrderTotal();
   };

   const isMealSelectorProduct = () => {
      return productDetail?.id && companyInfo?.uiSettings?.mealSelectorProducts?.includes(productDetail?.id);
   };

   const isMealSelectorField = (fieldIndex: number) => {
      if (!productDetail?.id || !isMealSelectorProduct() || !getValues(`variations.${fieldIndex}.variation`)) {
         return false;
      }
      const parentVarId = (getValues(`variations.${fieldIndex}.variation`) as IProductVariation).id;
      return fields.find((field, i: number) => {
         return (getValues(`variations.${i}.variation`) as IProductVariation)?.parentVariation === parentVarId;
      });
   };

   const isChildOfMealSelector = (fieldIndex: number) => {
      const parentVarId = (getValues(`variations.${fieldIndex}.variation`) as IProductVariation).parentVariation;
      if (!parentVarId) return false;
      return showMoreFields.includes(parentVarId);
   };

   const getDefaultDropdownOption = (variation: IProductVariation) => {
      if (!variation || !variation.options) return;
      const foundDefaultOption = variation.options.find((option: any) => {
         return option.default;
      });
      return foundDefaultOption ? foundDefaultOption : variation.options[0];
   };

   const getEditCartSelectedDropdownOption = (variation: IProductVariation) => {
      if (!editCartProduct) return null;
      const foundOption = editCartProduct.selectedVariations.find(
         (selectedVariation) => selectedVariation.id === variation.id,
      )?.options[0];
      return foundOption
         ? {
              ...foundOption,
              variations: variation.options.find((option) => option.id === foundOption.id)?.variations,
           }
         : null;
   };

   const getDefaultCheckboxOption = (variation: IProductVariation) => {
      const currOptionList: string[] = [];
      let price = 0;
      variation.options.forEach((option) => {
         if (option.default) {
            currOptionList.push(option.id);
            price = price + option.price;
         }
      });
      return {
         option_id: currOptionList,
         price: price,
      };
   };

   const getEditCartSelectedCheckboxOptions = (variation: IProductVariation) => {
      if (!editCartProduct) return;
      const currOptionList: string[] = [];
      let price = 0;
      editCartProduct.selectedVariations
         .find((selectedVariation) => {
            return selectedVariation.id === variation.id;
         })
         ?.options.forEach((option) => {
            currOptionList.push(option.id);
            price = price + option.price;
         });
      return {
         option_id: currOptionList,
         price: price,
      };
   };

   const getVariationFormLabel = (variation: IProductVariation) => {
      if (!variation) return;
      const fieldName = variation ? Common.renderHtml(variation.name) : null;
      const minRequired = variation.minRequired;
      const maxAllowed = variation.maxAllowed;

      let qtyRequired = '';
      if (minRequired && minRequired === maxAllowed) {
         qtyRequired = ` (Pick ${minRequired})`;
      } else if (minRequired || maxAllowed) {
         qtyRequired = ` (${minRequired ? `Min: ${minRequired}` : ''}${minRequired && maxAllowed ? ', ' : ''}${maxAllowed ? `Max: ${maxAllowed}` : ''})`;
      }
      return (
         <>
            {fieldName}
            {qtyRequired}
         </>
      );
   };

   const insertVariations = (variations: IProductVariation[], fieldIndex: number) => {
      if (variations?.length > 0) {
         for (let i = variations.length - 1; i >= 0; i--) {
            if (getVariationType(variations[i]) === 'drop-down') {
               const defaultOption =
                  getEditCartSelectedDropdownOption(variations[i]) ?? getDefaultDropdownOption(variations[i]);
               if (defaultOption?.variations?.length && defaultOption.variations.length > 0) {
                  insertVariations(defaultOption.variations, fieldIndex);
               }
               insert(fieldIndex + 1, {
                  variation: variations[i],
                  option_id: [defaultOption?.id],
                  price: defaultOption?.price,
               });
            } else if (getVariationType(variations[i]) === 'checkbox') {
               const defaultOption =
                  getEditCartSelectedCheckboxOptions(variations[i]) ?? getDefaultCheckboxOption(variations[i]);
               insert(fieldIndex + 1, {
                  variation: variations[i],
                  option_id: defaultOption.option_id,
                  price: defaultOption.price,
               });
            }
         }
      }
   };

   const handleOptionSelector = (fieldIndex: number) => {
      const currVariationOptions = (getValues(`variations.${fieldIndex}.variation`) as IProductVariation).options;
      const variationOptions = currVariationOptions?.[0].variations
         ? currVariationOptions?.[0].variations?.[0].options
         : currVariationOptions?.[1].variations?.[0].options ?? [];
      dispatch(setMenuMode('selector'));
      dispatch(
         setSelectorField({
            variationOptions: variationOptions,
            variationMax: getVariationNumFromSelectedOption(fieldIndex),
            fieldIndex: fieldIndex,
         }),
      );
   };

   const handleShowMore = (id: string) => {
      const newShowMore = [...showMoreFields];
      if (showMoreFields.includes(id)) {
         newShowMore.splice(newShowMore.indexOf(id), 1);
      } else {
         newShowMore.push(id);
      }
      setShowMoreFields(newShowMore);
   };

   useEffect(() => {
      if (productDetail && !getValues(`variations`).length) {
         insertVariations(productDetail.variations, -1);
      }
      adjustOrderTotal();
   }, [productDetail]);

   return fields && fields.length > 0 ? (
      <div>
         {fields.map((field, index) => {
            return (
               <div
                  key={field.id}
                  style={
                     (getValues(`variations.${index}.variation`) as IProductVariation)?.parentVariation
                        ? {
                             position: 'relative',
                             left: '30px',
                             display:
                                isMealSelectorProduct() && !isMealSelectorField(index) && !isChildOfMealSelector(index)
                                   ? 'none'
                                   : '',
                          }
                        : {}
                  }
               >
                  <FormLabel
                     id={`group-option-${index}`}
                     className="option-title"
                     style={{ color: errorFields.includes(index) ? theme.palette.error.main : 'inherit' }}
                  >
                     {getVariationFormLabel(getValues(`variations.${index}.variation`) as IProductVariation)}
                  </FormLabel>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                     {getVariationFormField(
                        getValues(`variations.${index}.variation`) as IProductVariation,
                        index,
                        control,
                        updateSelectionProps,
                        errorFields,
                        setErrorFields,
                     )}
                     {isMealSelectorField(index) ? (
                        <Button
                           variant="contained"
                           onClick={() => handleOptionSelector(index)}
                           aria-label="Option Selector"
                           sx={{ margin: '0 15px 0 15px' }}
                        >
                           Select or View
                        </Button>
                     ) : null}
                  </Box>
                  {isMealSelectorField(index) ? (
                     <MptLink
                        fontWeight={500}
                        fontSize={'medium'}
                        width={'100px'}
                        aria-label="Option Selector"
                        sx={{ cursor: 'pointer', paddingLeft: '32px' }}
                        onClick={() =>
                           handleShowMore((getValues(`variations.${index}.variation`) as IProductVariation).id)
                        }
                     >
                        {showMoreFields.includes((getValues(`variations.${index}.variation`) as IProductVariation).id)
                           ? 'Hide Selections'
                           : 'Show Selections'}
                     </MptLink>
                  ) : null}
               </div>
            );
         })}
      </div>
   ) : null;
};

export default MenuOption;
