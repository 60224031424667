import React, { useMemo, useState } from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { DEFAULT_IMAGE } from 'config/restaurant.const';
import { IProduct } from '../types/product.types';
import AddToCartBtn from './AddToCartBtn';
import { checkQuickVariation, getDefaultItemPrice } from '../utils/product-card.helper';
import { formatter } from 'features/menuItem/utils/menu-item.const';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import SkeletonLoader from 'components/SkeletonLoader';
import { useAppSelector } from 'app/hooks';
import { orderLoadStore } from '../stores/order-load.slice';
import { CardDescriptionContainer } from './CardDescriptionContainer';
import { companyStore } from '../../company/stores/company.slice';

interface IProductCardHorizontalProps {
   item: IProduct;
   countInCart: number;
   handleProductSelection: (productId: string) => void;
}

const ProductCardHorizontal: React.FC<IProductCardHorizontalProps> = ({
   item,
   countInCart,
   handleProductSelection,
}) => {
   const [numVariations, setNumVariations] = useState(2);
   const cardRef = React.useRef<HTMLDivElement>(null);
   const { cardLoading } = useAppSelector(orderLoadStore);
   const { companyInfo } = useAppSelector(companyStore);

   useMemo(() => {
      setNumVariations(checkQuickVariation(item, companyInfo));
   }, [item]);

   return (
      <Card
         ref={cardRef}
         className="product-card"
         sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
         style={{ borderRadius: '1rem', cursor: 'pointer' }}
      >
         <SkeletonLoader
            loading={cardLoading}
            skeletonProps={{ width: '100%', height: cardRef.current ? cardRef.current.offsetHeight : '128px' }}
         >
            <Box display="flex" flexDirection="column" margin="auto">
               <CardContent
                  sx={{ flex: '1 0 auto', padding: '10px', ':last-child': { paddingBottom: '0px' } }}
                  onClick={() => {
                     handleProductSelection(item.id);
                  }}
               >
                  <Typography
                     sx={{ fontWeight: 600 }}
                     gutterBottom
                     variant="subtitle1"
                     component="div"
                     className="card-content"
                  >
                     {item.name}
                  </Typography>
                  <CardDescriptionContainer className="card-content">
                     {(item.specifications || item.description) && (
                        <Markdown remarkPlugins={[remarkGfm]}>
                           {item.specifications?.length
                              ? item.specifications.replace(/\t/g, '   ')
                              : item.description.replace(/\t/g, '   ')}
                        </Markdown>
                     )}
                  </CardDescriptionContainer>
                  <Typography sx={{ fontWeight: 600, marginTop: '5px', textAlign: 'center' }} variant="body1">
                     {getDefaultItemPrice(item) ? formatter.format(getDefaultItemPrice(item) as number) : null}{' '}
                  </Typography>
               </CardContent>
            </Box>
            <CardMedia
               sx={{ minWidth: 136, maxWidth: 136, minHeight: 136, maxHeight: 136 }}
               component="img"
               height="140"
               image={item?.images && item.images.length ? `${item.images[0][3].url}` : DEFAULT_IMAGE}
               alt={item.name}
               onClick={() => {
                  handleProductSelection(item.id);
               }}
            />
            <AddToCartBtn {...{ item, numVariations, handleProductSelection, itemsInCart: countInCart }} />
         </SkeletonLoader>
      </Card>
   );
};

export default ProductCardHorizontal;
