import React, { RefObject } from 'react';
import { Typography, Grid, CardContent, Stack, Alert } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import CartItemsAccordion from 'features/cart/components/CartItemsAccordion';
import { useNavigate } from 'react-router-dom';
import SkeletonLoader from 'components/SkeletonLoader';
import { SectionCardHeader, SectionCardSlim, SectionCardSlimContent } from '../SectionCard';
import { getCartItemCount } from 'features/cart/utils/cart.helper';
import { MptLink } from 'components/MptLink';
import { checkoutLoadStore } from '../../stores/checkout-load.slice';
import { checkoutStore } from '../../stores/checkout.slice';
import { IError } from 'components/types/base.types';

interface IOrderSummaryProps {
   orderSummaryCardRef: RefObject<HTMLDivElement>;
}

const OrderSummary = ({ orderSummaryCardRef }: IOrderSummaryProps) => {
   const navigate = useNavigate();
   const { cardLoading } = useAppSelector(checkoutLoadStore);
   const { cart } = useAppSelector(cartStore);
   const { cartRuleError } = useAppSelector(checkoutStore);

   return (
      <SectionCardSlim ref={orderSummaryCardRef} hasError={cartRuleError.length > 0 && !cardLoading}>
         <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
            <SectionCardHeader
               title={
                  <Grid container alignItems="center">
                     <Grid item xs={8} sm={8}>
                        <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1} lineHeight="1.25">
                           {`Order Summary (${getCartItemCount(cart)} Items)`}
                        </Typography>
                     </Grid>
                     <Grid item xs={4} sm={4} textAlign="right">
                        <Typography variant={'subtitle1'} fontWeight="bold" mb={1} mt={1} lineHeight="1.25">
                           <MptLink
                              sx={{ marginLeft: '5px', cursor: 'pointer', fontWeight: 600 }}
                              onClick={() => navigate('/order')}
                           >
                              Continue shopping{' '}
                           </MptLink>
                        </Typography>
                     </Grid>
                  </Grid>
               }
            ></SectionCardHeader>
         </SkeletonLoader>
         <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '120px' }}>
            <SectionCardSlimContent style={{ paddingBottom: '8px' }}>
               {cartRuleError.map((checkoutError: IError) => (
                  <Alert sx={{ marginBottom: 2 }} severity="error">
                     {checkoutError.errorMessage}
                  </Alert>
               ))}
               <Stack direction="column" gap={1} width="100%">
                  <CardContent
                     sx={{
                        textAlign: 'center',
                        padding: '0px 0px',
                     }}
                  >
                     <CartItemsAccordion cartDeliveries={cart.deliveries} checkoutMode={true} />
                  </CardContent>
               </Stack>
            </SectionCardSlimContent>
         </SkeletonLoader>
      </SectionCardSlim>
   );
};

export default OrderSummary;
