import { apiServerUrl } from 'config/server';
import { expandApiServerHeader, prepareTokenPayload } from 'utils/mpt-session.helper';
import { ITrackEventData, TrackEventType } from '../types/event.types';

const eventTrackUrl = `/events/track`;

const prodUrls = {
   postEventTrack: `${apiServerUrl}${eventTrackUrl}`,
};

export const getAllCookies = (): Record<string, string> => {
   return document.cookie.split(';').reduce((cookies: Record<string, string>, cookie) => {
      const [name, val] = cookie.split('=').map((c) => c.trim());
      if (name && val) {
         cookies[name] = decodeURIComponent(val);
      }
      return cookies;
   }, {});
};

const locationParamsToJSON = (location: typeof window.location) => {
   const searchString = location.search.substring(1);

   if (searchString) {
      return searchString.split('&').reduce((acc: Record<string, string>, param) => {
         try {
            const [key, value] = param.split('=');
            acc[key] = decodeURIComponent(value);
         } catch (e) {
            console.error({ location, error: e });
         }
         return acc;
      }, {});
   }

   return null;
};

export function postEventTrack(eventType: TrackEventType, data: ITrackEventData) {
   return new Promise<boolean>((resolve, reject) => {
      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               eventType,
               data,
               href: window.location.href,
               cookies: getAllCookies(),
               params: locationParamsToJSON(window.location),
            }),
         ),
      };

      fetch(`${prodUrls.postEventTrack}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((_resultData: boolean) => {
            resolve(true);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
