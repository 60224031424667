import React, { useEffect, useState } from 'react';
import { Box, Button, DialogContent, Fab, Stack, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import OrderDateCard from './OrderDateCard';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import IconButton from '@mui/material/IconButton';
import '../styles/OrderDialog.scss';
import '../../../styles/style-vars.module.scss';
import OrderDialogContent from './OrderDialogContent';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { changeDeliveryAsync, orderStore, postDeliveryDateAsync } from 'features/order/stores/order.slice';
import { getShortDateStr, getShortDayStr, getTimeslotObjFromTimeslotStr } from 'features/order/config/order.helper';
import { IDeliveryDateSelection, IDeliveryOption, IDeliveryTimeSlot } from 'features/order/types/order.types';
import { cartStore } from 'features/cart/stores/cart.slice';
import { ICart } from 'features/cart/types/cart.types';
import dayjs from 'dayjs';
import OrderMethod from './OrderMethod';
import OrderFrequency from './OrderFrequency';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { orderBarStore, setOrderDialogOpen } from 'features/order/stores/order-bar.slice';
import { packageStore } from 'features/package/stores/package.slice';
import { LoadStatus } from '../../../config/utils';
import CircularProgress from '@mui/material/CircularProgress';

const OrderDialog = () => {
   const theme = useTheme();
   const dispatch = useAppDispatch();
   const { deliveryOptions, selectedDeliverySlot, postDeliveryDateStatus, changeDeliveryDateStatus } =
      useAppSelector(orderStore);
   const { orderDialogOpen, orderDialogOption } = useAppSelector(orderBarStore);
   const { packageId } = useAppSelector(packageStore);
   const { cart } = useAppSelector(cartStore);
   const isMediumScreenUp = useMediaQuery(theme.breakpoints.up('md'));
   const isExtraSmallScreenDown = useMediaQuery(theme.breakpoints.down('sm'));
   const [orderTimeslot, setOrderTimeslot] = useState('');
   const [remainingDeliveryOptions, setRemainingDeliveryOptions] = useState(deliveryOptions);
   const [orderDateIndex, setOrderDateIndex] = useState(remainingDeliveryOptions.length - 1);
   const [postDateObj, setPostDateObj] = useState<IDeliveryDateSelection | null>(null);
   const isSelecting = postDeliveryDateStatus === LoadStatus.loading || changeDeliveryDateStatus === LoadStatus.loading;
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const isTimeslotInCartDeliveries = (timeslot: IDeliveryTimeSlot, cart: ICart) => {
      return !!cart.deliveries.find((delivery) => {
         return (
            getShortDateStr(delivery.deliveryDayName) === getShortDateStr(timeslot.dateTime) &&
            delivery.timeSlotId === timeslot.id
         );
      });
   };

   const handleChangeDate = (newDateValue: number) => {
      setOrderDateIndex(newDateValue);
   };

   const handleChangeTimeslot = (timeslot: string, deliveryOption?: IDeliveryOption) => {
      setOrderTimeslot(timeslot);
      if (deliveryOption) {
         const timeslotObj = getTimeslotObjFromTimeslotStr(timeslot, deliveryOption.timeSlots);

         if (orderTimeslot === timeslot) return;

         setPostDateObj({
            dateTime: timeslotObj?.dateTime ?? '',
            deliveryDayId: deliveryOption.id,
            timeSlotId: timeslotObj?.id ?? '',
            timeSlot: getTimeslotObjFromTimeslotStr(timeslot, deliveryOption.timeSlots),
            ...((orderDialogOption === 'change' || orderDialogOption === 'change-package') && {
               id: selectedDeliverySlot.deliveryId,
            }),
            ...(packageId && { packageId: packageId }),
         });
      }
   };

   const handleCloseDialog = () => {
      setOrderTimeslot('');
      setOrderDateIndex(0);
      dispatch(setOrderDialogOpen(false));
   };

   const onSelect = () => {
      if (postDateObj) {
         if (postDateObj.id) {
            dispatch(changeDeliveryAsync({ ...postDateObj, packageId }));
         } else {
            dispatch(
               postDeliveryDateAsync({
                  ...postDateObj,
                  packageId,
               }),
            );
         }
      }
   };

   useEffect(() => {
      const filteredDeliveryOptions = deliveryOptions
         .map((deliveryOption) => {
            return {
               ...deliveryOption,
               timeSlots: deliveryOption.timeSlots.filter((timeslot) => {
                  return orderDialogOption === 'add'
                     ? !isTimeslotInCartDeliveries(timeslot, cart)
                     : !isTimeslotInCartDeliveries(timeslot, cart) ||
                          (getShortDateStr(selectedDeliverySlot.deliveryDayName) ===
                             getShortDateStr(timeslot.dateTime) &&
                             selectedDeliverySlot.timeSlotId === timeslot.id);
               }),
            };
         })
         .filter((deliveryOption) => {
            return deliveryOption.timeSlots.length > 0;
         });

      setRemainingDeliveryOptions(filteredDeliveryOptions);

      const highlightedTimeSlot =
         orderDialogOption === 'add'
            ? {
                 deliveryId: '',
                 dayId: '',
                 timeSlotId: filteredDeliveryOptions[0].timeSlots[0].id,
                 deliveryDayName:
                    filteredDeliveryOptions[0].name + ' ' + filteredDeliveryOptions[0].timeSlots[0].dateTime,
                 packageId: null,
                 start: '',
                 end: '',
              }
            : selectedDeliverySlot;
      const timeSlotStr = `${getShortDayStr(highlightedTimeSlot.deliveryDayName.substring(0, highlightedTimeSlot.deliveryDayName.indexOf(' ')))}|${getShortDateStr(highlightedTimeSlot.deliveryDayName)}|${highlightedTimeSlot.timeSlotId}`;
      setOrderTimeslot(timeSlotStr);
      const selectedOrderDateIndex = filteredDeliveryOptions.findIndex((option) => {
         return (
            dayjs(option.date).month() === dayjs(selectedDeliverySlot.deliveryDayName?.replace(',', '')).month() &&
            dayjs(option.date).date() === dayjs(selectedDeliverySlot.deliveryDayName?.replace(',', '')).date()
         );
      });
      setOrderDateIndex(selectedOrderDateIndex === -1 || orderDialogOption === 'add' ? 0 : selectedOrderDateIndex);
      handleChangeTimeslot(
         timeSlotStr,
         filteredDeliveryOptions[orderDialogOption === 'add' ? 0 : selectedOrderDateIndex],
      );
   }, [orderDialogOpen]);

   return (
      <Dialog
         className="order-dialog"
         fullWidth={true}
         maxWidth="lg"
         onClose={handleCloseDialog}
         open={orderDialogOpen}
         PaperProps={{ className: 'order-dialog-paper' }}
         aria-labelledby="order-dialog-title"
         aria-describedby="order-dialog-description"
         fullScreen={isMobileScreen}
         disableScrollLock
      >
         <DialogTitle>
            <Box display="flex">
               <Box ml="auto">
                  <Fab size="small" color="primary" onClick={handleCloseDialog}>
                     <CloseIcon />
                  </Fab>
               </Box>
            </Box>
            <Box>
               <Typography color="primary" align="center" variant={'h4'}>
                  When and how?
               </Typography>
            </Box>
            {isExtraSmallScreenDown ? null : (
               <Typography
                  sx={{ margin: '10px auto', width: isMediumScreenUp ? '60%' : '90%' }}
                  align="center"
                  paragraph={true}
               >
                  We only use the <b>freshest ingredients</b>, so menu options vary by date. Choosing a delivery day and
                  time will display the most accurate menu.
               </Typography>
            )}
         </DialogTitle>
         {isMediumScreenUp ? (
            <DialogContent>
               <Tabs
                  className="order-dialog-tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={orderDateIndex}
                  ScrollButtonComponent={(props) => {
                     if (props.direction === 'left' && !props.disabled) {
                        return (
                           <IconButton sx={{ borderRadius: '0px' }} onClick={props.onClick}>
                              <ArrowCircleLeftIcon color="primary" fontSize="large" />
                           </IconButton>
                        );
                     } else if (props.direction === 'right' && !props.disabled) {
                        return (
                           <IconButton sx={{ borderRadius: '0px' }} onClick={props.onClick}>
                              <ArrowCircleRightIcon color="primary" fontSize="large" />
                           </IconButton>
                        );
                     } else {
                        return null;
                     }
                  }}
               >
                  {remainingDeliveryOptions.map((deliveryOption, optionIndex) => {
                     return (
                        <OrderDateCard
                           key={`order-card-${optionIndex}`}
                           orderDay={getShortDayStr(deliveryOption.name)}
                           orderDate={getShortDateStr(deliveryOption.date)}
                           cardIndex={optionIndex}
                           orderDateIndex={orderDateIndex}
                           timeslotList={deliveryOption.timeSlots}
                           orderTimeslot={orderTimeslot}
                           handleChangeDate={handleChangeDate}
                           handleChangeTimeslot={(event) => handleChangeTimeslot(event, deliveryOption)}
                        />
                     );
                  })}
               </Tabs>
               <Stack direction="row" justifyContent="center" alignItems="center" mt={5} mb={3}>
                  <Stack ml="auto" direction="row" justifyContent="center" alignItems="center" spacing={4}>
                     <OrderMethod />
                     <OrderFrequency />
                  </Stack>
                  <Box ml="auto">
                     <Button
                        startIcon={
                           isSelecting ? <CircularProgress size={20} thickness={8} color={'inherit'} /> : <CheckIcon />
                        }
                        variant="contained"
                        onClick={onSelect}
                        disabled={isSelecting}
                     >
                        Select
                     </Button>
                  </Box>
               </Stack>
            </DialogContent>
         ) : (
            <OrderDialogContent
               deliveryOptions={remainingDeliveryOptions}
               orderTimeslot={orderTimeslot}
               handleChangeTimeslot={handleChangeTimeslot}
               onSelect={onSelect}
            />
         )}
      </Dialog>
   );
};

export default OrderDialog;
