import { createTheme } from '@mui/material';

interface IMptTheme {
   companyPrimary: string;
   companyPrimaryFontColor: string;
   companyFontFamily: string;
   companySecondary: string;
   companyPrimaryIcon: string;
   companyPrimaryTypography: string;
   companyBackground: string;
   orderBar: string;
}

export const createMptTheme = ({
   companyPrimary,
   companyPrimaryFontColor,
   companyFontFamily,
   companySecondary,
   companyPrimaryTypography,
   companyBackground,
   orderBar,
}: IMptTheme) => {
   const baseTheme = createTheme({
      typography: {
         fontFamily: companyFontFamily,
      },
      palette: {
         mode: 'light',
         primary: {
            main: companyPrimary,
            contrastText: companyPrimaryTypography,
         },
         secondary: {
            main: companySecondary,
         },
         background: {
            default: companyBackground,
            paper: companyBackground,
         },
      },
      components: {
         MuiButton: {
            styleOverrides: {
               root: {
                  textTransform: 'capitalize',
                  color: companyPrimaryFontColor,
                  borderRadius: '100px',
                  minWidth: '150px',
                  minHeight: '42.25px',
               },
               outlined: {
                  color: companyPrimary,
               },
               contained: {
                  '& .MuiSvgIcon-root': {
                     color: companyPrimaryFontColor,
                  },
               },
            },
         },
         MuiFab: {
            styleOverrides: {
               root: {
                  color: companyPrimaryFontColor,
                  '& .MuiSvgIcon-root': {
                     color: companyPrimaryFontColor,
                  },
                  '& .MuiSvgIcon-colorAction': {
                     color: 'rgba(0, 0, 0, 0.54)',
                  },
               },
            },
         },
         MuiLink: {
            styleOverrides: {
               root: {
                  cursor: 'pointer',
                  textDecorationColor: `${companyPrimary} !important`,
                  color: companyPrimaryTypography,
               },
            },
         },
         MuiPopover: {
            styleOverrides: {
               paper: {
                  backgroundColor: '#ffffff',
               },
            },
         },
         MuiSelect: {
            styleOverrides: {},
         },
         MuiTab: {
            styleOverrides: {
               textColorPrimary: {
                  '&.Mui-selected': {
                     color: companyPrimaryTypography,
                  },
               },
            },
         },
         MuiTabs: {
            styleOverrides: {
               indicator: {
                  width: '3px',
               },
            },
         },
         MuiTextField: {
            styleOverrides: {
               root: {
                  '& input': {
                     fontSize: '1rem',
                     color: '#000000',
                     border: 'none',
                     padding: '16.5px 14px',
                  },
               },
            },
         },
         MuiAutocomplete: {
            styleOverrides: {
               root: {
                  '& .MuiFilledInput-root': {
                     fontSize: '1rem',
                     color: '#000000',
                     border: 'none',
                     padding: '0 14px',
                  },
               },
            },
         },
         MuiToggleButton: {
            styleOverrides: {
               root: {
                  textTransform: 'capitalize',
               },
            },
         },
         MuiToggleButtonGroup: {
            styleOverrides: {
               root: {
                  '& .MuiToggleButton-root': {
                     '&.Mui-selected': {
                        border: '1px solid',
                        borderColor: companyPrimary,
                     },
                  },
               },
            },
         },
         MuiTypography: {
            styleOverrides: {
               root: {
                  fontFamily: companyFontFamily,
               },
            },
         },
         MuiPagination: {
            styleOverrides: {
               root: {
                  width: '100%',
                  margin: '50px 0',
                  '& .MuiPagination-ul': {
                     justifyContent: 'center',
                  },
               },
            },
         },
      },
   });

   return {
      ...baseTheme,
      typography: {
         ...baseTheme.typography,
         h6: {
            ...baseTheme.typography.h6,
            [baseTheme.breakpoints.down('sm')]: {
               fontSize: '1rem',
            },
         },
      },
      custom: {
         orderBar: {
            color: orderBar,
         },
         screen: {
            maxWidth: '1840px',
         },
      },
   };
};

export const drawerWidth = 420;
