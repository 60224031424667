import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import { CardContent, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore } from '../stores/cart.slice';
import '../styles/CartOrderSummary/CartOrderSummary.scss';
import { subscriptionStore } from 'features/subscription/stores/subscriptions.slice';
import CartItemsAccordion from './CartItemsAccordion';
import { loadMPTSession } from 'utils/mpt-session.helper';
import { setOrderDialogOpen, setOrderDialogOption } from 'features/order/stores/order-bar.slice';
import { MptLink } from 'components/MptLink';

const CartOrderSummary = () => {
   const loadedMptSession = loadMPTSession();
   const dispatch = useAppDispatch();
   const { subscriptionModActive } = useAppSelector(subscriptionStore);
   const { cart } = useAppSelector(cartStore);

   const singleDelivery = !!loadedMptSession?.companyInfo?.uiSettings?.singleDelivery;
   const [cartItemsHeight, setCartItemsHeight] = useState('');
   const summaryRef = useRef(null);

   const getTotals = () => {
      if (cart?.totals) {
         return (
            <div style={{ width: '100%' }}>
               {cart.totals.map((total, index) => {
                  return (
                     <div key={total.name + total.amount + index} className="checkout-totals">
                        <Typography>{total.name + ': '}</Typography>
                        <Typography>
                           {' '}
                           {total.amount.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                           })}
                        </Typography>
                     </div>
                  );
               })}
            </div>
         );
      }
      return null;
   };

   const handleAddAnotherDay = () => {
      dispatch(setOrderDialogOption('add'));
      dispatch(setOrderDialogOpen(true));
   };

   useEffect(() => {
      if (!summaryRef.current) return;

      const resizeObserver = new ResizeObserver((entries) => {
         for (let entry of entries) {
            setCartItemsHeight(`calc(100vh - 230px - ${entry.contentRect.height}px)`);
         }
      });

      resizeObserver.observe(summaryRef.current);

      return () => resizeObserver.disconnect();
   }, []);

   return (
      <>
         <Card className="order-summary-card" variant="outlined" sx={{ border: 'none' }}>
            {!singleDelivery && !subscriptionModActive ? (
               <Grid item xs={12}>
                  <div className="bar-box">
                     <Typography variant="body1">
                        <MptLink
                           sx={{ marginLeft: '10px', cursor: 'pointer', fontWeight: 600 }}
                           onClick={handleAddAnotherDay}
                        >
                           Add Another Day
                        </MptLink>
                     </Typography>
                  </div>
               </Grid>
            ) : null}
            <CardContent
               className="cart-content"
               sx={{
                  textAlign: 'center',
                  overflow: 'auto',
                  padding: '16px 0px',
                  maxHeight: cartItemsHeight,
               }}
            >
               {/*TODO need to replace subscriptionModResponse with actual response from API*/}
               <CartItemsAccordion cartDeliveries={cart?.deliveries} />
            </CardContent>
            <CardContent ref={summaryRef} className="checkout-summary">
               <div className="checkout-total">{getTotals()}</div>
            </CardContent>
         </Card>
      </>
   );
};

export default CartOrderSummary;
