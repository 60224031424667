import React from 'react';
import { useAppDispatch } from 'app/hooks';
import { useForm, FormProvider } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, Fab, TextField, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface IRequestChangeFormValues {
   first_name: string;
   last_name: string;
   email: string;
   phone: string;
}

const REQUEST_CHANGE_DEFAULT_VALUES = {
   first_name: '',
   last_name: '',
   email: '',
   phone: '',
};

const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

const REQUEST_CHANGE_SCHEMA = yup.object({
   first_name: yup.string().required('First name is required'),
   last_name: yup.string().required('Last name is required'),
   email: yup.string().email('Email format is not valid').required('Email is required'),
   phone: yup.string().matches(phoneRegex, 'Invalid phone number').required('Phone number is required'),
});

interface IRequestChangeProps {
   requestChangeOpen: boolean;
   setRequestChangeOpen: (open: boolean) => void;
}

const RequestChangeDialog: React.FC<IRequestChangeProps> = ({ requestChangeOpen, setRequestChangeOpen }) => {
   const dispatch = useAppDispatch();
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const methods = useForm<IRequestChangeFormValues>({
      defaultValues: REQUEST_CHANGE_DEFAULT_VALUES,
      resolver: yupResolver(REQUEST_CHANGE_SCHEMA),
   });

   const { errors } = methods.formState;

   const handleClose = () => {
      methods.reset();
      setRequestChangeOpen(false);
   };

   const onSubmit = (data: any) => {
      //TODO will need to add code for submit
      // dispatch(
      //    submitLoginAsync({
      //       bodyInput: {
      //          login_pass: data.password,
      //          login_user: data.email,
      //          login: 'login',
      //       },
      //    }),
      // );
   };

   const onError = (errors: any) => {};

   return (
      <Dialog
         className="base-dialog"
         fullScreen={isMobileScreen}
         disableScrollLock
         onClose={handleClose}
         open={requestChangeOpen}
         PaperProps={{
            className: 'base-style account-log-in',
         }}
      >
         <div className="base-dialog-header">
            <DialogTitle className={'title'}>Request Change</DialogTitle>
            <Fab size="small" color="primary" onClick={handleClose}>
               <CloseIcon />
            </Fab>
         </div>
         <DialogContent className="base-dialog-content">
            <div className="fields-container">
               <FormProvider {...methods}>
                  <form id="account-popup" onSubmit={methods.handleSubmit(onSubmit, onError)}>
                     <p className={'field-error'}>{errors.first_name?.message?.toString()}</p>
                     <TextField
                        className="fields sign-up"
                        label="First Name"
                        fullWidth
                        {...methods.register('first_name')}
                     />
                     <p className={'field-error'}>{errors.last_name?.message?.toString()}</p>
                     <TextField
                        className="fields sign-up"
                        label="Last Name"
                        fullWidth
                        {...methods.register('last_name')}
                     />
                     <p className={'field-error'}>{errors.email?.message?.toString()}</p>
                     <TextField
                        className="fields sign-up"
                        label="Email"
                        type="email"
                        fullWidth
                        {...methods.register('email')}
                     />
                     <p className={'field-error'}>{errors.phone?.message?.toString()}</p>
                     <TextField className="fields sign-up" label="Phone" fullWidth {...methods.register('phone')} />
                  </form>
               </FormProvider>
            </div>
         </DialogContent>
         <DialogActions className="base-dialog-footer">
            <Button type="submit" className="submit-button" form="account-popup">
               <div className="submit-content">Request Change</div>
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default RequestChangeDialog;
