import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import companyReducer from 'features/company/stores/company.slice';
import checkoutReducer from 'features/checkout/stores/checkout.slice';
import checkoutShippingReducer from 'features/checkout/stores/checkout-shipping.slice';
import checkoutPaymentReducer from 'features/checkout/stores/checkout-payment.slice';
import checkoutErrorReducer from 'features/checkout/stores/checkout-error.slice';
import checkoutLoadReducer from 'features/checkout/stores/checkout-load.slice';
import finixReducer from 'features/checkout/stores/finix.slice';
import cartReducer from 'features/cart/stores/cart.slice';
import packageReducer from 'features/package/stores/package.slice';
import productReducer from 'features/order/stores/product.slice';
import orderReducer from 'features/order/stores/order.slice';
import orderBarReducer from 'features/order/stores/order-bar.slice';
import orderLoadReducer from 'features/order/stores/order-load.slice';
import accountReducer from 'features/account/stores/account.slice';
import subscriptionReducer from 'features/subscription/stores/subscriptions.slice';
import menuItemReducer from 'features/menuItem/stores/menuItem.slice';
import profileReducer from 'features/profile/stores/profile.slice';
import featureItemReducer from 'standalone/feature-item/stores/feature-item.slice';

export const store = configureStore({
   reducer: {
      companyStore: companyReducer,
      checkoutStore: checkoutReducer,
      checkoutShippingStore: checkoutShippingReducer,
      checkoutPaymentStore: checkoutPaymentReducer,
      checkoutErrorStore: checkoutErrorReducer,
      finixStore: finixReducer,
      cartStore: cartReducer,
      packageStore: packageReducer,
      productStore: productReducer,
      orderStore: orderReducer,
      orderBarStore: orderBarReducer,
      orderLoadStore: orderLoadReducer,
      checkoutLoadStore: checkoutLoadReducer,
      accountStore: accountReducer,
      subscriptionStore: subscriptionReducer,
      profileStore: profileReducer,
      menuItemStore: menuItemReducer,
      featureItemStore: featureItemReducer,
   },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
