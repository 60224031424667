import * as yup from 'yup';
import { phoneRegex } from '../../account/config/account-form.const';

export const PERSONAL_INFO_DEFAULT_VALUES = {
   firstName: '',
   lastName: '',
   email: '',
   phone: '',
};

export const PERSONAL_INFO_SCHEMA = yup.object({
   firstName: yup.string().required('First name is required'),
   lastName: yup.string().required('Last name is required'),
   email: yup.string().email('Email format is not valid').required('Email is required'),
   phone: yup.string().matches(phoneRegex, 'Invalid phone number').required('Phone number is required'),
});
