import { COMPANY_STATE_COMPANY_INFO_DEFAULT } from '../../company/stores/default.const';

export const CART_DEFAULT = {
   success: false,
   cart: {
      id: null,
      isSubscription: false,
      status: null,
      creationDate: '0001-01-01T00:00:00',
      lastUpdate: '0001-01-01T00:00:00',
      clientCartId: null,
      isValid: false,
      billingProfileId: null,
      leadSource: null,
      leadDetail: null,
      isEmpty: true,
      hasLeasesOrLoans: false,
      needsApproval: false,
      newReferralCode: null,
      newReferralCodeMessage: null,
      deliveries: [],
      virtualProducts: [],
      appliedOffers: [],
      rewards: {},
      agreements: [],
      preselectedFilters: {},
      totals: [],
      init: true,
   },
   messages: [],
   deliveryLocations: [],
   companyInfo: COMPANY_STATE_COMPANY_INFO_DEFAULT,
   user: {
      email: '',
      displayName: '',
      firstName: '',
      lastName: '',
      phone: '',
      isGuestAcct: true,
      status: 'deleted',
   },
};

export const SUBSCRIBE_ALL_DEFAULT = {
   success: false,
   cart: {
      id: null,
      isSubscription: false,
      status: null,
      creationDate: '0001-01-01T00:00:00',
      lastUpdate: '0001-01-01T00:00:00',
      clientCartId: null,
      isValid: false,
      billingProfileId: null,
      leadSource: null,
      leadDetail: null,
      isEmpty: true,
      hasLeasesOrLoans: false,
      needsApproval: false,
      newReferralCode: null,
      newReferralCodeMessage: null,
      deliveries: [],
      virtualProducts: [],
      appliedOffers: [],
      rewards: {},
      agreements: [],
      preselectedFilters: {},
      totals: [],
   },
   cartModifications: {
      allUpdated: false,
   },
   messages: [],
   companyInfo: COMPANY_STATE_COMPANY_INFO_DEFAULT,
};
