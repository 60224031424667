import React from 'react';
import {
   Alert,
   Box,
   Button,
   Card,
   CardContent,
   CardHeader,
   Container,
   Grid,
   List,
   ListItem,
   ListItemText,
   Stack,
   styled,
   Typography,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import CartItemsAccordion from '../cart/components/CartItemsAccordion';
import { useAppSelector } from '../../app/hooks';
import { orderStore } from '../order/stores/order.slice';
import { ICartTotal } from '../cart/types/cart.types';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useNavigate } from 'react-router-dom';
import useHeaderSize from '../../app/useHeaderSize';
import ProfileHeader from '../profile/components/ProfileHeader';

const StyledContainer = styled(Container)(({ theme }) => {
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));

   return {
      '&': {
         padding: isTabletScreen || isMobileScreen ? '0px' : '40px',
      },
   };
});

const Confirmation = () => {
   const navigate = useNavigate();
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));
   const [headerSize, headerContainerRef, headerMainHeight, , hasFixedHeader] = useHeaderSize();
   const { userOrder: cart } = useAppSelector(orderStore);

   return (
      <>
         <StyledContainer maxWidth="xl" sx={{ padding: '0 0 100px 0' }}>
            <Grid
               container
               spacing={isDesktopScreen ? 3 : 1}
               position="relative"
               pb={1}
               pt={2}
               mt={0}
               sx={{
                  top: headerSize?.height
                     ? headerSize.height + (isLargeScreenDown || hasFixedHeader ? headerMainHeight : 0)
                     : 0,
                  backgroundColor: theme.palette.background.default,
                  marginBottom: theme.spacing(12),
               }}
               style={{ paddingTop: '4px' }}
            >
               <Grid item xs={12} style={{ paddingTop: '4px', paddingRight: '12px' }} ref={headerContainerRef}>
                  <ProfileHeader paddingBottom={0} />
               </Grid>
               <Grid item xs={12} sm={isMobileScreen ? 12 : 6}>
                  <ListItem>
                     <Stack direction="column" width="100%">
                        <Card variant="outlined">
                           <CardHeader
                              title="Thank you for your order!"
                              subheader="Please check your inbox. Your confirmation email is on the way."
                           />
                           {cart?.newReferralCodeMessage && cart?.newReferralCode && (
                              <CardContent>
                                 <Alert severity="success" icon={<ThumbUpIcon fontSize="inherit" />}>
                                    <Typography variant="body1">{cart.newReferralCodeMessage}</Typography>
                                    <Typography
                                       variant="body1"
                                       color="black"
                                       fontWeight="bold"
                                       textAlign="center"
                                       marginTop={2}
                                    >
                                       {cart.newReferralCode}
                                    </Typography>
                                 </Alert>
                              </CardContent>
                           )}
                        </Card>
                     </Stack>
                  </ListItem>
                  <ListItem>
                     <Grid item xs={12}>
                        <div className="bar-box">
                           <p>Order Summary</p>
                        </div>
                     </Grid>
                  </ListItem>
                  <ListItem>
                     <Stack direction="column" width="100%">
                        <List>
                           {cart?.totals?.map((total: ICartTotal, totalIndex: number) => (
                              <ListItem key={`total-${totalIndex}`} sx={{ paddingTop: '2px', paddingBottom: '2px' }}>
                                 <ListItemText primary={total.name} />
                                 <Typography variant="body1">
                                    {total.amount.toLocaleString('en-US', {
                                       style: 'currency',
                                       currency: 'USD',
                                    })}
                                 </Typography>
                              </ListItem>
                           ))}
                        </List>
                     </Stack>
                  </ListItem>
                  <Box display={'flex'} justifyContent={'flex-end'} padding={'8px 16px'}>
                     <Button variant="contained" onClick={() => navigate('/order')}>
                        Back to Menu
                     </Button>
                  </Box>
               </Grid>
               <Grid item xs={12} sm={isMobileScreen ? 12 : 6}>
                  <ListItem>
                     <Grid item xs={12}>
                        <div className="bar-box">
                           <p>Your Items</p>
                        </div>
                     </Grid>
                  </ListItem>
                  <ListItem>
                     <Stack direction="column" width="100%">
                        <CardContent sx={{ textAlign: 'center' }}>
                           {cart?.deliveries && (
                              <CartItemsAccordion cartDeliveries={cart.deliveries} viewOnlyMode={true} />
                           )}
                        </CardContent>
                     </Stack>
                  </ListItem>
               </Grid>
            </Grid>
         </StyledContainer>
      </>
   );
};

export default Confirmation;
