import React, { RefObject, useEffect } from 'react';
import { Stack, Grid, Typography, Alert } from '@mui/material';
import PaymentDetailsDialog from './PaymentDetailsDialog';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import FinixFormModal from './FinixFormModal';
import { finixStore, setFinixFormModalOpen } from '../../stores/finix.slice';
import {
   accountStore,
   fetchUserPaymentProfilesAsync,
   resetAddPaymentStatus,
   resetDefaultBillingStatus,
   resetDeleteBillingStatus,
   resetFetchBillingProfilesStatus,
} from 'features/account/stores/account.slice';
import DeletePaymentDialog from './DeletePayementDialog';
import DefaultPaymentDialog from './DefaultPaymentDialog';
import {
   checkoutPaymentStore,
   setDefaultPaymentDialogOpen,
   setDeletePaymentDialogOpen,
   setPaymentDialogOpen,
   setPaymentToDefault,
   setPaymentToDelete,
} from '../../stores/checkout-payment.slice';
import { LoadStatus } from 'config/utils';
import { checkoutStore, updateBillingProfileAsync } from '../../stores/checkout.slice';
import PaymentNameAddressDisplay from './PaymentNameAddressDisplay';
import { SectionCard, SectionCardContent, SectionCardHeader } from '../SectionCard';
import SkeletonLoader from 'components/SkeletonLoader';
import { IError } from 'components/types/base.types';
import { MptLink } from 'components/MptLink';
import { checkoutLoadStore } from '../../stores/checkout-load.slice';

interface IPaymentDetailsProps {
   paymentDetailsCardRef: RefObject<HTMLDivElement>;
   emptyPaymentMethod: boolean;
}

const PaymentDetails = ({ paymentDetailsCardRef, emptyPaymentMethod }: IPaymentDetailsProps) => {
   const dispatch = useAppDispatch();
   const { cardLoading } = useAppSelector(checkoutLoadStore);
   const { billingProfiles, addBillingStatus, defaultBillingStatus, deleteBillingStatus, fetchBillingProfilesStatus } =
      useAppSelector(accountStore);
   const { finixFormModalOpen } = useAppSelector(finixStore);
   const { paymentDialogOpen, deletePaymentDialogOpen, defaultPaymentDialogOpen } =
      useAppSelector(checkoutPaymentStore);
   const { paymentError } = useAppSelector(checkoutStore);

   const handleFinixPaymentOpen = () => {
      dispatch(setFinixFormModalOpen(true));
   };

   const onDeleteBillingStatus = () => {
      if (deleteBillingStatus === LoadStatus.complete) {
         dispatch(fetchUserPaymentProfilesAsync());
      }
   };

   const onDefaultBillingStatus = () => {
      if (defaultBillingStatus === LoadStatus.complete) {
         dispatch(fetchUserPaymentProfilesAsync());
      }
   };

   const onAddBillingStatus = () => {
      if (addBillingStatus === LoadStatus.complete) {
         dispatch(fetchUserPaymentProfilesAsync());
      }
   };

   const onFetchBillingProfilesStatus = () => {
      if (fetchBillingProfilesStatus === LoadStatus.complete && addBillingStatus === LoadStatus.complete) {
         dispatch(resetAddPaymentStatus());
         dispatch(resetFetchBillingProfilesStatus());

         const defaultBillingProfiles = billingProfiles.filter((profile) => profile.default);
         const firstProfile = billingProfiles.length > 0 ? billingProfiles[0] : null;
         const paymentProfileId = defaultBillingProfiles.length > 0 ? defaultBillingProfiles[0] : firstProfile;

         paymentProfileId &&
            dispatch(
               updateBillingProfileAsync({
                  billingProfileId: paymentProfileId.id,
               }),
            );

         if (finixFormModalOpen) {
            dispatch(setFinixFormModalOpen(false));
         }
      }

      if (fetchBillingProfilesStatus === LoadStatus.complete && deleteBillingStatus === LoadStatus.complete) {
         dispatch(resetDeleteBillingStatus());
         dispatch(resetFetchBillingProfilesStatus());
         dispatch(setPaymentToDelete(null));
         dispatch(setPaymentDialogOpen(true));
         dispatch(setDeletePaymentDialogOpen(false));
      }

      if (fetchBillingProfilesStatus === LoadStatus.complete && defaultBillingStatus === LoadStatus.complete) {
         dispatch(resetDefaultBillingStatus());
         dispatch(resetFetchBillingProfilesStatus());
         dispatch(setPaymentToDefault(null));
         dispatch(setPaymentDialogOpen(true));
         dispatch(setDefaultPaymentDialogOpen(false));
      }
   };

   useEffect(() => {
      onDefaultBillingStatus();
   }, [defaultBillingStatus]);

   useEffect(() => {
      onDeleteBillingStatus();
   }, [deleteBillingStatus]);

   useEffect(() => {
      onAddBillingStatus();
   }, [addBillingStatus]);

   useEffect(() => {
      onFetchBillingProfilesStatus();
   }, [fetchBillingProfilesStatus]);

   return (
      <>
         <SectionCard
            ref={paymentDetailsCardRef}
            hasError={(paymentError.length > 0 || emptyPaymentMethod) && !cardLoading}
         >
            <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
               <SectionCardHeader
                  title={
                     <Grid item xs={12}>
                        <div className="bar-box">
                           <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                              3. Payment Method
                           </Typography>
                           <Typography variant={'subtitle1'} fontWeight="bold" mb={1} mt={1}>
                              <MptLink
                                 sx={{ marginRight: '5px', cursor: 'pointer', fontWeight: 600 }}
                                 onClick={handleFinixPaymentOpen}
                              >
                                 Add{' '}
                              </MptLink>
                              {billingProfiles && billingProfiles.length > 0 ? (
                                 <>
                                    /
                                    <MptLink
                                       sx={{ marginLeft: '5px', cursor: 'pointer', fontWeight: 600 }}
                                       onClick={() => dispatch(setPaymentDialogOpen(true))}
                                    >
                                       Change{' '}
                                    </MptLink>
                                 </>
                              ) : null}
                           </Typography>
                        </div>
                     </Grid>
                  }
               ></SectionCardHeader>
            </SkeletonLoader>
            <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '120px' }}>
               <SectionCardContent>
                  {paymentError.map((checkoutError: IError) => (
                     <Alert sx={{ marginBottom: 2 }} severity="error">
                        {checkoutError.errorMessage}
                     </Alert>
                  ))}
                  <Stack direction="column" gap={2} width="100%" minHeight={120}>
                     <PaymentNameAddressDisplay />
                  </Stack>
               </SectionCardContent>
            </SkeletonLoader>
         </SectionCard>
         {paymentDialogOpen && <PaymentDetailsDialog />}
         {finixFormModalOpen && <FinixFormModal />}
         {deletePaymentDialogOpen && <DeletePaymentDialog />}
         {defaultPaymentDialogOpen && <DefaultPaymentDialog />}
      </>
   );
};

export default PaymentDetails;
