import { Tabs, Tab, Box, useMediaQuery, useTheme } from '@mui/material';
import React, { RefObject, useEffect, useState } from 'react';
import CategoryPopUp from './CategoryPopUp';
import { CategoryProductMapping } from '../types/category-product.types';
import SkeletonLoader from 'components/SkeletonLoader';
import { useAppSelector } from 'app/hooks';
import { orderLoadStore } from '../stores/order-load.slice';
import useScreenSize from 'app/useScreenSize';

type Orientation = 'vertical' | 'horizontal' | undefined;

const CategoryList = (props: {
   dataToShow: CategoryProductMapping;
   setCategory: React.Dispatch<React.SetStateAction<string>>;
   style: Orientation;
   category: string;
   categoryTabValue: string;
   setCategoryTabValue: (mapping: string) => void;
   scrollToCategory: (event: React.SyntheticEvent, categoryName: string) => void;
   headerContainerRef: RefObject<HTMLDivElement>;
}) => {
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const { cardLoading } = useAppSelector(orderLoadStore);
   const screenSize = useScreenSize();
   const [categoryContainerHeight, setCategoryContainerHeight] = useState('70px');

   const adjustCategoryHeight = () => {
      if (props.headerContainerRef.current) {
         const rect = props.headerContainerRef.current.getBoundingClientRect();
         const bottomPosition = rect.bottom;
         const clientHeight = document.documentElement.clientHeight;
         setCategoryContainerHeight(`${clientHeight - bottomPosition}px`);
      }
   };

   useEffect(() => {
      props.setCategoryTabValue(props.category);
   }, [props.category]);

   useEffect(() => {
      if (!isMobileScreen) {
         adjustCategoryHeight();
      }
   }, [screenSize]);

   return (
      <SkeletonLoader
         loading={cardLoading}
         skeletonProps={{ width: 'calc(100% - 4px)', height: '57px' }}
         sx={{ marginTop: '4px' }}
      >
         <div
            className={`${props.style === 'horizontal' ? 'categories-sticky-top' : 'categories-container'}`}
            style={{ height: props.style === 'horizontal' ? 'auto' : categoryContainerHeight }}
         >
            {props.style === 'horizontal' ? (
               <div className="list-icon">
                  <CategoryPopUp
                     dataToShow={props.dataToShow}
                     style="vertical"
                     categoryTabValue={props.categoryTabValue}
                     scrollToCategory={props.scrollToCategory}
                     setCategoryTabValue={props.setCategoryTabValue}
                     categoryContainerHeight={categoryContainerHeight}
                  />
               </div>
            ) : (
               ''
            )}
            <Box className={`${props.style === 'horizontal' ? 'tab-horizontal' : ''}`}>
               <Tabs
                  value={
                     props.categoryTabValue
                        ? props.categoryTabValue
                        : Object.entries(props.dataToShow).map((categoryMapping) => categoryMapping[0])[0]
                  }
                  onChange={props.scrollToCategory}
                  variant="scrollable"
                  textColor="primary"
                  indicatorColor="primary"
                  scrollButtons="auto"
                  orientation={props.style}
               >
                  {Object.entries(props.dataToShow).map((categoryMappingArray) => (
                     <Tab
                        key={categoryMappingArray[0]}
                        value={categoryMappingArray[0]}
                        label={categoryMappingArray[0]}
                     />
                  ))}
               </Tabs>
            </Box>
         </div>
      </SkeletonLoader>
   );
};

export default CategoryList;
