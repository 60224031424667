import { InputAdornment, TextField } from '@mui/material';
import { BaseTextFieldProps } from './types/base-text-field-props';
import SearchIcon from '@mui/icons-material/Search';
import React, { ChangeEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const BaseTextField: React.FC<BaseTextFieldProps> = ({ value, onChange, placeholder }) => {
   return (
      <TextField
         className="mpt-text-field"
         fullWidth
         variant="filled"
         placeholder={placeholder}
         onChange={onChange}
         value={value}
         autoComplete="off"
         InputProps={{
            className: 'mpt-input',
            startAdornment: (
               <InputAdornment position="start">
                  <SearchIcon />
               </InputAdornment>
            ),
            endAdornment: value ? (
               <InputAdornment position="end">
                  <IconButton
                     onClick={() => onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)}
                     aria-label="clear input"
                  >
                     <ClearIcon />
                  </IconButton>
               </InputAdornment>
            ) : null,
         }}
      />
   );
};

export default BaseTextField;
