import { apiServerUrl } from 'config/server';
import {
   IDeleteBillingResponse,
   ISubmitBillingPayload,
   ISubmitBillingResponse,
   ISubmitLoginPayload,
   ISubmitLoginResponse,
   ISubmitLogoutPayload,
   ISubmitShippingPayload,
   ISubmitShippingResponse,
   ISubmitRegisterPayload,
   ISubmitRegisterResponse,
   IBillingProfilesResponse,
   IFetchShippingResponse,
   ISubmitPasswordResetPayload,
   ISubmitPasswordResetResponse,
   ISubmitNewPasswordResetPayload,
   ISubmitNewPasswordResetResponse,
   IFetchAccountResponse,
   IUpdateAccountResponse,
   IUpdateAccountPayload,
   IFetchReferralCodeResponse,
   IFetchRewardsResponse,
} from '../types/account.types';
import { expandApiServerHeader, prepareTokenPayload, updateMPTSession } from 'utils/mpt-session.helper';
import { IBillingProfileForm, ICheckoutResponse } from '../../checkout/types/checkout.types';

const accountLogoutUrl = `/user/logout`;
const accountLoginUrl = `/user/login`;
const accountDataUrl = '/user/account';
const accountShippingUrl = '/user/shipping';
const accountBilling = '/user/billing';
const accountOrder = '/user/order';
const accountRegisterUrl = '/user/register';
const accountPasswordUrl = '/user/password';
const accountReferralCodeUrl = '/user/referral-code';
const accountRewardsUrl = '/user/rewards';

const prodUrls = {
   login: `${apiServerUrl}${accountLoginUrl}`,
   logout: `${apiServerUrl}${accountLogoutUrl}`,
   account: `${apiServerUrl}${accountDataUrl}`,
   shipping: `${apiServerUrl}${accountShippingUrl}`,
   billing: `${apiServerUrl}${accountBilling}`,
   order: `${apiServerUrl}${accountOrder}`,
   register: `${apiServerUrl}${accountRegisterUrl}`,
   password: `${apiServerUrl}${accountPasswordUrl}`,
   referralCode: `${apiServerUrl}${accountReferralCodeUrl}`,
   rewards: `${apiServerUrl}${accountRewardsUrl}`,
};

export function accountLogout(bodyInput: ISubmitLogoutPayload) {
   return new Promise((resolve, reject) => {
      const postData = {
         allSessions: bodyInput.allSessions,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.logout}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            updateMPTSession(null);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountLogin(bodyInput: ISubmitLoginPayload) {
   return new Promise<ISubmitLoginResponse>((resolve, reject) => {
      const postData = {
         username: bodyInput.login_user,
         password: bodyInput.login_pass,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.login}`, requestOptions)
         .then((response) => response.json())
         .then((resultData: ISubmitLoginResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchAccount() {
   return new Promise<IFetchAccountResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(`${prodUrls.account}`, requestOptions)
         .then((response) => response.json())
         .then((resultData: IFetchAccountResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function updateAccount(bodyInput: IUpdateAccountPayload) {
   return new Promise<IUpdateAccountResponse>((resolve, reject) => {
      const postData = {
         firstName: bodyInput.firstName,
         lastName: bodyInput.lastName,
         email: bodyInput.email,
         phone: bodyInput.phone,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.account}`, requestOptions)
         .then((response) => response.json())
         .then((resultData: IUpdateAccountResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountPasswordReset(bodyInput: ISubmitPasswordResetPayload) {
   return new Promise<ISubmitPasswordResetResponse>((resolve, reject) => {
      const postData = {
         ip: bodyInput.ip,
         username: bodyInput.username,
      };

      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.password}`, requestOptions)
         .then((response) => response.json())
         .then((resultData: ISubmitPasswordResetResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountNewPasswordReset(bodyInput: ISubmitNewPasswordResetPayload) {
   return new Promise<ISubmitNewPasswordResetResponse>((resolve, reject) => {
      const postData = {
         ip: bodyInput.ip,
         password: bodyInput.password,
         token: bodyInput.token,
         username: bodyInput.username,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.password}`, requestOptions)
         .then((response) => response.json())
         .then((resultData: ISubmitNewPasswordResetResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountRegister(bodyInput: ISubmitRegisterPayload) {
   return new Promise<ISubmitRegisterResponse>((resolve, reject) => {
      const postData = {
         clientCartId: bodyInput.clientCartId,
         firstName: bodyInput.firstName,
         ip: bodyInput.ip,
         lastName: bodyInput.lastName,
         password: bodyInput.password,
         username: bodyInput.username,
         phoneNumber: bodyInput.phoneNumber,
         ...(bodyInput.turnstileToken && { turnstileToken: bodyInput.turnstileToken }),
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.register}`, requestOptions)
         .then((response) => response.json())
         .then((resultData: ISubmitRegisterResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountFetchShipping() {
   return new Promise<IFetchShippingResponse>((resolve, reject) => {
      const postData = {};

      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.shipping}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountAddShipping(bodyInput: ISubmitShippingPayload, editAddressId?: string) {
   return new Promise<ISubmitShippingResponse>((resolve, reject) => {
      const postData = {
         address: bodyInput.address,
         address2: bodyInput.address2,
         city: bodyInput.city,
         country: bodyInput.country,
         firstName: bodyInput.firstName,
         instructions: bodyInput.instructions,
         lastName: bodyInput.lastName,
         phone: bodyInput.phone,
         postal: bodyInput.postal,
         region: bodyInput.region,
         ...(bodyInput.setAsDefault !== undefined && { setAsDefault: bodyInput.setAsDefault }),
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      const addAddressId = editAddressId ? `/${editAddressId}` : '';

      fetch(`${prodUrls.shipping}${addAddressId}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountSetDefaultShipping(defaultAddressId: string) {
   return new Promise<ISubmitShippingResponse>((resolve, reject) => {
      const postData = {
         setAsDefault: true,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.shipping}/${defaultAddressId}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountDeleteShipping(deleteAddressId: string) {
   return new Promise<ISubmitShippingResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'DELETE',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(`${prodUrls.shipping}/${deleteAddressId}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountAddBilling(bodyInput: ISubmitBillingPayload) {
   return new Promise((resolve, reject) => {
      const postData = {
         acctType: bodyInput.acctType,
         ccToken: bodyInput.ccToken,
         onCheckout: bodyInput.onCheckout,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.billing}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountEditBilling(bodyInput: IBillingProfileForm, editPaymentId?: string) {
   return new Promise<ISubmitShippingResponse>((resolve, reject) => {
      const postData = {
         address: bodyInput.address,
         address2: bodyInput.address2,
         city: bodyInput.city,
         country: bodyInput.country,
         firstName: bodyInput.firstName,
         lastName: bodyInput.lastName,
         postal: bodyInput.postal,
         region: bodyInput.region,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.billing}/${editPaymentId}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountSetDefaultBilling(defaultPaymentId: string) {
   return new Promise<ISubmitBillingResponse>((resolve, reject) => {
      const postData = {
         setAsDefault: true,
      };

      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload(postData)),
      };

      fetch(`${prodUrls.billing}/${defaultPaymentId}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function accountDeleteBilling(deletePaymentId: string) {
   return new Promise<IDeleteBillingResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'DELETE',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(`${prodUrls.billing}/${deletePaymentId}`, requestOptions)
         .then((response) => response.json())
         .then((resultData) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchUserOrder(cartId: string) {
   return new Promise<ICheckoutResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({ cartId })),
      };

      fetch(prodUrls.order, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICheckoutResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchUserPaymentProfiles() {
   return new Promise<IBillingProfilesResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(prodUrls.billing, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IBillingProfilesResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchReferralCode() {
   return new Promise<IFetchReferralCodeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(prodUrls.referralCode, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IFetchReferralCodeResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchRewards() {
   return new Promise<IFetchRewardsResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(prodUrls.rewards, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IFetchRewardsResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
