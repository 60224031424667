import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import React from 'react';
import Select from '@mui/material/Select';

export interface SelectMenuItem {
   name: string;
   value: string;
}

interface FormSelectProps {
   name: string;
   label: string;
   menuItems: SelectMenuItem[];
   autoComplete?: string;
}

const FormSelect: React.FC<FormSelectProps> = ({ name, label, autoComplete, menuItems }) => {
   const {
      control,
      formState: { errors },
   } = useFormContext();

   return (
      <FormControl fullWidth error={!!errors[name]}>
         <InputLabel id={`form-${name}-label`}>{label}</InputLabel>
         <Controller
            name={name}
            control={control}
            render={({ field }) => (
               <Select
                  {...field}
                  labelId={`form-${name}-label`}
                  label={label}
                  autoComplete={autoComplete}
                  onChange={(e: SelectChangeEvent) => field.onChange(e.target.value)}
               >
                  {menuItems.map((menuItem, itemIndex) => (
                     <MenuItem key={`name-menu-item-${itemIndex}`} value={menuItem.value}>
                        {menuItem.name}
                     </MenuItem>
                  ))}
               </Select>
            )}
         />
         <FormHelperText>{errors[name] ? String(errors[name]?.message) : ''}</FormHelperText>
      </FormControl>
   );
};

export default FormSelect;
