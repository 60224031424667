import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   DialogActions,
   DialogContent,
   Divider,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
   Stack,
   Typography,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { IDeliveryOption } from 'features/order/types/order.types';
import { getSelectedTimeslotDesc, getShortDateStr, getShortDayStr } from 'features/order/config/order.helper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { orderStore } from 'features/order/stores/order.slice';
import { useAppSelector } from 'app/hooks';
import OrderMethod from './OrderMethod';
import OrderFrequency from './OrderFrequency';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import { LoadStatus } from 'config/utils';

interface IOrderDialogContentProps {
   deliveryOptions: IDeliveryOption[];
   orderTimeslot: string;
   handleChangeTimeslot: (timeslot: string, deliveryOption?: IDeliveryOption) => void;
   onSelect: () => void;
}

const OrderDialogContent = ({
   deliveryOptions,
   orderTimeslot,
   handleChangeTimeslot,
   onSelect,
}: IOrderDialogContentProps) => {
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const { selectedDeliverySlot, postDeliveryDateStatus, changeDeliveryDateStatus } = useAppSelector(orderStore);
   const [expand, setExpand] = useState<number | null>(null);
   const [orderMethodExpand, setOrderMethodExpand] = useState(true);
   const [footerPadding, setFooterPadding] = useState(0);
   const isSelecting = postDeliveryDateStatus === LoadStatus.loading || changeDeliveryDateStatus === LoadStatus.loading;
   const dialogContentRefs = useRef<any>(null);
   const accordionMainRefs = useRef<any>(null);
   const accordionExpansionRefs = useRef<any>(null);
   const dialogFooterRefs = useRef<any>(null);

   const displayInitialRender = () => {
      const initDeliveryOptionIndex = deliveryOptions.findIndex((deliveryOption) => {
         return getShortDateStr(deliveryOption.date) === getShortDateStr(selectedDeliverySlot.deliveryDayName);
      });
      setExpand(initDeliveryOptionIndex ?? null);
   };

   useEffect(() => {
      displayInitialRender();
   }, [deliveryOptions]);

   useEffect(() => {
      setTimeout(() => {
         if (accordionMainRefs?.current && dialogContentRefs?.current) {
            const mainBoundingRect = accordionMainRefs.current.getBoundingClientRect();
            const isMainOffScreen = mainBoundingRect.top < dialogContentRefs.current.offsetTop;
            if (isMainOffScreen) {
               accordionMainRefs.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
         }
         if (accordionExpansionRefs?.current) {
            const expansionBoundingRect = accordionExpansionRefs.current.getBoundingClientRect();
            const isExpansionOffScreen = expansionBoundingRect.bottom > window.innerHeight;
            if (isExpansionOffScreen) {
               accordionExpansionRefs.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
         }
      }, 200);
   }, [expand]);

   useEffect(() => {
      if (dialogContentRefs.current && dialogFooterRefs?.current) {
         const padding = dialogContentRefs.current.getBoundingClientRect().bottom - dialogFooterRefs.current.offsetTop;
         setFooterPadding(padding);
      }
   }, [dialogContentRefs, dialogFooterRefs]);

   return (
      <>
         <DialogContent
            sx={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: `${footerPadding}px` }}
            className="order-accordion-content"
            ref={(el) => (dialogContentRefs.current = el)}
         >
            <Accordion onChange={() => setOrderMethodExpand(!orderMethodExpand)} expanded={orderMethodExpand}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-delivery-option">
                  <Typography className="order-day" variant="h6">
                     Delivery Method and Frequency
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Divider className="order-date-divider" variant="middle" orientation="vertical" flexItem />
                  <Stack
                     sx={{
                        marginTop: '10px',
                        marginBottom: '10px',
                     }}
                     direction={isMobileScreen ? 'column' : 'row'}
                     justifyContent="center"
                     alignItems="center"
                     spacing={2}
                  >
                     <OrderMethod />
                     <OrderFrequency />
                  </Stack>
               </AccordionDetails>
            </Accordion>
            {deliveryOptions.map((deliveryOption, optionIndex) => {
               const orderDay = getShortDayStr(deliveryOption.name);
               const orderDate = getShortDateStr(deliveryOption.date);

               return (
                  <Accordion
                     key={deliveryOption.id + deliveryOption.date}
                     onChange={() => setExpand(optionIndex === expand ? null : optionIndex)}
                     expanded={expand === optionIndex}
                  >
                     <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-delivery-option"
                        ref={(el) => (expand === optionIndex ? (accordionMainRefs.current = el) : null)}
                     >
                        <Stack spacing={1} alignItems="center" direction="row" width="100%">
                           <Typography className="order-day" variant="h6">
                              {deliveryOption.name}
                           </Typography>
                           ,
                           <Typography className="order-date" variant="h6">
                              {getShortDateStr(deliveryOption.date)}
                           </Typography>
                        </Stack>
                     </AccordionSummary>
                     <AccordionDetails
                        ref={(el) => (expand === optionIndex ? (accordionExpansionRefs.current = el) : null)}
                     >
                        <Divider className="order-date-divider" variant="middle" orientation="vertical" flexItem />
                        <FormControl>
                           <RadioGroup
                              aria-labelledby="order-timeslot-group-label"
                              name="order-timeslot-group"
                              value={orderTimeslot}
                              onChange={(event) => handleChangeTimeslot(event.target.defaultValue, deliveryOption)}
                           >
                              {[...deliveryOption.timeSlots]
                                 .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
                                 .map((timeslot) => {
                                    const slotValue = `${orderDay}|${orderDate}|${timeslot.id}`;
                                    return (
                                       <FormControlLabel
                                          key={slotValue}
                                          value={slotValue}
                                          control={<Radio />}
                                          label={
                                             timeslot.textOverride
                                                ? timeslot.textOverride
                                                : `${timeslot.start} - ${timeslot.end}`
                                          }
                                       />
                                    );
                                 })}
                           </RadioGroup>
                        </FormControl>
                        <Box mt={'auto'} pt={'20px'} style={{ textWrap: 'wrap' }}>
                           <Typography variant="body1" fontWeight="500">
                              {getSelectedTimeslotDesc(deliveryOption.timeSlots, orderTimeslot)}
                           </Typography>
                        </Box>
                     </AccordionDetails>
                  </Accordion>
               );
            })}
         </DialogContent>
         <DialogActions ref={(el) => (dialogFooterRefs.current = el)} className="order-dialog-footer">
            <Box display="flex" justifyContent="center" alignItems="center">
               <Button
                  startIcon={
                     isSelecting ? <CircularProgress size={20} thickness={8} color={'inherit'} /> : <CheckIcon />
                  }
                  variant="contained"
                  onClick={onSelect}
                  disabled={isSelecting}
               >
                  Select
               </Button>
            </Box>
         </DialogActions>
      </>
   );
};

export default OrderDialogContent;
