import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { updateShippingProfile } from '../services/checkout.api';
import { IShippingProfile } from '../types/checkout.types';
import { LoadStatus } from 'config/utils';
import { setCart } from 'features/cart/stores/cart.slice';
import {
   checkSuccessResult,
   setAccountMessages,
   setBillingProfiles,
   setShippingProfiles,
} from 'features/account/stores/account.slice';
import { IDeliveryChangeResponse } from 'features/order/types/order.types';
import { IError } from 'components/types/base.types';
import { resetCartRuleError, resetDeliveryRuleError } from './checkout.slice';
import { IMessage } from 'features/account/types/account.types';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

interface CheckoutShippingState {
   updateShippingProfileStatus: LoadStatus;
   presetShippingProfileStatus: LoadStatus;
   updateBillingProfileStatus: LoadStatus;
   addressRuleError: IError[];
   hasSelectedAddressRuleError: boolean;
   deliveryDialogOpen: boolean;
   addDeliveryDialogOpen: boolean;
   deleteAddressDialogOpen: boolean;
   defaultAddressDialogOpen: boolean;
   addressToDelete: IShippingProfile | null;
   addressToDefault: IShippingProfile | null;
   overrideWithDefault: boolean;
}

const initialState: CheckoutShippingState = {
   updateShippingProfileStatus: LoadStatus.idle,
   presetShippingProfileStatus: LoadStatus.idle,
   updateBillingProfileStatus: LoadStatus.idle,
   addressRuleError: [],
   hasSelectedAddressRuleError: false,
   addressToDelete: null,
   addressToDefault: null,
   deliveryDialogOpen: false,
   addDeliveryDialogOpen: false,
   deleteAddressDialogOpen: false,
   defaultAddressDialogOpen: false,
   overrideWithDefault: false,
};

const performUpdateShippingProfile = async (
   input: { shippingLocationId: string; updatePresets: boolean; shippingProfileId: string },
   dispatch: any,
) => {
   const result = await updateShippingProfile(input.shippingLocationId, input.updatePresets, input.shippingProfileId);

   if (result?.success) {
      const { billingProfiles, shippingProfiles, cart } = result;
      cart && dispatch(setCart(cart));
      shippingProfiles && dispatch(setShippingProfiles(shippingProfiles));
      billingProfiles && dispatch(setBillingProfiles(billingProfiles));
      dispatch(setAccountMessages(result.messages as IMessage[]));
   }

   checkSuccessResult(result, dispatch);
   return { ...result, shippingProfileId: input.shippingProfileId };
};

export const updateShippingProfileAsync = createAsyncThunk(
   'checkout/updateShippingProfile',
   async (
      input: {
         shippingLocationId: string;
         updatePresets: boolean;
         shippingProfileId: string;
      },
      { dispatch },
   ) => {
      const result = await performUpdateShippingProfile(input, dispatch);
      if (result?.success) {
         dispatch(resetDeliveryRuleError());
         dispatch(resetCartRuleError());
      }
      return result;
   },
);

export const presetShippingProfileAsync = createAsyncThunk(
   'checkout/presetShippingProfile',
   async (
      input: {
         shippingLocationId: string;
         updatePresets: boolean;
         shippingProfileId: string;
      },
      { dispatch },
   ) => performUpdateShippingProfile(input, dispatch),
);

const checkoutShippingSlice = createSlice({
   name: 'checkoutShipping',
   initialState,
   reducers: {
      setDeliveryDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.deliveryDialogOpen = action.payload;
      },
      setAddDeliveryDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.addDeliveryDialogOpen = action.payload;
      },
      setDeleteAddressDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.deleteAddressDialogOpen = action.payload;
      },
      setDefaultAddressDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.defaultAddressDialogOpen = action.payload;
      },
      setAddressToDelete: (state, action: PayloadAction<IShippingProfile | null>) => {
         state.addressToDelete = action.payload;
      },
      setAddressToDefault: (state, action: PayloadAction<IShippingProfile | null>) => {
         state.addressToDefault = action.payload;
      },
      resetUpdateShippingProfileStatus: (state) => {
         state.updateShippingProfileStatus = LoadStatus.idle;
      },
      resetPresetShippingProfileStatus: (state) => {
         state.presetShippingProfileStatus = LoadStatus.idle;
      },
      resetAddressRuleError: (state) => {
         state.addressRuleError = [];
      },
      setOverrideWithDefault: (state, action: PayloadAction<boolean>) => {
         state.overrideWithDefault = action.payload;
      },
      setHasSelectedAddressRuleError: (state, action: PayloadAction<boolean>) => {
         state.hasSelectedAddressRuleError = action.payload;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(updateShippingProfileAsync.pending, (state, action) => {
            const shippingProfileId = action.meta.arg.shippingProfileId;
            state.updateShippingProfileStatus = LoadStatus.loading;
            state.addressRuleError = state.addressRuleError.filter((error) => error.id !== shippingProfileId);
         })
         .addCase(updateShippingProfileAsync.fulfilled, (state, action: PayloadAction<IDeliveryChangeResponse>) => {
            state.updateShippingProfileStatus = LoadStatus.complete;

            if (action.payload.error) {
               state.addressRuleError = [
                  ...state.addressRuleError,
                  ...action.payload.error.map((error) => ({
                     ...error,
                     id: action.payload.shippingProfileId,
                  })),
               ];
            } else {
               state.addressRuleError = [];
            }
         })
         .addCase(updateShippingProfileAsync.rejected, (state) => {
            state.updateShippingProfileStatus = LoadStatus.failed;
         })
         .addCase(presetShippingProfileAsync.pending, (state, action) => {
            const shippingProfileId = action.meta.arg.shippingProfileId;
            state.presetShippingProfileStatus = LoadStatus.loading;
            state.addressRuleError = state.addressRuleError.filter((error) => error.id !== shippingProfileId);
         })
         .addCase(presetShippingProfileAsync.fulfilled, (state, action: PayloadAction<IDeliveryChangeResponse>) => {
            state.presetShippingProfileStatus = LoadStatus.complete;

            if (action.payload.error) {
               state.addressRuleError = [
                  ...state.addressRuleError,
                  ...action.payload.error.map((error) => ({
                     ...error,
                     id: action.payload.shippingProfileId,
                  })),
               ];
            }
         })
         .addCase(presetShippingProfileAsync.rejected, (state) => {
            state.presetShippingProfileStatus = LoadStatus.failed;
         });
   },
});

export const {
   setDeliveryDialogOpen,
   setAddDeliveryDialogOpen,
   setDeleteAddressDialogOpen,
   setDefaultAddressDialogOpen,
   setAddressToDelete,
   setAddressToDefault,
   resetAddressRuleError,
   resetUpdateShippingProfileStatus,
   resetPresetShippingProfileStatus,
   setOverrideWithDefault,
   setHasSelectedAddressRuleError,
} = checkoutShippingSlice.actions;

export const checkoutShippingStore = (state: RootState) => state.checkoutShippingStore;

export default checkoutShippingSlice.reducer;
