import React from 'react';
import { Backdrop, Box, CircularProgress, SxProps, Theme } from '@mui/material';

interface FullPageLoaderProps {
   loading: boolean;
   backdropProps?: SxProps<Theme>;
   loaderProps?: SxProps<Theme>;
   message?: string;
}

const FullPageLoader: React.FC<FullPageLoaderProps> = ({ loading, backdropProps, loaderProps, message }) => {
   return (
      <Backdrop
         open={loading}
         sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: (theme) => theme.zIndex.modal + 1,
            color: '#fff',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            ...backdropProps,
         }}
      >
         <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress
               color="inherit"
               sx={{
                  ...loaderProps,
               }}
            />
            {message && (
               <Box fontSize="1.2rem" fontWeight="bold" p={2} textAlign="center">
                  {message}
               </Box>
            )}
         </Box>
      </Backdrop>
   );
};

export default FullPageLoader;
