import { ITrackEventData, TrackEventType, TrackGTagType, TrackPixelType } from '../types/event.types';
import { IProduct } from '../types/product.types';
import { ICartProduct, ICartResponse, IDelivery, IPostCartProductPayload } from 'features/cart/types/cart.types';
import { postEventTrack } from '../services/event.api';
import { ICheckoutResponse } from 'features/checkout/types/checkout.types';
import { OrderType } from '../config/order.const';
import { ISubmitRegisterResponse } from 'features/account/types/account.types';

export const pixelActive = () => typeof window.fbq !== 'undefined';

export const gaActive = () => typeof window.gtag !== 'undefined';

export const pinActive = () => typeof window.pntrk !== 'undefined';

export const getCartTotal = (
   cartResponse: ICartResponse | ISubmitRegisterResponse | ICheckoutResponse,
   type?: string,
) => {
   let cartTotal: number;
   try {
      cartTotal = Math.max.apply(
         null,
         cartResponse.cart.totals
            .filter((cli) => cli.name.toLowerCase().includes(type ? type : 'total'))
            .map((cli) => {
               return cli.amount;
            }),
      );
   } catch (errMessage) {
      cartTotal = 0;
   }

   return cartTotal;
};

export const convertInitCheckoutEventData = (cartResponse: ICartResponse): ITrackEventData => {
   const cartTotal = getCartTotal(cartResponse);
   return {
      cartObj: JSON.stringify(cartResponse.cart),
      page_path: `${window.location.href.split('#')[1]}`,
      productId: null,
      cartTotal,
   };
};

export const convertCompleteCheckoutEventData = (checkoutResponse: ICheckoutResponse): ITrackEventData => {
   const delivery = checkoutResponse.cart.deliveries.length ? checkoutResponse.cart.deliveries[0] : ({} as IDelivery);
   const foundShippingProfile = checkoutResponse.user.shippingProfiles?.find(
      (findProfile) => findProfile.id === delivery.shippingProfileId,
   );

   const trackedShippingObj =
      foundShippingProfile && delivery?.shippingLocationType === OrderType.homeDelivery
         ? {
              shippingFirstName: foundShippingProfile?.firstName,
              shippingLastName: foundShippingProfile?.lastName,
              shippingAddress: foundShippingProfile?.address,
              shippingAddress2: foundShippingProfile?.address2,
              shippingCity: foundShippingProfile?.city,
              shippingRegion: foundShippingProfile?.region,
              shippingPostal: foundShippingProfile?.postal,
              shippingPhone: foundShippingProfile?.phone,
              shippingInstructions: foundShippingProfile?.instructions,
              shippingProfileId: foundShippingProfile?.id,
           }
         : delivery;

   const trackedBillingObj = checkoutResponse.user.billingProfiles?.find(
      (findProfile) => findProfile.id === checkoutResponse.cart?.billingProfileId,
   );

   return {
      cartObj: JSON.stringify(checkoutResponse.cart),
      selectedShippingObj: JSON.stringify({
         firstName: trackedShippingObj.shippingFirstName,
         lastName: trackedShippingObj.shippingLastName,
         address: trackedShippingObj.shippingAddress,
         address2: trackedShippingObj.shippingAddress2,
         city: trackedShippingObj.shippingCity,
         region: trackedShippingObj.shippingRegion,
         postal: trackedShippingObj.shippingPostal,
         phone: trackedShippingObj.shippingPhone,
      }),
      selectedBillingObj: JSON.stringify({
         firstName: trackedBillingObj?.firstName,
         lastName: trackedBillingObj?.lastName,
         address: trackedBillingObj?.address,
         address2: trackedBillingObj?.address2,
         city: trackedBillingObj?.city,
         region: trackedBillingObj?.region,
         postal: trackedBillingObj?.postal,
      }),
   };
};

export const convertPageViewEventData = (): ITrackEventData => {
   return {
      page_path: `${window.location.href.split('#')[1]}`,
      productId: null,
   };
};

export const convertProductViewEventData = (product: IProduct): ITrackEventData => {
   const productURL = `${window.location.href.split('#')[0]}#/order/${product.id}`;

   return {
      productId: product.id,
      productName: product.name,
      price: product.price?.toFixed(2),
      categories: JSON.stringify(product.categories.map((category) => category.name)),
      imageURL:
         product.images && product.images.length > 0 && product.images[0].length > 1 ? product.images[0][1].url : '',
      productURL,
   };
};

export const convertUpdateToCartEventData = (product: ICartProduct) => {
   return {
      page_path: `${window.location.href.split('#')[1]}`,
      productId: product.productId,
      productName: product.name,
   };
};

export const convertAddToCartEventData = (
   productPayLoad: IPostCartProductPayload,
   product: IProduct,
   cartResponse: ICartResponse,
): ITrackEventData => {
   const cartTotal = getCartTotal(cartResponse);

   return {
      page_path: `${window.location.href.split('#')[1]}`,
      productId: product.id,
      productName: product.name,
      qty: productPayLoad.qty.toString(),
      cartObj: JSON.stringify(cartResponse.cart),
      price: product.price?.toFixed(2),
      cartTotal,
   };
};

export const convertAddToCartPixelEventData = (product: IProduct): any => {
   return {
      value: product.price,
      currency: 'USD',
      content_ids: [product.id],
      content_type: 'product',
      content_name: product.name,
   };
};

export const convertPurchasePixelEventData = (checkoutResponse: ICheckoutResponse): any => {
   const cartTotal = getCartTotal(checkoutResponse);
   const productIds = checkoutResponse?.cart?.deliveries[0]?.products.map((product) => product.productId);

   return {
      value: cartTotal,
      currency: 'USD',
      content_name: checkoutResponse?.user.displayName,
      content_ids: productIds,
      content_type: 'product',
   };
};

export const convertPurchaseGAEventData = (checkoutResponse: ICheckoutResponse): any => {
   const cartTotal = getCartTotal(checkoutResponse);
   const shippingTotal = getCartTotal(checkoutResponse, 'shipping');
   const taxTotal = getCartTotal(checkoutResponse, 'tax');

   return {
      transaction_id: checkoutResponse.cart.id,
      value: cartTotal,
      currency: 'USD',
      tax: taxTotal,
      shipping: shippingTotal,
   };
};

export const convertConversionGAEventData = (checkoutResponse: ICheckoutResponse): any => {
   const cartTotal = getCartTotal(checkoutResponse);

   return {
      value: cartTotal,
      currency: 'USD',
   };
};

export const convertInitCheckoutPixelEventData = (cartResponse: ICartResponse): any => {
   const cartTotal = getCartTotal(cartResponse);
   return {
      value: cartTotal,
      currency: 'USD',
      content_name: cartResponse?.user?.displayName || 'Authenticated User',
      content_type: 'product',
   };
};

export const trackMptEvent = (eventType: TrackEventType, trackData: ITrackEventData | IProduct | undefined) => {
   void postEventTrack(eventType, trackData as ITrackEventData);
};

export const trackPixelEvent = (evenType: TrackPixelType, trackData: any) => {
   if (pixelActive()) {
      window.fbq('track', evenType, trackData);
   }
};

export const trackGaEvent = (eventType: TrackGTagType, trackData?: any) => {
   if (gaActive()) {
      if (trackData) {
         window.gtag('event', eventType, trackData);
      } else {
         window.gtag('event', eventType);
      }
   }
};

export const trackPinEvent = () => {};
