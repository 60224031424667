import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface CheckoutLoadState {
   cardLoading: boolean;
}

export const initialCheckoutLoadState: CheckoutLoadState = {
   cardLoading: true,
};

export const checkoutLoadSlice = createSlice({
   name: 'checkoutLoad',
   initialState: initialCheckoutLoadState,
   reducers: {
      setCardLoading: (state, action: PayloadAction<boolean>) => {
         state.cardLoading = action.payload;
      },
   },
});

export const { setCardLoading } = checkoutLoadSlice.actions;

export const checkoutLoadStore = (state: RootState) => state.checkoutLoadStore;

export default checkoutLoadSlice.reducer;
