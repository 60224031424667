import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography, Grid, Stack } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import AddTip from './AddTip';
import SkeletonLoader from 'components/SkeletonLoader';
import { SectionCardHeader, SectionCardSlim, SectionCardSlimContent } from '../SectionCard';
import { MptLink } from 'components/MptLink';
import TermsDialog from './TermsDialog';
import { companyStore } from 'features/company/stores/company.slice';
import { checkoutLoadStore } from '../../stores/checkout-load.slice';
import { changeCustomModalOpen } from '../../../../components/utils/dialog.helper';

const OrderTotal = () => {
   const { cardLoading } = useAppSelector(checkoutLoadStore);
   const { cart } = useAppSelector(cartStore);
   const { companyInfo } = useAppSelector(companyStore);
   const [termsDialogOpen, setTermsDialogOpen] = useState(false);

   useEffect(() => {
      changeCustomModalOpen(termsDialogOpen);
   }, [termsDialogOpen]);

   return (
      <SectionCardSlim>
         <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
            <SectionCardHeader
               title={
                  <Grid item xs={12}>
                     <div className="bar-box">
                        <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                           Order Total
                        </Typography>
                     </div>
                  </Grid>
               }
            ></SectionCardHeader>
         </SkeletonLoader>
         <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '120px' }}>
            <SectionCardSlimContent>
               <Stack direction="column" width="100%">
                  <List>
                     {cart?.totals.map((total, totalIndex) => (
                        <ListItem key={`total-${totalIndex}`} sx={{ paddingTop: '2px', paddingBottom: '2px' }}>
                           <ListItemText
                              primary={total.name}
                              sx={{
                                 '& .MuiListItemText-primary': { fontWeight: 'bold' },
                              }}
                           />
                           <Typography variant="body1" fontWeight="bold">
                              {total.amount.toLocaleString('en-US', {
                                 style: 'currency',
                                 currency: 'USD',
                              })}
                           </Typography>
                        </ListItem>
                     ))}
                  </List>
                  {!companyInfo.uiSettings.hideTip && <AddTip />}
                  {companyInfo.terms && (
                     <Typography variant="body2" textAlign="center">
                        By placing your order, you agree the{' '}
                        <MptLink sx={{ cursor: 'pointer' }} onClick={() => setTermsDialogOpen(true)}>
                           privacy notice and conditions of use.{' '}
                        </MptLink>
                     </Typography>
                  )}
               </Stack>
            </SectionCardSlimContent>
         </SkeletonLoader>
         <TermsDialog {...{ termsDialogOpen, setTermsDialogOpen }} />
      </SectionCardSlim>
   );
};

export default OrderTotal;
