import React from 'react';
import 'features/account/styles/Account.scss';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { accountSetDefaultBillingAsync, accountStore } from 'features/account/stores/account.slice';
import { LoadStatus } from 'config/utils';
import {
   checkoutPaymentStore,
   setDefaultPaymentDialogOpen,
   setPaymentDialogOpen,
} from '../../stores/checkout-payment.slice';

const DefaultPaymentDialog = () => {
   const dispatch = useAppDispatch();
   const { defaultPaymentDialogOpen, paymentToDefault } = useAppSelector(checkoutPaymentStore);
   const { defaultBillingStatus, fetchBillingProfilesStatus } = useAppSelector(accountStore);
   const isLoading = defaultBillingStatus === LoadStatus.loading || fetchBillingProfilesStatus === LoadStatus.loading;

   const onPaymentDefaultClose = () => {
      dispatch(setDefaultPaymentDialogOpen(false));
      dispatch(setPaymentDialogOpen(true));
   };

   const handlePaymentDefault = () => {
      paymentToDefault && dispatch(accountSetDefaultBillingAsync({ defaultPaymentId: paymentToDefault.id }));
   };

   return (
      <ConfirmationDialog
         open={defaultPaymentDialogOpen}
         onClose={onPaymentDefaultClose}
         confirmContent={
            <>
               {paymentToDefault ? (
                  <Box>
                     <span className="shipping-address-name">
                        {paymentToDefault.firstName}, {paymentToDefault.lastName}
                     </span>
                     <br />
                     {paymentToDefault.address}
                     {paymentToDefault.address2 ? (
                        <>
                           <br />
                           {paymentToDefault.address2}
                        </>
                     ) : null}
                     <br />
                     {paymentToDefault.city}, {paymentToDefault.region}, {paymentToDefault.postal}
                     <br />
                     {`Card ending in ${paymentToDefault.accountNum}`}
                  </Box>
               ) : null}
            </>
         }
         title={'Set Default Payment Method?'}
         confirmBtnText={'Save'}
         cancelBtnText={'Cancel'}
         isLoading={isLoading}
         onConfirm={() => handlePaymentDefault()}
         onCancel={() => onPaymentDefaultClose()}
      />
   );
};

export default DefaultPaymentDialog;
