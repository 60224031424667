import React, { useState } from 'react';
import { Stack, CircularProgress, useMediaQuery, useTheme, SelectChangeEvent, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addCheckoutSettingsAsync, checkoutStore } from '../../stores/checkout.slice';
import { ICompanyAvailableSetting } from 'features/company/types/company.types';
import Select from '@mui/material/Select';
import { companyStore } from 'features/company/stores/company.slice';
import { StyledApplyButton } from './ApplyButton';

interface DropdownProps {
   setting: ICompanyAvailableSetting;
}

const Dropdown = (props: DropdownProps) => {
   const theme = useTheme();
   const dispatch = useAppDispatch();
   const { companyInfo } = useAppSelector(companyStore);
   const { settings } = useAppSelector(checkoutStore);
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const settingsOptions = companyInfo.availableSettings
      ?.find((setting) => {
         return setting.id === props.setting.id;
      })
      ?.options?.map((option) => {
         return { label: option.text, value: option.id };
      });
   const defaultOption = settings?.find((setting) => {
      return setting.id === props.setting.id;
   })?.options?.[0].id;

   const [selectedOption, setSelectedOption] = useState(defaultOption ?? 'none');
   const [isButtonLoading, setButtonLoading] = useState<boolean>(false);

   const handleChange = (event: SelectChangeEvent<string>) => {
      setSelectedOption(event.target.value);
   };

   const handleSubmit = () => {
      setButtonLoading(true);
      dispatch(addCheckoutSettingsAsync({ settingId: props.setting.id, settingValues: [selectedOption] }))
         .then(() => setButtonLoading(false))
         .catch(() => setButtonLoading(false));
   };

   return settingsOptions?.length && settingsOptions.length > 0 ? (
      <>
         <Stack direction={'column'} spacing={2} sx={{ width: '100%' }} alignItems="center">
            <Select fullWidth value={selectedOption} onChange={handleChange}>
               <MenuItem value="none">
                  <em>Please select an option</em>
               </MenuItem>
               {settingsOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                     {option.label}
                  </MenuItem>
               ))}
            </Select>
            <StyledApplyButton
               style={{ marginLeft: 'auto' }}
               sx={{
                  maxHeight: '42.25px',
                  ...(!isMobileScreen && { minWidth: '100px' }),
               }}
               variant="outlined"
               color="inherit"
               onClick={handleSubmit}
               disabled={isButtonLoading || selectedOption === 'none'}
            >
               {isButtonLoading ? <CircularProgress size={14} thickness={8} color="inherit" /> : 'Apply'}
            </StyledApplyButton>
         </Stack>
      </>
   ) : null;
};

export default Dropdown;
