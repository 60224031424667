import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
   Grid,
   Stack,
   ToggleButton,
   ToggleButtonGroup,
   useMediaQuery,
   useTheme,
   SelectChangeEvent,
} from '@mui/material';
import 'features/order/styles/ProductList.scss';
import { CategoryProductMapping } from './types/category-product.types';
import ProductList from './components/ProductList';
import CategoryList from './components/CategoryList';
import { productStore, setSelectedProduct } from './stores/product.slice';
import { menuItemStore, setOpenMenuItem } from 'features/menuItem/stores/menuItem.slice';
import OrderBar from 'features/order-bar/OrderBar';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import MenuItem from 'features/menuItem/MenuItem';
import { useSearchParams } from 'react-router-dom';
import BaseSelect from 'components/BaseSelect';
import { convertProductViewEventData, trackMptEvent } from './utils/event.helper';
import { TrackEventType } from './types/event.types';
import useHeaderSize from 'app/useHeaderSize';
import useScreenSize from 'app/useScreenSize';
import { adjustHeaderTop } from './utils/prduct-list.helper';
import SearchBar from './components/searchBar';
import SkeletonLoader from 'components/SkeletonLoader';
import { buildCardSkeletons } from './config/order.helper';
import { orderLoadStore } from './stores/order-load.slice';
import { SKELETON_LOADING } from './config/order.const';
import { CustomContainer } from './components/CustomContainer';

type Orientation = 'vertical' | 'horizontal' | undefined;

const Order = () => {
   const dispatch = useAppDispatch();
   const theme = useTheme();
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const [headerSize, headerContainerRef, headerMainHeight, setHeaderMainHeight, hasFixedHeader] = useHeaderSize();
   const screenSize = useScreenSize();

   const { cardLoading, searchBarCardLoading } = useAppSelector(orderLoadStore);
   const { productList, productCategoryList } = useAppSelector(productStore);
   const { openMenuItem } = useAppSelector(menuItemStore);

   const [searchParams] = useSearchParams();
   const productId = searchParams.get('viewProduct');
   const viewCategory = searchParams.get('viewCategory');
   const paramsCategoryName = productCategoryList.find((category) => category.id === viewCategory)?.name ?? '';
   const useInitialProductView = () => {
      return useMediaQuery(theme.breakpoints.down('sm')) ? 'list' : 'card';
   };

   const [category, setCategory] = useState('');
   const [searchTerm, setSearchTerm] = useState('');
   const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
   const [isScrolled, setIsScrolled] = useState(false);
   const mainContainerRef = useRef(null);
   const menuContainerRef = useRef(null);
   const categoriesContainerRef = useRef(null);
   const stickyCategoriesRef = useRef<HTMLDivElement>(null);
   const resultDisplayRef = useRef<HTMLDivElement>(null);
   const isProgrammaticScroll = useRef(false);
   const categoryRefs = useRef<{ [key: string]: RefObject<HTMLDivElement> }>({});
   const [productView, setProductView] = React.useState(useInitialProductView());

   const verticalStyle: Orientation = 'vertical';
   const horizontalStyle: Orientation = 'horizontal';
   const [dataToShow, setDataToShow] = useState<CategoryProductMapping>({});
   const [categoryTabValue, setCategoryTabValue] = useState(
      Object.entries(dataToShow).map((categoryMapping) => categoryMapping[0])[0],
   );

   const handleCategoryScroll = () => {
      const headerMain = document.getElementsByClassName(isLargeScreenDown ? 'app-bar' : 'header-main');
      const headerWrapper = document.getElementsByClassName('header-wrapper');

      if (
         headerMain?.length > 0 &&
         headerWrapper?.length > 0 &&
         headerMain[0].clientHeight - headerWrapper[0].clientHeight !== 0
      ) {
         setHeaderMainHeight(headerMain[0].clientHeight - headerWrapper[0].clientHeight);
      }

      const orderHeaderElement = document.getElementById('order-sticky-header');
      if (categoryRefs?.current && orderHeaderElement) {
         const { bottom: orderHeaderBottom } = orderHeaderElement.getBoundingClientRect();
         const resultDisplayHeight = resultDisplayRef?.current?.clientHeight
            ? resultDisplayRef.current.clientHeight
            : 0;
         const orderBarCheckHeight = orderHeaderBottom - 10 + resultDisplayHeight;

         setTimeout(() => {
            Object.keys(categoryRefs.current).forEach((refKey) => {
               if (categoryRefs.current) {
                  const categoryElement = categoryRefs.current[refKey]?.current;

                  if (categoryElement) {
                     const { top: categoryTop, bottom: categoryBottom } = categoryElement.getBoundingClientRect();

                     if (
                        orderBarCheckHeight > categoryTop &&
                        orderBarCheckHeight < categoryBottom &&
                        category !== refKey &&
                        refKey !== SKELETON_LOADING
                     ) {
                        if (!isProgrammaticScroll.current) {
                           setCategory(refKey);
                        }
                     }
                  }
               }
            });
         }, 700);
      }
      return;
   };

   const scrollToCategory = (_event: React.SyntheticEvent | null, categoryName: string) => {
      const categoryElement = document.getElementById(categoryName);
      isProgrammaticScroll.current = true;
      if (categoryElement) {
         const resultDisplayHeight = resultDisplayRef?.current?.clientHeight
            ? resultDisplayRef.current.clientHeight
            : 0;
         window.scrollTo({
            top: categoryElement.offsetTop + 1 - resultDisplayHeight, //TODO need to customize this for different client + (isLargeScreen ? 10 : 0)
            left: 0,
            behavior: 'smooth',
         });
      }
      setTimeout(() => {
         isProgrammaticScroll.current = false;
      }, 700);
      setCategoryTabValue(categoryName);
      setCategory(categoryName);
   };

   const selectDropdownChange = (event: SelectChangeEvent) => {
      scrollToCategory(null, event.target.value);
   };

   const handleViewChange = (_event: React.MouseEvent<HTMLElement>, newView: string) => {
      newView && setProductView(newView);
   };

   useEffect(() => {
      window.addEventListener('scroll', handleCategoryScroll);
      return () => {
         window.removeEventListener('scroll', handleCategoryScroll);
      };
   }, []);

   useEffect(() => {
      adjustHeaderTop(headerContainerRef, isLargeScreenDown);
   }, [screenSize]);

   useEffect(() => {
      adjustHeaderTop(headerContainerRef, isLargeScreenDown);
   }, [headerContainerRef]);

   useEffect(() => {
      const handleScroll = () => {
         const scrollThreshold = 200;
         const currentScrollY = window.scrollY;
         const buffer = 50; // Add a small buffer to prevent rapid toggling

         if (!isScrolled && currentScrollY > scrollThreshold + buffer) {
            setIsScrolled(true);
         } else if (isScrolled && currentScrollY < scrollThreshold - buffer) {
            setIsScrolled(false);
         }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
   }, [isScrolled]);

   useEffect(() => {
      if (productId && productList.length > 0) {
         const selectedProduct = productList.find((product) => product.id === productId);
         selectedProduct && trackMptEvent(TrackEventType.productView, convertProductViewEventData(selectedProduct));
         dispatch(setSelectedProduct(selectedProduct));
         dispatch(setOpenMenuItem(true));
      }
   }, [productId, productList]);

   useEffect(() => {
      const cardSkeletons = buildCardSkeletons(isLargeScreen ? 8 : 6);
      setDataToShow({ skeletonLoading: cardSkeletons });
   }, []);

   useEffect(() => {
      if (document.getElementById(paramsCategoryName)) {
         scrollToCategory(null, paramsCategoryName);
      }
   }, [document.getElementById(paramsCategoryName)]);

   return (
      <CustomContainer maxWidth={false}>
         <Grid
            container
            display="contents"
            sx={{ backgroundColor: theme.palette.background.default }}
            className="menu-container"
            ref={mainContainerRef}
         >
            <Grid
               item
               xs={12}
               id="order-sticky-header"
               className="mpt-sticky-header"
               ref={headerContainerRef}
               justifyContent="flex-end"
               style={{ maxWidth: theme.custom.screen.maxWidth, marginLeft: 'auto', marginRight: 'auto' }}
            >
               <Grid container className="menu-header" spacing={1} alignItems="flex-end">
                  {isLargeScreen ? <Grid width="100%" item sm={12} md={2}></Grid> : null}
                  <Grid
                     width="100%"
                     item
                     sm={12}
                     md={isLargeScreen ? 6 : 6}
                     sx={{ display: 'flex', justifyContent: isLargeScreen ? 'flex-end' : 'center' }}
                  >
                     <OrderBar headerContainerRef={headerContainerRef} />
                  </Grid>

                  <Grid width="100%" item sm={12} md={isLargeScreen ? 4 : 6} display="flex">
                     <SkeletonLoader loading={searchBarCardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
                        <Stack spacing={1} alignItems="center" direction="row" width="100%">
                           <SearchBar
                              searchTerm={searchTerm}
                              setSearchTerm={setSearchTerm}
                              selectedOptions={selectedOptions}
                              setSelectedOptions={setSelectedOptions}
                              setDataToShow={setDataToShow}
                           />
                           <ToggleButtonGroup
                              color="primary"
                              size="small"
                              exclusive
                              value={productView}
                              onChange={handleViewChange}
                           >
                              <ToggleButton value="list" key="left" title="List View">
                                 <ViewHeadlineIcon />
                              </ToggleButton>
                              <ToggleButton value="card" key="center" title="Card View">
                                 <ViewModuleIcon />
                              </ToggleButton>
                           </ToggleButtonGroup>
                        </Stack>
                     </SkeletonLoader>
                  </Grid>
               </Grid>
               {isLargeScreen ? null : (
                  <Grid item xs={12} className="menu-header">
                     {isMobileScreen ? (
                        <Grid
                           sx={{ marginTop: '10px' }}
                           width="100%"
                           item
                           sm={12}
                           md={isLargeScreen ? 4 : 6}
                           display="flex"
                        >
                           <SkeletonLoader
                              loading={cardLoading}
                              skeletonProps={{ width: 'calc(100% - 4px)', height: '57px' }}
                           >
                              <BaseSelect
                                 label="categories"
                                 name={category}
                                 onChange={selectDropdownChange}
                                 options={Object.keys(dataToShow).map((categoryName) => {
                                    return { name: categoryName, id: categoryName, images: [] };
                                 })}
                              />
                           </SkeletonLoader>
                        </Grid>
                     ) : (
                        <Grid item xs={12} ref={stickyCategoriesRef}>
                           <CategoryList
                              dataToShow={dataToShow}
                              setCategory={setCategory}
                              style={horizontalStyle}
                              category={category}
                              categoryTabValue={categoryTabValue}
                              setCategoryTabValue={setCategoryTabValue}
                              scrollToCategory={scrollToCategory}
                              headerContainerRef={headerContainerRef}
                           />
                        </Grid>
                     )}
                  </Grid>
               )}
            </Grid>

            <Grid
               container
               className="product-card-container"
               spacing={2}
               sx={{
                  backgroundColor: theme.palette.background.default,
                  marginLeft: undefined,
                  marginTop: theme.spacing(1),
                  marginBottom: !isLargeScreen ? theme.spacing(12) : theme.spacing(2),
                  top: headerSize?.height
                     ? headerSize.height + (isLargeScreenDown || hasFixedHeader ? headerMainHeight : 0)
                     : 0,
               }}
               ref={menuContainerRef}
            >
               {isLargeScreen ? (
                  <Grid item lg={2} flexDirection={'row-reverse'} display="flex">
                     <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '95%', height: '50vh' }}>
                        <Grid item ref={categoriesContainerRef} position={'fixed'}>
                           <CategoryList
                              dataToShow={dataToShow}
                              setCategory={setCategory}
                              style={verticalStyle}
                              category={category}
                              categoryTabValue={categoryTabValue}
                              setCategoryTabValue={setCategoryTabValue}
                              scrollToCategory={scrollToCategory}
                              headerContainerRef={headerContainerRef}
                           />
                        </Grid>
                     </SkeletonLoader>
                  </Grid>
               ) : null}
               <Grid item xs={12} lg={10} pt={2}>
                  <ProductList
                     {...{
                        dataToShow,
                        searchTerm,
                        selectedOptions,
                        setSelectedOptions,
                        setCategory,
                        productView,
                        categoryRefs,
                        addToCartText: 'Add to Cart',
                        resultDisplayRef,
                     }}
                  />
               </Grid>
            </Grid>
         </Grid>
         <MenuItem open={openMenuItem} />
      </CustomContainer>
   );
};

export default Order;
