import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import { accountStore } from 'features/account/stores/account.slice';
import {
   checkoutShippingStore,
   setAddDeliveryDialogOpen,
   setHasSelectedAddressRuleError,
} from '../../stores/checkout-shipping.slice';
import { IDelivery } from 'features/cart/types/cart.types';
import { OrderType } from 'features/order/config/order.const';
import AddDeliveryAddressLink from './AddDeliveryAddressLink';
import { Alert, Link, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { MISSING_PICKUP_INFO_MSG } from '../../config/checkout-form.const';
import { setPickupDialogOpen } from '../../stores/checkout.slice';

interface IDeliveryNameAddressDisplayProps {
   emptyDeliveryAddress: boolean;
   missingDeliverInformation: boolean;
}

const DeliveryNameAddressDisplay = ({
   emptyDeliveryAddress,
   missingDeliverInformation,
}: IDeliveryNameAddressDisplayProps) => {
   const dispatch = useAppDispatch();
   const { cart } = useAppSelector(cartStore);
   const { shippingProfiles } = useAppSelector(accountStore);
   const { addressRuleError } = useAppSelector(checkoutShippingStore);

   const delivery = cart.deliveries.length ? cart.deliveries[0] : ({} as IDelivery);
   const foundShippingProfile = shippingProfiles?.find((findProfile) => findProfile.id === delivery.shippingProfileId);

   const onAddDeliveryAddressOpen = () => {
      dispatch(setAddDeliveryDialogOpen(true));
   };

   const displayShippingInfo =
      foundShippingProfile && delivery?.shippingLocationType === OrderType.homeDelivery
         ? {
              shippingFirstName: foundShippingProfile?.firstName,
              shippingLastName: foundShippingProfile?.lastName,
              shippingAddress: foundShippingProfile?.address,
              shippingAddress2: foundShippingProfile?.address2,
              shippingCity: foundShippingProfile?.city,
              shippingRegion: foundShippingProfile?.region,
              shippingPostal: foundShippingProfile?.postal,
              shippingPhone: foundShippingProfile?.phone,
              shippingInstructions: foundShippingProfile?.instructions,
              shippingProfileId: foundShippingProfile?.id,
           }
         : delivery;

   const updateSelectedAddressRuleError = () => {
      dispatch(
         setHasSelectedAddressRuleError(
            addressRuleError.filter((filterError) => filterError.id === displayShippingInfo.shippingProfileId).length >
               0,
         ),
      );
   };

   useEffect(() => {
      updateSelectedAddressRuleError();
   }, [addressRuleError]);

   if (emptyDeliveryAddress) {
      return <AddDeliveryAddressLink onOpen={onAddDeliveryAddressOpen} />;
   }

   return (
      <>
         {addressRuleError
            .filter((filterError) => filterError.id === displayShippingInfo.shippingProfileId)
            .map((checkoutError, errorIndex) => (
               <Alert key={`delivery-error-${errorIndex}`} severity="error">
                  {checkoutError.errorMessage}
               </Alert>
            ))}
         {missingDeliverInformation && (
            <Alert key={`pickup-error`} severity="error">
               {MISSING_PICKUP_INFO_MSG}{' '}
               <Link
                  color="inherit"
                  sx={{ marginLeft: '5px', cursor: 'pointer', fontWeight: 600 }}
                  onClick={() => dispatch(setPickupDialogOpen(true))}
               >
                  Update
               </Link>
            </Alert>
         )}
         <Stack direction="row" display="block">
            <Typography variant="body1" fontWeight="bold">
               {displayShippingInfo?.shippingFirstName} {displayShippingInfo?.shippingLastName}
            </Typography>
            <Typography variant="body1">{displayShippingInfo?.shippingAddress}</Typography>
            <Typography variant="body1">{displayShippingInfo?.shippingAddress2}</Typography>
            <Typography variant="body1">
               {displayShippingInfo?.shippingCity}, {displayShippingInfo?.shippingRegion}{' '}
               {displayShippingInfo?.shippingPostal}
            </Typography>
            <Typography variant="body1">{displayShippingInfo?.shippingPhone}</Typography>
         </Stack>
         {displayShippingInfo.shippingInstructions ? (
            <Stack direction="row" display="block">
               <Typography variant="body1" fontWeight="bold">
                  Instructions
               </Typography>
               <Typography variant="body1">{displayShippingInfo?.shippingInstructions}</Typography>
            </Stack>
         ) : null}
      </>
   );
};

export default DeliveryNameAddressDisplay;
