import {
   Badge,
   Box,
   Button,
   ClickAwayListener,
   Fab,
   MenuItem,
   MenuList,
   Paper,
   Popper,
   styled,
   Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';
import React from 'react';
import '../styles/AddToCartBtn.scss';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import clsx from 'clsx';
import { IProduct, IProductVariationOption } from '../types/product.types';
import { IPostAddRemoveCartProductPayload, IPostCartProductPayload } from 'features/cart/types/cart.types';
import { postAddRemoveCartProductAsync, postCartProductAsync, cartStore } from 'features/cart/stores/cart.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { productStore, setQuickAddPopperOpen, setSelectedProduct } from '../stores/product.slice';
import { orderStore } from '../stores/order.slice';
import { convertProductViewEventData, convertUpdateToCartEventData, trackMptEvent } from '../utils/event.helper';
import { TrackEventType, TrackGTagType } from '../types/event.types';
import { isWeeklyOrder } from '../config/order.helper';
import { packageStore } from 'features/package/stores/package.slice';

interface IAddToCartBtnProps {
   itemsInCart: number;
   item: IProduct;
   handleProductSelection: (productId: string) => void;
   numVariations: number;
   addToCartText?: string;
}

interface IAddQuickMenuItemProps {
   option: IProductVariationOption;
   placement: string;
}

const AddToCartBtn = ({
   itemsInCart,
   item,
   addToCartText,
   numVariations,
   handleProductSelection,
}: IAddToCartBtnProps) => {
   const [open, setOpen] = React.useState(false);
   const [selectedOptionId, setSelectedOptionId] = React.useState<string>();
   const anchorRef = React.useRef<HTMLButtonElement>(null);
   const dispatch = useAppDispatch();
   const { productDetail, quickAddPopperOpen } = useAppSelector(productStore);
   const { cart, orderFrequency, postCartStatus, postAddRemoveStatus } = useAppSelector(cartStore);
   const { selectedDeliverySlot } = useAppSelector(orderStore);
   const { packageRecurrence } = useAppSelector(packageStore);

   const StyledIconButton = styled(IconButton)(({ theme }) => ({
      '&': {
         backgroundColor: theme.palette.primary.main,
         color: 'white',
      },
      '&:hover': {
         backgroundColor: theme.palette.primary.dark,
      },
   }));

   const getProductFromVariationOption = (productId: string, variationId: string, optionId: string) => {
      if (cart?.deliveries) {
         return cart?.deliveries
            ?.find((delivery) => {
               return delivery.id === selectedDeliverySlot?.deliveryId;
            })
            ?.products.find((product) => {
               return (
                  product.productId === productId &&
                  product.selectedVariations.length > 0 &&
                  product.selectedVariations[0].id === variationId &&
                  product.selectedVariations[0].options[0].id === optionId
               );
            });
      }
   };

   function AddQuickMenuItem({ option, placement }: IAddQuickMenuItemProps) {
      return (
         <MenuItem>
            <Box
               sx={{
                  marginLeft: placement === 'top-end' ? 'auto' : '0',
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '100%',
               }}
            >
               {placement === 'top-end' || placement === 'bottom-end' ? (
                  <Typography sx={{ fontWeight: 600, marginLeft: '5px', marginRight: '5px' }} variant="h6">
                     {option.text}
                  </Typography>
               ) : null}
               <div className="item-count-nav">
                  <StyledIconButton onClick={() => handleRemoveItem(item.id, item.variations[0].id, option.id)}>
                     {option.id === selectedOptionId && postAddRemoveStatus === 'loading' ? (
                        <CircularProgress size={20} thickness={8} sx={{ color: 'white' }} />
                     ) : (
                        <RemoveIcon className="add-remove-icon" />
                     )}
                  </StyledIconButton>
                  <div className="item-count-frame">
                     <div className="item-count">
                        {getProductFromVariationOption(item.id, item.variations[0].id, option.id)?.qty ?? 0}
                     </div>
                  </div>
                  <StyledIconButton onClick={() => handleAddItem(item.variations[0].id, option.id)}>
                     {option.id === selectedOptionId && postCartStatus === 'loading' ? (
                        <CircularProgress size={20} thickness={8} sx={{ color: 'white' }} />
                     ) : (
                        <AddIcon className="add-remove-icon" />
                     )}
                  </StyledIconButton>
               </div>
               {placement === 'top-start' || placement === 'bottom-start' ? (
                  <Typography
                     sx={{
                        fontWeight: 600,
                        marginLeft: '5px',
                        marginRight: '5px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                     }}
                     variant="h6"
                  >
                     {option.text}
                  </Typography>
               ) : null}
            </Box>
         </MenuItem>
      );
   }

   const handleToggle = () => {
      trackMptEvent(TrackEventType.productView, convertProductViewEventData(item));
      dispatch(setSelectedProduct(item));
      if (numVariations === 0) {
         handleAddItem();
      } else if (numVariations === 1) {
         setOpen((prevOpen) => !prevOpen);
         dispatch(setQuickAddPopperOpen(!quickAddPopperOpen));
      } else {
         handleProductSelection(item.id);
      }
   };

   const handleClose = (event: Event | React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
         return;
      }

      setOpen(false);
      dispatch(setQuickAddPopperOpen(false));
   };

   function handleListKeyDown(event: React.KeyboardEvent) {
      if (event.key === 'Escape') {
         setOpen(false);
         dispatch(setQuickAddPopperOpen(false));
      }
   }

   const handleRemoveItem = (productId: string, variationId: string, optionId: string) => {
      const cartProduct = getProductFromVariationOption(productId, variationId, optionId);
      if (!cartProduct) return;
      const data: IPostAddRemoveCartProductPayload = {
         recordId: cartProduct.id,
         qty: cartProduct.qty - 1,
      };
      trackMptEvent(TrackEventType.removeFromCart, convertUpdateToCartEventData(cartProduct));
      dispatch(postAddRemoveCartProductAsync(data));
      setSelectedOptionId(optionId);
   };

   const handleAddItem = (variationId?: string, optionId?: string) => {
      const variations =
         variationId && optionId
            ? [
                 {
                    id: variationId,
                    options: [optionId],
                 },
              ]
            : [];

      const submitData: IPostCartProductPayload = {
         ...{
            deliveryId: selectedDeliverySlot.deliveryId,
            productId: item.id,
            qty: 1,
            variations: variations,
            addons: item && item.addons ? item.addons : [],
            preferences: [],
            ...(isWeeklyOrder(packageRecurrence, item, orderFrequency) && { recurrence: 'weekly' }),
         },
      };

      dispatch(
         postCartProductAsync({
            productPayLoad: submitData,
            product: productDetail,
            gtagEvent: TrackGTagType.addToCart2,
         }),
      );
      setSelectedOptionId(optionId);
   };

   // return focus to the button when we transitioned from !open -> open
   const prevOpen = React.useRef(open);
   React.useEffect(() => {
      prevOpen.current = open;
   }, [open]);

   return (
      <div className={clsx('product-quick-add-fab', { 'product-quick-add-btn': addToCartText })}>
         {addToCartText ? (
            <Badge badgeContent={itemsInCart} color="success">
               <Button
                  variant="contained"
                  startIcon={
                     item.id === productDetail?.id &&
                     (postCartStatus === 'loading' || postAddRemoveStatus === 'loading') ? (
                        <CircularProgress size={20} thickness={8} color={'inherit'} />
                     ) : (
                        <ShoppingCartIcon />
                     )
                  }
                  ref={anchorRef}
                  onClick={handleToggle}
               >
                  <div>{addToCartText}</div>
               </Button>
            </Badge>
         ) : (
            <Fab
               className="add-cart-fab"
               color="primary"
               aria-label="add"
               size="small"
               ref={anchorRef}
               aria-controls={open ? 'composition-menu' : undefined}
               aria-expanded={open ? 'true' : undefined}
               aria-haspopup="true"
               onClick={handleToggle}
            >
               <Badge badgeContent={itemsInCart} color="success">
                  {item.id === productDetail?.id &&
                  (postCartStatus === 'loading' || postAddRemoveStatus === 'loading') ? (
                     <CircularProgress size={20} thickness={8} color={'inherit'} />
                  ) : (
                     <AddIcon />
                  )}
               </Badge>
            </Fab>
         )}

         <Popper
            role={'main'}
            className="quick-add-popper"
            open={open}
            anchorEl={anchorRef.current}
            placement="top-start"
            transition
            autoFocus={true}
         >
            {({ TransitionProps, placement }) => {
               return (
                  <Grow
                     {...TransitionProps}
                     style={{
                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                     }}
                  >
                     <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                           <Box>
                              <Typography fontWeight="600" margin="auto" textAlign="center" variant="h6">
                                 {item.variations[0].name}
                              </Typography>
                              <MenuList
                                 sx={{ padding: '0px 0px 5px 0px' }}
                                 autoFocusItem={open}
                                 onKeyDown={handleListKeyDown}
                              >
                                 {item.variations[0].options.map((option, optionIdx) => {
                                    return (
                                       <AddQuickMenuItem key={`quick-item-${optionIdx}`} {...{ option, placement }} />
                                    );
                                 })}
                              </MenuList>
                           </Box>
                        </ClickAwayListener>
                     </Paper>
                  </Grow>
               );
            }}
         </Popper>
      </div>
   );
};

export default AddToCartBtn;
