import { Container, styled } from '@mui/material';

export const CustomContainer = styled(Container)(({ theme }) => ({
   maxWidth: theme.custom.screen.maxWidth, // Custom max-width
   width: '100%', // Ensures full width while maintaining max-width
   display: 'flex',
   flexDirection: 'column',
   minHeight: '90vh', // Full viewport height
   justifyContent: 'space-between', // Push content to take full height
   paddingLeft: '0 !important',
   paddingRight: '0 !important',
}));
