import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoadStatus } from 'config/utils';
import { setSystemError } from 'features/company/stores/company.slice';
import { fetchFeatureProducts } from '../services/feature-item.api';
import { IProduct } from 'features/order/types/product.types';

export interface FeatureItemState {
   fetchFeatureProductsStatus: LoadStatus;
   featureProducts: IProduct[];
}

const initialState: FeatureItemState = {
   fetchFeatureProductsStatus: LoadStatus.idle,
   featureProducts: [],
};

export const fetchFeatureProductsAsync = createAsyncThunk(
   'featureItem/fetchFeatureProducts',
   async (_input: {}, { dispatch }) => {
      const result = await fetchFeatureProducts();
      if (!result.success) {
         dispatch(setSystemError(null));
      }
      return result;
   },
);

export const featureItemSlice = createSlice({
   name: 'featureItem',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(fetchFeatureProductsAsync.pending, (state) => {
            state.fetchFeatureProductsStatus = LoadStatus.loading;
         })
         .addCase(fetchFeatureProductsAsync.fulfilled, (state, action) => {
            state.fetchFeatureProductsStatus = LoadStatus.complete;
            state.featureProducts = action.payload.products;
         })
         .addCase(fetchFeatureProductsAsync.rejected, (state) => {
            state.fetchFeatureProductsStatus = LoadStatus.failed;
         });
   },
});

export const {} = featureItemSlice.actions;

export const featureItemStore = (state: RootState) => state.featureItemStore;

export default featureItemSlice.reducer;
