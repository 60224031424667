import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
   addCheckoutSettings,
   addOfferCode,
   deleteOfferCode,
   fetchCheckout,
   postCheckout,
   updateBillingProfile,
   updateTip,
} from '../services/checkout.api';
import { ICheckoutResponse } from '../types/checkout.types';
import { LoadStatus } from 'config/utils';
import { setCart } from 'features/cart/stores/cart.slice';
import {
   setAccountMessages,
   setBillingProfiles,
   setShippingProfiles,
   setUser,
} from 'features/account/stores/account.slice';
import { IDeliveryChangeResponse } from 'features/order/types/order.types';
import { IProduct } from 'features/order/types/product.types';
import { CART_DEFAULT } from 'features/cart/stores/default.const';
import { ErrorCode, IError } from 'components/types/base.types';
import { IMessage } from 'features/account/types/account.types';
import {
   convertCompleteCheckoutEventData,
   convertConversionGAEventData,
   convertPurchaseGAEventData,
   convertPurchasePixelEventData,
   trackGaEvent,
   trackMptEvent,
   trackPixelEvent,
} from 'features/order/utils/event.helper';
import { TrackEventType, TrackGTagType, TrackPixelType } from 'features/order/types/event.types';
import { setCompanyInfo } from 'features/company/stores/company.slice';
import { ICompanyAvailableSetting } from 'features/company/types/company.types';
import { changeCustomModalOpen } from 'components/utils/dialog.helper';
import { setPackageId } from 'features/package/stores/package.slice';
import { updateMPTPackageId } from 'utils/mpt-session.helper';

interface CheckoutState {
   status: LoadStatus;
   checkoutLoadStatus: LoadStatus;
   postCheckStatus: LoadStatus;
   addCheckoutSettingsStatus: LoadStatus;
   updateBillingProfileStatus: LoadStatus;
   addOfferCodeStatus: LoadStatus;
   updateTipStatus: LoadStatus;
   error: IError[];
   offerError: IError[];
   paymentError: IError[];
   deliveryRuleError: IError[];
   cartRuleError: IError[];
   pickupDialogOpen: boolean;
   featuredProducts: IProduct[];
   settings: ICompanyAvailableSetting[];
   completedCartId: string | null;
   checkingOutMessage: string;
}

const initialState: CheckoutState = {
   status: LoadStatus.idle,
   checkoutLoadStatus: LoadStatus.idle,
   postCheckStatus: LoadStatus.idle,
   addCheckoutSettingsStatus: LoadStatus.idle,
   updateBillingProfileStatus: LoadStatus.idle,
   addOfferCodeStatus: LoadStatus.idle,
   updateTipStatus: LoadStatus.idle,
   error: [],
   offerError: [],
   paymentError: [],
   deliveryRuleError: [],
   cartRuleError: [],
   pickupDialogOpen: false,
   featuredProducts: [],
   settings: [],
   completedCartId: '',
   checkingOutMessage: '',
};

const performUpdateBillingProfile = async (input: { billingProfileId: string }, dispatch: any) => {
   const result = await updateBillingProfile(input.billingProfileId);

   if (result?.success) {
      const { billingProfiles, cart } = result;
      cart && dispatch(setCart(cart));
      billingProfiles && dispatch(setBillingProfiles(billingProfiles));
   }

   return result;
};

// Create an async thunk for the checkout process
export const fetchCheckoutAsync = createAsyncThunk('checkout/fetchCheckout', async (undefined, { dispatch }) => {
   const result = await fetchCheckout();

   if (result?.success) {
      const { billingProfiles, shippingProfiles, cart, user, companyInfo } = result;
      dispatch(setUser(user));
      dispatch(setCart(cart));
      dispatch(setCompanyInfo(companyInfo));
      dispatch(setShippingProfiles(shippingProfiles));
      dispatch(setBillingProfiles(billingProfiles));
      dispatch(setAccountMessages(result.messages as IMessage[]));
   }

   return result;
});

export const postCheckoutAsync = createAsyncThunk(
   'checkout/postCheckout',
   async (input: { sessionId: string; activeSubscriptionId?: string }, { dispatch }) => {
      const result = await postCheckout(input.sessionId, input.activeSubscriptionId);

      if (result?.success) {
         const { billingProfiles, shippingProfiles, user } = result;
         trackMptEvent(TrackEventType.completeCheckout, convertCompleteCheckoutEventData(result));
         trackPixelEvent(TrackPixelType.purchase, convertPurchasePixelEventData(result));
         trackGaEvent(TrackGTagType.purchase, convertPurchaseGAEventData(result));
         trackGaEvent(TrackGTagType.conversion, convertConversionGAEventData(result));
         dispatch(setUser(user));
         dispatch(setCart(CART_DEFAULT.cart));
         dispatch(setPackageId(null));
         updateMPTPackageId(null);
         dispatch(setShippingProfiles(shippingProfiles));
         dispatch(setBillingProfiles(billingProfiles));
         dispatch(setAccountMessages(result.messages as IMessage[]));
      }

      dispatch(setCheckingOutMessage(''));
      return result;
   },
);

export const addCheckoutSettingsAsync = createAsyncThunk(
   'checkout/addCheckoutSettings',
   async (input: { settingId: string; settingValues: string[] }) => {
      return await addCheckoutSettings(input.settingId, input.settingValues);
   },
);

export const updateBillingProfileAsync = createAsyncThunk(
   'checkout/updateBillingProfile',
   async (
      input: {
         billingProfileId: string;
      },
      { dispatch },
   ) => performUpdateBillingProfile(input, dispatch),
);

export const addOfferCodeAsync = createAsyncThunk(
   'checkout/addOfferCode',
   async (
      input: {
         code: string;
      },
      { dispatch },
   ) => {
      const result = await addOfferCode(input.code);

      if (result?.success) {
         dispatch(fetchCheckoutAsync());
      }

      return result;
   },
);

export const deleteOfferCodeAsync = createAsyncThunk(
   'checkout/deleteOfferCode',
   async (
      input: {
         code: string;
      },
      { dispatch },
   ) => {
      const result = await deleteOfferCode(input.code);

      if (result?.success) {
         const { cart } = result;
         cart && dispatch(setCart(cart));
      }

      return result;
   },
);

export const updateTipAsync = createAsyncThunk(
   'checkout/updateTip',
   async (
      input: {
         tip: number;
      },
      { dispatch },
   ) => {
      const result = await updateTip(input.tip);

      if (result?.success) {
         const { cart } = result;
         cart && dispatch(setCart(cart));
      }

      return result;
   },
);

const checkoutSlice = createSlice({
   name: 'checkout',
   initialState,
   reducers: {
      checkoutStart(state) {
         state.status = LoadStatus.loading;
      },
      checkoutSuccess(state) {
         state.status = LoadStatus.complete;
      },
      setCheckoutError(state, action: PayloadAction<IError[]>) {
         state.error = action.payload;
      },
      setPickupDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.pickupDialogOpen = action.payload;
      },
      setCheckingOutMessage: (state, action: PayloadAction<string>) => {
         state.checkingOutMessage = action.payload;
      },
      resetCheckoutLoadStatus: (state) => {
         state.checkoutLoadStatus = LoadStatus.idle;
      },
      resetPostCheckoutStatus: (state) => {
         state.postCheckStatus = LoadStatus.idle;
      },
      resetUpdateBillingProfileStatus: (state) => {
         state.updateBillingProfileStatus = LoadStatus.idle;
      },
      resetAddOfferCodeStatus: (state) => {
         state.addOfferCodeStatus = LoadStatus.idle;
      },
      resetUpdateTipStatus: (state) => {
         state.updateTipStatus = LoadStatus.idle;
      },
      resetError: (state) => {
         state.error = [];
      },
      resetOfferError: (state) => {
         state.offerError = [];
      },
      resetDeliveryRuleError: (state) => {
         state.deliveryRuleError = [];
      },
      resetCartRuleError: (state) => {
         if (state.cartRuleError.length > 0) {
            state.cartRuleError = [];
         }
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchCheckoutAsync.pending, (state) => {
            state.checkoutLoadStatus = LoadStatus.loading;
            state.error = [];
         })
         .addCase(fetchCheckoutAsync.fulfilled, (state, action: PayloadAction<ICheckoutResponse>) => {
            state.checkoutLoadStatus = LoadStatus.complete;
            state.settings = action.payload.settings ?? [];
            if (action.payload.success) {
               const { featuredProducts } = action.payload;
               if (featuredProducts) {
                  state.featuredProducts = featuredProducts;
               }
            } else {
               state.checkoutLoadStatus = LoadStatus.failed;
               state.error = action.payload.error;
            }
         })
         .addCase(fetchCheckoutAsync.rejected, (state) => {
            state.checkoutLoadStatus = LoadStatus.failed;
         })
         .addCase(postCheckoutAsync.pending, (state) => {
            state.postCheckStatus = LoadStatus.loading;
            state.paymentError = [];
            state.deliveryRuleError = [];
            state.cartRuleError = [];
         })
         .addCase(postCheckoutAsync.fulfilled, (state, action: PayloadAction<ICheckoutResponse>) => {
            state.postCheckStatus = LoadStatus.complete;

            if (!action.payload.success) {
               state.postCheckStatus = LoadStatus.failed;
               if (action.payload.error) {
                  action.payload.error.forEach((error) => {
                     if (error.errorCode === ErrorCode.PaymentDeclined) {
                        state.paymentError = [...state.paymentError, error];
                     }
                     if (error.errorCode === ErrorCode.InvalidCartViolatedDeliveryRule) {
                        state.deliveryRuleError = [...state.deliveryRuleError, error];
                     }
                     if (error.errorCode === ErrorCode.InvalidCartViolatedCartRule) {
                        state.cartRuleError = [...state.cartRuleError, error];
                     }
                  });
               }
            } else {
               state.completedCartId = action.payload.cart.id;
            }
         })
         .addCase(postCheckoutAsync.rejected, (state) => {
            state.postCheckStatus = LoadStatus.failed;
         })
         .addCase(addCheckoutSettingsAsync.pending, (state) => {
            state.addCheckoutSettingsStatus = LoadStatus.loading;
         })
         .addCase(addCheckoutSettingsAsync.fulfilled, (state) => {
            state.addCheckoutSettingsStatus = LoadStatus.complete;
         })
         .addCase(addCheckoutSettingsAsync.rejected, (state) => {
            state.addCheckoutSettingsStatus = LoadStatus.failed;
         })
         .addCase(updateBillingProfileAsync.pending, (state) => {
            state.updateBillingProfileStatus = LoadStatus.loading;
            state.paymentError = [];
         })
         .addCase(updateBillingProfileAsync.fulfilled, (state, action: PayloadAction<IDeliveryChangeResponse>) => {
            state.updateBillingProfileStatus = LoadStatus.complete;

            if (action.payload.error) {
               state.paymentError = action.payload.error;
            }
         })
         .addCase(updateBillingProfileAsync.rejected, (state) => {
            state.updateBillingProfileStatus = LoadStatus.failed;
         })
         .addCase(deleteOfferCodeAsync.pending, (state) => {
            state.checkoutLoadStatus = LoadStatus.loading;
            state.offerError = [];
         })
         .addCase(deleteOfferCodeAsync.fulfilled, (state, action: PayloadAction<IDeliveryChangeResponse>) => {
            state.checkoutLoadStatus = LoadStatus.complete;

            if (action.payload.error) {
               state.offerError = action.payload.error;
            }
         })
         .addCase(deleteOfferCodeAsync.rejected, (state) => {
            state.checkoutLoadStatus = LoadStatus.failed;
         })
         .addCase(addOfferCodeAsync.pending, (state) => {
            state.addOfferCodeStatus = LoadStatus.loading;
            state.offerError = [];
         })
         .addCase(addOfferCodeAsync.fulfilled, (state, action: PayloadAction<IDeliveryChangeResponse>) => {
            state.addOfferCodeStatus = LoadStatus.complete;

            if (!action.payload.success && action.payload.error) {
               state.offerError = action.payload.error;
            }
         })
         .addCase(addOfferCodeAsync.rejected, (state) => {
            state.addOfferCodeStatus = LoadStatus.failed;
         })
         .addCase(updateTipAsync.pending, (state) => {
            state.updateTipStatus = LoadStatus.loading;
         })
         .addCase(updateTipAsync.fulfilled, (state) => {
            state.updateTipStatus = LoadStatus.complete;
         })
         .addCase(updateTipAsync.rejected, (state) => {
            state.updateTipStatus = LoadStatus.failed;
         });
   },
});

export const {
   setCheckoutError,
   setPickupDialogOpen,
   resetError,
   resetOfferError,
   resetUpdateTipStatus,
   resetCheckoutLoadStatus,
   resetPostCheckoutStatus,
   resetUpdateBillingProfileStatus,
   resetAddOfferCodeStatus,
   resetDeliveryRuleError,
   resetCartRuleError,
   setCheckingOutMessage,
} = checkoutSlice.actions;

export const checkoutStore = (state: RootState) => state.checkoutStore;

export default checkoutSlice.reducer;
