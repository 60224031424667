export interface MainMenuItem {
   id: string | null; // The ID of the menu item (from <li> ID)
   title: string | null; // The text inside the <a> tag
   url: string | null; // The URL from the <a> tag's href
   children: MainMenuItem[]; // Nested menu items
}

export const parseMainMenu = (menuElement: HTMLElement) => {
   const menuItems: MainMenuItem[] = [];
   const listItems = menuElement.querySelectorAll(':scope > li'); // Get direct children <li>

   listItems.forEach((li) => {
      const link = li.querySelector(':scope > a'); // Get direct <a> child
      const subMenu = li.querySelector(':scope > ul'); // Check for nested <ul>

      const menuItem: MainMenuItem = {
         id: li.id || null, // Extract the `id` attribute of the <li>
         title: link ? link.textContent?.trim() || null : null, // Extract the text from the <a>
         url: link ? link.getAttribute('href') : null, // Extract the `href` from the <a>
         children: subMenu ? parseMainMenu(subMenu as HTMLElement) : [], // Recursively process submenus
      };

      if (menuItem?.title && !['logout', 'login', 'account'].includes(menuItem.title.replace(' ', '').toLowerCase())) {
         menuItems.push(menuItem);
      }
   });

   return menuItems;
};
