import React from 'react';
import { Dialog, DialogTitle, DialogContent, Fab, Typography, Alert, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface MessageDialogProps {
   open: boolean;
   onClose: () => void;
   title?: string;
   severity?: 'error' | 'info' | 'success' | 'warning' | null;
   message: string;
}

const MessageDialog: React.FC<MessageDialogProps> = ({ open, onClose, title, severity, message }) => {
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   return (
      <Dialog
         open={open}
         onClose={onClose}
         className="base-dialog"
         fullScreen={isMobileScreen}
         disableScrollLock
         PaperProps={{
            className: 'base-style',
         }}
      >
         <div className="base-dialog-header">
            <DialogTitle className="title">{title}</DialogTitle>
            <Fab size="small" color="primary" onClick={onClose}>
               <CloseIcon />
            </Fab>
         </div>
         <DialogContent
            className="base-dialog-content"
            style={{ display: 'flex', flexDirection: 'column', padding: '0 20px' }}
         >
            {severity ? (
               <Alert severity={severity} sx={{ alignItems: 'center', fontSize: '20px' }}>
                  {message}
               </Alert>
            ) : (
               <Typography variant={'h6'} marginTop={'10px'}>
                  {message}
               </Typography>
            )}
         </DialogContent>
      </Dialog>
   );
};

export default MessageDialog;
