import { ICart } from 'features/cart/types/cart.types';
import { IShippingProfile } from '../types/checkout.types';
import { OrderType } from 'features/order/config/order.const';

export const getHomeDeliveryShippingProfile = (cart: ICart, shippingProfiles: IShippingProfile[]) => {
   // Return early if there are no deliveries or shipping profiles
   if (!cart?.deliveries.length || !shippingProfiles?.length) return null;

   const { shippingLocationType, shippingProfileId, shippingLocationId } = cart.deliveries[0];

   // Check if this is a home delivery
   if (shippingLocationType !== OrderType.homeDelivery) return null;

   // Try to find the current shipping profile in the list
   const foundShippingProfile = shippingProfiles.find((shippingProfile) => shippingProfile.id === shippingProfileId);

   // If the current profile is not found and shippingLocationId is present, update to the default or first profile
   if (!foundShippingProfile && shippingLocationId) {
      // Look for the default shipping profile
      const foundShippingDefault = shippingProfiles.find((shippingProfile) => shippingProfile.default);

      // If a default profile exists, update with it, otherwise use the first available profile
      const shippingProfileToUse = foundShippingDefault ?? shippingProfiles[0];

      return { id: shippingProfileToUse.id, shippingLocationId };
   } else if (foundShippingProfile && shippingLocationId) {
      return { id: foundShippingProfile.id, shippingLocationId };
   }

   console.warn('Could not determine a valid shipping profile for home delivery.');

   return null;
};
