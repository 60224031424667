import React, { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
   Box,
   Button,
   Card,
   CardContent,
   CardMedia,
   DialogActions,
   DialogContent,
   Typography,
   useTheme,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import '../styles/MenuItem.scss';
import { productStore } from 'features/order/stores/product.slice';
import Grid from '@mui/material/Grid';
import { DEFAULT_IMAGE } from 'config/restaurant.const';
import { IProductVariationOption } from '../../order/types/product.types';
import { getOptionImg } from '../utils/menu-item.helper';
import {
   menuItemStore,
   setMenuMode,
   setDetailMenuProductId,
   setSelectorOptionCounts,
   setModeMessage,
} from '../stores/menuItem.slice';
import { IOptionCount } from '../types/menu-item.types';
import { cartStore } from '../../cart/stores/cart.slice';
import { MptLink } from 'components/MptLink';

const SelectorMenu = () => {
   const theme = useTheme();
   const { fields } = useFieldArray({ name: 'variations' });
   const { getValues, setValue } = useFormContext();
   const dispatch = useAppDispatch();
   const { selectorProductList } = useAppSelector(productStore);
   const { selectorOptionCounts, selectorField } = useAppSelector(menuItemStore);
   const { editCartProduct } = useAppSelector(cartStore);

   const getOptionDetail = (option: IProductVariationOption) => {
      return selectorProductList?.find((product) => product.id === option.associations?.[0]?.productId) ?? null;
   };

   const isMaxItemReached = () => {
      return selectorOptionCounts.reduce((acc, option) => acc + option.count, 0) >= selectorField.variationMax;
   };

   const getTotalSelectedCount = () => {
      return selectorOptionCounts.reduce((acc, option) => acc + option.count, 0);
   };

   const getProductOptionCount = (option: IProductVariationOption) => {
      return (
         selectorOptionCounts.find((optionCount) => optionCount.assoProductId === option.associations?.[0]?.productId)
            ?.count ?? 0
      );
   };

   const handleRemoveItem = (option: IProductVariationOption) => {
      let newOptionCounts = [...selectorOptionCounts];
      const foundIndex = newOptionCounts.findIndex((optionCount) => {
         return optionCount.assoProductId === option.associations?.[0]?.productId;
      });
      if (foundIndex >= 0) {
         newOptionCounts[foundIndex] = {
            assoProductId: selectorOptionCounts[foundIndex].assoProductId,
            count: selectorOptionCounts[foundIndex].count - 1,
         };
      }
      dispatch(setSelectorOptionCounts(newOptionCounts));
   };

   const handleAddItem = (option: IProductVariationOption) => {
      if (isMaxItemReached()) {
         return;
      }
      let newOptionCounts = [...selectorOptionCounts];
      const foundIndex = newOptionCounts.findIndex((optionCount) => {
         return optionCount.assoProductId === option.associations?.[0]?.productId;
      });
      if (foundIndex >= 0) {
         newOptionCounts[foundIndex] = {
            assoProductId: selectorOptionCounts[foundIndex].assoProductId,
            count: selectorOptionCounts[foundIndex].count + 1,
         };
      } else {
         newOptionCounts.push({
            assoProductId: option.associations?.[0]?.productId,
            count: 1,
         });
      }
      dispatch(setSelectorOptionCounts(newOptionCounts));
   };

   const handleClearChoices = () => {
      dispatch(setSelectorOptionCounts([]));
   };

   const mapFromSelectedField = () => {
      if (selectorOptionCounts.length <= 0) {
         //To make sure that it is the first initial load of 'selector' mode from the 'main' mode
         const getAssoProductIdFromField = (field: any) => {
            const fieldOptionId = field.option_id[0];
            return field.variation?.options?.find((option: IProductVariationOption) => {
               return option.id === fieldOptionId;
            })?.associations?.[0]?.productId;
         };

         const availableAssoProductIds = selectorField.variationOptions.map((option) => {
            return option.associations?.[0]?.productId;
         });
         const mappedOptionCounts: IOptionCount[] = [];
         fields.forEach((field: any) => {
            const assoProductId = getAssoProductIdFromField(field);
            if (availableAssoProductIds.includes(assoProductId)) {
               const foundIndex = mappedOptionCounts.findIndex((optionCount) => {
                  return optionCount.assoProductId === assoProductId;
               });
               if (foundIndex >= 0) {
                  mappedOptionCounts[foundIndex] = {
                     assoProductId: assoProductId,
                     count: mappedOptionCounts[foundIndex].count + 1,
                  };
               } else {
                  mappedOptionCounts.push({
                     assoProductId: assoProductId,
                     count: 1,
                  });
               }
            }
         });
         dispatch(setSelectorOptionCounts(mappedOptionCounts));
      }
   };

   const handleChooseSelected = () => {
      const getOptionIdFromAssoProductId = (index: number, productId: string) => {
         return getValues(`variations.${index}.variation`)?.options?.find(
            (option: IProductVariationOption) => option.associations?.[0]?.productId === productId,
         )?.id;
      };
      let insertIndex = selectorField.fieldIndex + 1;
      selectorOptionCounts.forEach((optionCount) => {
         for (let i = 0; i < optionCount.count; i++) {
            setValue(`variations.${insertIndex}.option_id`, [
               getOptionIdFromAssoProductId(insertIndex, optionCount.assoProductId),
            ]);
            insertIndex++;
         }
      });
      dispatch(setSelectorOptionCounts([]));
      dispatch(setMenuMode(editCartProduct ? 'edit' : 'main'));
      dispatch(setModeMessage(null));
   };

   const handleProductSelection = (productId: string) => {
      dispatch(setDetailMenuProductId(productId));
      dispatch(setMenuMode('detail'));
      dispatch(setModeMessage(null));
   };

   useEffect(() => {
      mapFromSelectedField();
      dispatch(setModeMessage(`Choose ${selectorField.variationMax} item${selectorField.variationMax > 1 ? 's' : ''}`));
   }, []);

   return (
      <>
         <DialogContent className="menu-item-dialog-content">
            <Grid container>
               <Grid sx={{ padding: '10px 10px 30px 10px' }} container display="flex" spacing={2}>
                  {selectorField.variationOptions.map((option) => {
                     const optionDetail = getOptionDetail(option);
                     return optionDetail ? (
                        <Grid
                           item
                           xs={12}
                           sm={6}
                           md={4}
                           key={option.id}
                           sx={{ backgroundColor: theme.palette.background.default }}
                           className={'menu-container'}
                        >
                           <Box className={'product-card-container'}>
                              <Card
                                 className="product-card"
                                 sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border:
                                       getProductOptionCount(option) > 0
                                          ? `solid 2px ${theme.palette.success.main}`
                                          : '',
                                 }}
                                 style={{ borderRadius: '1rem', cursor: 'pointer' }}
                              >
                                 <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent
                                       sx={{
                                          flex: '1 0 auto',
                                          padding: '10px',
                                          ':last-child': { paddingBottom: '0px' },
                                       }}
                                    >
                                       <div
                                          onClick={() => {
                                             handleProductSelection(option?.associations?.[0].productId);
                                          }}
                                       >
                                          <Typography
                                             sx={{ fontWeight: 600 }}
                                             gutterBottom
                                             variant="subtitle1"
                                             component="div"
                                             className="card-content"
                                          >
                                             {optionDetail.name}
                                          </Typography>
                                          <Typography variant="body2" color="text.secondary" className="card-content">
                                             {optionDetail.specifications?.length
                                                ? optionDetail.specifications
                                                : optionDetail.description}
                                          </Typography>
                                       </div>
                                       <div className="item-count-nav" style={{ marginTop: '15px' }}>
                                          <IconButton
                                             onClick={() => handleRemoveItem(option)}
                                             size={'small'}
                                             className={'item-add-remove-icon'}
                                             disabled={getProductOptionCount(option) <= 0}
                                          >
                                             <RemoveIcon className={'add-remove-icon'} />
                                          </IconButton>
                                          <div className="item-count-frame">
                                             <div className="item-count">{getProductOptionCount(option)}</div>
                                          </div>
                                          <IconButton
                                             onClick={() => handleAddItem(option)}
                                             size={'small'}
                                             className={'item-add-remove-icon'}
                                             disabled={isMaxItemReached()}
                                          >
                                             <AddIcon className={'add-remove-icon'} />
                                          </IconButton>
                                       </div>
                                    </CardContent>
                                 </Box>
                                 <CardMedia
                                    sx={{ width: 128 }}
                                    component="img"
                                    height="140"
                                    image={getOptionImg(option, selectorProductList, 'medium') ?? DEFAULT_IMAGE}
                                    alt={option.text}
                                    onClick={() => {
                                       handleProductSelection(option.associations[0].productId);
                                    }}
                                 />
                              </Card>
                           </Box>
                        </Grid>
                     ) : null;
                  })}
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions className="menu-item-dialog-footer">
            <div style={{ display: 'flex', gap: 10 }}>
               <MptLink sx={{ cursor: 'pointer', alignSelf: 'center' }} onClick={handleClearChoices}>
                  <Typography color={'primary'} fontWeight={500}>
                     Clear choices
                  </Typography>
               </MptLink>
               <Button variant="contained" disabled={!isMaxItemReached()} onClick={handleChooseSelected}>
                  {isMaxItemReached()
                     ? 'Choose Selected Items'
                     : `${getTotalSelectedCount()}/${selectorField.variationMax} Selected`}
               </Button>
            </div>
         </DialogActions>
      </>
   );
};

export default SelectorMenu;
