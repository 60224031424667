import React from 'react';
import 'features/account/styles/Account.scss';
import CommonDialog from 'components/CommonDialog';
import { Box } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { companyStore } from 'features/company/stores/company.slice';

interface ITermsDialogProps {
   termsDialogOpen: boolean;
   setTermsDialogOpen: (open: boolean) => void;
}

const TermsDialog: React.FC<ITermsDialogProps> = ({ termsDialogOpen, setTermsDialogOpen }) => {
   const { companyInfo } = useAppSelector(companyStore);

   function handleClose() {
      if (termsDialogOpen) {
         setTermsDialogOpen(false);
      }
   }

   return (
      <CommonDialog
         open={termsDialogOpen}
         onClose={handleClose}
         title={`${companyInfo.name}'s Terms of Agreement`}
         onSubmit={() => setTermsDialogOpen(false)}
         isLoading={false}
         submitLabel="Close"
      >
         <Box whiteSpace="pre-line">{companyInfo.terms}</Box>
      </CommonDialog>
   );
};

export default TermsDialog;
