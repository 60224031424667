import React, { useEffect } from 'react';
import Subscriptions from '../features/subscription/Subscriptions';
import { handleFooterElementDisplay, handleMainElementDisplay } from '../config/utils';

export default function SubscriptionsPage() {
   useEffect(() => {
      handleMainElementDisplay(false);
      handleFooterElementDisplay(true);
   }, []);

   return <Subscriptions />;
}
