export const COMPANY_STATE_COMPANY_INFO_DEFAULT = {
   name: '',
   allowUsersToCancel: false,
   allowUsersToEdit: false,
   allowUsersToFreeze: false,
   allowAnonymousCheckout: false,
   checkRulesOnShop: false,
   overrideContactForms: false,
   createSubAccountIdentifiers: false,
   hasPackages: false,
   hasHomeDelivery: false,
   multiSelectDeliveryDates: false,
   currency: '',
   country: '',
   region: '',
   availableSettings: [],
   billingSettings: {
      weekBasedBillingDOW: 0,
      paymentMethods: [],
   },
   cutoffs: {
      weeklyLocks: [],
      freezeSettings: {
         maxFreezeLength: '',
         maxFreezeStart: '',
      },
      cancelSettings: {
         noticeRequirement: '',
         maxCancelation: '',
      },
   },
   prohibitedRules: [],
   modifierRules: [],
   homeDeliveryRules: [],
   uiSettings: {
      subscribeAllOnlyMode: true,
      showProductTotalInCart: false,
      autoAddDelivery: false,
      fullProductPages: false,
      hideRemoveDelivery: false,
      textOverrides: {
         displayAddress: '',
         newDeliveryOverride: '',
         allSubscribedDescriptionText: '',
         editDeliveryText: '',
         addDeliveryText: '',
         emptyAddDeliveryText: '',
      },
      allowSubscriptionCartMatch: false,
      hideTip: false,
      hideDeliveryInstructions: false,
      autofitImages: false,
      serverSideShopPage: false,
      preselectCategories: false,
      autoPickFirstPickup: false,
      preventModalRegeneration: false,
      showAdditionalCheckoutButton: false,
      skipSingleTimeSlotSelection: false,
      preventAutoFilterGeneration: false,
      v3: false,
      singleDelivery: false,
   },
};
