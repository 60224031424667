export const FINIX_API = {
   form: { submit: (finixEnv: string, applicationId: string, callback: (err: string, res: any) => void) => {} },
   state: '',
   finixEnv: 'live',
   applicationId: 'APeLbnkceyrnMKMku7NRKbu2',
   options: {
      defaultCountry: 'USA',
      showAddress: true,
      showLabels: true,
      labels: {
         name: 'Cardholder Name',
         address_postal_code: 'Zip Code',
      },
      showPlaceholders: false,
      placeholders: {
         name: 'Cardholder Name',
         address_postal_code: 'Zip Code',
         address_region: 'State',
      },
      hideFields: ['address_region'],
      requiredFields: ['name', 'address_line1', 'address_city', 'address_state', 'address_postal_code'],
      hideErrorMessages: false,
      errorMessages: {},
      styles: {
         default: {
            color: '#000',
            border: '1px solid #CCCDCF',
            borderRadius: '8px',
            padding: '8px 16px',
            fontFamily: 'Helvetica',
            fontSize: '16px',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03)',
            width: 'calc(100% - 5px)',
         },
         success: {},
         error: {
            border: '1px solid rgba(255,0,0, 0.3)',
         },
      },
      onLoad: function () {
         console.log('done loading form fields');
      },
      onSubmit: () => {},
      submitLabel: 'Submit',
   },
};
