import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import 'features/account/styles/Account.scss';
import { IShippingProfile } from 'features/checkout/types/checkout.types';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';

import { COUNTRIES, US_AND_CANADA_REGIONS } from 'config/regions.const';
import { useAppSelector } from 'app/hooks';
import { companyStore } from 'features/company/stores/company.slice';
import { accountStore } from 'features/account/stores/account.slice';

interface IEditAddressDialogContentProps {
   editAddressId: string;
}

const EditAddressDialogContent: React.FC<IEditAddressDialogContentProps> = ({ editAddressId }) => {
   const { companyInfo } = useAppSelector(companyStore);
   const { shippingProfiles } = useAppSelector(accountStore);
   const regionList = US_AND_CANADA_REGIONS.filter((region) => region.country === companyInfo.currency).map(
      (region) => ({ name: region.name, value: region.value }),
   );
   const countryList = COUNTRIES.map((country) => {
      return {
         name: country.name,
         value: country.value,
      };
   });

   const { setValue } = useFormContext();

   useEffect(() => {
      const profileToEdit = shippingProfiles.find((shippingProfile) => shippingProfile.id === editAddressId);
      if (profileToEdit) {
         Object.keys(profileToEdit).forEach((key) => {
            setValue(key as keyof IShippingProfile, profileToEdit[key as keyof IShippingProfile]);
         });
      }
   }, [editAddressId, setValue, shippingProfiles]);

   return (
      <Box sx={{ p: 0, mt: 1, mb: 1 }}>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
               <FormInput name="firstName" label="First Name" autoComplete="given-name" />
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormInput name="lastName" label="Last Name" autoComplete="family-name" />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="address" label="Address Line 1" autoComplete="shipping address-line1" />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="address2" label="Address Line 2" autoComplete="shipping address-line2" />
            </Grid>
            <Grid item xs={12}>
               <FormSelect name="country" label="Country" autoComplete="shipping country" menuItems={countryList} />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="city" label="City" autoComplete="shipping address-level2" />
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormSelect
                  name="region"
                  label="State/Province/Region"
                  autoComplete="shipping address-level1"
                  menuItems={regionList}
               />
            </Grid>
            <Grid item xs={12} sm={6}>
               <FormInput name="postal" label="Zip / Postal Code" autoComplete="shipping postal-code" />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="phone" label="Phone" autoComplete="tel" />
            </Grid>
            <Grid item xs={12}>
               <FormInput name="instructions" label="Delivery Instructions" multiline={true} />
            </Grid>
         </Grid>
      </Box>
   );
};

export default EditAddressDialogContent;
