import { styled } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface StyledIconProps {
   checkoutMode?: boolean;
}

export const StyledAddCircleIcon = styled(AddCircleIcon, {
   shouldForwardProp: (prop) => prop !== 'checkoutMode',
})<StyledIconProps>(({ theme, checkoutMode }) => ({
   '&': {
      color: `${checkoutMode ? 'rgba(0, 0, 0, 0.6)' : theme.palette.primary.main} !important`,
   },
   '&:hover': {
      color: `${checkoutMode ? 'rgba(0, 0, 0, 0.8)' : theme.palette.primary.dark} !important`,
   },
}));

export const StyledDeleteIcon = styled(DeleteIcon, {
   shouldForwardProp: (prop) => prop !== 'checkoutMode',
})<StyledIconProps>(({ theme, checkoutMode }) => ({
   '&': {
      color: `${checkoutMode ? 'rgba(0, 0, 0, 0.6)' : theme.palette.primary.main} !important`,
   },
   '&:hover': {
      color: `${checkoutMode ? 'rgba(0, 0, 0, 0.8)' : theme.palette.primary.dark} !important`,
   },
}));

export const StyledRemoveCircleIcon = styled(RemoveCircleIcon, {
   shouldForwardProp: (prop) => prop !== 'checkoutMode',
})<StyledIconProps>(({ theme, checkoutMode }) => ({
   '&': {
      color: `${checkoutMode ? 'rgba(0, 0, 0, 0.6)' : theme.palette.primary.main} !important`,
   },
   '&:hover': {
      color: `${checkoutMode ? 'rgba(0, 0, 0, 0.8)' : theme.palette.primary.dark} !important`,
   },
}));
