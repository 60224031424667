import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ISelectorField } from 'features/order/types/product.types';
import { IOptionCount } from '../types/menu-item.types';
import { LoadStatus } from 'config/utils';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

export interface MenuItemState {
   status: LoadStatus;
   menuMode: 'main' | 'selector' | 'detail' | 'edit';
   openMenuItem: boolean;
   selectorField: ISelectorField;
   selectorOptionCounts: IOptionCount[];
   detailMenuProductId: string | null;
   modeMessage: string | null;
}

const initialState: MenuItemState = {
   status: LoadStatus.idle,
   menuMode: 'main',
   openMenuItem: false,
   selectorField: {
      variationOptions: [],
      variationMax: 0,
      fieldIndex: 0,
   },
   selectorOptionCounts: [],
   detailMenuProductId: null,
   modeMessage: null,
};

export const menuItemSlice = createSlice({
   name: 'menuItem',
   initialState,
   reducers: {
      setOpenMenuItem: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.openMenuItem = action.payload;
      },
      setMenuMode: (state, action: PayloadAction<'main' | 'selector' | 'detail' | 'edit'>) => {
         state.menuMode = action.payload;
      },
      setSelectorField: (state, action: PayloadAction<ISelectorField>) => {
         state.selectorField = action.payload;
      },
      setDetailMenuProductId: (state, action: PayloadAction<string | null>) => {
         state.detailMenuProductId = action.payload;
      },
      setSelectorOptionCounts: (state, action: PayloadAction<IOptionCount[]>) => {
         state.selectorOptionCounts = action.payload;
      },
      setModeMessage: (state, action: PayloadAction<string | null>) => {
         state.modeMessage = action.payload;
      },
   },
   extraReducers: (builder) => {},
});

export const {
   setOpenMenuItem,
   setMenuMode,
   setSelectorField,
   setDetailMenuProductId,
   setSelectorOptionCounts,
   setModeMessage,
} = menuItemSlice.actions;

export const menuItemStore = (state: RootState) => state.menuItemStore;

export default menuItemSlice.reducer;
