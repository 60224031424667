import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Box, Button, Typography } from '@mui/material';
import { accountStore, submitPasswordResetAsync } from 'features/account/stores/account.slice';
import 'features/account/styles/Account.scss';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadStatus } from 'config/utils';
import { loadMPTSession } from 'utils/mpt-session.helper';
import ProfileHeader from './ProfileHeader';

const PasswordReset = () => {
   const loadedMptSession = loadMPTSession();
   const { user, passwordResetStatus } = useAppSelector(accountStore);
   const dispatch = useAppDispatch();
   const isPasswordResetLoading = passwordResetStatus === LoadStatus.loading;

   const onSubmit = () => {
      dispatch(
         submitPasswordResetAsync({
            bodyInput: {
               ip: loadedMptSession.ip,
               username: user.email,
            },
         }),
      );
   };

   return (
      <>
         <ProfileHeader headerTitle="Reset Password" />
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Typography variant="h6" gutterBottom mb={2}>
               Do you wish to reset your password? This will send you an email with instructions on how to set a new
               one.
            </Typography>
            <Typography variant="inherit" gutterBottom mb={4}>
               {user.email}
            </Typography>
            <Button variant="contained" size="large" disabled={isPasswordResetLoading} onClick={onSubmit}>
               {isPasswordResetLoading ? (
                  <CircularProgress size={14} thickness={8} color={'inherit'} />
               ) : (
                  'Reset My Password'
               )}
            </Button>
         </Box>
      </>
   );
};

export default PasswordReset;
