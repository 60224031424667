import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MptLink } from 'components/MptLink';

interface IProfileHeaderProps {
   headerTitle?: string;
   paddingBottom?: number;
   linkText?: string;
   navigatePath?: string;
}
const ProfileHeader = ({ headerTitle, paddingBottom, linkText, navigatePath }: IProfileHeaderProps) => {
   const navigate = useNavigate();

   return (
      <Stack
         direction="row"
         justifyContent="space-between"
         alignItems="center"
         pb={paddingBottom !== undefined ? paddingBottom : 2}
      >
         <Typography variant="h6">{headerTitle}</Typography>
         <MptLink onClick={() => navigate(navigatePath ? navigatePath : '/order')}>
            {linkText ? linkText : 'Back to menu'}
         </MptLink>
      </Stack>
   );
};

export default ProfileHeader;
