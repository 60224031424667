import { apiServerUrl } from 'config/server';
import { IShopTasksResponse, ITasksCheckResponse } from '../types/order.types';
import { expandApiServerHeader, prepareTokenPayload } from 'utils/mpt-session.helper';

const shopTasksUrl = `/products/shoptasks`;
const allProductsUrl = `/products/all`;
const taskCheckUrl = `/tasks/check`;

const prodUrls = {
   fetchShopTasks: `${apiServerUrl}${shopTasksUrl}`,
   fetchAllProducts: `${apiServerUrl}${allProductsUrl}`,
   fetchTaskCheck: `${apiServerUrl}${taskCheckUrl}`,
};

export function fetchShopTasks(
   deliveryId: string | null = null,
   packageId: string | null = null,
   productIds: string | null = null,
   filters: string | null = null,
   activateToken: string | null = null,
) {
   return new Promise<IShopTasksResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               deliveryId,
               packageId,
               productIds,
               filters,
               activateToken,
            }),
         ),
      };

      fetch(
         `${prodUrls.fetchShopTasks}${activateToken ? `?activateToken=${activateToken}` : ''}`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: IShopTasksResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchSelectorProductList() {
   return new Promise<IShopTasksResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(`${prodUrls.fetchAllProducts}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IShopTasksResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchTasksCheck(taskId: string) {
   return new Promise<ITasksCheckResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      fetch(`${prodUrls.fetchTaskCheck}/${taskId}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ITasksCheckResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
