import { IPackage } from '../types/package.types';

export const getPackageRecurrence = (mptPackage: IPackage) => {
   if (mptPackage.oneTime) {
      return 'onetime';
   } else if (mptPackage.weekly) {
      return 'weekly';
   }

   return 'onetime';
};
