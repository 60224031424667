import { COMPANY_STATE_COMPANY_INFO_DEFAULT } from '../../company/stores/default.const';
import { ICart } from '../../cart/types/cart.types';
import { ICompanyInfo } from '../../company/types/company.types';

export const SHOP_TASKS_RESPONSE_DEFAULT = {
   success: false,
   filters: {
      recurrenceTask: '',
      categoriesTask: '',
      tagsTask: '',
      groupsTask: '',
   },
   products: [],
   productSummary: {
      productNames: [],
      productCount: 0,
   },
   cart: {
      id: '',
      isSubscription: false,
      status: '',
      creationDate: '',
      lastUpdate: '',
      clientCartId: '',
      isValid: false,
      isEmpty: false,
      hasLeasesOrLoans: false,
      needsApproval: false,
      deliveries: [],
      totals: [],
   },
   companyInfo: COMPANY_STATE_COMPANY_INFO_DEFAULT,
};

export const DELIVERY_DATES_RESPONSE_DEFAULT = {
   success: false,
   hasDelSelectionCountLimits: false,
   immediatePickup: false,
   companyInfo: COMPANY_STATE_COMPANY_INFO_DEFAULT,
   deliveryOptions: [],
};

export const DELIVERY_CHANGE_RESPONSE_DEFAULT = {
   success: false,
   cart: {
      id: '',
      isSubscription: false,
      status: '',
      creationDate: '',
      lastUpdate: '',
      clientCartId: '',
      isValid: false,
      isEmpty: false,
      hasLeasesOrLoans: false,
      needsApproval: false,
      deliveries: [],
      totals: [],
   },
   companyInfo: COMPANY_STATE_COMPANY_INFO_DEFAULT,
   messages: [],
   clientCartId: '',
   newDeliveryIds: [],
};
