import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { Box, Card, CardContent, Grid, Pagination, Typography } from '@mui/material';
import { accountStore } from 'features/account/stores/account.slice';
import 'features/account/styles/Account.scss';
import { SectionCard, SectionCardContent, SectionCardHeader } from '../../checkout/components/SectionCard';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { formatter } from '../../menuItem/utils/menu-item.const';
import ProfileHeader from './ProfileHeader';

const TRANSACTIONS_PER_PAGE = 5;

const Rewards = () => {
   dayjs.extend(localizedFormat);
   const { rewards } = useAppSelector(accountStore);
   const [numPages, setNumPages] = useState(1);
   const [currentPage, setCurrentPage] = useState(1);

   const ACCOUNT_SUMMARY_FIELDS = [
      { label: 'Account Email', value: rewards?.account?.data?.email },
      { label: 'Created On', value: dayjs(rewards?.account?.data?.created_at).format('LL') },
      { label: 'Account Active', value: rewards?.account?.data?.enabled ? 'Yes' : 'No' },
      { label: 'Points', value: rewards?.account?.included?.balance?.points },
      { label: 'Gift Card', value: rewards?.account?.included?.balance?.giftcard },
      { label: 'Tier Level', value: rewards?.account?.included?.tier_level?.name },
   ];

   const getTransactionFields = (transaction: any) => {
      return [
         { label: 'Name', value: transaction.name },
         { label: 'Points', value: transaction.points },
         { label: 'Amount', value: formatter.format(transaction.amount) },
      ];
   };

   const getAccountSummary = () => {
      return ACCOUNT_SUMMARY_FIELDS.map((field) => {
         return (
            <div key={field.label} className={'rewards-account-summary'}>
               <Typography variant={'subtitle1'} fontWeight="bold" mr={2} textAlign={'right'} width={'20%'}>
                  {field.label}
               </Typography>
               <Typography variant={'inherit'} fontWeight="bold">
                  {field.value}
               </Typography>
            </div>
         );
      });
   };

   const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
   };

   useEffect(() => {
      if (rewards?.transactions?.data?.length) {
         setNumPages(Math.ceil(rewards?.transactions?.data?.length / TRANSACTIONS_PER_PAGE));
      } else {
         setNumPages(1);
      }
   }, [rewards]);

   return (
      <>
         <ProfileHeader headerTitle="Your Rewards" />
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' }}>
            <div style={{ width: '100%', marginBottom: '2rem' }}>
               <SectionCard>
                  <SectionCardHeader
                     title={
                        <Grid item xs={12}>
                           <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                              Account Summary
                           </Typography>
                        </Grid>
                     }
                  ></SectionCardHeader>
                  <SectionCardContent>
                     <Grid container>
                        <Grid item xs={12}>
                           <div style={{ display: 'flex', flexDirection: 'column' }}>{getAccountSummary()}</div>
                        </Grid>
                     </Grid>
                  </SectionCardContent>
               </SectionCard>
            </div>
            <div style={{ width: '100%' }}>
               <SectionCard>
                  <SectionCardHeader
                     title={
                        <Grid item xs={12}>
                           <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                              Transactions
                           </Typography>
                        </Grid>
                     }
                  ></SectionCardHeader>
                  <SectionCardContent>
                     <Pagination
                        count={numPages}
                        shape="rounded"
                        size={'large'}
                        page={currentPage}
                        showFirstButton
                        showLastButton
                        onChange={handlePageChange}
                     />
                     {rewards?.transactions?.data?.length ? (
                        rewards.transactions.data
                           .filter((_transaction, index) => {
                              return (
                                 index >= TRANSACTIONS_PER_PAGE * (currentPage - 1) &&
                                 index < TRANSACTIONS_PER_PAGE * currentPage
                              );
                           })
                           .map((transaction) => {
                              return (
                                 <Card key={transaction.id} className="subscription-summary" variant="outlined">
                                    <CardContent className="section-header">
                                       <Grid container>
                                          <Grid xs={12} md={6} item className={'dates-container'}>
                                             <Box marginRight={'25px'} marginBottom={'10px'}>
                                                <Typography variant={'h5'}>Updated On</Typography>
                                                <Typography variant={'h6'}>
                                                   {dayjs(transaction.created_at).format('ll')}
                                                </Typography>
                                             </Box>
                                          </Grid>
                                       </Grid>
                                    </CardContent>
                                    <CardContent className="section-content">
                                       <Grid container>
                                          <Grid xs={12} md={9} item>
                                             <div>
                                                {getTransactionFields(transaction).map((field) => {
                                                   return (
                                                      <div
                                                         key={field.label}
                                                         className={'rewards-account-summary'}
                                                         style={{ display: 'flex', flexDirection: 'row' }}
                                                      >
                                                         <Typography
                                                            variant={'subtitle1'}
                                                            fontWeight="bold"
                                                            mr={2}
                                                            textAlign={'right'}
                                                            width={'20%'}
                                                         >
                                                            {field.label}
                                                         </Typography>
                                                         <Typography variant={'body1'} fontWeight="bold">
                                                            {field.value}
                                                         </Typography>
                                                      </div>
                                                   );
                                                })}
                                             </div>
                                          </Grid>
                                       </Grid>
                                    </CardContent>
                                 </Card>
                              );
                           })
                     ) : (
                        <Typography>No records</Typography>
                     )}
                     <Pagination
                        count={numPages}
                        shape="rounded"
                        size={'large'}
                        page={currentPage}
                        showFirstButton
                        showLastButton
                        onChange={handlePageChange}
                     />
                  </SectionCardContent>
               </SectionCard>
            </div>
         </Box>
      </>
   );
};

export default Rewards;
