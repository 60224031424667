import React, { useEffect } from 'react';
import { Box, Grid, SelectChangeEvent, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { accountStore, submitLogoutAsync } from '../../account/stores/account.slice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import BaseSelect from '../../../components/BaseSelect';
import { IProfileCategory } from '../types/profile.types';
import ProfileTabPopUp from './ProfileTabPopUp';
import { profileStore, setNavListIndex } from '../stores/profile.slice';

const NavList = () => {
   const theme = useTheme();
   const location = useLocation();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
   const { referralCode, rewards } = useAppSelector(accountStore);
   const { navListIndex } = useAppSelector(profileStore);
   const dispatch = useAppDispatch();
   const navigate = useNavigate();

   const PROFILE_CATEGORY_LIST: IProfileCategory[] = [
      { value: 0, name: 'Account Info', route: 'info' },
      { value: 4, name: 'Your Orders', route: 'orders' },
      { value: 1, name: 'Your Addresses', route: 'addresses' },
      { value: 5, name: 'Payment Options', route: 'payments' },
      { value: 3, name: 'Your Subscriptions', route: 'subscriptions' },
      { value: 7, name: 'Referral Code', route: 'referral' },
      { value: 8, name: 'Rewards', route: 'rewards' },
      { value: 2, name: 'Reset Password', route: 'reset' },
      { value: 6, name: 'Sign Out', route: 'signout' },
   ];

   const filteredProfileCategoryList = PROFILE_CATEGORY_LIST.filter((category) => {
      return (
         ![7, 8].includes(category.value) ||
         (category.value === 7 && referralCode?.code) ||
         (category.value === 8 && rewards?.account)
      );
   });

   const getCategoryNameFromValue = (value: number) => {
      return (
         filteredProfileCategoryList.find((category) => {
            return category.value === value;
         })?.name ?? ''
      );
   };

   const getCategoryValueFromName = (name: string) => {
      return (
         filteredProfileCategoryList.find((category) => {
            return category.name === name;
         })?.value ?? 0
      );
   };

   const selectDropdownChange = (event: SelectChangeEvent) => {
      handleListItemClick(null, getCategoryValueFromName(event.target.value));
   };

   const handleListItemClick = (event: React.SyntheticEvent | null, index: number) => {
      const foundProfileCategory = PROFILE_CATEGORY_LIST.find((profileCategory) => profileCategory.value === index);
      if (foundProfileCategory) {
         window.location.hash = foundProfileCategory.route;
      }
      dispatch(setNavListIndex(index));
   };

   const signOutProfile = () => {
      dispatch(
         submitLogoutAsync({
            bodyInput: {
               allSessions: true,
            },
         }),
      );
      navigate('/order');
   };

   const getTabsList = (orientation: 'vertical' | 'horizontal') => {
      return (
         <Box className={`${orientation === 'horizontal' ? 'tab-horizontal' : ''}`}>
            <Tabs
               value={navListIndex}
               orientation={orientation}
               scrollButtons={orientation === 'horizontal' ? 'auto' : false}
               variant="scrollable"
               onChange={handleListItemClick}
            >
               {filteredProfileCategoryList.map((category) => {
                  return <Tab key={category.value} value={category.value} label={category.name} />;
               })}
            </Tabs>
         </Box>
      );
   };

   useEffect(() => {
      if (navListIndex === 6) {
         signOutProfile();
      }
   }, [navListIndex]);

   useEffect(() => {
      if (location.hash) {
         const currentHashRoute = location.hash.replace('#', '') || '';
         const foundProfileCategory = PROFILE_CATEGORY_LIST.find(
            (profileCategory) => profileCategory.route === currentHashRoute,
         );
         if (foundProfileCategory) {
            dispatch(setNavListIndex(foundProfileCategory.value));
         }
      }
   }, [location]);

   return (
      <>
         {!isMobileScreen ? (
            <div className={`${isLargeScreen ? 'categories-container' : 'categories-sticky-top'}`}>
               <Box display="flex" flexDirection="row">
                  {!isLargeScreen ? (
                     <div className="list-icon">
                        <ProfileTabPopUp tabsList={getTabsList('vertical')}></ProfileTabPopUp>
                     </div>
                  ) : null}
                  {getTabsList(isLargeScreen ? 'vertical' : 'horizontal')}
               </Box>
            </div>
         ) : (
            <Grid
               sx={{ marginTop: '10px', paddingLeft: 1, paddingRight: 1 }}
               width="100%"
               item
               sm={12}
               md={isLargeScreen ? 4 : 6}
               display="flex"
            >
               <BaseSelect
                  label="categories"
                  name={getCategoryNameFromValue(navListIndex)}
                  onChange={selectDropdownChange}
                  options={filteredProfileCategoryList.map((category) => {
                     return category;
                  })}
               />
            </Grid>
         )}
      </>
   );
};

export default NavList;
