import React, { useEffect, useMemo, useState } from 'react';
import {
   AppBar,
   Backdrop,
   Badge,
   Box,
   Collapse,
   Fab,
   Link,
   List,
   ListItem,
   ListItemButton,
   ListItemText,
   Stack,
   Toolbar,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import './styles/Appbar/Appbar.scss';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import { companyStore, setAppbarSmall } from 'features/company/stores/company.slice';
import MenuIcon from '@mui/icons-material/Menu';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadStatus } from '../config/utils';
import NotificationButton from './NotificationButton';
import ProfileButton from './ProfileButton';
import { getCartItemCount } from 'features/cart/utils/cart.helper';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { MainMenuItem, parseMainMenu } from './utils/appbar.helper';
import { DrawerContainer, DrawerHeader } from 'features/cart/components/CartDrawer';
import CloseIcon from '@mui/icons-material/Close';
import { accountStore } from 'features/account/stores/account.slice';

interface IAppbarProps {
   handleCartToggle: () => void;
}

const Appbar: React.FC<IAppbarProps> = ({ handleCartToggle }) => {
   const theme = useTheme();
   const dispatch = useAppDispatch();
   const { cart, postCartStatus, postAddRemoveStatus } = useAppSelector(cartStore);
   const { appbarSmall, companyInfo } = useAppSelector(companyStore);
   const { accountMessages, loginMessages } = useAppSelector(accountStore);
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
   const [mainMenuItems, setMainMenuItems] = useState<MainMenuItem[]>([]);

   const filteredMessages = useMemo(() => {
      const combinedMessages = [...(accountMessages ?? []), ...(loginMessages ?? [])];
      return combinedMessages.filter(
         (message, index, self) => self.findIndex((m) => JSON.stringify(m) === JSON.stringify(message)) === index,
      );
   }, [accountMessages, loginMessages]);

   const toggleDrawer = () => {
      setIsDrawerOpen(!isDrawerOpen);
   };

   const toggleExpand = (id: string | null) => {
      if (!id) return;
      setExpandedItems((prev) => ({ ...prev, [id]: !prev[id] }));
   };

   const renderMenuItems = (items: MainMenuItem[], level: number = 0) => {
      return items.map((item) => (
         <div key={item.id || item.title}>
            <ListItem disablePadding>
               <ListItemButton sx={{ pl: 2 + level * 2 }}>
                  {' '}
                  <a
                     href={item.url || '#'}
                     style={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}
                     onClick={(e) => {
                        if (item.children.length) {
                           e.stopPropagation(); // Prevent triggering parent events when clicking a link
                        }
                     }}
                  >
                     <ListItemText primary={item.title} />
                  </a>
                  {item.children.length > 0 && (
                     <IconButton
                        edge="end"
                        size="small"
                        onClick={(e) => {
                           e.stopPropagation(); // Prevent link navigation
                           toggleExpand(item.id);
                        }}
                     >
                        {expandedItems[item.id || ''] ? <ExpandLess /> : <ExpandMore />}
                     </IconButton>
                  )}
               </ListItemButton>
            </ListItem>
            {item.children.length > 0 && (
               <Collapse in={expandedItems[item.id || '']} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                     {renderMenuItems(item.children, level + 1)}
                  </List>
               </Collapse>
            )}
         </div>
      ));
   };

   useEffect(() => {
      if (typeof window !== 'undefined') {
         const wpMenuItems = document.querySelector('.mobile-nav-wrap .menu-wrap .mobile-menu') as HTMLElement;

         if (wpMenuItems) {
            const menuObject: MainMenuItem[] = parseMainMenu(wpMenuItems);
            setMainMenuItems(menuObject);
         }

         window.addEventListener('scroll', () => dispatch(setAppbarSmall(window.pageYOffset > 200)));
      }
   }, []);

   return (
      <>
         <AppBar
            position="fixed"
            color="transparent"
            className="app-bar"
            sx={{
               backgroundColor: isLargeScreenDown ? theme.palette.common.white : 'transparent',
               zIndex: (theme) => theme.zIndex.drawer + 1,
               width: isLargeScreenDown ? '100%' : 'auto',
               right: 0,
               left: 'auto',
            }}
         >
            <Toolbar>
               {isLargeScreenDown && (
                  <>
                     <Stack className="mobile-menu-stack" direction="row">
                        <IconButton
                           size="large"
                           aria-controls="menu-appbar"
                           aria-haspopup="true"
                           color="inherit"
                           onClick={() => toggleDrawer()}
                        >
                           <MenuIcon />
                        </IconButton>
                     </Stack>
                     <Stack
                        className={clsx('app-logo', { 'app-small-logo': appbarSmall })}
                        direction="row"
                        spacing={3}
                        sx={{ flexGrow: 1 }}
                     >
                        <Link onClick={() => (window.location.href = '/')}>
                           <Box className="logo-img" display="flex" alignItems="center" justifyContent="center">
                              <img src={companyInfo.logo} alt="" />
                           </Box>
                        </Link>
                     </Stack>
                  </>
               )}

               <Stack
                  direction="row"
                  sx={{ flexGrow: 0 }}
                  display="flex"
                  spacing={1}
                  ml={'auto'}
                  mr={0.5}
                  pt={2}
                  pb={2}
                  pl={isMobileScreen ? 0 : 1}
                  pr={isMobileScreen ? 0 : 1}
                  alignItems="right"
               >
                  {filteredMessages.length > 0 && <NotificationButton filteredMessages={filteredMessages} />}
                  <ProfileButton />
                  <Fab size="medium" className="cart-btn" color="primary" onClick={() => handleCartToggle()}>
                     <Badge badgeContent={getCartItemCount(cart)} color="success">
                        {postCartStatus === LoadStatus.loading || postAddRemoveStatus === LoadStatus.loading ? (
                           <CircularProgress size={20} thickness={8} color={'inherit'} />
                        ) : (
                           <ShoppingCartIcon />
                        )}
                     </Badge>
                  </Fab>
               </Stack>
            </Toolbar>
         </AppBar>
         {isDrawerOpen && (
            <Backdrop
               open={isDrawerOpen}
               onClick={() => setIsDrawerOpen(false)}
               sx={{ zIndex: (theme) => theme.zIndex.drawer - 1, backgroundColor: 'rgba(0, 0, 0, 0)' }}
            />
         )}
         <DrawerContainer variant="persistent" anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
            <DrawerHeader appbarSmall={appbarSmall}>
               <Stack spacing={2} alignItems="flex-end" direction="column" width="100%" p={1}>
                  <Fab size="small" color="primary" onClick={() => toggleDrawer()}>
                     <CloseIcon />
                  </Fab>
               </Stack>
            </DrawerHeader>
            <Box sx={{ minWidth: 250 }} role="presentation">
               <List>{renderMenuItems(mainMenuItems)}</List>
            </Box>
         </DrawerContainer>
      </>
   );
};

export default Appbar;
