import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IBillingProfile } from '../types/checkout.types';
import { IError } from 'components/types/base.types';
import { changeCustomModalOpen } from '../../../components/utils/dialog.helper';

interface CheckoutPaymentState {
   paymentDialogOpen: boolean;
   paymentError: IError[];
   deletePaymentDialogOpen: boolean;
   defaultPaymentDialogOpen: boolean;
   paymentToDelete: IBillingProfile | null;
   paymentToDefault: IBillingProfile | null;
}

const initialState: CheckoutPaymentState = {
   paymentDialogOpen: false,
   paymentError: [],
   deletePaymentDialogOpen: false,
   defaultPaymentDialogOpen: false,
   paymentToDelete: null,
   paymentToDefault: null,
};

const checkoutPaymentSlice = createSlice({
   name: 'checkoutPayment',
   initialState,
   reducers: {
      setPaymentDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.paymentDialogOpen = action.payload;
      },
      setDeletePaymentDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.deletePaymentDialogOpen = action.payload;
      },
      setDefaultPaymentDialogOpen: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.defaultPaymentDialogOpen = action.payload;
      },
      setPaymentToDelete: (state, action: PayloadAction<IBillingProfile | null>) => {
         state.paymentToDelete = action.payload;
      },
      setPaymentToDefault: (state, action: PayloadAction<IBillingProfile | null>) => {
         state.paymentToDefault = action.payload;
      },
   },
   extraReducers: (builder) => {},
});

export const {
   setPaymentDialogOpen,
   setDeletePaymentDialogOpen,
   setDefaultPaymentDialogOpen,
   setPaymentToDelete,
   setPaymentToDefault,
} = checkoutPaymentSlice.actions;

export const checkoutPaymentStore = (state: RootState) => state.checkoutPaymentStore;

export default checkoutPaymentSlice.reducer;
