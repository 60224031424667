import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { SectionCard, SectionCardContent, SectionCardHeader } from '../SectionCard';
import SkeletonLoader from 'components/SkeletonLoader';
import { companyStore } from 'features/company/stores/company.slice';
import { ICompanyAvailableSetting } from 'features/company/types/company.types';
import FreeForm from './FreeForm';
import MultiSelect from './MultiSelect';
import Dropdown from './Dropdown';
import { checkoutLoadStore } from '../../stores/checkout-load.slice';

const CustomFields = () => {
   const { cardLoading } = useAppSelector(checkoutLoadStore);
   const { companyInfo } = useAppSelector(companyStore);
   const availableSettings = companyInfo?.availableSettings.filter((setting) => {
      return setting.onCheckout;
   });

   const getFieldType = (setting: ICompanyAvailableSetting) => {
      if (setting.type === 'free-form') {
         return <FreeForm setting={setting} />;
      } else if (setting.type === 'multiselect') {
         return <MultiSelect setting={setting} />;
      } else if (setting.type === 'drop-down') {
         return <Dropdown setting={setting} />;
      }
   };

   return availableSettings.length > 0 ? (
      <>
         {availableSettings.map((setting) => {
            return (
               <SectionCard key={setting.id}>
                  <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
                     <SectionCardHeader
                        title={
                           <Grid item xs={12}>
                              <div className="bar-box">
                                 <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                                    {`${setting.name}`}
                                 </Typography>
                              </div>
                           </Grid>
                        }
                     ></SectionCardHeader>
                  </SkeletonLoader>
                  <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '120px' }}>
                     <SectionCardContent>{getFieldType(setting)}</SectionCardContent>
                  </SkeletonLoader>
               </SectionCard>
            );
         })}
      </>
   ) : null;
};

export default CustomFields;
