import React from 'react';
import { Box, Typography, Radio } from '@mui/material';
import '../styles/Subscriptions.scss';
import { ISubsEditOption } from '../types/subscriptions.types';
import { setCancelDate, subscriptionStore } from '../stores/subscriptions.slice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

const SubscriptionCancellation: React.FC<{ cancellationOptions: ISubsEditOption[] }> = ({ cancellationOptions }) => {
   const dispatch = useAppDispatch();
   const { cancelDate } = useAppSelector(subscriptionStore);

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setCancelDate(event.target.value));
   };

   return cancellationOptions?.length > 0 ? (
      <Box display="flex" flexDirection="column" sx={{ '& > *': { marginBottom: 1.5 } }}>
         {cancellationOptions.map((option, index) => (
            <Box key={`${option.value}-${index}`} display="flex" alignItems="center">
               <Radio
                  checked={cancelDate === option.value}
                  onChange={handleChange}
                  value={option.value}
                  name="radio-buttons"
               />
               <Typography sx={{ fontWeight: 'bold' }}>{`${option.name} and ${option.dateLabel}`}</Typography>
            </Box>
         ))}
      </Box>
   ) : null;
};

export default React.memo(SubscriptionCancellation);
