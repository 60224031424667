import React, { useEffect } from 'react';
import { MenuItem, Typography, Select, SelectChangeEvent } from '@mui/material';
import '../styles/Subscriptions.scss';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { ISubsEditOption } from '../types/subscriptions.types';
import { setEditFromDate, setEditToDate, subscriptionStore } from '../stores/subscriptions.slice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

interface SubscriptionFreezeProps {
   freezeOptions: ISubsEditOption[];
}

const SubscriptionFreeze: React.FC<SubscriptionFreezeProps> = ({ freezeOptions }) => {
   const dispatch = useAppDispatch();
   const { editFromDate } = useAppSelector(subscriptionStore);

   const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      dispatch(setEditFromDate(newExpanded ? panel : null));
      dispatch(setEditToDate(null));
   };

   return (
      <>
         {freezeOptions?.length > 0 &&
            freezeOptions.map((option, index) => (
               <Accordion
                  key={option.name + index}
                  square
                  className={editFromDate === option.value ? 'freeze-option-selected' : ''}
                  expanded={editFromDate === option.value}
                  onChange={handleChange(option.value)}
               >
                  <AccordionSummary
                     aria-controls="panel-sub-freeze-option"
                     sx={{ fontWeight: 'bold' }}
                     expandIcon={<ExpandMoreIcon />}
                  >
                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Starting after {option.name}</Typography>
                     </div>
                  </AccordionSummary>
                  <AccordionDetails>
                     <Typography sx={{ fontWeight: 'bold' }}>Ending on:</Typography>
                     <ResumeOptions selected={editFromDate === option.value} toDateSelector={option.toDateSelector} />
                  </AccordionDetails>
               </Accordion>
            ))}
      </>
   );
};

interface ResumeOptionsProps {
   selected: boolean;
   toDateSelector: {
      dateLabel: string;
      name: string;
      value: string;
   }[];
}

const ResumeOptions: React.FC<ResumeOptionsProps> = ({ selected, toDateSelector }) => {
   const dispatch = useAppDispatch();
   const { editToDate } = useAppSelector(subscriptionStore);

   const handleDateChange = (event: SelectChangeEvent) => {
      dispatch(setEditToDate(event.target.value));
   };

   useEffect(() => {
      if (selected && toDateSelector?.[0]?.value) {
         dispatch(setEditToDate(toDateSelector[0].value));
      }
   }, [selected, toDateSelector]);

   return selected && toDateSelector?.length ? (
      <Select
         value={editToDate ?? ''}
         defaultValue={toDateSelector[0].value}
         onChange={handleDateChange}
         sx={{ width: '100%' }}
      >
         {toDateSelector.map((selector, index) => (
            <MenuItem key={selector.value + index} value={selector.value ?? ''}>
               {selector.name}
            </MenuItem>
         ))}
      </Select>
   ) : null;
};

export default SubscriptionFreeze;
