import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import AddressBook from './AddressBook';
import { accountStore, resetAddShippingStatus } from 'features/account/stores/account.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { checkoutShippingStore, setAddDeliveryDialogOpen } from 'features/checkout/stores/checkout-shipping.slice';
import AddDeliveryDialog from 'features/checkout/components/orderDetails/AddDeliveryDialog';
import { LoadStatus } from 'config/utils';
import ProfileHeader from './ProfileHeader';
import AddDeliveryAddressLink from '../../checkout/components/orderDetails/AddDeliveryAddressLink';

const DeliveryAddresses = () => {
   const dispatch = useAppDispatch();
   const { addDeliveryDialogOpen } = useAppSelector(checkoutShippingStore);
   const { addShippingStatus } = useAppSelector(accountStore);

   const onAddAddressCancel = () => {
      dispatch(setAddDeliveryDialogOpen(false));
   };

   useEffect(() => {
      if (addShippingStatus === LoadStatus.complete) {
         dispatch(resetAddShippingStatus());
         if (addDeliveryDialogOpen) {
            dispatch(setAddDeliveryDialogOpen(false));
         }
      }
   }, [addShippingStatus]);

   return (
      <>
         <ProfileHeader headerTitle="Your Addresses" />
         <Box display="flex" flexDirection="column" alignItems="start" width="100%" margin="auto" maxWidth="650px">
            <Box sx={{ display: 'flex', pt: 2 }}>
               <AddDeliveryAddressLink
                  onOpen={() => dispatch(setAddDeliveryDialogOpen(true))}
                  text={'Add new address'}
               />
            </Box>
            <AddressBook />
         </Box>
         {addDeliveryDialogOpen && <AddDeliveryDialog onCancel={onAddAddressCancel} />}
      </>
   );
};

export default DeliveryAddresses;
