import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { DEFAULT_IMAGE } from 'config/restaurant.const';
import { IProduct } from 'features/order/types/product.types';
import clsx from 'clsx';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import SkeletonLoader from 'components/SkeletonLoader';
import { useAppSelector } from 'app/hooks';
import { orderLoadStore } from 'features/order/stores/order-load.slice';
import { Variant } from '@mui/material/styles/createTypography';
import { CardDescriptionContainer } from 'features/order/components/CardDescriptionContainer';
import Tag from 'features/menuItem/components/Tag';

interface IFeatureProductCardVerticalProps {
   item: IProduct;
   handleProductSelection: (productId: string) => void;
   addToCartText?: string;
   titleVariant?: Variant;
}

const FeatureProductCardVertical: React.FC<IFeatureProductCardVerticalProps> = ({
   item,
   addToCartText,
   handleProductSelection,
   titleVariant,
}) => {
   const cardRef = React.useRef<HTMLDivElement>(null);
   const { cardLoading } = useAppSelector(orderLoadStore);

   return (
      <>
         <Card
            ref={cardRef}
            className={clsx('product-card product-card-vertical', { 'with-add-text': addToCartText })}
            sx={{ justifyContent: 'space-between', alignItems: 'center', borderRadius: '1rem' }}
         >
            <SkeletonLoader
               loading={cardLoading}
               skeletonProps={{ width: '100%', height: cardRef.current ? cardRef.current.offsetHeight : '500px' }}
            >
               <Box className="product-image" sx={{ position: 'relative' }}>
                  <Box position="absolute" zIndex={2} top={0} left={0}>
                     <Tag product={item} />
                  </Box>
                  <CardMedia
                     component="img"
                     image={item?.images && item.images.length ? `${item.images[0][2].url}` : DEFAULT_IMAGE}
                     alt={item.name}
                     onClick={() => {
                        handleProductSelection(item.id);
                     }}
                     sx={{ position: 'relative', zIndex: 1 }}
                  />
               </Box>
               <Box className={clsx('product-detail', { 'with-add-text': addToCartText })}>
                  <CardContent sx={{ flex: '1 0 auto', padding: '10px' }}>
                     <Typography
                        sx={{ fontWeight: 600, textAlign: 'center' }}
                        gutterBottom
                        variant={titleVariant ? titleVariant : 'h5'}
                        component="div"
                        className="card-content card-title"
                     >
                        {item.name}
                     </Typography>
                     <CardDescriptionContainer className="card-content">
                        <Markdown remarkPlugins={[remarkGfm]}>
                           {item.specifications?.length ? item.specifications : item.description}
                        </Markdown>
                     </CardDescriptionContainer>
                  </CardContent>
               </Box>
            </SkeletonLoader>
         </Card>
      </>
   );
};

export default FeatureProductCardVertical;
