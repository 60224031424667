import React from 'react';
import ProfileInfo from './ProfileInfo';
import DeliveryAddresses from './DeliveryAddresses';
import OrderStatus from './OrderStatus';
import PaymentMethods from './PaymentMethods';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import SubscriptionsCart from './SubscriptionsCart';
import PasswordReset from './PasswordReset';
import ReferralCode from './ReferralCode';
import Rewards from './Rewards';
import { useAppSelector } from '../../../app/hooks';
import { profileStore } from '../stores/profile.slice';

const ProfileContent = () => {
   const { navListIndex } = useAppSelector(profileStore);
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const renderContent = () => {
      switch (navListIndex) {
         case 0:
            return <ProfileInfo />;
         case 1:
            return <DeliveryAddresses />;
         case 2:
            return <PasswordReset />;
         case 3:
            return <SubscriptionsCart />;
         case 4:
            return <OrderStatus />;
         case 5:
            return <PaymentMethods />;
         case 7:
            return <ReferralCode />;
         case 8:
            return <Rewards />;
         default:
            return <ProfileInfo />;
      }
   };

   return (
      <>
         <Box
            className={isMobileScreen ? 'nav-info-mobile' : 'nav-info'}
            justifyContent="center"
            width="100%"
            minHeight={'50vh'}
            pl={isMobileScreen ? 0 : 4}
            pr={isMobileScreen ? 0 : 4}
         >
            {renderContent()}
         </Box>
      </>
   );
};

export default ProfileContent;
