import React, { useEffect } from 'react';
import Confirmation from '../features/confirmation/Confirmation';
import { useSearchParams } from 'react-router-dom';
import { cartStore, resetFetchCart } from '../features/cart/stores/cart.slice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchUserOrderAsync } from '../features/order/stores/order.slice';
import useUserCart from '../app/useUserCart';
import { handleFooterElementDisplay, handleMainElementDisplay, LoadStatus } from '../config/utils';

export default function ConfirmationPage() {
   const dispatch = useAppDispatch();
   const [searchParams] = useSearchParams();
   const cartId = searchParams.get('cartId');
   const { fetchCartResponse, fetchCartStatus } = useAppSelector(cartStore);
   useUserCart({ fetchCart: true, fetchShopTasks: true });

   useEffect(() => {
      if (fetchCartStatus === LoadStatus.complete && fetchCartResponse.success) {
         dispatch(resetFetchCart());
      }
   }, [fetchCartResponse]);

   useEffect(() => {
      handleMainElementDisplay(false);
      handleFooterElementDisplay(true);
   }, []);

   useEffect(() => {
      cartId && dispatch(fetchUserOrderAsync({ cartId }));
   }, [cartId]);

   return <Confirmation />;
}
