import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { CategoryProductMapping } from '../types/category-product.types';

type Orientation = 'vertical' | 'horizontal' | undefined;

const TabsList = (props: {
   dataToShow: CategoryProductMapping;
   categoryTabValue: string;
   scrollToCategory: (event: React.SyntheticEvent, categoryName: string) => void;
   style: Orientation;
   categoryContainerHeight: string;
}) => {
   return (
      <Tabs
         value={
            props.categoryTabValue
               ? props.categoryTabValue
               : Object.entries(props.dataToShow).map((categoryMapping) => categoryMapping[0])[0]
         }
         onChange={props.scrollToCategory}
         variant="scrollable"
         scrollButtons="auto"
         orientation={props.style}
         sx={{ minWidth: '240px', height: props.categoryContainerHeight }}
      >
         {Object.entries(props.dataToShow).map((categoryMappingArray) => (
            <Tab key={categoryMappingArray[0]} value={categoryMappingArray[0]} label={categoryMappingArray[0]} />
         ))}
      </Tabs>
   );
};

export default TabsList;
