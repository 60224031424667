import React, { useEffect } from 'react';
import { convertPageViewEventData, trackMptEvent } from 'features/order/utils/event.helper';
import { TrackEventType } from 'features/order/types/event.types';
import { handleFooterElementDisplay, handleMainElementDisplay, LoadStatus } from 'config/utils';
import { cartStore, resetFetchCart } from 'features/cart/stores/cart.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Package from 'features/package/Package';
import { packageStore, resetFetchPackagesStatus } from 'features/package/stores/package.slice';
import useUserCart from '../app/useUserCart';
import FullPageLoader from '../components/FullPageLoader';
import { useNavigate } from 'react-router-dom';

export default function PackagePage() {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { fetchCartResponse, fetchCartStatus } = useAppSelector(cartStore);
   const { fetchPackagesStatus, packages } = useAppSelector(packageStore);
   const isLoading = fetchPackagesStatus === LoadStatus.loading || fetchCartStatus === LoadStatus.loading;

   useUserCart({ fetchCart: true, fetchShopTasks: true });

   useEffect(() => {
      if (fetchCartStatus === LoadStatus.complete && fetchCartResponse.success) {
         dispatch(resetFetchCart());
      }
   }, [fetchCartResponse]);

   useEffect(() => {
      if (fetchPackagesStatus === LoadStatus.complete) {
         dispatch(resetFetchPackagesStatus());
         if (packages && packages.length === 0) {
            navigate('/order');
         }
      }
   }, [fetchPackagesStatus]);

   useEffect(() => {
      trackMptEvent(TrackEventType.pageView, convertPageViewEventData());
      handleMainElementDisplay(true);
      handleFooterElementDisplay(true);
   }, []);

   return (
      <>
         <FullPageLoader loading={isLoading} />
         <Package />
      </>
   );
}
