import { IError } from 'components/types/base.types';
import { useAppSelector } from 'app/hooks';
import { companyStore } from 'features/company/stores/company.slice';
import { Alert } from '@mui/material';
import { MptLink } from 'components/MptLink';
import React from 'react';
import { checkoutStore } from '../../stores/checkout.slice';

interface IDeliveryRuleErrorProps {
   handleChangeOrderDate: () => void;
}

const DeliveryRuleError = ({ handleChangeOrderDate }: IDeliveryRuleErrorProps) => {
   const { companyInfo } = useAppSelector(companyStore);
   const { deliveryRuleError } = useAppSelector(checkoutStore);

   return (
      <>
         {deliveryRuleError.map((checkoutError: IError) => {
            if (companyInfo.uiSettings?.orderLinkOnError && checkoutError.errorMessage) {
               companyInfo.uiSettings?.orderLinkOnError.includes(checkoutError.errorMessage);
            }
            return (
               <Alert sx={{ marginBottom: 2 }} severity="error">
                  {checkoutError.errorMessage}{' '}
                  <MptLink fontWeight="600" onClick={() => handleChangeOrderDate()}>
                     Select another option here
                  </MptLink>
               </Alert>
            );
         })}
      </>
   );
};

export default DeliveryRuleError;
