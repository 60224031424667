import { apiServerUrl } from 'config/server';
import { IDeliveryChangeResponse, IDeliveryDatesResponse, IUpdateRewardResponse } from '../types/order.types';
import { ICartResponse } from 'features/cart/types/cart.types';
import { expandApiServerHeader, prepareTokenPayload, updateMPTSession } from 'utils/mpt-session.helper';

const deliveryDatesUrl = `/delivery/dates`;
const deliveryChangeUrl = `/delivery/dates/change`;
const deliveryAutoAddUrl = `/delivery/dates/autoadd`;
const deliveryLocationsUrl = '/delivery/locations';
const deliveryUpdateRewardUrl = '/delivery/updatereward';

const prodUrls = {
   fetchAutoAddDate: `${apiServerUrl}${deliveryAutoAddUrl}`,
   fetchDeliveryDates: `${apiServerUrl}${deliveryDatesUrl}`,
   changeDelivery: `${apiServerUrl}${deliveryChangeUrl}`,
   changeDeliveryLocation: `${apiServerUrl}${deliveryLocationsUrl}`,
   updateReward: `${apiServerUrl}${deliveryUpdateRewardUrl}`,
};

export function fetchAutoAddDate(packageId?: string | null) {
   return new Promise<ICartResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(prepareTokenPayload({})),
      };

      const packageParam = packageId ? packageId : '';

      fetch(
         `${prodUrls.fetchAutoAddDate}?packageId=${packageParam}`,
         process.env.REACT_APP_SERVER ? requestOptions : undefined,
      )
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function fetchDeliveryDates(packageId?: string) {
   return new Promise<IDeliveryDatesResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'GET',
         headers: expandApiServerHeader(),
      };

      const queryParam = packageId ? `?packageId=${packageId}` : '';
      fetch(`${prodUrls.fetchDeliveryDates}${queryParam}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IDeliveryDatesResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function postDeliveryDate(
   dateTime: string,
   deliveryDayId: string,
   timeSlotId: string,
   packageId?: string | null,
) {
   return new Promise<IDeliveryChangeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'POST',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               dateTime,
               deliveryDayId,
               timeSlotId,
               packageId,
            }),
         ),
      };

      fetch(`${prodUrls.fetchDeliveryDates}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IDeliveryChangeResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function changeDelivery(
   dateTime: string,
   deliveryDayId: string,
   timeSlotId: string,
   id?: string,
   packageId?: string | null,
) {
   return new Promise<IDeliveryChangeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               dateTime,
               deliveryDayId,
               id,
               timeSlotId,
               packageId,
            }),
         ),
      };

      fetch(`${prodUrls.changeDelivery}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IDeliveryChangeResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function deleteDeliveryDates(deliveryId: string) {
   return new Promise<IDeliveryChangeResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'DELETE',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               deliveryId,
            }),
         ),
      };

      fetch(`${prodUrls.fetchDeliveryDates}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IDeliveryChangeResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function changeDeliveryLocation(
   shippingLocationId: string,
   updatePresets: boolean,
   shippingProfileId?: string,
   shippingFirstName?: string,
   shippingLastName?: string,
   shippingPhone?: string,
) {
   return new Promise<IDeliveryChangeResponse>((resolve, reject) => {
      let bodyString = '';

      if (shippingProfileId) {
         bodyString = JSON.stringify(prepareTokenPayload({ shippingLocationId, shippingProfileId, updatePresets }));
      } else {
         bodyString = JSON.stringify(
            prepareTokenPayload({
               shippingLocationId,
               shippingFirstName,
               shippingLastName,
               shippingPhone,
               updatePresets,
            }),
         );
      }

      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: bodyString,
      };

      fetch(`${prodUrls.changeDeliveryLocation}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: ICartResponse) => {
            updateMPTSession(resultData);
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export function updateReward(reward: number) {
   return new Promise<IUpdateRewardResponse>((resolve, reject) => {
      let requestOptions = {
         method: 'PUT',
         headers: expandApiServerHeader(),
         body: JSON.stringify(
            prepareTokenPayload({
               reward,
            }),
         ),
      };

      fetch(`${prodUrls.updateReward}`, process.env.REACT_APP_SERVER ? requestOptions : undefined)
         .then((response) => response.json())
         .then((resultData: IUpdateRewardResponse) => {
            resolve(resultData);
         })
         .catch((err) => {
            reject(err);
         });
   });
}
