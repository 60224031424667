import { ISelectedDeliverySlot, TOrderRecurrence } from '../types/cart.types';
import { getTimeslotFromSelected } from 'features/order/config/order.helper';
import { IDeliveryOption } from 'features/order/types/order.types';
import { ICart } from '../types/cart.types';
import { OrderType } from 'features/order/config/order.const';
import { LoadStatus } from '../../../config/utils';

export const getOrderFrequency = (
   subscriptionModActive: boolean,
   subscribeAllOnlyMode: boolean,
   orderFrequency: TOrderRecurrence,
   cart: ICart,
   packageRecurrence?: TOrderRecurrence | null,
) => {
   if (subscriptionModActive) {
      return 'Weekly';
   }

   if (packageRecurrence) {
      return packageRecurrence === 'weekly' ? 'Weekly' : 'One-Time';
   }

   if (orderFrequency && subscribeAllOnlyMode) {
      return orderFrequency === 'weekly' ? 'Weekly' : 'One-Time';
   }

   if (cart.deliveries?.length > 0 && cart.deliveries[0].products.length > 0) {
      const hasWeekly = cart.deliveries[0].products.some((product) => product.recurrence === 'weekly');
      const hasOneTime = cart.deliveries[0].products.some((product) => !product.recurrence);

      if (hasWeekly && hasOneTime) {
         return 'Both';
      }

      return hasOneTime ? 'One-Time' : 'Weekly';
   }

   return 'One-Time';
};

export const getOrderMethod = (
   subscriptionModActive: boolean,
   orderMethod: OrderType | null,
   activeSubscription?: ICart,
) => {
   const method = subscriptionModActive ? activeSubscription?.deliveries[0].shippingLocationType : orderMethod;
   return method === OrderType.homeDelivery || !method ? 'Delivery' : 'Pick-Up';
};

export const getOrderDate = (
   subscriptionModActive: boolean,
   selectedDeliverySlot: ISelectedDeliverySlot,
   deliveryOptions: IDeliveryOption[],
   orderBar?: boolean,
   activeSubscription?: ICart,
   postUserSubscriptionStatus?: string,
) => {
   let dateText: string;
   if (orderBar) {
      const selectedTimeslot = getTimeslotFromSelected(selectedDeliverySlot, deliveryOptions);

      if (subscriptionModActive || postUserSubscriptionStatus === LoadStatus.loading) {
         return `${activeSubscription?.deliveries[0]?.shippingLocationName}, ${activeSubscription?.deliveries[0]?.deliveryDayName}`;
      } else if (selectedTimeslot?.textOverride) {
         const textParts = selectedTimeslot.textOverride.split(':');

         if (textParts.length > 2) {
            return `${Number.isNaN(Number(textParts[0])) ? textParts[0] + ': ' : ''}${selectedDeliverySlot.deliveryDayName}`;
         } else {
            return `${selectedTimeslot.textOverride}: ${selectedDeliverySlot.deliveryDayName}`;
         }
      } else {
         return selectedDeliverySlot.deliveryDayName;
      }
   } else {
      dateText =
         subscriptionModActive && activeSubscription
            ? activeSubscription?.deliveries[0].deliveryDayName
            : selectedDeliverySlot.deliveryDayName;
      return dateText.substring(0, dateText.indexOf(','));
   }
};

export const getCartItemCount = (cart: ICart) => {
   let itemCount = 0;
   if (!cart?.deliveries?.length) {
      return 0;
   }
   cart?.deliveries?.forEach((delivery) => {
      delivery?.products?.forEach((product) => {
         itemCount += product.qty;
      });
   });
   return itemCount;
};
