import React, { RefObject } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import 'features/order-bar/styles/OrderBar.scss';
import { cartStore } from 'features/cart/stores/cart.slice';
import OrderMethod from 'features/order-bar/components/OrderMethod';
import OrderFrequency from 'features/order-bar/components/OrderFrequency';
import { getOrderDate } from 'features/cart/utils/cart.helper';
import { subscriptionStore } from 'features/subscription/stores/subscriptions.slice';
import { orderStore } from 'features/order/stores/order.slice';
import { setOrderDialogOpen, setOrderDialogOption } from 'features/order/stores/order-bar.slice';
import { SectionCard, SectionCardContent, SectionCardHeader, SectionCardListItem } from '../SectionCard';
import SkeletonLoader from 'components/SkeletonLoader';
import { checkoutStore } from '../../stores/checkout.slice';
import { MptLink } from 'components/MptLink';
import { checkoutLoadStore } from '../../stores/checkout-load.slice';
import DeliveryRuleError from './DeliveryRuleError';

interface IOrderDetailsProps {
   orderDetailCardRef: RefObject<HTMLDivElement>;
   hasDeliveryMethodError: boolean;
}

const OrderDetails = ({ orderDetailCardRef, hasDeliveryMethodError }: IOrderDetailsProps) => {
   const dispatch = useAppDispatch();
   const { cart } = useAppSelector(cartStore);
   const { subscriptionModActive } = useAppSelector(subscriptionStore);
   const { selectedDeliverySlot, deliveryOptions } = useAppSelector(orderStore);
   const { deliveryRuleError } = useAppSelector(checkoutStore);
   const { cardLoading } = useAppSelector(checkoutLoadStore);

   const handleChangeOrderDate = () => {
      dispatch(setOrderDialogOption('change'));
      dispatch(setOrderDialogOpen(true));
   };

   return (
      <>
         <SectionCard
            ref={orderDetailCardRef}
            hasError={(deliveryRuleError.length > 0 || hasDeliveryMethodError) && !cardLoading}
         >
            <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
               <SectionCardHeader
                  title={
                     <Grid item xs={12}>
                        <div className="bar-box">
                           <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                              1. Order Options
                           </Typography>
                           <Typography variant={'subtitle1'} fontWeight="bold" mb={1} mt={1}>
                              <MptLink
                                 sx={{ marginLeft: '5px', cursor: 'pointer', fontWeight: 600 }}
                                 onClick={handleChangeOrderDate}
                              >
                                 Change{' '}
                              </MptLink>
                           </Typography>
                        </div>
                     </Grid>
                  }
               ></SectionCardHeader>
            </SkeletonLoader>
            <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '120px' }}>
               <SectionCardContent>
                  <DeliveryRuleError handleChangeOrderDate={handleChangeOrderDate} />
                  <Stack direction="column" gap={2} width="100%" minHeight={120}>
                     <SectionCardListItem>
                        <Typography variant="body1" fontWeight="bold">
                           {getOrderDate(subscriptionModActive, selectedDeliverySlot, deliveryOptions, true, cart)}
                        </Typography>
                     </SectionCardListItem>
                     <SectionCardListItem>
                        <OrderMethod standard hasDeliveryMethodError={hasDeliveryMethodError} />
                     </SectionCardListItem>
                     <SectionCardListItem>
                        <OrderFrequency standard />
                     </SectionCardListItem>
                  </Stack>
               </SectionCardContent>
            </SkeletonLoader>
         </SectionCard>
      </>
   );
};

export default OrderDetails;
