import React from 'react';
import { Typography, Stack, Chip, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import { IAppliedOffer } from 'features/cart/types/cart.types';
import { deleteOfferCodeAsync } from '../../stores/checkout.slice';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SkeletonLoader from 'components/SkeletonLoader';
import { SectionCardHeader, SectionCardSlim, SectionCardSlimContent } from '../SectionCard';
import Reward from '../reward/Reward';
import OfferPromoCode from '../offerPromoCode/OfferPromoCode';
import { IOfferCodeRewardValues } from 'features/checkout/types/checkout.types';
import { checkoutLoadStore } from '../../stores/checkout-load.slice';

const schema = yup.object().shape({
   code: yup.string(),
   reward: yup
      .number()
      .typeError('Reward amount must be a number')
      .min(0, 'Reward amount cannot be negative')
      .nullable()
      .notRequired(),
});

const Discounts = () => {
   const { cardLoading } = useAppSelector(checkoutLoadStore);
   const { cart } = useAppSelector(cartStore);
   const dispatch = useAppDispatch();
   const methods = useForm<IOfferCodeRewardValues>({
      defaultValues: { code: '', reward: 0 },
      resolver: yupResolver(schema),
   });
   const rewardBalance: number | null = cart?.rewards?.account?.included?.balance?.points;
   const handleDeleteOffer = (offerToDelete: IAppliedOffer) => () => {
      dispatch(deleteOfferCodeAsync({ code: offerToDelete.code }));
   };

   return (
      <SectionCardSlim>
         <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
            <SectionCardHeader
               title={
                  <Grid item xs={12}>
                     <div className="bar-box">
                        <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                           Promo Code, Gift Card & Rewards
                        </Typography>
                     </div>
                  </Grid>
               }
            ></SectionCardHeader>
         </SkeletonLoader>
         <SkeletonLoader loading={cardLoading} skeletonProps={{ width: '100%', height: '120px' }}>
            <SectionCardSlimContent>
               <Stack spacing={2} width="100%">
                  <FormProvider {...methods}>
                     <form>
                        <Stack direction={'column'} spacing={2} sx={{ width: '100%' }} alignItems="center">
                           <OfferPromoCode methods={methods} />
                           {rewardBalance && rewardBalance > 0 && <Reward methods={methods} />}
                        </Stack>
                     </form>
                  </FormProvider>
                  {cart.appliedOffers && cart.appliedOffers.length > 0 ? (
                     <Stack direction="column" width="100%" spacing={1}>
                        <Typography variant="body1" fontWeight="bold">
                           Applied Discounts
                        </Typography>
                        <Stack
                           direction="row"
                           flexWrap="wrap"
                           justifyContent="flex-start"
                           alignItems="flex-start"
                           sx={{
                              rowGap: 1,
                              columnGap: 1,
                           }}
                        >
                           {cart.appliedOffers.map((offer, offerIndex) =>
                              offer.code ? (
                                 <Chip
                                    key={`offer-${offerIndex}`}
                                    label={offer.message}
                                    onDelete={handleDeleteOffer(offer)}
                                 />
                              ) : (
                                 <Chip key={`offer-${offerIndex}`} label={offer.message} />
                              ),
                           )}
                        </Stack>
                     </Stack>
                  ) : null}
               </Stack>
            </SectionCardSlimContent>
         </SkeletonLoader>
      </SectionCardSlim>
   );
};

export default Discounts;
