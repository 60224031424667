import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import FinixFormModal from 'features/checkout/components/paymentDetails/FinixFormModal';
import { finixStore, setFinixFormModalOpen } from 'features/checkout/stores/finix.slice';
import PaymentBook from './PaymentBook';
import { LoadStatus } from 'config/utils';
import {
   accountStore,
   fetchUserPaymentProfilesAsync,
   resetAddPaymentStatus,
} from 'features/account/stores/account.slice';
import ProfileHeader from './ProfileHeader';
import AddPaymentLink from '../../checkout/components/paymentDetails/AddPaymentLink';

const PaymentMethods = () => {
   const dispatch = useAppDispatch();
   const { finixFormModalOpen } = useAppSelector(finixStore);
   const { addBillingStatus } = useAppSelector(accountStore);

   const handleFinixFormModalOpen = () => {
      dispatch(setFinixFormModalOpen(true));
   };

   useEffect(() => {
      if (addBillingStatus === LoadStatus.complete) {
         dispatch(setFinixFormModalOpen(false));
         dispatch(resetAddPaymentStatus());
         dispatch(fetchUserPaymentProfilesAsync());
      }
   }, [addBillingStatus]);

   return (
      <>
         <ProfileHeader headerTitle="Your Payment Options" />
         <Box display="flex" flexDirection="column" alignItems="start" width="100%" margin="auto" maxWidth="650px">
            <Box sx={{ display: 'flex', pt: 2 }}>
               <AddPaymentLink onOpen={handleFinixFormModalOpen} />
            </Box>
            <PaymentBook />
         </Box>
         {finixFormModalOpen && <FinixFormModal />}
      </>
   );
};

export default PaymentMethods;
