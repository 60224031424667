import { Grid, useMediaQuery, useTheme } from '@mui/material';
import './styles/Profile.scss';
import React, { useEffect } from 'react';
import NavList from './components/NavList';
import ProfileContent from './components/ProfileContent';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchUserOrdersAsync, profileStore } from './stores/profile.slice';
import {
   accountFetchShippingAsync,
   fetchAccountAsync,
   fetchReferralCodeAsync,
   fetchRewardsAsync,
   fetchUserPaymentProfilesAsync,
} from '../account/stores/account.slice';
import useHeaderSize from 'app/useHeaderSize';
import { adjustHeaderTop } from 'features/order/utils/prduct-list.helper';
import useScreenSize from 'app/useScreenSize';
import 'features/order/styles/ProductList.scss';
import { CustomContainer } from '../order/components/CustomContainer';
import { fetchPackagesAsync, packageStore, resetFetchPackagesStatus } from '../package/stores/package.slice';
import { LoadStatus } from '../../config/utils';

const Profile = () => {
   const theme = useTheme();
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.only('lg'));
   const { fetchPackagesStatus } = useAppSelector(packageStore);
   const [headerSize, headerContainerRef, headerMainHeight, , hasFixedHeader] = useHeaderSize();
   const screenSize = useScreenSize();
   const dispatch = useAppDispatch();
   const { navListIndex } = useAppSelector(profileStore);

   useEffect(() => {
      dispatch(fetchPackagesAsync({ fetchCart: true }));
      dispatch(fetchUserOrdersAsync());
      dispatch(accountFetchShippingAsync());
      dispatch(fetchUserPaymentProfilesAsync());
      dispatch(fetchAccountAsync());
      dispatch(fetchReferralCodeAsync());
      dispatch(fetchRewardsAsync());
   }, []);

   useEffect(() => {
      if (fetchPackagesStatus === LoadStatus.complete) {
         dispatch(resetFetchPackagesStatus());
      }
   }, [fetchPackagesStatus]);

   useEffect(() => {
      adjustHeaderTop(headerContainerRef, isLargeScreenDown);
   }, [screenSize]);

   useEffect(() => {
      adjustHeaderTop(headerContainerRef, isLargeScreenDown);
   }, [headerContainerRef]);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [navListIndex]);

   return (
      <>
         <CustomContainer maxWidth={false}>
            <Grid container sx={{ backgroundColor: theme.palette.background.default }} className="menu-container">
               <Grid
                  item
                  xs={12}
                  id="order-sticky-header"
                  className="mpt-sticky-header"
                  ref={headerContainerRef}
                  sx={{ padding: 0 }}
               >
                  {!isLargeScreen && (
                     <Grid item xs={12} className="menu-header">
                        <NavList />
                     </Grid>
                  )}
               </Grid>
               <Grid
                  container
                  className="product-card-container"
                  sx={{
                     backgroundColor: theme.palette.background.default,
                     marginLeft: isLargeScreen ? undefined : '0px',
                     top:
                        headerSize?.height !== undefined
                           ? headerSize.height + (!isLargeScreen || hasFixedHeader ? headerMainHeight : 0)
                           : 0,
                  }}
               >
                  {isLargeScreen && (
                     <Grid item lg={2} flexDirection={'row-reverse'} display="flex">
                        <Grid item position={'fixed'}>
                           <NavList />
                        </Grid>
                     </Grid>
                  )}
                  <Grid item xs={12} lg={10} p={0}>
                     <ProfileContent />
                  </Grid>
               </Grid>
            </Grid>
         </CustomContainer>
      </>
   );
};

export default Profile;
