import React, { useState } from 'react';
import { Box, Fab, Menu, MenuItem, Avatar } from '@mui/material';
import './styles/Appbar/Appbar.scss';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
   accountStore,
   setAccountDialogOpen,
   setNoCloseBtn,
   submitLogoutAsync,
} from '../features/account/stores/account.slice';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Logout } from '@mui/icons-material';
import { setNavListIndex } from '../features/profile/stores/profile.slice';

const ProfileButton = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { isLoggedIn } = useAppSelector(accountStore);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

   const signOutProfile = () => {
      dispatch(
         submitLogoutAsync({
            bodyInput: {
               allSessions: true,
            },
         }),
      );
   };

   const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleMenuClose = () => {
      setAnchorEl(null);
   };

   const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
      if (!isLoggedIn) {
         dispatch(setNoCloseBtn(false));
         dispatch(setAccountDialogOpen(true));
      } else {
         handleMenuOpen(event);
      }
   };

   const handleAccountClick = () => {
      navigate('/user');
      dispatch(setNavListIndex(0));
      handleMenuClose();
   };

   const handleSignOutClick = () => {
      signOutProfile();
      handleMenuClose();
   };

   return (
      <Box sx={{ position: 'relative' }}>
         <Fab size="medium" color="default" onClick={(event) => handleProfileClick(event)}>
            <PersonIcon fontSize={'large'} color="action" />
         </Fab>
         <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            slotProps={{
               paper: {
                  elevation: 0,
                  sx: {
                     overflow: 'visible',
                     filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                     mt: 1.5,
                     '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                     },
                  },
               },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            autoFocus={false}
            disableScrollLock={true}
         >
            <MenuItem onClick={handleAccountClick}>
               <Avatar />
               Account
            </MenuItem>
            <MenuItem onClick={handleSignOutClick}>
               <ListItemIcon>
                  <Logout fontSize="small" />
               </ListItemIcon>
               Sign Out
            </MenuItem>
         </Menu>
      </Box>
   );
};

export default ProfileButton;
