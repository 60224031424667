import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import 'features/account/styles/Account.scss';
import CommonDialog from 'components/CommonDialog';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadStatus } from 'config/utils';
import { cartStore } from 'features/cart/stores/cart.slice';
import {
   accountStore,
   accountUpdateShippingAsync,
   resetUpdateShippingAddressStatus,
} from 'features/account/stores/account.slice';
import { getHomeDeliveryShippingProfile } from '../../utils/checkout.helper';
import SelectAddressDialogContent from './SelectAddressDialogContent';
import EditAddressDialogContent from 'features/profile/components/EditAddressDialogContent';
import { ADD_DELIVERY_DEFAULT_VALUES, ADD_DELIVERY_SCHEMA } from '../../config/checkout-form.const';
import { IShippingProfileForm } from '../../types/checkout.types';
import { ISubmitShippingPayload } from 'features/account/types/account.types';
import {
   checkoutShippingStore,
   resetUpdateShippingProfileStatus,
   setDeliveryDialogOpen,
   setOverrideWithDefault,
   updateShippingProfileAsync,
} from '../../stores/checkout-shipping.slice';

const schema = yup.object().shape({});

const DeliveryDialog = () => {
   const dispatch = useAppDispatch();
   const { deliveryDialogOpen, updateShippingProfileStatus, addressRuleError, overrideWithDefault } =
      useAppSelector(checkoutShippingStore);
   const { shippingProfiles, updateShippingAddressStatus } = useAppSelector(accountStore);
   const { cart } = useAppSelector(cartStore);

   const [editAddressId, setEditAddressId] = useState('');
   const defaultShipping = shippingProfiles.find((sp) => sp.default);
   const firstShipping = cart.deliveries.length ? cart.deliveries[0].shippingProfileId : null;
   const defaultSelectedAddressId = overrideWithDefault && defaultShipping ? defaultShipping.id : firstShipping;
   const [selectedAddressId, setSelectedAddressId] = useState(defaultSelectedAddressId);

   const shippingLocationId =
      cart?.deliveries && cart.deliveries.length > 0 && cart.deliveries[0].shippingLocationId
         ? cart.deliveries[0].shippingLocationId
         : '';
   const selectedProfile = shippingProfiles.find((shippingProfile) => shippingProfile.id === selectedAddressId);

   const editAddressMethods = useForm<IShippingProfileForm>({
      defaultValues: ADD_DELIVERY_DEFAULT_VALUES,
      resolver: yupResolver(ADD_DELIVERY_SCHEMA),
   });

   const selectAddressMethods = useForm({
      defaultValues: {},
      resolver: yupResolver(schema),
   });

   const isLoading =
      updateShippingProfileStatus === LoadStatus.loading || updateShippingAddressStatus === LoadStatus.loading;

   const presetSelectedAddress = () => {
      if (!cart.deliveries.length || !shippingProfiles.length) return;

      const shippingProfile = shippingProfiles.find((profile) => profile.id === cart.deliveries[0].shippingProfileId);
      const pickedShipping =
         shippingProfile?.id || shippingProfiles.find((profile) => profile.default)?.id || shippingProfiles[0].id;
      const overrideShipping = overrideWithDefault && defaultShipping ? defaultShipping.id : pickedShipping;

      setSelectedAddressId(overrideShipping);
   };

   const onUpdateShippingProfileStatus = () => {
      if (updateShippingProfileStatus === LoadStatus.complete) {
         dispatch(resetUpdateShippingProfileStatus());

         if (!addressRuleError.find((error) => error.id === selectedAddressId)) {
            dispatch(setOverrideWithDefault(false));
            dispatch(setDeliveryDialogOpen(false));
            selectAddressMethods.reset();
         }
      }
   };

   const onUpdateShippingAddressStatus = () => {
      if (updateShippingAddressStatus === LoadStatus.complete) {
         dispatch(resetUpdateShippingAddressStatus());
         setSelectedAddressId(editAddressId);
         dispatch(
            updateShippingProfileAsync({
               shippingLocationId,
               shippingProfileId: editAddressId,
               updatePresets: true,
            }),
         );
         handleCancelEdit();
      }
   };

   const onSelectSubmit = () => {
      if (selectedProfile) {
         dispatch(
            updateShippingProfileAsync({
               shippingLocationId,
               shippingProfileId: selectedProfile.id,
               updatePresets: true,
            }),
         );
      } else {
         if (addressRuleError.length && cart?.deliveries.length) {
            const shippingProfileToUse = getHomeDeliveryShippingProfile(cart, shippingProfiles);

            if (shippingProfileToUse !== null) {
               dispatch(
                  updateShippingProfileAsync({
                     shippingLocationId: shippingProfileToUse.shippingLocationId,
                     shippingProfileId: shippingProfileToUse.id,
                     updatePresets: true,
                  }),
               );
            } else {
               dispatch(setDeliveryDialogOpen(false));
            }
         } else {
            dispatch(setDeliveryDialogOpen(false));
         }
      }
   };

   const onEditSubmit = (data: IShippingProfileForm) => {
      const { address, address2, city, firstName, lastName, instructions, phone, postal, region, country } =
         data as ISubmitShippingPayload;

      dispatch(
         accountUpdateShippingAsync({
            bodyInput: {
               address,
               address2,
               city,
               country,
               firstName,
               lastName,
               instructions,
               phone,
               postal,
               region,
            },
            editAddressId,
         }),
      );
   };

   const handleClose = () => {
      selectAddressMethods.reset();
      editAddressMethods.reset();
      dispatch(setDeliveryDialogOpen(false));
      dispatch(setOverrideWithDefault(false));
   };

   const handleSwitchToEdit = (addressId: string) => {
      setEditAddressId(addressId);
   };

   const handleCancelEdit = () => {
      setEditAddressId('');
      editAddressMethods.reset();
   };

   useEffect(() => {
      presetSelectedAddress();
   }, [cart, shippingProfiles]);

   useEffect(() => {
      onUpdateShippingProfileStatus();
   }, [updateShippingProfileStatus]);

   useEffect(() => {
      onUpdateShippingAddressStatus();
   }, [updateShippingAddressStatus]);

   return (
      <>
         <CommonDialog
            open={deliveryDialogOpen}
            title={editAddressId ? 'Edit Delivery Address' : 'Select Delivery Address'}
            onClose={handleClose}
            onSubmit={
               editAddressId
                  ? editAddressMethods.handleSubmit(onEditSubmit)
                  : selectAddressMethods.handleSubmit(onSelectSubmit)
            }
            isLoading={isLoading}
            submitLabel={editAddressId ? 'Save' : 'Select'}
            onCancel={editAddressId ? handleCancelEdit : null}
         >
            {editAddressId ? (
               <FormProvider {...editAddressMethods}>
                  <form onSubmit={editAddressMethods.handleSubmit(onEditSubmit)}>
                     <EditAddressDialogContent {...{ editAddressId }} />
                  </form>
               </FormProvider>
            ) : (
               <FormProvider {...selectAddressMethods}>
                  <form onSubmit={selectAddressMethods.handleSubmit(onSelectSubmit)}>
                     <SelectAddressDialogContent {...{ handleSwitchToEdit, selectedAddressId, setSelectedAddressId }} />
                  </form>
               </FormProvider>
            )}
         </CommonDialog>
      </>
   );
};

export default DeliveryDialog;
