import React from 'react';
import Chip from '@mui/material/Chip';
import { IProduct } from '../../order/types/product.types';
import { loadMPTSession } from '../../../utils/mpt-session.helper';
import { ITagOverride } from '../types/menu-item.types';
import { getReplacedTag } from '../utils/menu-item.helper';

const Tag = (props: { product: IProduct }) => {
   const loadedMptSession = loadMPTSession();
   const tagOverrides: ITagOverride[] | null = loadedMptSession?.companyInfo?.uiSettings?.tagOverrides?.length
      ? loadedMptSession?.companyInfo?.uiSettings?.tagOverrides
      : null;

   const getTags = () => {
      if (!props.product?.tags) return;
      const extractedTags = props.product.tags.map((tag) => {
         if (tagOverrides) {
            return {
               id: tag.id,
               name: getReplacedTag(tag.name, tagOverrides).name,
               color: getReplacedTag(tag.name, tagOverrides).color,
               fontColor: getReplacedTag(tag.name, tagOverrides).fontColor,
            };
         }
         return { id: tag.id, name: tag.name, color: '#2b9446' };
      });

      return extractedTags
         .filter((tag) => {
            return tag.name.length > 0 && tag.color.length > 0;
         })
         .map((tag) => {
            return (
               <Chip
                  key={tag.id}
                  label={tag.name}
                  sx={{
                     margin: '2px',
                     borderRadius: '0px',
                     fontWeight: 'bold',
                     fontSize: '13px',
                     height: 'auto',
                     backgroundColor: tag.color,
                     color: tag.fontColor ?? '#ffffff',
                     '& .MuiChip-label': {
                        paddingLeft: '5px',
                        paddingRight: '5px',
                     },
                  }}
               />
            );
         });
   };

   return <div style={{ padding: '5px' }}>{getTags()}</div>;
};

export default Tag;
