import React, { useState, ChangeEvent } from 'react';
import { Stack, TextField, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { addCheckoutSettingsAsync, checkoutStore } from '../../stores/checkout.slice';
import { ICompanyAvailableSetting } from 'features/company/types/company.types';
import { StyledApplyButton } from './ApplyButton';

interface FreeFormProps {
   setting: ICompanyAvailableSetting;
}

const FreeForm = (props: FreeFormProps) => {
   const theme = useTheme();
   const dispatch = useAppDispatch();
   const { settings } = useAppSelector(checkoutStore);
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const defaultContent = settings?.find((setting) => {
      return setting.id === props.setting.id;
   })?.options?.[0].text;
   const [content, setContent] = useState(defaultContent ?? '');
   const [isButtonLoading, setButtonLoading] = useState<boolean>(false);

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setContent(event.target.value);
   };

   const handleSubmit = () => {
      setButtonLoading(true);
      dispatch(addCheckoutSettingsAsync({ settingId: props.setting.id, settingValues: [content] }))
         .then(() => setButtonLoading(false))
         .catch(() => setButtonLoading(false));
   };

   return (
      <>
         <Stack direction={'column'} spacing={2} sx={{ width: '100%' }} alignItems="center">
            <TextField
               className={'settings-free-form'}
               multiline
               variant="outlined"
               fullWidth
               value={content}
               inputProps={{ maxLength: 500 }}
               onChange={handleChange}
            />
            <StyledApplyButton
               style={{ marginLeft: 'auto' }}
               sx={{ maxHeight: '42.25px', ...(!isMobileScreen && { minWidth: '100px' }) }}
               variant="outlined"
               color="inherit"
               onClick={handleSubmit}
               disabled={isButtonLoading}
            >
               {isButtonLoading ? <CircularProgress size={14} thickness={8} color="inherit" /> : 'Apply'}
            </StyledApplyButton>
         </Stack>
      </>
   );
};

export default FreeForm;
