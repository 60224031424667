import React, { useEffect, useState, useMemo, RefObject } from 'react';
import { Box, Button, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import './styles/OrderBar.scss';
import EventIcon from '@mui/icons-material/Event';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import 'features/order/styles/ProductList.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { orderStore } from 'features/order/stores/order.slice';
import { cartStore, fetchUserCartAsync } from 'features/cart/stores/cart.slice';
import {
   resetPostUserSubscriptionStatus,
   setError,
   setSubscriptionModActive,
   subscriptionStore,
} from 'features/subscription/stores/subscriptions.slice';
import { getOrderFrequency, getOrderMethod, getOrderDate } from '../cart/utils/cart.helper';
import { useBlocker, useNavigate } from 'react-router-dom';
import { setOrderDialogOpen, setOrderDialogOption } from 'features/order/stores/order-bar.slice';
import { setNavListIndex } from '../profile/stores/profile.slice';
import { LoadStatus } from '../../config/utils';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import CircularProgress from '@mui/material/CircularProgress';
import { handleSubscriptionSave } from './utils/order-bar.helper';
import { MptLink } from 'components/MptLink';
import { adjustHeaderTop } from '../order/utils/prduct-list.helper';
import SkeletonLoader from 'components/SkeletonLoader';
import { orderLoadStore } from '../order/stores/order-load.slice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import CommonDialog from '../../components/CommonDialog';
import { changeCustomModalOpen } from '../../components/utils/dialog.helper';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import { packageStore } from '../package/stores/package.slice';
import { companyStore } from '../company/stores/company.slice';
import useIsSmallMobileScreen from 'app/useIsSmallMobileScreen';

interface IOrderBarProps {
   headerContainerRef: RefObject<HTMLDivElement>;
}

const OrderBar = ({ headerContainerRef }: IOrderBarProps) => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const theme = useTheme();

   const [isScrolled, setIsScrolled] = useState(false);
   const [errorOpen, setErrorOpen] = useState(false);
   const { selectedSubscription, subscriptionModActive, postUserSubscriptionStatus, error } =
      useAppSelector(subscriptionStore);
   const { orderBarCardLoading } = useAppSelector(orderLoadStore);
   const { orderFrequency, orderMethod, cart } = useAppSelector(cartStore);
   const { selectedDeliverySlot, deliveryOptions } = useAppSelector(orderStore);
   const { packageName, packageRecurrence } = useAppSelector(packageStore);
   const { companyInfo } = useAppSelector(companyStore);
   const isSmallMobileScreen = useIsSmallMobileScreen();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));

   const fullMobileBar = !isMobileScreen || (isMobileScreen && !isScrolled);
   const orderDate = useMemo(
      () =>
         getOrderDate(
            subscriptionModActive,
            selectedDeliverySlot,
            deliveryOptions,
            true,
            cart,
            postUserSubscriptionStatus,
         ),
      [subscriptionModActive, selectedDeliverySlot, deliveryOptions],
   );
   const isSubEditBar = subscriptionModActive || postUserSubscriptionStatus === LoadStatus.loading;

   const renderOrderInfo = () => {
      const orderMethodDisplay = getOrderMethod(subscriptionModActive, orderMethod, cart);
      const orderFrequencyDisplay = getOrderFrequency(
         subscriptionModActive,
         companyInfo.uiSettings.subscribeAllOnlyMode,
         orderFrequency,
         cart,
         packageRecurrence,
      );

      return (
         <Box display="flex" marginRight={'12px'}>
            <Box display="flex" alignItems="center" marginRight={'5px'}>
               {(orderFrequencyDisplay === 'One-Time' || orderFrequencyDisplay === 'Both') && (
                  <>
                     {!(orderFrequencyDisplay == 'Both' && isSmallMobileScreen) && <EventIcon style={{ margin: 4 }} />}
                     <Typography color={theme.custom.orderBar.color}>One-Time</Typography>
                  </>
               )}
               {orderFrequencyDisplay === 'Both' && (
                  <Typography
                     color={theme.custom.orderBar.color}
                     ml={!(orderFrequencyDisplay == 'Both' && isSmallMobileScreen) ? 1 : 0.5}
                     mr={!(orderFrequencyDisplay == 'Both' && isSmallMobileScreen) ? 0 : 0.5}
                  >
                     and
                  </Typography>
               )}
               {(orderFrequencyDisplay === 'Weekly' || orderFrequencyDisplay === 'Both') && (
                  <>
                     {!(orderFrequencyDisplay == 'Both' && isSmallMobileScreen) && (
                        <EventRepeatOutlinedIcon style={{ margin: 4 }} />
                     )}
                     <Typography color={theme.custom.orderBar.color}>Weekly</Typography>
                  </>
               )}
            </Box>
            <Box display="flex" alignItems="center">
               {orderMethodDisplay === 'Delivery' ? (
                  <LocalShippingIcon style={{ margin: 4 }} />
               ) : (
                  <ShoppingBagOutlinedIcon style={{ margin: 4 }} />
               )}
               <Typography color={theme.custom.orderBar.color}>{orderMethodDisplay}</Typography>
            </Box>
         </Box>
      );
   };

   const renderOrderDate = () => (
      <Box display="contents" flexDirection={isMobileScreen ? 'column' : 'row'} alignItems="center" textAlign="center">
         <Typography padding={0} color={theme.custom.orderBar.color}>
            {packageName ? <>{packageName}, </> : ''}
            {packageName && isMobileScreen && <br />}
            {orderDate}
            {!subscriptionModActive && postUserSubscriptionStatus === LoadStatus.idle && (
               <MptLink marginLeft="10px" fontWeight={600} sx={{ cursor: 'pointer' }} onClick={handleChangeOrderDate}>
                  Change
               </MptLink>
            )}
         </Typography>
      </Box>
   );

   const blocker = useBlocker(subscriptionModActive);

   const handleSubEditLeave = () => {
      blocker.state === 'blocked' && blocker.proceed();
      dispatch(setSubscriptionModActive(false));
      dispatch(fetchUserCartAsync());
   };

   const handleSubEditCancel = () => {
      blocker.state === 'blocked' && blocker.reset();
   };

   const handleBackToSubscription = () => {
      navigate('/user');
      dispatch(setNavListIndex(3));
   };

   const handleChangeOrderDate = () => {
      dispatch(setOrderDialogOption('change'));
      dispatch(setOrderDialogOpen(true));
   };

   const handleCloseErrorDialog = () => {
      setErrorOpen(false);
      dispatch(setError([]));
   };

   useEffect(() => {
      if (postUserSubscriptionStatus === LoadStatus.complete && !subscriptionModActive) {
         handleBackToSubscription();
         dispatch(resetPostUserSubscriptionStatus());
      }
   }, [postUserSubscriptionStatus, subscriptionModActive]);

   useEffect(() => {
      adjustHeaderTop(headerContainerRef, isLargeScreenDown);
      const handleScroll = () => {
         const scrollThreshold = 200;
         const currentScrollY = window.scrollY;
         const buffer = 50; // Add a small buffer to prevent rapid toggling

         if (!isScrolled && currentScrollY > scrollThreshold + buffer) {
            setIsScrolled(true);
         } else if (isScrolled && currentScrollY < scrollThreshold - buffer) {
            setIsScrolled(false);
         }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
   }, [isScrolled]);

   useEffect(() => {
      if (error?.[0]?.errorMessage) {
         setErrorOpen(true);
      }
   }, [error]);

   useEffect(() => {
      changeCustomModalOpen(blocker.state === 'blocked' && subscriptionModActive);
   }, [blocker.state, subscriptionModActive]);

   return (
      <>
         <SkeletonLoader loading={orderBarCardLoading} skeletonProps={{ width: '100%', height: '57px' }}>
            <Box className="order-bar-container" sx={{ color: theme.custom.orderBar.color }}>
               <Stack
                  className={isSubEditBar ? 'order-bar-box subscription-edit-active' : 'order-bar-box'}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  divider={<OrderBarDivider />}
                  useFlexGap
                  flexWrap="wrap"
                  alignItems="center"
                  padding={'5px 5px'}
                  sx={{
                     '& svg': { m: 1 },
                  }}
               >
                  {isSubEditBar ? (
                     <>
                        <Typography>{'Editing Subscription:\u00A0'}</Typography>
                        {((isMobileScreen && !isScrolled) || !isMobileScreen) && renderOrderDate()}
                        <Box
                           display={'flex'}
                           justifyItems={'center'}
                           justifyContent={'center'}
                           gap={isMobileScreen ? 2 : 4}
                           width={'100%'}
                           pt={1}
                           pb={1}
                        >
                           <Button
                              size={isMobileScreen ? 'small' : 'medium'}
                              startIcon={<ArrowBackIcon />}
                              variant="text"
                              onClick={handleBackToSubscription}
                              sx={{ minWidth: '100px', justifyContent: 'flex-start' }}
                           >
                              {isMobileScreen ? 'Back' : 'Back To Subscriptions'}
                           </Button>
                           <Button
                              size={isMobileScreen ? 'small' : 'medium'}
                              startIcon={
                                 postUserSubscriptionStatus === LoadStatus.loading ? (
                                    <CircularProgress size={35} thickness={8} color="inherit" />
                                 ) : (
                                    <DoneIcon />
                                 )
                              }
                              onClick={() => handleSubscriptionSave(selectedSubscription, dispatch, navigate)}
                              disabled={postUserSubscriptionStatus === LoadStatus.loading}
                              variant="outlined"
                              sx={{
                                 ':disabled': { color: '#666666' },
                                 minWidth: '120px',
                                 justifyContent: 'flex-start',
                              }}
                           >
                              {isMobileScreen ? 'Save' : 'Save Changes'}
                           </Button>
                        </Box>
                     </>
                  ) : (
                     <>
                        {fullMobileBar && renderOrderInfo()}
                        {renderOrderDate()}
                     </>
                  )}
               </Stack>
            </Box>
         </SkeletonLoader>
         <ConfirmationDialog
            open={blocker.state === 'blocked' && subscriptionModActive}
            onClose={handleSubEditCancel}
            title={'Leave subscription edit site?'}
            confirmContent={
               <Typography variant={'h5'}>Changes you made to this subscription may not be saved.</Typography>
            }
            confirmBtnText={'Leave'}
            cancelBtnText={'Cancel'}
            onConfirm={handleSubEditLeave}
            onCancel={handleSubEditCancel}
         />
         <CommonDialog
            title={''}
            open={errorOpen}
            onClose={handleCloseErrorDialog}
            onSubmit={handleCloseErrorDialog}
            submitLabel={'Ok'}
            alertMessage={error?.[0]?.errorMessage ?? ''}
            alertSeverity={'error'}
         />
      </>
   );
};

const OrderBarDivider = () => (
   <Divider sx={{ borderWidth: '1px', borderColor: '#504C5680' }} variant="middle" orientation="vertical" flexItem />
);

export default OrderBar;
