import React from 'react';
import { Box, Skeleton, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface SkeletonLoaderProps {
   loading: boolean;
   skeletonProps?: {
      width?: number | string;
      height?: number | string;
      variant?: 'text' | 'rectangular' | 'circular';
      animation?: 'pulse' | 'wave' | false;
   };
   sx?: SxProps<Theme>;
   children: React.ReactNode;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ loading, skeletonProps, sx, children }) => {
   if (loading) {
      return (
         <Box width={skeletonProps?.width || '100%'}>
            {loading ? (
               <Skeleton
                  variant={skeletonProps?.variant || 'rectangular'}
                  width={skeletonProps?.width || '100%'}
                  height={skeletonProps?.height || 100}
                  animation={skeletonProps?.animation || 'wave'}
                  sx={sx}
               />
            ) : (
               children
            )}
         </Box>
      );
   }

   return <>{children}</>;
};

export default SkeletonLoader;
