import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { Alert, Box, Button, DialogActions, DialogContent, Fab, Link, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import {
   accountStore,
   submitNewPasswordResetAsync,
   setAccountDialogOpen,
   setSeverityMessage,
} from 'features/account/stores/account.slice';
import { INewPasswordResetFormValues } from 'features/account/types/account.types';
import 'features/account/styles/Account.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@mui/material/CircularProgress';
import {
   NEW_PASSWORD_RESET_DEFAULT_VALUES,
   NEW_PASSWORD_RESET_SCHEMA,
} from 'features/account/config/account-form.const';
import { LoadStatus } from 'config/utils';
import { loadMPTSession } from 'utils/mpt-session.helper';
import Grid from '@mui/material/Grid';
import FormInput from '../../../components/FormInput';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

interface INewPasswordResetProps {
   username: string;
   token: string;
   newPasswordResetOpen: boolean;
   setNewPasswordResetOpen: (open: boolean) => void;
}
const NewPasswordResetDialog = ({
   username,
   token,
   newPasswordResetOpen,
   setNewPasswordResetOpen,
}: INewPasswordResetProps) => {
   const loadedMptSession = loadMPTSession();
   const navigate = useNavigate();
   const { severityMessage, newPasswordResetStatus, newPasswordResetResponse, noCloseBtn } =
      useAppSelector(accountStore);
   const dispatch = useAppDispatch();
   const isNewPasswordResetLoading = newPasswordResetStatus === LoadStatus.loading;
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const methods = useForm<INewPasswordResetFormValues>({
      defaultValues: NEW_PASSWORD_RESET_DEFAULT_VALUES,
      resolver: yupResolver(NEW_PASSWORD_RESET_SCHEMA),
   });

   const onBackToMenu = () => {
      navigate('/order');
      handleClose();
   };

   const handleClose = () => {
      setNewPasswordResetOpen(false);
      methods.reset();
   };

   const onSubmit = (data: any) => {
      dispatch(setSeverityMessage(null));
      dispatch(
         submitNewPasswordResetAsync({
            bodyInput: {
               ip: loadedMptSession.ip,
               password: data.password,
               token: token,
               username: username,
            },
         }),
      );
   };

   const onError = (errors: any) => {};

   const onResetAgain = () => {
      setAccountDialogOpen(true);
      handleClose();
   };

   useEffect(() => {
      if (!isNewPasswordResetLoading && newPasswordResetStatus === LoadStatus.complete) {
         if (newPasswordResetResponse?.success) {
            setAccountDialogOpen(true);
            handleClose();
         }
      }
   }, [newPasswordResetStatus]);

   return (
      <>
         <Dialog
            className="base-dialog"
            onClose={handleClose}
            open={newPasswordResetOpen}
            fullScreen={isMobileScreen}
            disableScrollLock
            PaperProps={{
               className: 'base-style',
            }}
         >
            <div className="base-dialog-header">
               <DialogTitle className={'title'}>Enter New Password</DialogTitle>
               {noCloseBtn ? (
                  <Button startIcon={<ArrowBackIcon />} variant="contained" onClick={onBackToMenu}>
                     Back to menu
                  </Button>
               ) : (
                  <Fab size="small" color="primary" onClick={handleClose}>
                     <CloseIcon />
                  </Fab>
               )}
            </div>
            <DialogContent className="base-dialog-content">
               <div className="fields-container">
                  <div className={'account-instruction'}>{username}</div>
                  <FormProvider {...methods}>
                     <form id="account-popup" onSubmit={methods.handleSubmit(onSubmit, onError)}>
                        <Box sx={{ p: 0 }}>
                           <Grid container spacing={2}>
                              <Grid item xs={12}>
                                 <FormInput name="password" label="Password" type={'password'} />
                              </Grid>
                              <Grid item xs={12}>
                                 <FormInput name="password_confirmation" label="Confirm Password" type={'password'} />
                              </Grid>
                           </Grid>
                        </Box>
                     </form>
                  </FormProvider>
                  {severityMessage && (
                     <Box marginTop={1.5}>
                        <Alert severity={severityMessage.severity}>
                           {severityMessage.message}
                           <Link
                              color="inherit"
                              sx={{ marginLeft: '5px', cursor: 'pointer', fontWeight: 600 }}
                              onClick={() => onResetAgain()}
                           >
                              try reset again
                           </Link>
                        </Alert>
                     </Box>
                  )}
               </div>
            </DialogContent>
            <DialogActions className="base-dialog-footer">
               <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  form="account-popup"
                  disabled={isNewPasswordResetLoading}
               >
                  {isNewPasswordResetLoading ? (
                     <CircularProgress size={14} thickness={8} color={'inherit'} />
                  ) : (
                     'Reset My Password'
                  )}
               </Button>
            </DialogActions>
         </Dialog>
      </>
   );
};

export default NewPasswordResetDialog;
