import React from 'react';
import { Box, Grid, List, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import Slider from 'react-slick';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { checkoutStore } from '../../stores/checkout.slice';
import { IProduct } from 'features/order/types/product.types';
import { convertProductViewEventData, trackMptEvent } from 'features/order/utils/event.helper';
import { TrackEventType } from 'features/order/types/event.types';
import { setSelectedProduct } from 'features/order/stores/product.slice';
import { setOpenMenuItem } from 'features/menuItem/stores/menuItem.slice';
import { getCartDeliveryIndexFromSelected } from 'features/order/config/order.helper';
import { cartStore } from 'features/cart/stores/cart.slice';
import { orderStore } from 'features/order/stores/order.slice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/FeatureItems.scss';
import ProductCardHorizontal from 'features/order/components/ProductCardHorizontal';
import ProductCardVertical from 'features/order/components/ProductCardVertical';

const FeatureItems = () => {
   const dispatch = useAppDispatch();
   const theme = useTheme();
   const { featuredProducts } = useAppSelector(checkoutStore);
   const { cart } = useAppSelector(cartStore);
   const { selectedDeliverySlot } = useAppSelector(orderStore);
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

   const customArrows = {
      color: theme.palette.primary.main,
      width: '40px',
      height: '50px',
      '&:hover': {
         color: 'gray',
      },
      zIndex: 10,
   };

   const settings = {
      slidesToShow: 2,
      slidesToScroll: 1,
      nextArrow: <ArrowForwardIosIcon sx={{ ...customArrows, right: '-5px' }} />,
      prevArrow: <ArrowBackIosNewIcon sx={{ ...customArrows, left: '-5px' }} />,
      speed: 0,
   };

   const findCountInCart = (product: IProduct) => {
      let count = 0;
      cart?.deliveries[getCartDeliveryIndexFromSelected(cart, selectedDeliverySlot)]?.products
         .filter((cartProduct) => {
            return cartProduct.productId === product.id;
         })
         ?.forEach((cartProduct) => {
            count += cartProduct.qty;
         });

      return count;
   };

   const onOpenMenuItem = (open: boolean) => {
      dispatch(setOpenMenuItem(open));
   };

   const handleProductSelection = (itemId: string) => {
      const selectedProduct = featuredProducts.find((product) => product.id === itemId);
      selectedProduct && trackMptEvent(TrackEventType.productView, convertProductViewEventData(selectedProduct));
      dispatch(setSelectedProduct(selectedProduct));
      onOpenMenuItem(true);
   };

   const listProducts = featuredProducts.map((featuredProduct: IProduct, productIndex: number) => {
      const countInCart = findCountInCart(featuredProduct);
      return (
         <Box key={`featured-${productIndex}`} p={1}>
            {isMobileScreen ? (
               <ProductCardVertical
                  {...{
                     item: featuredProduct,
                     countInCart,
                     handleProductSelection,
                     titleVariant: isMobileScreen ? 'h6' : 'h5',
                  }}
               />
            ) : (
               <ProductCardHorizontal {...{ item: featuredProduct, countInCart, handleProductSelection }} />
            )}
         </Box>
      );
   });

   return (
      <>
         <List>
            <ListItem>
               <Grid item xs={12}>
                  <div className="bar-box">
                     <Typography variant={'h6'} color="primary" fontWeight="bold" mb={1} mt={1}>
                        Feature Items
                     </Typography>
                  </div>
               </Grid>
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
               <Grid className="feature-items-container" container>
                  <Grid item xs={12}>
                     <Slider {...settings}>{listProducts}</Slider>
                  </Grid>
               </Grid>
            </ListItem>
         </List>
      </>
   );
};

export default FeatureItems;
