import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'app/hooks';
import {
   ListItemText,
   Box,
   ListItem,
   useTheme,
   Stack,
   Popover,
   List,
   ListItemButton,
   Badge,
   useMediaQuery,
   FormControlLabel,
   Switch,
} from '@mui/material';
import 'features/order/styles/ProductList.scss';
import { IProduct, IProductCategory } from '../types/product.types';
import { CategoryProductMapping } from '../types/category-product.types';
import { productStore } from '../stores/product.slice';
import { getReplacedTag } from '../../menuItem/utils/menu-item.helper';
import { loadMPTSession } from 'utils/mpt-session.helper';
import { ITagOverride } from '../../menuItem/types/menu-item.types';
import Checkbox from '@mui/material/Checkbox';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import BaseTextField from 'components/BaseTextField';
import IconButton from '@mui/material/IconButton';

interface SearchBarProps {
   searchTerm: string;
   setSearchTerm: (searchTerm: string) => void;
   selectedOptions: string[];
   setSelectedOptions: (selectedOptions: string[]) => void;
   setDataToShow: (dataToShow: CategoryProductMapping) => void;
}
const SearchBar = ({
   searchTerm,
   setSearchTerm,
   selectedOptions,
   setSelectedOptions,
   setDataToShow,
}: SearchBarProps) => {
   const theme = useTheme();
   const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
   const { productList, productCategoryList } = useAppSelector(productStore);
   const loadedMptSession = loadMPTSession();
   const tagOverrides: ITagOverride[] | null = loadedMptSession?.companyInfo?.uiSettings?.tagOverrides?.length
      ? loadedMptSession?.companyInfo?.uiSettings?.tagOverrides
      : null;
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [isMatchAllMode, setIsMatchAllMode] = useState<boolean>(true);
   const open = Boolean(anchorEl);
   const id = open ? 'filter-popover' : undefined;

   const categoryProductMapping = useMemo(() => {
      const mapping: CategoryProductMapping = {};
      const categoriesWithProducts: IProductCategory[] = [];
      productCategoryList.forEach((category: IProductCategory) => {
         const products = productList.filter((product: IProduct) => {
            const productCategoryIds = product.categories.map((category) => {
               return category.id;
            });
            return productCategoryIds.includes(category.id);
         });
         if (products.length > 0) {
            mapping[category.name] = products;
            categoriesWithProducts.push(category);
         }
      });
      return { mapping, categoriesWithProducts };
   }, [productList, productCategoryList]);

   const [searchResults, setSearchResults] = useState<CategoryProductMapping>({});

   const isSearching = searchTerm.trim() !== '' || selectedOptions.length > 0;

   const productListTags = Array.from(
      new Set(
         productList.flatMap((product: IProduct) =>
            product.tags
               .map((tag) =>
                  getReplacedTag(tag.name, tagOverrides).name.length > 0
                     ? getReplacedTag(tag.name, tagOverrides).name
                     : tag.name,
               )
               .filter((tag) => tag.length > 0),
         ),
      ),
   );

   const handleTagOptionsChange = (value: string) => () => {
      const currentIndex = selectedOptions.indexOf(value);
      const newChecked = [...selectedOptions];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }
      setSelectedOptions(newChecked);
   };

   const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
   };

   const matchedTag = (product: IProduct): boolean => {
      const tags = product.tags.map((tag) =>
         tagOverrides ? getReplacedTag(tag.name, tagOverrides).name || tag.name : tag.name,
      );
      return (
         selectedOptions.length === 0 ||
         (isMatchAllMode
            ? selectedOptions.every((tag) => tags.includes(tag))
            : tags.some((tag) => selectedOptions.includes(tag)))
      );
   };

   const handleSearch = (searchTerm: string) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      const filteredResults = Object.entries(categoryProductMapping.mapping).reduce(
         (acc: CategoryProductMapping, [categoryName, products]) => {
            const isCategoryMatch = searchTerm.length > 0 && categoryName.toLowerCase().includes(lowerCaseSearchTerm);

            const filteredProducts = products.filter((product) => {
               return (
                  (matchedTag(product) &&
                     (product.description.toLowerCase().includes(lowerCaseSearchTerm) ||
                        product.name.toLowerCase().includes(lowerCaseSearchTerm))) ||
                  isCategoryMatch
               );
            });
            if (isCategoryMatch || filteredProducts.length > 0) {
               acc[categoryName] = filteredProducts;
            }
            return acc;
         },
         {},
      );
      window.scrollTo(0, 0);
      setSearchResults(filteredResults);
   };

   const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget as unknown as HTMLElement);
   };

   const handleFilterClose = () => {
      setAnchorEl(null);
   };

   const handleClearOptions = (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setSelectedOptions([]);
   };

   const handleSwitchMatchToggle = (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      setIsMatchAllMode((prev) => !prev);
   };

   useEffect(() => {
      handleSearch(searchTerm);
   }, [searchTerm]);

   useEffect(() => {
      handleSearch(searchTerm);
   }, [selectedOptions, isMatchAllMode]);

   useEffect(() => {
      setDataToShow(isSearching ? searchResults : categoryProductMapping.mapping);
   }, [isSearching, searchResults, categoryProductMapping.mapping, selectedOptions]);

   return (
      <Stack direction="row" alignItems="center" width="100%" justifyContent="flex-end">
         {productListTags?.length > 0 ? (
            <IconButton
               onClick={(e) => handleFilterClick(e)}
               sx={{
                  backgroundColor: theme.palette.secondary.main,
                  width: '50px',
                  height: '50px',
                  marginRight: '4px',
               }}
            >
               <Badge
                  badgeContent={selectedOptions.length}
                  color="success"
                  sx={{
                     '& .MuiBadge-badge': {
                        marginTop: '-8px',
                        marginRight: '-8px',
                     },
                  }}
               >
                  <FilterAltOutlinedIcon fontSize="medium" color="primary" sx={{ cursor: 'pointer' }} />
               </Badge>
            </IconButton>
         ) : null}

         <Popover
            id={id}
            open={open}
            sx={{ width: '100%' }}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
            }}
         >
            <List sx={{ minWidth: '240px' }}>
               {['Clear', ...productListTags].map((option) =>
                  option === 'Clear' ? (
                     <ListItem
                        key={option}
                        style={{
                           position: 'sticky',
                           top: '-10px',
                           marginTop: '-5px',
                           zIndex: 1000,
                           cursor: 'default',
                           pointerEvents: 'none',
                           backgroundColor: '#ffffff',
                        }}
                     >
                        <Stack direction="row" alignItems="center" spacing={3} sx={{ width: '100%' }}>
                           <FormControlLabel
                              control={
                                 <Switch
                                    checked={isMatchAllMode}
                                    onChange={handleSwitchMatchToggle}
                                    color="primary"
                                    style={{
                                       pointerEvents: 'auto',
                                    }}
                                 />
                              }
                              label={'Match All'}
                           />
                           <Box
                              onClick={handleClearOptions}
                              justifyContent={'flex-end'}
                              style={{
                                 pointerEvents: 'auto',
                                 fontWeight: 'bold',
                                 textDecoration: 'underline',
                                 cursor: 'pointer',
                                 marginLeft: 'auto',
                              }}
                              pr={2}
                           >
                              {option}
                           </Box>
                        </Stack>
                     </ListItem>
                  ) : (
                     <ListItem key={option} disablePadding>
                        <ListItemButton onClick={handleTagOptionsChange(option)}>
                           <Checkbox
                              checked={selectedOptions.includes(option)}
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                           />

                           <ListItemText primary={option} />
                        </ListItemButton>
                     </ListItem>
                  ),
               )}
            </List>
         </Popover>
         <Box width="100%" maxWidth={isLargeScreen ? theme.spacing(30) : 'none'}>
            <BaseTextField value={searchTerm} onChange={handleSearchChange} placeholder="Search" />
         </Box>
      </Stack>
   );
};

export default SearchBar;
