import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import {
   SectionCard,
   SectionCardActions,
   SectionCardContent,
   SectionCardHeader,
} from '../../checkout/components/SectionCard';
import FormInput from '../../../components/FormInput';
import { FormProvider } from 'react-hook-form';
import FormButton from '../../../components/FormButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from '../../../app/hooks';
import { accountStore } from '../../account/stores/account.slice';

interface ProfileCardProps {
   fields: Array<{
      label: string;
      name: string;
      required: boolean;
   }>;
   profileTitle?: string;
   methods: any;
   onSubmit: (data: any) => void;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ fields, profileTitle, methods, onSubmit }) => {
   const { updateAccountStatus } = useAppSelector(accountStore);

   return (
      <SectionCard>
         <SectionCardHeader
            title={
               <Grid item xs={12}>
                  <div className="bar-box">
                     <Typography variant={'h6'} fontWeight="bold" mb={1} mt={1}>
                        {profileTitle}
                     </Typography>
                  </div>
               </Grid>
            }
         ></SectionCardHeader>
         <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
               <SectionCardContent>
                  <Stack direction="column" gap={2} width="100%" minHeight={120}>
                     <Box sx={{ p: 0, mt: 3, mb: 1 }}>
                        <Grid container spacing={2}>
                           {fields.map((field) => {
                              return (
                                 <Grid key={field.name} item xs={12} sm={6}>
                                    <FormInput
                                       name={field.name}
                                       label={field.label}
                                       InputLabelProps={{ shrink: methods.getValues(field.name).length > 0 }}
                                    />
                                 </Grid>
                              );
                           })}
                        </Grid>
                     </Box>
                  </Stack>
               </SectionCardContent>
            </form>
         </FormProvider>
         <SectionCardActions>
            <FormButton
               label={
                  <Stack spacing={1} alignItems="center" width="100%" justifyContent="center" direction="row">
                     {updateAccountStatus === 'loading' ? (
                        <CircularProgress size={20} thickness={8} color={'inherit'} />
                     ) : (
                        <Typography variant="body1" component="span">
                           Save
                        </Typography>
                     )}
                  </Stack>
               }
               onClick={methods.handleSubmit(onSubmit)}
            />
         </SectionCardActions>
      </SectionCard>
   );
};

export default ProfileCard;
