import { FormControl, Select, MenuItem, InputAdornment } from '@mui/material';
import { BaseSelectProps } from './types/base-select-props.types';
import ReorderIcon from '@mui/icons-material/Reorder';
import React from 'react';

const BaseSelect: React.FC<BaseSelectProps> = ({ label, name, onChange, options }) => {
   return (
      <FormControl fullWidth variant="outlined">
         <Select
            value={name}
            onChange={onChange}
            displayEmpty
            renderValue={(selected) => {
               if (selected !== '') {
                  return selected;
               }
               return (
                  <React.Fragment>
                     <ReorderIcon style={{ verticalAlign: 'middle' }} /> <span>Categories</span>
                  </React.Fragment>
               );
            }}
            // label={label}
         >
            {options.map((option, index) => (
               <MenuItem key={index} value={option.name}>
                  {option.name}
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   );
};

export default BaseSelect;
