import React, { useState, MouseEvent } from 'react';
import { Menu, MenuItem, Stack, Typography, ToggleButton, FormHelperText, Box } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import { OrderType } from 'features/order/config/order.const';
import { changeDeliveryLocationAsync, orderStore } from 'features/order/stores/order.slice';
import { LoadStatus } from 'config/utils';
import { accountStore } from 'features/account/stores/account.slice';
import { StyledToggleButtonGroup } from './StyledToggleButtonGroup';
import CommonProgressLoader from 'components/CommonLoader';
import useIsSmallMobileScreen from 'app/useIsSmallMobileScreen';

interface IOrderMethodProps {
   standard?: boolean;
   hasDeliveryMethodError?: boolean;
}

const OrderMethod = ({ standard, hasDeliveryMethodError }: IOrderMethodProps) => {
   const dispatch = useAppDispatch();
   const isSmallMobileScreen = useIsSmallMobileScreen();
   const { cart, orderMethod, deliveryLocations } = useAppSelector(cartStore);
   const { changeLocationStatus } = useAppSelector(orderStore);
   const { user } = useAppSelector(accountStore);

   const [deliveryAnchorEl, setDeliveryAnchorEl] = useState<null | HTMLElement>(null);
   const [pickupAnchorEl, setPickupAnchorEl] = useState<null | HTMLElement>(null);

   const openDeliveryMenu = Boolean(deliveryAnchorEl);
   const openPickupMenu = Boolean(pickupAnchorEl);

   const homeDeliveryLocations = deliveryLocations.filter((location) => location.type === OrderType.homeDelivery);
   const pickupLocations = deliveryLocations.filter((location) => location.type === OrderType.pickup);

   const closeMenu = (method: string) => {
      if (method === OrderType.homeDelivery) setDeliveryAnchorEl(null);
      if (method === OrderType.pickup) setPickupAnchorEl(null);
   };

   const handleMenuItemClick = (method: string, locationId: string) => {
      const { shippingFirstName, shippingLastName, shippingPhone, shippingLocationId } = cart.deliveries[0] || {};

      if (locationId !== shippingLocationId) {
         const payload = {
            shippingLocationId: locationId,
            updatePresets: true,
            shippingFirstName: shippingFirstName || user.firstName,
            shippingLastName: shippingLastName || user.lastName,
            shippingPhone: shippingPhone || user.phone,
         };

         dispatch(changeDeliveryLocationAsync(payload));
      }

      closeMenu(method);
   };

   const handleToggleClick = (event: MouseEvent<HTMLElement>, method: string) => {
      const locations = method === OrderType.homeDelivery ? homeDeliveryLocations : pickupLocations;

      if (locations.length === 1) {
         handleMenuItemClick(method, locations[0].id);
      } else {
         method === OrderType.homeDelivery
            ? setDeliveryAnchorEl(event.currentTarget)
            : setPickupAnchorEl(event.currentTarget);
      }
   };

   const renderMenuItems = (method: string) => {
      return deliveryLocations
         .filter((location) => location.type === method)
         .map((location) => (
            <MenuItem
               key={location.id}
               onClick={() => handleMenuItemClick(method, location.id)}
               selected={!!cart?.deliveries?.length && location.id === cart.deliveries[0].shippingLocationId}
            >
               {location.name}
            </MenuItem>
         ));
   };

   const renderButtonContent = (label: string, icon?: React.ReactNode) => {
      const locations = label === 'Delivery' ? homeDeliveryLocations : pickupLocations;

      return (
         <>
            {changeLocationStatus === LoadStatus.loading && <CommonProgressLoader />}
            {icon ? icon : null}
            <span>{label}</span>
            {locations.length > 1 && <ArrowDropDownIcon />}
         </>
      );
   };

   return (
      <>
         <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Typography sx={{ fontWeight: '600' }} color={standard ? 'initial' : 'primary'} variant="body1">
               Method
            </Typography>
            <Box>
               <StyledToggleButtonGroup
                  useDefault={standard}
                  hasError={hasDeliveryMethodError}
                  value={orderMethod}
                  exclusive
                  aria-label="order-method"
                  color={standard ? 'standard' : 'primary'}
               >
                  {homeDeliveryLocations?.length > 0 && (
                     <ToggleButton
                        value={OrderType.homeDelivery}
                        onClick={(event) => handleToggleClick(event, OrderType.homeDelivery)}
                        disabled={changeLocationStatus === LoadStatus.loading}
                     >
                        {renderButtonContent(
                           'Delivery',
                           !isSmallMobileScreen ? <LocalShippingIcon sx={{ mr: 1 }} /> : null,
                        )}
                     </ToggleButton>
                  )}
                  {pickupLocations?.length > 0 && (
                     <ToggleButton
                        value={OrderType.pickup}
                        onClick={(event) => handleToggleClick(event, OrderType.pickup)}
                        disabled={changeLocationStatus === LoadStatus.loading}
                     >
                        {renderButtonContent(
                           'Pick-up',
                           !isSmallMobileScreen ? <ShoppingBagOutlinedIcon sx={{ mr: 1 }} /> : null,
                        )}
                     </ToggleButton>
                  )}
               </StyledToggleButtonGroup>
               <FormHelperText error={hasDeliveryMethodError}>
                  {hasDeliveryMethodError ? 'method is required' : ''}
               </FormHelperText>
            </Box>
         </Stack>
         <Menu anchorEl={deliveryAnchorEl} open={openDeliveryMenu} onClose={() => closeMenu(OrderType.homeDelivery)}>
            {renderMenuItems(OrderType.homeDelivery)}
         </Menu>
         <Menu anchorEl={pickupAnchorEl} open={openPickupMenu} onClose={() => closeMenu(OrderType.pickup)}>
            {renderMenuItems(OrderType.pickup)}
         </Menu>
      </>
   );
};

export default OrderMethod;
