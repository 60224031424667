import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { LoadStatus } from 'config/utils';
import { IPackage } from '../types/package.types';
import { fetchPackages } from '../services/package.api';
import { changeCustomModalOpen } from 'components/utils/dialog.helper';
import { TOrderRecurrence } from '../../cart/types/cart.types';
import { getPackageRecurrence } from '../utils/package.helper';
import { fetchUserCartAsync } from '../../cart/stores/cart.slice';

export interface PackageState {
   fetchPackagesStatus: LoadStatus;
   packages: IPackage[] | null;
   packageId: string | null;
   packageRecurrence: TOrderRecurrence | null;
   packageName: string | null;
   packageMenuMode: 'main' | 'selector' | 'detail' | 'edit';
   openPackageItem: boolean;
   packageDetail: IPackage | undefined;
}

export const initialPackageState: PackageState = {
   fetchPackagesStatus: LoadStatus.idle,
   packages: null,
   packageId: null,
   packageName: null,
   packageRecurrence: null,
   packageMenuMode: 'main',
   openPackageItem: false,
   packageDetail: {
      addtlDescription: '',
      attributesJArray: null,
      description: '',
      id: '',
      image: '',
      name: '',
      packageOrder: 0,
      type: '',
      oneTime: false,
      weekly: false,
      biWeekly: false,
      monthly: false,
   },
};

export const fetchPackagesAsync = createAsyncThunk(
   'package/fetchPackages',
   async (input: { fetchCart: boolean }, { dispatch }) => {
      const result = await fetchPackages();
      if (input.fetchCart) {
         await dispatch(fetchUserCartAsync());
      }

      return result;
   },
);

export const packageSlice = createSlice({
   name: 'package',
   initialState: initialPackageState,
   reducers: {
      setOpenPackageItem: (state, action: PayloadAction<boolean>) => {
         changeCustomModalOpen(action.payload);
         state.openPackageItem = action.payload;
      },
      setPackageMenuMode: (state, action: PayloadAction<'main' | 'selector' | 'detail' | 'edit'>) => {
         state.packageMenuMode = action.payload;
      },
      setPackageId: (state, action: PayloadAction<string | null>) => {
         const foundPackage = state.packages?.find((mptPackage) => mptPackage.id === action.payload);
         if (foundPackage) {
            state.packageRecurrence = getPackageRecurrence(foundPackage);
            state.packageName = foundPackage.name;
         }
         state.packageId = action.payload;
      },
      setSelectedPackage: (state, action: PayloadAction<IPackage | undefined>) => {
         state.packageDetail = action.payload;
      },
      resetFetchPackagesStatus: (state) => {
         state.fetchPackagesStatus = LoadStatus.idle;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchPackagesAsync.pending, (state) => {
            state.fetchPackagesStatus = LoadStatus.loading;
         })
         .addCase(fetchPackagesAsync.fulfilled, (state, action) => {
            state.fetchPackagesStatus = LoadStatus.complete;

            if (action.payload.success) {
               state.packages = action.payload.packages;
            }
         })
         .addCase(fetchPackagesAsync.rejected, (state) => {
            state.fetchPackagesStatus = LoadStatus.failed;
         });
   },
});

export const { setOpenPackageItem, setPackageMenuMode, setPackageId, setSelectedPackage, resetFetchPackagesStatus } =
   packageSlice.actions;

export const packageStore = (state: RootState) => state.packageStore;

export default packageSlice.reducer;
