import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Common } from 'config/utils';
import { useAppSelector } from 'app/hooks';
import { IProductVariation } from 'features/order/types/product.types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { productStore } from 'features/order/stores/product.slice';
import { getOptionImg } from '../utils/menu-item.helper';
import { Box } from '@mui/material';

interface IFormDropdownProps {
   variation: IProductVariation;
   fieldIndex: number;
   control: any;
   updateSelectionProps: (index: number, item: any, type: string) => void;
}

const FormDropdown = ({ variation, fieldIndex, control, updateSelectionProps }: IFormDropdownProps) => {
   const { getValues } = useFormContext();

   const getDefaultOption = () => {
      if (!variation || !variation.options) return;
      const foundDefaultOption: any = variation.options.find((option: any) => {
         return option.default;
      });
      return foundDefaultOption ? foundDefaultOption : variation.options[0];
   };

   const getPrevSelectedOption = () => {
      if (!variation || !variation.options) return;
      const foundOption: any = variation.options.find((option: any) => {
         return option.id === getValues(`variations.${fieldIndex}.option_id`)?.[0];
      });
      return foundOption;
   };

   const { selectorProductList } = useAppSelector(productStore);
   const [checkedOption, setCheckedOption] = useState(getPrevSelectedOption() ?? getDefaultOption());

   const handleChange = (event: SelectChangeEvent) => {
      updateSelectionProps(fieldIndex, event.target.value, 'drop-down');
      setCheckedOption(event.target.value);
   };

   return variation && variation.options ? (
      <>
         <div className="item-options">
            <Controller
               name={`variations.${fieldIndex}` as const}
               control={control}
               render={() => (
                  <>
                     <Select className={'dropdown-option'} value={checkedOption} onChange={handleChange}>
                        {variation.options.map((option: any) => {
                           return (
                              <MenuItem key={option.id} value={option}>
                                 <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    overflow={'hidden'}
                                    textOverflow={'ellipsis'}
                                 >
                                    {getOptionImg(option, selectorProductList, 'small') && (
                                       <ListItemIcon>
                                          <img
                                             className={'option-image'}
                                             src={getOptionImg(option, selectorProductList, 'small')}
                                             alt={'no image'}
                                          />
                                       </ListItemIcon>
                                    )}
                                    <Box overflow={'hidden'} textOverflow={'ellipsis'}>
                                       {Common.renderHtml(option.text)}
                                    </Box>
                                 </Box>
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </>
               )}
            />
         </div>
      </>
   ) : null;
};

export default FormDropdown;
