import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Fab, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'features/menuItem/styles/MenuItem.scss';
import { Common } from 'config/utils';
import { IMenuItemFormValues } from 'features/menuItem/types/menu-item-form.types';
import { MENU_ITEM_DEFAULT_VALUES } from 'features/menuItem/utils/menu-item.const';
import { productStore, resetProductDetail } from 'features/order/stores/product.slice';
import { cartStore, setEditCartProduct, setSelectedCartItemIdx } from 'features/cart/stores/cart.slice';
import { LoadStatus } from 'config/utils';
import {
   menuItemStore,
   setMenuMode,
   setModeMessage,
   setSelectorOptionCounts,
} from 'features/menuItem/stores/menuItem.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import PackageMenu from './PackageMenu';
import { packageStore, setOpenPackageItem } from '../stores/package.slice';

const PackageItem = (props: { open: boolean }) => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const { productDetail } = useAppSelector(productStore);
   const { openPackageItem, packageMenuMode } = useAppSelector(packageStore);
   const methods = useForm<IMenuItemFormValues>({
      defaultValues: MENU_ITEM_DEFAULT_VALUES,
      mode: 'onChange',
   });
   const { modeMessage } = useAppSelector(menuItemStore);
   const { postCartStatus } = useAppSelector(cartStore);

   const handleClose = () => {
      const newLocation = {
         pathname: location.pathname,
      };
      navigate(newLocation, { replace: true });
      dispatch(setOpenPackageItem(false));
   };

   useEffect(() => {
      if (postCartStatus === LoadStatus.complete) {
         handleClose();
      }
   }, [postCartStatus]);

   useEffect(() => {
      if (!openPackageItem) {
         dispatch(setMenuMode('main'));
         dispatch(resetProductDetail());
         dispatch(setSelectedCartItemIdx(-1));
         dispatch(setSelectorOptionCounts([]));
         dispatch(setModeMessage(null));
         dispatch(setEditCartProduct(null));
         methods.reset();
      }
   }, [openPackageItem]);

   return typeof productDetail !== 'undefined' ? (
      <FormProvider {...methods}>
         <Dialog
            className="menu-item-dialog"
            fullScreen={isMobileScreen}
            disableScrollLock
            onClose={handleClose}
            open={props.open}
            maxWidth={'xl'}
            PaperProps={{ className: 'menu-item-style' }}
         >
            <div className={'menu-item-header'}>
               <DialogTitle className={'title'}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                     {Common.renderHtml(productDetail.name)}
                     {modeMessage ? (
                        <Typography color={'error'} fontWeight={'bold'}>
                           {modeMessage}
                        </Typography>
                     ) : null}
                  </div>
               </DialogTitle>
               <Stack direction="row" gap={2}>
                  <Fab size="small" color="primary" onClick={handleClose}>
                     <CloseIcon />
                  </Fab>
               </Stack>
            </div>
            {packageMenuMode === 'main' && <PackageMenu />}
         </Dialog>
      </FormProvider>
   ) : null;
};

export default PackageItem;
