import React from 'react';
import { Backdrop, CircularProgress, Box } from '@mui/material';

interface LoadingOverlayProps {
   loading: boolean;
   message?: string;
   fullPage?: boolean;
   children: React.ReactNode;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading, message = '', fullPage = false, children }) => {
   return (
      <Box position="relative" display="inline-block" width="100%">
         {children}
         {loading && (
            <Backdrop
               open={loading}
               sx={{
                  position: fullPage ? 'fixed' : 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  color: '#333',
                  bgcolor: 'rgba(0, 0, 0, 0.0)',
               }}
            >
               <Box display="flex" flexDirection="column" alignItems="center">
                  <CircularProgress color="inherit" />
                  {message && (
                     <Box mt={2} fontSize="1.2rem" fontWeight="bold">
                        {message}
                     </Box>
                  )}
               </Box>
            </Backdrop>
         )}
      </Box>
   );
};

export default LoadingOverlay;
