import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Fab, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles/MenuItem.scss';
import { Common } from 'config/utils';
import { IMenuItemFormValues } from './types/menu-item-form.types';
import { MENU_ITEM_DEFAULT_VALUES } from './utils/menu-item.const';
import { productStore, resetProductDetail } from 'features/order/stores/product.slice';
import { cartStore, setEditCartProduct, setSelectedCartItemIdx } from '../cart/stores/cart.slice';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { LoadStatus } from 'config/utils';
import MainMenu from './components/MainMenu';
import SelectorMenu from './components/SelectorMenu';
import DetailedMenu from './components/DetailedMenu';
import {
   menuItemStore,
   setMenuMode,
   setModeMessage,
   setOpenMenuItem,
   setSelectorOptionCounts,
} from './stores/menuItem.slice';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import EditMenu from './components/EditMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { subscriptionStore } from '../subscription/stores/subscriptions.slice';
import { changeCustomModalOpen } from '../../components/utils/dialog.helper';

const MenuItem = (props: { open: boolean }) => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   const theme = useTheme();
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const { productDetail } = useAppSelector(productStore);
   const methods = useForm<IMenuItemFormValues>({
      defaultValues: MENU_ITEM_DEFAULT_VALUES,
      mode: 'onChange',
   });
   const { openMenuItem, menuMode, modeMessage } = useAppSelector(menuItemStore);
   const { subscriptionModActive } = useAppSelector(subscriptionStore);
   const { postCartStatus } = useAppSelector(cartStore);
   const { editCartProduct } = useAppSelector(cartStore);
   const [confirmationOpen, setConfirmationOpen] = useState(false);

   const onConfirm = () => {
      handleClose();
      setConfirmationOpen(false);
   };

   const handleClose = () => {
      if (!subscriptionModActive) {
         const newLocation = {
            pathname: location.pathname,
         };
         navigate(newLocation, { replace: true });
      }
      dispatch(setOpenMenuItem(false));
   };

   const handleBack = () => {
      if (menuMode === 'selector') {
         dispatch(setSelectorOptionCounts([]));
         dispatch(setMenuMode(editCartProduct ? 'edit' : 'main'));
      }
      if (menuMode === 'detail') dispatch(setMenuMode('selector'));
      dispatch(setModeMessage(null));
   };

   useEffect(() => {
      if (postCartStatus === LoadStatus.complete) {
         handleClose();
      }
   }, [postCartStatus]);

   useEffect(() => {
      if (!openMenuItem) {
         dispatch(setMenuMode('main'));
         dispatch(resetProductDetail());
         dispatch(setSelectedCartItemIdx(-1));
         dispatch(setSelectorOptionCounts([]));
         dispatch(setModeMessage(null));
         dispatch(setEditCartProduct(null));
         methods.reset();
      }
   }, [openMenuItem]);

   useEffect(() => {
      changeCustomModalOpen(confirmationOpen);
   }, [confirmationOpen]);

   return typeof productDetail !== 'undefined' ? (
      <FormProvider {...methods}>
         <Dialog
            className="menu-item-dialog"
            fullScreen={isMobileScreen}
            disableScrollLock
            onClose={['selector', 'detail'].includes(menuMode) ? () => setConfirmationOpen(true) : handleClose}
            open={props.open}
            maxWidth={'xl'}
            PaperProps={{ className: 'menu-item-style' }}
         >
            <div className={'menu-item-header'}>
               <DialogTitle className={'title'}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                     {Common.renderHtml(productDetail.name)}
                     {modeMessage ? (
                        <Typography color={'error'} fontWeight={'bold'}>
                           {modeMessage}
                        </Typography>
                     ) : null}
                  </div>
               </DialogTitle>
               <Stack direction="row" gap={2}>
                  {['selector', 'detail'].includes(menuMode) && (
                     <Fab size="small" color="default" onClick={handleBack}>
                        <KeyboardBackspaceIcon />
                     </Fab>
                  )}
                  <Fab
                     size="small"
                     color="primary"
                     onClick={['selector', 'detail'].includes(menuMode) ? () => setConfirmationOpen(true) : handleClose}
                  >
                     <CloseIcon />
                  </Fab>
               </Stack>
            </div>
            {menuMode === 'main' && <MainMenu />}
            {menuMode === 'selector' && <SelectorMenu />}
            {menuMode === 'detail' && <DetailedMenu />}
            {menuMode === 'edit' && <EditMenu />}
         </Dialog>
         <ConfirmationDialog
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            confirmContent={
               <Typography variant={'h5'}>
                  Are you sure to close the option selector? All existing selections will be lost.
               </Typography>
            }
            confirmBtnText={'Proceed'}
            cancelBtnText={'Cancel'}
            onConfirm={onConfirm}
            onCancel={() => setConfirmationOpen(false)}
         />
      </FormProvider>
   ) : null;
};

export default MenuItem;
