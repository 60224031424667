export const CompanyDefaults = {
   foodie: {
      client_api_key:
         'ODc5YmNmYTktMWFlNS00ZDBmLTljNjMtNTUzZTBiYWZkNTQ3OmFhNmJiMWNkLTRjMjQtNGJlMS1iNDMzLThmMWNmYzFhMzJlNA==',
      colors: {
         primary: '#EF5454',
         primary_font: '#ffffff',
         primary_icon: '#EF5454',
         primary_typography: '#242831',
         primary_background: '#fff',
         secondary: '#FBFBFA',
         order_bar: '#242831',
      },
      fonts: {
         primary_font_family: 'Roboto',
      },
   },
   fitkitchen: {
      client_api_key:
         'M2MyMGFlNjktNjJjYy00MDJmLWFkZjAtZTRlNWQ4YmIyMTk4OmQ2NTNkZTVkLTNlMzktNDI2Yy1hMjQ2LTQzMTcyODc1MTcxZQ==',
      colors: {
         primary: '#00af9a',
         primary_font: '#ffffff',
         primary_icon: '#00af9a',
         primary_typography: '#00af9a',
         primary_background: '#fffcf6',
         secondary: '#00af9a08',
         order_bar: '#fb3448',
      },
   },
   chefdebbie: {
      client_api_key:
         'MGIwNjBiNjQtNjQ2ZS00YmQzLWIyMjQtMjFkOGNiMzQ2YjA5OmMxMWY1Zjg2LTEwZGQtNDQxYy05M2IxLTc4MDY2NTRhYjA5NQ==',
      colors: {
         primary: '#c64f3f',
         primary_font: '#ffffff',
         primary_icon: '#c64f3f',
         primary_typography: '#c64f3f',
         primary_background: '#fffcf6',
         secondary: '#c64f3f0d',
         order_bar: '#c64f3f',
      },
   },
   vibrantmeals: {
      client_api_key:
         'MDU0YjAwMTAtNTEzZi00NTIzLTlkOTAtYzQxOWQ2OWQyMDQzOjM1ZTgyMzU1LTMwMjktNGEzYi1iZmNmLTc1YmE5N2FiMTk0Ng==',
      colors: {
         primary: '#fcba03',
         primary_font: '#000000',
         primary_icon: '#fcba03',
         primary_typography: '#000000',
         primary_background: '#fffcf6',
         secondary: '#fcba030d',
         order_bar: '#c64f3f',
      },
   },
   maddox: {
      client_api_key:
         'Y2FlMTljNTUtNDllNi00OTgzLWJhNjAtYzE2NmMxZDgxNzgwOjc1MjliMzg5LWY3MTYtNDMwOS1hZGY3LWU3Nzc0N2IzMTUzNA==',
      colors: {
         primary: '#b11e29',
         primary_font: '#ffffff',
         primary_icon: '#b11e29',
         primary_typography: '#000000',
         primary_background: '#fffcf6',
         secondary: '#b11e290d',
         order_bar: '#b11e29',
      },
   },
   demo: {
      client_api_key:
         'MDNkOTExMzMtMDkyNy00Nzc3LWFkNDUtYWVhZmE2ZjJhYzE1OmUxOTM3YjcyLTAyYjQtNGJhYS1hOWZjLWI2NWNmNTJhNTU2OA==',
      colors: {
         primary: '#fcba03',
         primary_font: '#000000',
         primary_icon: '#fcba03',
         primary_typography: '#000000',
         primary_background: '#fffcf6',
         secondary: '#fcba030d',
         order_bar: '#c64f3f',
      },
   },
   sprout: {
      client_api_key:
         'YmE3ZGEzMjAtMDViZC00OGRjLWFlYzMtMzkxN2ZjNDcwYTQxOjNlNzc2YTYzLWM1NTctNGYwZC1iOTM4LTQ2NTdiNTJiMmZmNQ==',
      colors: {
         primary: '#f4c1b4',
         primary_font: '#000000',
         primary_icon: '#f4c1b4',
         primary_typography: '#000000',
         primary_background: '#fffcf6',
         secondary: '#b11e290d',
         order_bar: '#000000',
      },
   },
   fitmealsdirect: {
      client_api_key:
         'OGMxNmZlMzItOGJmNC00OWQwLTgxOTItNDRiMzYyZTA3YTFjOjU0ZWE4NzllLTE0MzItNDcwYS04ZGRmLTMwOTZhMzY0M2QwNQ==',
      colors: {
         primary: '#0068b8',
         primary_font: '#ffffff',
         primary_icon: '#0068b8',
         primary_typography: '#000000',
         primary_background: '#fffcf6',
         secondary: '#0068b808',
         order_bar: '#000000',
      },
   },
   wellpreps: {
      client_api_key:
         'OGIyOTU0ZTEtYjZiYS00MGU0LWFhOTMtYzAxZmI2ZTFlNDZhOjU0ZDMyNGZjLTUxMmQtNDc4Zi04YTAzLWNhOGFlOGM3YTVkZg==',
      colors: {
         primary: '#23813A',
         primary_font: '#ffffff',
         primary_icon: '#23813A',
         primary_typography: '#23813A',
         primary_background: 'rgb(255, 252, 246)',
         secondary: 'rgba(0, 175, 154, 0.03)',
         order_bar: '#000000',
      },
   },
   fitkitmeal: {
      client_api_key:
         'ZmUwZDE1NDQtMmZlNi00MDg5LWI1NGYtYzQ0OTU2NmExNTVlOjBmNWQyYWE3LTliOWQtNDczNy1hMDRhLTU3ZWYzODg3NDI3OA==',
      colors: {
         primary: '#91c723',
         primary_font: '#000000',
         primary_icon: '#91c723',
         primary_typography: '#f36817',
         primary_background: 'rgb(255, 252, 246)',
         secondary: 'rgba(0, 175, 154, 0.03)',
         order_bar: '#000000',
      },
   },
   healthkitchen: {
      client_api_key:
         'ZTkyNDMyZGQtMWQxMC00YjM0LWJiZjItZDdkOGFhMzFiYzllOmNkOTRlMDIyLTFjMzYtNGJiMi1iMjEyLTQ5ZDY4NWI0YWY1Yw==',
      colors: {
         primary: '#3db225',
         primary_font: '#ffffff',
         primary_icon: '#3db225',
         primary_typography: '#000000',
         primary_background: '#ffffff',
         secondary: 'rgb(255, 252, 246)',
         order_bar: '#f36817',
      },
   },
   baysidebistro: {
      client_api_key:
         'NzA3Y2RmZjUtOWQ3Zi00ODNiLTkwN2YtMzQwMTc5ZmJkNTU4OjVkM2ExZDgzLTg3MzMtNGYzNS1iMzQ3LTY4OTkzMTJlNzFhYQ==',
      colors: {
         primary: '#ABD5DD',
         primary_font: 'rgb(36, 40, 49)',
         primary_icon: 'rgb(171, 213, 221)',
         primary_typography: 'rgb(36, 40, 49)',
         primary_background: 'rgb(255, 255, 255)',
         secondary: 'rgba(0, 175, 154, 0.03)',
         order_bar: 'rgb(36, 40, 49)',
      },
   },
};
