import React, { useEffect, useState } from 'react';
import {
   ListItem,
   Stack,
   ToggleButtonGroup,
   ToggleButton,
   CircularProgress,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { cartStore } from 'features/cart/stores/cart.slice';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import FormInput from 'components/FormInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { checkoutStore, resetUpdateTipStatus, updateTipAsync } from '../../stores/checkout.slice';
import { LoadStatus } from 'config/utils';
import { StyledApplyButton } from '../customFields/ApplyButton';

interface ITipValue {
   tip?: number | undefined;
}

const schema = yup.object().shape({
   tip: yup.number().optional(),
});

const AddTip = () => {
   const theme = useTheme();
   const dispatch = useAppDispatch();
   const { cart } = useAppSelector(cartStore);
   const { updateTipStatus } = useAppSelector(checkoutStore);
   const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const foundSubtotal = cart.totals?.find((cartTotalItem) => cartTotalItem.name === 'Subtotal');
   const foundWeeklySubtotal = cart.totals?.find((cartTotalItem) => cartTotalItem.name === 'Weekly Subtotal');
   const foundTip = cart.totals?.find((cartTotalItem) => cartTotalItem.name === 'Tip');
   const cartSubtotal = foundSubtotal?.amount || foundWeeklySubtotal?.amount || 0;
   const [tip, setTip] = useState(0);
   const name = 'tip';
   const isLoading = updateTipStatus === LoadStatus.loading;

   const methods = useForm<ITipValue>({
      defaultValues: { tip: foundTip ? foundTip.amount : 0 },
      resolver: yupResolver(schema),
   });
   const { control, setValue } = methods;
   const fieldValue = useWatch({
      control,
      name,
   });
   const tipBtnText = foundTip ? 'Update' : 'Apply';

   const handleTipChange = (_event: React.MouseEvent<HTMLElement>, newTip: number) => {
      if (newTip) {
         const tipAmount = parseFloat((cartSubtotal * (newTip / 100)).toFixed(2));
         setValue(name, tipAmount);
         setTip(newTip);
      }
   };

   const onSubmit = (data: ITipValue) => {
      dispatch(updateTipAsync({ tip: data?.tip ? data?.tip : 0 }));
   };

   const onUpdateTipStatus = () => {
      if (updateTipStatus === LoadStatus.complete) {
         dispatch(resetUpdateTipStatus());
      }
   };

   const handleClear = () => {
      setValue(name, 0);
      setTip(0);
   };

   useEffect(() => {
      if (typeof fieldValue !== 'undefined') {
         const tipAmount = parseFloat((cartSubtotal * (tip / 100)).toFixed(2));
         if (tipAmount !== fieldValue) {
            setTip(0);
         }
      } else {
         setTip(0);
      }
   }, [fieldValue]);

   useEffect(() => {
      onUpdateTipStatus();
   }, [updateTipStatus]);

   return (
      <>
         <ListItem>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} width="100%">
               <ToggleButtonGroup
                  className="order-toggle-group"
                  color="primary"
                  value={tip}
                  exclusive
                  onChange={handleTipChange}
                  aria-label="order-frequency"
                  sx={{ width: '100%' }}
               >
                  <ToggleButton value={10} sx={{ flex: 1 }} disableRipple>
                     10%
                  </ToggleButton>

                  <ToggleButton value={15} sx={{ flex: 1 }} disableRipple>
                     15%
                  </ToggleButton>

                  <ToggleButton value={20} sx={{ flex: 1 }} disableRipple>
                     20%
                  </ToggleButton>
               </ToggleButtonGroup>
            </Stack>
         </ListItem>
         <ListItem sx={{ marginBottom: '20px' }}>
            <Stack spacing={2} width="100%">
               <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                     <Stack
                        direction={isMobileScreen ? 'column' : 'row'}
                        spacing={2}
                        sx={{ width: '100%' }}
                        alignItems="center"
                     >
                        <FormInput
                           name="tip"
                           type="number"
                           label="Add a tip (optional)"
                           InputProps={{
                              endAdornment: fieldValue ? (
                                 <InputAdornment position="end">
                                    <IconButton onClick={handleClear} edge="end" aria-label="clear input">
                                       <ClearIcon />
                                    </IconButton>
                                 </InputAdornment>
                              ) : null,
                           }}
                        />

                        <StyledApplyButton
                           style={{ ...(isMobileScreen && { marginLeft: 'auto' }) }}
                           sx={{ maxHeight: '42.25px', ...(!isMobileScreen && { minWidth: '100px' }) }}
                           variant="outlined"
                           color="inherit"
                           onClick={methods.handleSubmit(onSubmit)}
                           disabled={isLoading}
                        >
                           {isLoading ? <CircularProgress size={14} thickness={8} color="inherit" /> : tipBtnText}
                        </StyledApplyButton>
                     </Stack>
                  </form>
               </FormProvider>
            </Stack>
         </ListItem>
      </>
   );
};

export default AddTip;
