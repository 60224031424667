import React from 'react';
import { Box } from '@mui/material';
import ProfileForm from './ProfileForm';
import ProfileHeader from './ProfileHeader';

const ProfileInfo = () => {
   return (
      <>
         <ProfileHeader headerTitle="Account Info" />
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' }}>
            <ProfileForm />
         </Box>
      </>
   );
};

export default ProfileInfo;
