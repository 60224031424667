import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import '../styles/MenuItem.scss';
import { productStore } from 'features/order/stores/product.slice';
import Grid from '@mui/material/Grid';
import { menuItemStore, setModeMessage, setSelectorOptionCounts } from '../stores/menuItem.slice';
import ProductGallery from './ProductGallery';
import ProductAccordion from './ProductAccordion';
import Tag from './Tag';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { StyledDescriptionContainer } from './MainMenu';

const DetailedMenu = () => {
   const dispatch = useAppDispatch();
   const { selectorProductList } = useAppSelector(productStore);
   const { detailMenuProductId, selectorOptionCounts, selectorField } = useAppSelector(menuItemStore);
   const productDetail = selectorProductList.find((product) => product.id === detailMenuProductId);
   const productImages = productDetail?.images && productDetail.images.length ? productDetail.images : null;

   const getProductOptionCount = () => {
      return selectorOptionCounts.find((optionCount) => optionCount.assoProductId === detailMenuProductId)?.count ?? 0;
   };

   const productCount = getProductOptionCount();

   const isMaxItemReached = () => {
      if (!productDetail) return true;
      return (
         selectorOptionCounts.reduce(
            (acc, optionCount) => (optionCount.assoProductId !== productDetail.id ? acc + optionCount.count : acc),
            0,
         ) +
            productCount >=
         selectorField.variationMax
      );
   };

   const handleItem = (operation: 'add' | 'remove') => {
      const newOptionCounts = [...selectorOptionCounts];
      const foundIndex = selectorOptionCounts.findIndex(
         (optionCount) => optionCount.assoProductId === productDetail?.id,
      );
      if (foundIndex >= 0 && productDetail) {
         if (operation === 'add') {
            newOptionCounts[foundIndex] = { assoProductId: productDetail.id, count: productCount + 1 };
         } else if (operation === 'remove') {
            if (selectorOptionCounts[foundIndex].count > 1) {
               newOptionCounts[foundIndex] = { assoProductId: productDetail.id, count: productCount - 1 };
            } else {
               newOptionCounts.splice(foundIndex, 1);
            }
         }
      } else if (productDetail && operation === 'add') {
         newOptionCounts.push({ assoProductId: productDetail.id, count: 1 });
      }
      dispatch(setSelectorOptionCounts(newOptionCounts));
   };

   useEffect(() => {
      dispatch(setModeMessage(isMaxItemReached() ? 'Maximum selections reached' : null));
   }, [productCount]);

   return productDetail ? (
      <>
         <DialogContent className="menu-item-dialog-content">
            <Grid container>
               <Grid item xs={12} md={6} className={'menu-item-grid'}>
                  {productImages ? <ProductGallery images={productDetail.images} /> : null}
                  <ProductAccordion />
               </Grid>
               <Grid item xs={12} md={6} className={'menu-item-grid'}>
                  <Typography variant={'h5'}>{productDetail.name}</Typography>
                  <Tag product={productDetail} />
                  <StyledDescriptionContainer className="item-description">
                     <Markdown remarkPlugins={[remarkGfm]}>{productDetail.description}</Markdown>
                  </StyledDescriptionContainer>
               </Grid>
            </Grid>
         </DialogContent>
         <DialogActions className="menu-item-dialog-footer">
            <div className="item-count-nav">
               <IconButton
                  onClick={() => handleItem('remove')}
                  size={'small'}
                  className={'item-add-remove-icon'}
                  disabled={productCount <= 0}
               >
                  <RemoveIcon className={'add-remove-icon'} />
               </IconButton>
               <div className="item-count-frame">
                  <div className="item-count">{productCount}</div>
               </div>
               <IconButton
                  onClick={() => handleItem('add')}
                  size={'small'}
                  className={'item-add-remove-icon'}
                  disabled={isMaxItemReached()}
               >
                  <AddIcon className={'add-remove-icon'} />
               </IconButton>
            </div>
         </DialogActions>
      </>
   ) : null;
};

export default DetailedMenu;
