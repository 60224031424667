import React from 'react';
import { Button, CircularProgress } from '@mui/material';

interface FormButtonProps {
   label: string | React.ReactNode;
   onClick?: () => void;
   isLoading?: boolean;
   variant?: 'contained' | 'text' | 'outlined' | undefined;
}

const FormButton: React.FC<FormButtonProps> = ({ label, onClick, isLoading, variant = 'contained' }) => {
   return (
      <Button variant={variant} size="large" onClick={onClick} disabled={isLoading}>
         {isLoading ? <CircularProgress size={14} thickness={8} color="inherit" /> : label}
      </Button>
   );
};

export default FormButton;
