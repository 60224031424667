import React from 'react';
import { useAppSelector } from 'app/hooks';
import { Box, Button, Typography } from '@mui/material';
import { accountStore } from 'features/account/stores/account.slice';
import 'features/account/styles/Account.scss';
import ProfileHeader from './ProfileHeader';

const ReferralCode = () => {
   const { referralCode } = useAppSelector(accountStore);
   const [copied, setCopied] = React.useState(false);

   const handleCopy = () => {
      if (referralCode?.code) {
         navigator.clipboard.writeText(referralCode.code).then(() => {
            setCopied(true);
         });
      }
   };

   return (
      <>
         <ProfileHeader headerTitle="Your Referral Code" />
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Typography variant="inherit" gutterBottom mb={4}>
               {referralCode?.description}
            </Typography>
            <Typography variant="h5" gutterBottom mb={4}>
               {referralCode?.code}
            </Typography>
            <Button variant="contained" size="large" onClick={handleCopy}>
               {copied ? 'Copied!' : 'Copy'}
            </Button>
         </Box>
      </>
   );
};

export default ReferralCode;
