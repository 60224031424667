import React, { useEffect } from 'react';
import { Box, styled, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CartDrawer from 'features/cart/components/CartDrawer';
import Account from 'features/account/Account';
import { drawerWidth } from 'styles/custom-theme';
import Appbar from 'components/Appbar';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
   accountStore,
   resetLoginStatus,
   resetLogoutStatus,
   setIsLoggedIn,
} from 'features/account/stores/account.slice';
import { loadMPTSession } from 'utils/mpt-session.helper';
import { IMptSettings } from 'types/mpt-session.types';
import { LoadStatus } from 'config/utils';
import { fetchUserCartAsync, setCart, setOrderFrequency } from 'features/cart/stores/cart.slice';
import OrderDialog from 'features/order-bar/components/OrderDialog';
import { orderBarStore } from 'features/order/stores/order-bar.slice';
import { loadMptTheme } from 'utils/theme.helper';
import { handleUpdateOrderFrequency, setDeliverySlots } from 'features/order/config/order.helper';
import { store } from 'app/store';
import fastEqual from 'fast-deep-equal/es6';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
   open?: boolean;
}>(({ theme, open }) => ({
   flexGrow: 1,
   marginTop: '70px',
   padding: theme.spacing(3),
   transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
   }),
   marginRight: open ? -drawerWidth : '0',
   ...(open && {
      transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.easeOut,
         duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
   }),
   /**
    * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
    * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
    * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
    * proper interaction with the underlying content.
    */
   position: 'relative',
}));

export default function MainLayout() {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const location = useLocation();
   const theme = useTheme();
   const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));
   const { orderDialogOpen } = useAppSelector(orderBarStore);
   const { logoutStatus, loginStatus, gotInvalidAuthToken } = useAppSelector(accountStore);
   const [cartOpen, setCartOpen] = React.useState(false);
   const loadedMptSession = loadMPTSession();
   const pb = location?.pathname.startsWith('/order') ? 18 : 0;

   const mptTheme = React.useMemo(() => {
      return loadMptTheme(loadedMptSession);
   }, [loadedMptSession]);

   const isEqual = (obj1: any, obj2: any): boolean => {
      if (obj1 === obj2) return true;
      if (obj1 == null || obj2 == null) return false;
      if (obj1.constructor !== obj2.constructor) return false;
      if (Array.isArray(obj1)) {
         if (obj1.length !== obj2.length) return false;
         for (let i = 0; i < obj1.length; i++) {
            if (!isEqual(obj1[i], obj2[i])) return false;
         }
         return true;
      }
      if (typeof obj1 === 'object') {
         for (const key in obj1) {
            if (obj1.hasOwnProperty(key)) {
               if (!obj2.hasOwnProperty(key)) return false;
               if (!isEqual(obj1[key], obj2[key])) return false;
            }
         }
         for (const key in obj2) {
            if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) return false;
         }
         return true;
      }
      return false;
   };

   // Respond to the `storage` event
   const storageEventHandler = (event: any) => {
      const authTokenChangedAction = () => {
         dispatch(setIsLoggedIn((JSON.parse(event.newValue) as IMptSettings).authToken !== null));
      };

      const oldObject = JSON.parse(event.oldValue as string);
      const newObject = JSON.parse(event.newValue as string);

      for (const property in newObject) {
         if (!fastEqual(newObject[property], oldObject[property])) {
            const selectedDeliverySlot = store.getState().orderStore.selectedDeliverySlot;
            const deliveryOptions = store.getState().orderStore.deliveryOptions;
            const packageId = store.getState().packageStore.packageId;
            if (property === 'authToken') {
               authTokenChangedAction();
            }
            if (property === 'cart' && !fastEqual(oldObject[property].deliveries, newObject[property].deliveries)) {
               dispatch(setCart(newObject[property]));
               handleUpdateOrderFrequency(newObject[property], selectedDeliverySlot, true);
               setDeliverySlots(newObject[property], deliveryOptions, selectedDeliverySlot, packageId);
            }
            if (property === 'MPTOrderFrequency') {
               dispatch(setOrderFrequency({ orderRecurrence: newObject[property], dontUpdateSessionStorage: true }));
            }
         }
      }
   };

   useEffect(() => {
      // Hook up the event handler
      window.addEventListener('storage', storageEventHandler);
      return () => {
         // Remove the handler when the component unmounts
         window.removeEventListener('storage', storageEventHandler);
      };
   }, []);

   useEffect(() => {
      dispatch(setIsLoggedIn(loadedMptSession.authToken !== null));
   }, []);

   useEffect(() => {
      const headerWrapper = document.querySelector('.header-wrapper');
      if (headerWrapper instanceof HTMLElement) {
         headerWrapper.style.display = isLargeScreenDown ? 'none' : 'block';
      }
   }, [isLargeScreenDown]);

   useEffect(() => {
      if (gotInvalidAuthToken) {
         window.location.reload();
      }
   }, [gotInvalidAuthToken]);

   useEffect(() => {
      if (logoutStatus === LoadStatus.complete) {
         dispatch(resetLogoutStatus());
         location.pathname === '/order' ? navigate(0) : navigate('/order');
      }
   }, [logoutStatus]);

   useEffect(() => {
      if (loginStatus === LoadStatus.complete) {
         dispatch(resetLoginStatus());
         dispatch(fetchUserCartAsync());

         if (location?.hash && location.hash.startsWith('#resetpassword')) {
            navigate('/order');
         }
      }
   }, [loginStatus]);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [location.pathname]);

   const handleCartToggle = () => {
      setCartOpen((prevState) => !prevState);
   };

   const handleCartClose = () => {
      setCartOpen(false);
   };

   return (
      <ThemeProvider theme={mptTheme}>
         <Box display="inline">
            <Appbar {...{ handleCartToggle }} />
            <Main sx={{ p: 0, m: 0, backgroundColor: mptTheme.palette.background.default }} open={cartOpen}>
               <Box
                  component="main"
                  sx={{ flexGrow: 1, p: 0, backgroundColor: mptTheme.palette.background.default, pb: pb }}
               >
                  <Outlet />
               </Box>
            </Main>
            <CartDrawer {...{ cartOpen, handleCartClose }} />
            {orderDialogOpen && <OrderDialog />}
            <Account />
         </Box>
      </ThemeProvider>
   );
}
