import React, { useState } from 'react';
import { Box } from '@mui/material';
import '../styles/Account.scss';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { Page } from '../config/account-form.const';
import '../styles/Account.scss';
import FormInput from 'components/FormInput';
import Grid from '@mui/material/Grid';
import { MptLink } from 'components/MptLink';

const LogInFields = (props: any) => {
   const [showPassword, setShowPassword] = useState(false);

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   return (
      <Box sx={{ p: 0 }}>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               <FormInput name="email" label="Email" autoComplete="email" />
            </Grid>
            <Grid item xs={12}>
               <FormInput
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                     endAdornment: (
                        <InputAdornment position="end">
                           <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                           >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                           </IconButton>
                        </InputAdornment>
                     ),
                  }}
               />
            </Grid>
         </Grid>
         <Box mt={2}>
            <MptLink onClick={() => props.handlePageSwitch(Page.PASSWORDRESET)} fontWeight="700">
               Reset Password
            </MptLink>
         </Box>
      </Box>
   );
};

export default LogInFields;
